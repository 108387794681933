import { Button, message, Radio } from "antd";
import React, { useState, useEffect } from "react";
import './index.css'
import Request from "../../../../../utils/Request";
import { EHostType } from "../../../../../utils/Request";
interface props {
    detailInfo:any,
    taskType:number|string
}
const TaskIsShow:React.FC<props> = (props) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        if(props.detailInfo.isShow == 1){
            setValue(props.detailInfo.isShow)
        }
        // console.log(value,'value')
        // console.log(props,'')
    },[])
    const onChange = (e) => {
        setValue(e.target.value)
    }
    const submit = () => {
        let params = {
            taskId:props.detailInfo.id,
            taskType:props.taskType,
            isShow:value
        }
        Request.jsonPost(EHostType.DDlk,'/mgr/ddlk/excellentTask/addExcellentTask',params).then(res => {
            if(res.code == 0){
                message.open({
                    type: 'success',
                    content: '提交成功',
                  });
            }
        })
    }
    return <div>
        <div>选择是，此案例会在优秀案例中展示，选择否此案例不会在案例中展示</div>
        <div>
            <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>是</Radio>
                <Radio className="radio" value={0}>否</Radio>
            </Radio.Group>
        </div>
        <div className="submit">
            <Button onClick={submit} type="primary">确定提交</Button>
        </div>
    </div>
}

export default TaskIsShow