import { Button, Table, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

interface IProps {
    onCheckSubStore: boolean,
    details:{
        subStore:string[]
    }
}

export const SubStore = (props: IProps) => {
    const [onCheckSubStore, setOnCheckSubStore] = useState<boolean>(false);

    return (
        <div>
            <Button type="link"   onClick={()=>{setOnCheckSubStore(true)}}>查看子门店</Button>
            <Modal title="门店列表" footer={null} width={600} visible={onCheckSubStore} onCancel={()=>{ setOnCheckSubStore(false) }} destroyOnClose>
                <div>共有{props?.details?.subStore.length}家门店参与</div>
                <div style={{margin:"24px 0"}}>
                    {props?.details?.subStore?.map((item) => {
                        return (
                            <div>{item.split(",")[1]}</div>
                        )
                    })}
                </div>
            </Modal>
        </div>
    )
}
