import * as Icon from "@ant-design/icons";
import {message, Modal, Space, Table, Typography} from "antd";
import React, {Component} from "react";
import Request, {EHostType} from "../../../../utils/Request";
import {EnumBtn} from "../index";

export default class TableCom extends Component<
  {
    articleTitle: string;
    onModal: (title: string, record: any) => void;
    onCancel: () => void;
  },
  {}
> {
  state = {
    loading: false,
    pageSize: 10,
    pageNo: 1,
    total: 0,
    data: [],
    tableHeight: 300,
  };
  getTableData: (item?: any) => void = (item) => {
    let url = `/mgr/ddlk/article/queryAllArticle`;
    this.setState({
      loading: true,
      pageNo: item || this.state.pageNo,
    });
    Request.jsonPost(EHostType.DDlk,url, {
      pageSize: this.state.pageSize,
      pageNo: item ? item : this.state.pageNo,
      articleTitle: this.props.articleTitle,
    })
      .then((res) => {
        if (!res.error) {
          this.setState({
            data: res.data,
            total: res.totalCount,
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "标题",
      dataIndex: "articleTitle",
      key: "articleTitle",
    },
    {
      title: "所属分类",
      dataIndex: "articleFor",
      key: "articleFor",
      render:function(text, record, index) {
        return text == 0?"商家端":"达人端";
      }
    },
    {
      title: "点击量",
      dataIndex: "pv",
      key: "pv",
    },
    {
      title: "添加人",
      dataIndex: "operatorName",
      key: "operatorName",
    },
    {
      title: "添加时间",
      dataIndex: "createdTime",
      key: "createdTime",
    },
    {
      title: "操作",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Typography.Link onClick={this.openModal.bind(null, record)}>
            编辑
          </Typography.Link>
          <Typography.Link onClick={this.delArt.bind(null, record)}>
            删除
          </Typography.Link>
        </Space>
      ),
    },
  ];

  data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: ["nice", "developer"],
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: ["loser"],
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sidney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
  ];
  openModal: (record: any) => void = (record: any) => {
    this.props.onModal(EnumBtn.edit, record);
  };
  delArt: (record: any) => void = (record) => {
    const _this = this;
    Modal.confirm({
      title: "是否确认删除",
      icon: <Icon.ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认删除</p>,
      async onOk() {
        let host: any = sessionStorage.getItem("host");
        let url = `/mgr/ddlk/article/${record.id}`;
        let res = await Request.delete(EHostType.DDlk,url);
        if (!res.error) {
          _this.getTableData();
          // _this.getData({ size: 10, current: 1 });
          message.success("删除成功");
        }
      },
    });
  };
  componentDidMount() {
    this.getTableData();
    this.tableAdapt();
  }

  /**表格高度自适应 */
  tableAdapt = () => {
    let search: any =
      document.getElementsByClassName("search-container")[0].clientHeight + 265;
    this.setState({ tableHeight: search });
  };
  // componentDidUpdate() {
  //   this.getTableData();
  // }
  render() {
    return (
      <Table
        columns={this.columns}
        dataSource={this.state.data}
        scroll={{ y: `calc(100vh - ${this.state.tableHeight}px)` }}
        loading={this.state.loading}
        rowKey="id"
        pagination={{
          pageSize: this.state.pageSize,
          current: this.state.pageNo,
          total: this.state.total,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: this.getTableData,
        }}
      />
    );
  }
}
