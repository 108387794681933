/*
 * @Date: 2022-04-12 15:49:47
 * @LastEditors: litj
 * @LastEditTime: 2022-04-30 10:05:39
 * @FilePath: \算法c:\Users\Admin\Desktop\新建文件夹 (2)\bkm-admin\src\module\page\DDLK\ArticalMange\index.tsx
 */
import React, { Component, ReactElement } from "react";
import Search from "./Search";
import TopContainer from "./TopContainer";
import BottomContainer from "./BottomContainer";
import TableCom from "./TableCom";
import { message, Modal } from "antd";
import Add from "./Add";
import * as Icon from "@ant-design/icons";

interface IButton {
  text: string;
  icon?: any;
  onClick: () => void;
}
type State = {
  modelTitle: string;
  visible: boolean;
  modalChildren: ReactElement | any;
};

export enum EnumBtn {
  add = "添加文章",
  edit = "编辑文章",
  delete = "删除",
}

export default class ArticalMange extends Component {
  state: State = {
    visible: false,
    modelTitle: "",
    modalChildren: "",
  };
  tableRef = React.createRef<TableCom>();
  articleTitle: string = "";
  private id = null;
  listContent: (title: string, rowData?: any) => void = (
    title: string,
    rowData
  ) => {
    const modalMap = {
      [EnumBtn.add]: (
        <Add
          onCancel={this.modalCancel}
          getTableData={this.getTableData}
          onRefresh={this.onRefresh}
        />
      ),
      [EnumBtn.edit]: (
        <Add
          onCancel={this.modalCancel}
          id={this.id || undefined}
          getTableData={this.getTableData}
          onRefresh={this.onRefresh}
          rowData={rowData}
        />
      ),
    };
    return modalMap[title];
  };
  onRefresh: () => void = () => {
    this.setState({});
    this.tableRef.current!.getTableData(1);
  };
  getTableData: () => void = () => { };
  modalOpen: (title: string, record: any) => void = (title, record) => {
    this.setState({
      visible: true,
      modelTitle: title,
      modalChildren: this.listContent(title, record),
    });
  };
  modalCancel = () => {
    this.setState({
      visible: false,
    });
  };
  searchFinish = (params) => {
    console.log(111, params);
    this.articleTitle = params!.articleTitle;
    this.onRefresh();
    // this.setState({});
  };

  /**删除 */
  delUser = () => {
    // let _this = this;
    Modal.confirm({
      title: "是否确认删除",
      icon: <Icon.ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认删除</p>,
      async onOk() {
        // let host:any=sessionStorage.getItem("host");
        // let url = `${JSON.parse(host)?.xmgx}/mgr/activity/topic/${_this.state.rowData.id}`;
        // let ret = await Request.delete(url);
        // if (ret.success) {
        //   // _this.getData({ size: 10, current: 1 });
        //   message.success("删除成功");
        // } else {
        //   message.error("删除失败");
        // }
      },
    });
  };
  render() {
    const btns: IButton[] = [
      {
        text: EnumBtn.add,
        onClick: () => {
          console.log("add");
          this.modalOpen(EnumBtn.add, "");
        },
      },
    ];
    return (
      <div className="container">
        <TopContainer>
            <Search onFinish={this.searchFinish}></Search>
          </TopContainer>
          <BottomContainer btns={btns}>
            <TableCom
              ref={this.tableRef}
              articleTitle={this.articleTitle}
              onModal={(title: string, record) => {
                this.modalOpen(title, record);
              }}
              onCancel={this.modalCancel}
            ></TableCom>
          </BottomContainer>
          <Modal
            destroyOnClose={true}
            title={this.state.modelTitle}
            centered={true}
            visible={this.state.visible}
            maskClosable={false}
            width="800px"
            className="from-modal"
            onCancel={this.modalCancel}
            footer={null}
          >
            {this.state.modalChildren}
          </Modal>
      </div>
    );
  }
}
