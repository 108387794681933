import {Button, Modal, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import Request, {EHostType} from '../../../../../utils/Request';

interface IProps {
    onOpenRechargeDetail: boolean,
    id:string,
}

export const RechargeTable = (props: IProps) => {

    const columns:any = [
        {
            title: '充值时间',
            dataIndex: 'rechargeTime',
            key: 'rechargeTime',
            align:'center'
        },
        {
            title: '充值奖金池金额(元)',
            dataIndex: 'rewardPoolAdding',
            key: 'rewardPoolAdding',
            align:'center'
        },
        {
            title: '服务费金额(元)',
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            align:'center'
        },
        {
            title: '累计应付金额(元)',
            dataIndex: 'totalFee',
            key: 'totalFee',
            align:'center'
        },
        {
            title: '支付单号',
            dataIndex: 'payorderId',
            key: 'payorderId',
            align: 'center'
        }
    ];
    const [dataSource, setDataSource] = useState([]);
    const [onOpenRechargeDetail, setOnOpenRechargeDetail] = useState(false);

    useEffect(()=>{
        if(props.id){
            getList(props.id)
        }
    },[props.id]);

    const getList = async (params:any) => {
        let host:any=sessionStorage.getItem("host");
        let url = `/mgr/ddlk/consumertask/getReChargeRecords?id=${props.id}`;
        Request.jsonPost(EHostType.DDlk, url, {}).then(res => {
            if (res.data) {
                setDataSource(res?.data?.map(item => {
                    return {
                        ...item,
                        rewardPoolAdding: item.rewardPoolAdding?`${item.rewardPoolAdding/100}`:0,
                        serviceFee: item.serviceFee?`${item.serviceFee/100}`:0,
                        totalFee: item.totalFee?`${item.totalFee/100}`:0
                    }
                }));
            }
        })
    }

    return (
        <div>
            <Button type="primary" onClick={()=>{setOnOpenRechargeDetail(true)}}>明细</Button>
            <Modal title="充值明细" footer={null} width={1000} visible={onOpenRechargeDetail} onCancel={()=>{ setOnOpenRechargeDetail(false) }} destroyOnClose>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{ y: 620 }}
                    pagination={false}
                />
            </Modal>
        </div>
    )
}
