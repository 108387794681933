/*
 * @Date: 2022-04-29 21:58:51
 * @LastEditors: litj
 * @LastEditTime: 2022-04-30 12:13:11
 * @FilePath: \算法c:\Users\Admin\Desktop\新建文件夹 (2)\bkm-admin\src\module\page\DDLK\PromotionMange\index.tsx
 */
import { Tabs } from "antd";
import React, {useEffect, useState} from "react";
import { CloudPromotion } from "./Cloud";
import { ConsumerPromotion } from "./Consumer";
import { TalentPromotion } from "./Talent";
import { EmployeePromotion } from "./Employee";
import Tab from "../../componets/Tab";
export const PromotionMange = () => {
      return (
        <div>
            <Tab Cloud={CloudPromotion} Consumer={ConsumerPromotion} Tanlent={TalentPromotion} Employee={EmployeePromotion} ></Tab>
        </div>
    );
};
