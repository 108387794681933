import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd'
import { resolve } from 'dns';
const { TabPane } = Tabs

interface Components {
  Cloud,
  Consumer,
  Tanlent,
  Employee
}

const Tab = (props: Components) => {
  const [sellerDto, setSellerDto] = useState(JSON.parse(sessionStorage.getItem('ddlkPlatformSellerDTO') || '0'));
  const [activeKey, setActiveKey] = useState<string>("3");
  const { Cloud, Employee, Consumer, Tanlent } = props;

  return (
    <div>
      <Tabs type='card' activeKey={activeKey} onChange={(activeKey: string) => {console.log(activeKey,'parentKey');  setActiveKey(activeKey)}} >
        <TabPane tab={`${sellerDto.cloudVisitShopCustNamePrefix}${sellerDto.cloudVisitShopCustNameSuffix}`} key="3">
          <Cloud    darenType={[1, 2]} />
        </TabPane>
        <TabPane tab={`${sellerDto.employeeTaskCustNamePrefix}${sellerDto.employeeTaskCustNameSuffix}`} key="2">
          <Employee   tab={activeKey} />
        </TabPane>
        <TabPane tab="成片分发" key="5">
          <Cloud   darenType={[3]} />
        </TabPane>
        <TabPane tab={`${sellerDto.customerTaskCustNamePrefix}${sellerDto.customerTaskCustNameSuffix}`} key="1">
          <Consumer  tab={activeKey}/>
        </TabPane>
        <TabPane tab={`${sellerDto.visitShopCustNamePrefix}${sellerDto.visitShopCustNameSuffix}`} key="4">
            <Tanlent   tab={activeKey}/>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Tab