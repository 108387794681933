/*
 * @Date: 2022-04-29 21:58:51
 * @LastEditors: litj
 * @LastEditTime: 2022-07-19 20:32:06
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\PromotionMange\Cloud\index.tsx
 */
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { TaskListDetail } from "./detail";
import { TaskListFilter } from "./filter";
import { TaskListTable } from "./table";
import { UserContext } from "../../../../root/MainFrame";
import SubmitModal from "../components/submitModal";
import { message, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
// import Request from "../../../../utils/SellerRequest";
import Request, { EHostType } from "../../../../utils/Request";
export const CloudPromotion = (props) => {
  const [currenItem, setCurrentItem] = useState();
  const darenTypeQueryParamsObj = { 'darenType': props.darenType };
  const [searchParams, setSearchParams] = useState(darenTypeQueryParamsObj);
  //debugger;
  const [tabActive, setTabActive] = useState("1");
  const [isShareList, setIsShareList] = useState<boolean | undefined>(true);
  const [isDetail, setIsDetail] = useState<boolean | undefined>(true);
  const [isLimits, setIsLimits] = useState<boolean | undefined>(true);
  const [isCustom, setIsCustom] = useState<boolean | undefined>(true);
  const [isTalentList, setIsTalentList] = useState<boolean | undefined>(true);
  const [taskInfo,setTaskInfo] = useState("");
  const table: MutableRefObject<any> = useRef(null)
  const [queryType, setQueryType] = useState("")
  const [isShow, setIsShow] = useState(false);
  const [detailId, setDetailId] = useState("");
  const [flag, setFlag] = useState(false);
  const getQueryType = (key) => {
    setQueryType(key);
  }
  const isShowModal = (res, i, item?) => {
    if (item) {
      let infoData = JSON.parse(item);
      
      setTaskInfo(infoData);
      if (!infoData.isAppShow || res === true) {
        setIsShow(res)
      } else {
        Modal.confirm({
          title: '提示',
          icon: <ExclamationCircleFilled />,
          content: '确认取消在商家端首页展示吗?',
          centered: true,
          onOk() {
            let params = {
              id: infoData.id,
              orderNo: infoData.orderNo,
              adviserSellerDesc: infoData.adviserSellerDesc,
              saleVolume: infoData.saleVolume,
              saleNo: infoData.saleNo,
              adviserUserShowUrl: infoData.adviserUserShowUrl,
              topProduct: infoData.topProduct,
              videoMaterial: infoData.videoMaterial,
              drainagesProduct: infoData.drainagesProduct,
              mainProduct: infoData.mainProduct,
              profitProduct: infoData.profitProduct,
              summary: infoData.summary,
              logo:infoData.logo,
              isAppShow: 0
            }
            let url = `/mgr/ddlk/excellentTask/updateExcellentTaskDetail`;
            // props.isShowModal(false)
            Request.jsonPost(EHostType.DDlk, url, params).then(res => {
              if (res.code == 0) {
                message.open({
                  type: 'success',
                  content: '提交成功',
                });
                setFlag(true)
              }
              setFlag(false)
            })
          },
          onCancel() { },
        });
      }
    }else{
      setIsShow(res);
    }
    if (i) {
      setDetailId(i)
    }
  }
  const reGetList = (res) => {
    setFlag(true)
    setFlag(false)
  }
  useEffect(() => {
    setIsShareList(sessionStorage.getItem("functionLimits")?.includes("a12"));
    setIsDetail(sessionStorage.getItem("functionLimits")?.includes("a11"));
    setIsTalentList(sessionStorage.getItem("functionLimits")?.includes("a13"));
    setIsLimits(sessionStorage.getItem("functionLimits")?.includes("a8"))
    setIsCustom(sessionStorage.getItem("functionLimits")?.includes("a6"))
  })

  return (
    <div>
      <UserContext.Consumer>
        {
          (menuType) => (

            <div>
              {
                !menuType ? <TaskListFilter
                  tab={props.tab}
                  queryType={queryType}
                  onSearch={(item: any) => {
                    setSearchParams({ ...item, ...darenTypeQueryParamsObj });
                  }}
                /> : ""
              }

              <TaskListTable
                flag={flag}
                menuType={menuType}
                isDetail={isDetail}
                isCustom={isCustom}
                tab={props.tab}
                searchParams={searchParams}
                getQueryType={getQueryType}
                onOpenDetail={(item: any, active: string) => {
                  setCurrentItem(item);
                  setTabActive(active);
                }}
                isShowModal={isShowModal}
              />
              <TaskListDetail
                menuType={menuType}
                queryType={queryType}
                isLimits={isLimits}
                isDetail={isDetail}
                isTalentList={isTalentList}
                isShareList={isShareList}
                currenItem={currenItem}
                tabActive={tabActive}
                onClear={() => {
                  setCurrentItem(undefined);
                }}
              />
              <SubmitModal isShowModal={isShowModal} id={detailId} taskInfo={taskInfo} reGetList={reGetList} isShow={isShow} />

            </div>
          )
        }
      </UserContext.Consumer>

    </div>
  );
};
