/*
 * @Date: 2022-04-29 21:58:51
 * @LastEditors: litj
 * @LastEditTime: 2022-04-30 12:13:11
 * @FilePath: \算法c:\Users\Admin\Desktop\新建文件夹 (2)\bkm-admin\src\module\page\DDLK\PromotionMange\index.tsx
 */
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { CloudPromotion } from "../DDLK/PromotionMange/Cloud";
import { ConsumerPromotion } from "../DDLK/PromotionMange/Consumer";
import { TalentPromotion } from "../DDLK/PromotionMange/Talent";
import { EmployeePromotion } from "../DDLK/PromotionMange/Employee";
import Tab from '../componets/Tab'
import { UserContext } from "../../root/MainFrame";
const { TabPane } = Tabs;

const GoodTaskExamples = () => {
    return (
        <div>
            <Tab Cloud={CloudPromotion} Consumer={ConsumerPromotion} Tanlent={TalentPromotion} Employee={EmployeePromotion} ></Tab>
        </div>
    );
};

export default GoodTaskExamples
