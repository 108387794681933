/*
 * @Date: 2022-04-11 14:41:03
 * @LastEditors: litj
 * @LastEditTime: 2022-10-31 19:52:55
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/module/page/VideoStatistics/filter/index.tsx
 */
import * as React from "react";
import "../index.css";
import {
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Card,
  Button,
  Select,
  Modal,
  message,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import { FormInstance } from "antd/es/form/hooks/useForm";
import Request, { EHostType } from "../../../utils/Request";

const { RangePicker } = DatePicker;
const { Option } = Select;
let timeArr: [string, string] | string;
interface IProps {
  onSearch?: any;
  downloadRecords?: any;
  tab: string;
}

export const TaskListFilter = (props: IProps) => {
  // @ts-ignore
  // @ts-ignore
  const taskTypeMap = {
    1: "远程探店",
    2: "消费者活动",
    3: "达人探店",
    4: "员工推广",
  };
  const formRef = React.createRef<FormInstance>();
  const [oemState, setoemState] = useState(false);
  useEffect(() => {
    let url = "/mgr/ddlk/oemVideoBill/displayOemSearch";
    Request.get(EHostType.DDlk, url, {}).then((res) => {
      // console.log(res, "jljljl");
      setoemState(res.data);
    });
  }, []);

  const onChange = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    timeArr = dateString;
  };
  return (
    <div>
      <Card className={"taskListFilterWrap " + "search-container" + props.tab}>
        <Form
          onFinish={(values) => {
            let param = { ...values };
            if (timeArr && timeArr.length) {
              param.billTimeFrom = timeArr[0];
              param.billTimeTo = timeArr[1];
            }
            props.onSearch(param);
          }}
          ref={formRef}
        >
          <Row gutter={24}>
            <Col span={5} style={{ display: oemState ? "flex" : "none" }}>
              <Form.Item label="OEM名称" name="oemOperatingEntityName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="时间">
                <RangePicker format="YYYY-MM-DD" onChange={onChange} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="类型" name="taskType">
                <Select allowClear>
                  <Select.Option value="1">远程探店</Select.Option>
                  <Select.Option value="2">消费者活动</Select.Option>
                  <Select.Option value="3">达人探店</Select.Option>
                  <Select.Option value="4">员工推广</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  let values = formRef.current!.getFieldsValue();
                  let param = { ...values };
                  if (timeArr && timeArr.length) {
                    param.billTimeFrom = timeArr[0];
                    param.billTimeTo = timeArr[1];
                  }
                  props.downloadRecords(param);
                }}
              >
                下载
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};
