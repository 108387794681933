import React, { useEffect, useState } from "react";
import { TaskListDetail } from "./detail";
import { TaskListFilter } from "./filter";
import { TaskListTable } from "./table";
import type { UploadProps } from "antd";
import { message, Modal, Tabs, Upload } from "antd";
import { ExclamationCircleFilled, InboxOutlined } from "@ant-design/icons";
import Request, { EHostType } from "../../../../utils/Request";
import { UserContext } from "../../../../root/MainFrame";
import SubmitModal from "../components/submitModal";
const { TabPane } = Tabs;
const { Dragger } = Upload;

export const TalentPromotion = (props) => {
  const [currenItem, setCurrentItem] = useState();
  const [searchParams, setSearchParams] = useState({});
  const [tabActive, setTabActive] = useState("1");
  const [tabTaskActive, setTabTaskActive] = useState("1");

  const onTabsChange = (key: any) => {
    setTabTaskActive(key);
  };
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [qrcode, setQrcode] = useState<any>([]);
  const [newFileList, setNewFileList] = useState<any>([]);
  const [num, setNum] = useState<number>(0);
  const [isLimits, setIsLimits] = useState<boolean | undefined>(true);
  const [taskAudit, setTaskAudit] = useState<boolean | undefined>(true);
  const [worksAudit, setWorksAudit] = useState<boolean | undefined>(true);
  const [isDetail, setIsDetail] = useState<boolean | undefined>(true);
  const [isCustom, setIsCustom] = useState<boolean | undefined>(true);
  const [queryType,setQueryType] = useState("");
  const [isShow,setIsShow] = useState(false);
  const [detailId,setDetailId] = useState("");
  const [taskInfo,setTaskInfo] = useState("");
  const [flag, setFlag] = useState(false);
  const getQueryType = (key) =>{ 
    setQueryType(key);
  };
  const isShowModal = (res, i, item?) => {
    if (item) {
      let infoData = JSON.parse(item);
      setTaskInfo(infoData);
      if (!infoData.isAppShow || res === true) {
        setIsShow(res)
      } else {
        Modal.confirm({
          title: '提示',
          icon: <ExclamationCircleFilled />,
          content: '确认取消在商家端首页展示吗?',
          centered: true,
          onOk() {
            let params = {
              id: infoData.id,
              orderNo: infoData.orderNo,
              adviserSellerDesc: infoData.adviserSellerDesc,
              saleVolume: infoData.saleVolume,
              saleNo: infoData.saleNo,
              adviserUserShowUrl: infoData.adviserUserShowUrl,
              topProduct: infoData.topProduct,
              videoMaterial: infoData.videoMaterial,
              drainagesProduct: infoData.drainagesProduct,
              mainProduct: infoData.mainProduct,
              profitProduct: infoData.profitProduct,
              summary: infoData.summary,
              logo: infoData.logo,
              isAppShow: 0
            }
            let url = `/mgr/ddlk/excellentTask/updateExcellentTaskDetail`;
            // props.isShowModal(false)
            Request.jsonPost(EHostType.DDlk, url, params).then(res => {
              if (res.code == 0) {
                message.open({
                  type: 'success',
                  content: '提交成功',
                });
                setFlag(true)
              }
              setFlag(false)
            })
          },
          onCancel() { },
        });
      }
    }else{
      setIsShow(res);
    }
    if (i) {
      setDetailId(i)
    }
  }
  const reGetList = (res) => {
    setFlag(true)
    setFlag(false)
  }

  useEffect(() => {
    getAvailableWxCode();
    setIsLimits(sessionStorage.getItem("functionLimits")?.includes("a7"));
    setTaskAudit(sessionStorage.getItem("functionLimits")?.includes("a5"));
    setWorksAudit(sessionStorage.getItem("functionLimits")?.includes("a8"));
    setIsDetail(sessionStorage.getItem("functionLimits")?.includes("a11"));
    setIsCustom(sessionStorage.getItem("functionLimits")?.includes("a6"))
  }, [showUpload]);

  let arr: any[] = [];
  let host: any = sessionStorage.getItem("host");

  const getAvailableWxCode: any = async () => {
    Request.get(EHostType.DDlk, `/mgr/ddlk/getAvailableWxCode`, {}).then((result) => {
      if (result.code == 0) {
        setNum(result.data);
        setNewFileList([]);
      }
    });
  };
  const uploadProps: UploadProps = {
    accept: "image/*",
    listType: "picture-card",
    multiple: true,
    showUploadList: true,
    fileList: newFileList,
    beforeUpload: (file, fileList) => {
      // var blob = new Blob([file], { type: "application/octet-stream" });
      return new Promise((resolve, reject) => {
        Request.get(EHostType.DDlk, `/mgr/ddlk/getOssTemporaryAuthorizationUrl?fileName=${file.name}`
        ).then((res) => {
          let ossUrl;
          if (res.code === 0) {
            arr.push({
              uid: file.uid,
              url: `${res.data.key.filePrefix}${res.data.key.filePath}`,
            });
            ossUrl = res.data.key.filePath;
            let filePrefix = res.data.key.filePrefix;
            let result: any = [
              { status: "uploading", uid: file.uid, percent: 0 },
            ];

            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = () => {
              Request.putOss(
                EHostType.NONE,
                res.data.value.url,
                reader.result,
                res.data.value.contentType,
                (percent) => {
                  // result[result.length - 1].percent = percent;
                  // if (percent === 100) {
                  //     setTimeout(() => {
                  //         result[result.length - 1].path = res.data.key.filePath;
                  //         result[result.length - 1].filePrefix = res.data.key.filePrefix;
                  //         result[result.length - 1].status = "done";
                  //     }, 1500);
                  // }
                }
              )
                .then((resp) => {
                  setNewFileList([...newFileList, ...arr]);
                  let temp = [...newFileList, ...arr].map((item) => item.url);
                  setQrcode([...temp]); //字符串数组传给redis
                })
                .catch((err) => {
                  message.error("上传失败");
                });
            };
          }
        });
      });
      //   setInfo([...result]);
    },
  };

  const doSubmit = () => {
    if (newFileList.length > 0) {
      Request.jsonPost(EHostType.DDlk, `/mgr/ddlk/saveBatchWxCodeUrl`,
        qrcode
      ).then((result) => {
        if (result.code == 0) {
          setShowUpload(false);
          message.success(`已上传成功${newFileList.length}张`);
        }
      });
    } else {
      setShowUpload(false);
    }
  };

  return (
    <div>
      <UserContext.Consumer>
        {
          (menuType) => (
            <div>
              {
                !menuType ? <TaskListFilter
                  tab={props.tab}
                  isLimits={isLimits}
                  queryType={queryType}
                  onSearch={(item: any) => {
                    setSearchParams(item);
                  }}
                  onShow={(e: any) => {
                    setShowUpload(true);
                  }}
                /> : ""
              }
              <TaskListTable
                flag={flag}
                menuType={menuType}
                isLimits={taskAudit}
                isDetail={isDetail}
                isCustom={isCustom}
                tab={props.tab}
                getQueryType={getQueryType}
                searchParams={searchParams}
                onOpenDetail={(item: any, active: string) => {
                  setCurrentItem(item);
                  setTabActive(active);
                }}
                isShowModal={isShowModal}
              />

              <TaskListDetail
                menuType={menuType}
                queryType={queryType}
                isLimits={worksAudit}
                currenItem={currenItem}
                tabActive={tabActive}
                onClear={() => {
                  setCurrentItem(undefined);
                }}
              />

              {/*批量上传二维码*/}
              <Modal
                visible={showUpload}
                onCancel={() => {
                  setShowUpload(false);
                }}
                title="批量上传探店沟通群二维码"
                destroyOnClose
                onOk={doSubmit}
              >
                <p>可用二维码：{num}个</p>
                <Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">支持单张或多张图片导入</p>
                </Dragger>
              </Modal>
              <SubmitModal isShowModal={isShowModal} id={detailId} taskInfo={taskInfo} reGetList={reGetList} isShow={isShow} />
            </div>
          )
        }
      </UserContext.Consumer>

    </div>
  );
};
