import { Table, Tabs } from "antd";
import { TabPane } from "rc-tabs";
import React, { useCallback, useEffect, useState } from "react";
import Request, {EHostType} from "../../utils/Request";
import "./index.css";
export default function TeamRelation(props: any) {
  const columnsData: any = [
    {
      title: "用户ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "昵称",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "phone",
      align: "center",
    },
    {
      title: "注册订单号",
      dataIndex: "orderId",
      key: "orderId",
      width: 120,
      align: "center",
    },
    {
      title: "价格",
      dataIndex: "orderPrice",
      key: "orderPrice",
      width: 120,
      align: "center",
    },
    {
      title: "邀请代理商ID",
      dataIndex: "invitedByAgentId",
      key: "invitedByAgentId",
      width: 120,
      align: "center",
    },
    {
      title: "邀请代理商名称",
      dataIndex: "invitedByAgentName",
      key: "invitedByAgentName",
      width: 120,
      align: "center",
    },
    {
      title: "推广注册时间",
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
    },
  ];
  const tabChange = useCallback((key) => {
    setTab(key);
  }, []);
  const [dataSource, setDataSource] = useState([{ name: "1", id: 1 }]);
  //   const [columns, setColumns] = useState(columnsData);
  const [tab, setTab] = useState("1");

  const [height, setHeight] = useState(300);
  useEffect(() => {
    Request.jsonPost(EHostType.XMGX,`/op/agent/getInvitedAgent?agentId=${props.id}&direct=${tab}`, {}).then((res) => {
      if (!res.error) {
        setDataSource(res.data);
      }
    });
  }, [tab, props.id]);

  useEffect(() => {
    const tableHeight = document.getElementsByClassName("tab-container")[0].clientHeight;
    setHeight(tableHeight);
  }, []);

  return (
    <div className="team-container">
      <h5>关联关系</h5>
      <p>
        城市运营商： 用户ID: {props.parentAgentId} - 昵称：
        {props.parentAgentName}
      </p>
      <p>
        推荐人：用户ID:{props.invitedByAgentId}-昵称：{props.invitedByAgentName}
      </p>
      <Tabs defaultActiveKey="1" onChange={tabChange}>
        <TabPane tab="直接推荐" key="1"></TabPane>
        <TabPane tab="间接推荐" key="0"></TabPane>
      </Tabs>
      <div className="tab-container">
        <Table
          columns={columnsData}
          dataSource={dataSource}
          rowKey={(record) => Math.random()}
          className="virtual-table"
          pagination={false}
          scroll={{
            y: `${500}px)`,
          }}
        />
      </div>
    </div>
  );
}
