import * as React from "react";
import {Button, DatePicker, Form, Image, Input, message, Modal, Radio, Upload,} from "antd";
import "./index.css";
import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import reqwest from "reqwest";
import moment from "moment";
import Request, {EHostType} from "../../utils/Request";

interface page {
  fileList: any;
  uploading: boolean;
}

export default class AgentAdd extends React.Component<{ parent?: any }, page> {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
    };
  }

  onFinish = (value) => {
    for (let key in value) {
      if (value[key] === undefined || value[key] === "") delete value[key];
    }
    value["expiredTime"] = moment(value.expiredTime).format("YYYY-MM-DD");
    value["id"] = this.props.parent.state.rowData.id;
    const formData = new FormData();
    if (this.state.fileList.length !== 0) {
      this.state.fileList.forEach((file) => {
        formData.append("businessLicense", file);
      });
    }
    for (let key in value) {
      if (value[key] === undefined || value[key] === "") {
        delete value[key];
      } else {
        formData.append(key, value[key]);
      }
    }
    let _this = this;
    Modal.confirm({
      title: "是否确认提交",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认提交？</p>,
      async onOk() {
        _this.setState({ uploading: true });
        let host: any = sessionStorage.getItem("host");
        // let url =`${JSON.parse(host)?.xmgx}/op/agent/saveOrUpdate`;
        // let ret = await Request.post(url, Utils.recordToForm(value));
        // Request.jsonPost(url,{
        //     ...formData
        // }).then(res=>{
        //     if (ret.code==0) {
        //                     _this.props.parent.visiblePop(false);
        //                     _this.props.parent.getData({ size: 10, current: 1 });
        //                     message.success('提交成功');
        //                     _this.props.parent.setState({ loadings: false });
        //                     _this.setState({ uploading: false });
        //                 }
        //     // setVisible(false)
        // })
        reqwest({
          url: `${JSON.parse(host)?.xmgx}/op/agent/saveOrUpdate`,
          method: "post",
          processData: false,
          data: formData,
          confirmLoading: _this.state.uploading,
          headers: { Authorization: sessionStorage.getItem("token") },
          success: (ret) => {
            if (ret.code == 0) {
              _this.props.parent.visiblePop(false);
              _this.props.parent.getData({ size: 10, current: 1 });
              message.success("提交成功");
              _this.props.parent.setState({ loadings: false });
              _this.setState({ uploading: false });
            }
          },
        });
      },
    });
  };

  /**密码重置 */
  resetPassword = () => {
    let _this = this;
    Modal.confirm({
      title: "密码重置提示",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认重置密码？</p>,
      async onOk() {
        let url = `/op/agent/password/rest/${_this.props.parent.state.rowData.id}`;
        let ret = await Request.post(EHostType.XMGX,url, "");
        if (ret.success) {
          message.success("重置成功");
        }
      },
    });
  };

  public render() {
    const { fileList } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    return (
      <Form
        name="form"
        className="form-container"
        initialValues={this.props.parent.state.rowData}
        onFinish={this.onFinish}
      >
        <div className="form-content">
          <Form.Item
            label="代理商姓名"
            className="form-item ant-col-23"
            name="name"
            rules={[{ required: true, message: "负责人姓名不能为空！" }]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="代理商手机号码"
            className="form-item ant-col-23"
            name="phone"
            rules={[{ required: true, message: "代理商手机号码不能为空！" }]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="登录密码"
            className="form-item resetPassword ant-col-23"
          >
            <Input disabled placeholder="******" className="ant-col-14" />
            <Button onClick={this.resetPassword}>密码重置</Button>
          </Form.Item>

          <Form.Item
            label="所在机构（选填）"
            className="form-item ant-col-23"
            name="organization"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="营业执照（选填）"
            className="form-item ant-col-23 form-img"
          >
            {this.props.parent.state.rowData.businessLicenseUrl !==
            undefined ? (
              <Image src={this.props.parent.state.rowData.businessLicenseUrl} />
            ) : (
              <span style={{ color: "#b1b0b0" }}>暂无图片...</span>
            )}
            <Upload {...props} fileList={this.state.fileList}>
              <Button icon={<UploadOutlined />}>上传图片</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="代理商等级"
            className="form-radio"
            name="level"
            rules={[{ required: true, message: "代理商等级不能为空！" }]}
          >
            <Radio.Group>
              <Radio value={Number(1)}>金牌代理</Radio>
              <Radio value={Number(2)}>银牌代理</Radio>
              <Radio value={Number(3)}>铜牌代理</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="代理有效期"
            className="form-item ant-col-23"
            name="expiredTime"
            rules={[{ required: true, message: "代理有效期不能为空！" }]}
          >
            <DatePicker />
          </Form.Item>
        </div>

        <div className="form-btn">
          <Button type="primary" htmlType="submit">
            提交
          </Button>
          <Button onClick={() => this.props.parent.visiblePop(false)}>
            取消
          </Button>
        </div>
      </Form>
    );
  }
}
