import { Table } from "antd";
import React from "react";
interface IProps {
  data: any[];
  columns: any[];
  tableHeight: number;
  handleTableChange: any;
  pagination: any;
  tableLoading: boolean;
}
export default function TableC(props: IProps) {
  return (
    <Table
      columns={props.columns}
      dataSource={props.data}
      rowKey={(record) => record.id}
      className="virtual-table"
      loading={props.tableLoading}
      pagination={{
        pageSize: props.pagination.pageSize,
        current: props.pagination.current,
        total: props.pagination.total,
        showTotal: (total) => `共 ${props.pagination.total} 条数据`,
        onChange: (page: number) => {
            //console.log('page:'+page);
            props.handleTableChange({ pageNo: page});
        },
      }}
      scroll={{
        y: `calc(100vh - ${props.tableHeight}px)`,
        x: 1200,
      }}
    />
  );
}
