import {Button, DatePicker, Descriptions, Image, Input, message, Modal, Table, Checkbox} from "antd";
import React, {useEffect, useState} from "react";
import Request, {EHostType} from "../../../../../../utils/Request";
import {verifyStatusMap} from "../../../../helper";
import type {CheckboxChangeEvent} from 'antd/es/checkbox';

const {RangePicker} = DatePicker;

interface IProps {
    detailInfo: any;
}

let host: any = sessionStorage.getItem("host");

export const TaskDetailInfo = (props: IProps) => {
    const {detailInfo} = props;
    const [taskDesc, setTaskDesc] = useState(detailInfo.taskDesc);
    const [taskName, setTaskName] = useState(detailInfo.name);
    const [pubTimeFrom] = useState<any>(detailInfo.pubTimeFrom);
    const [pubTimeTo] = useState(detailInfo.pubTimeTo);
    const [darenNum, setDarenNum] = useState(detailInfo.darenNum);
    const [eachDarenPrice, setEachDarenPrice] = useState(detailInfo.eachDarenPrice ? detailInfo.eachDarenPrice / 100 : 0);
    const [groupbyBonusFee, setGroupbyBonusFee] = useState(detailInfo.groupbyBonusFee ? detailInfo.groupbyBonusFee / 100 : 0);
    const [loading, setLoading] = useState(false);

    const [showTalentDetail, setShowTalentDetail] = useState<boolean>(false);
    const [isSubmit, setIsSubmit] = useState<boolean|undefined>(true);

    const [talentInfo, setTalentInfo] = useState<any>([]);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize] = useState<number>(8);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState([]);
    const columns: any = [
        {
            title: "用户信息",
            dataIndex: "darenDyUserNick",
            key: "darenDyUserNick",
            align: "center",
            width: 150,
            render: (row, i) => {
                return (
                    <>
                        <p>昵称：{i.darenDyUserNick}</p>
                        <p>id：{i.darenUserId}</p>
                    </>
                );
            },
        },
        {
            title: "市场价(元)",
            dataIndex: "contactMarketPrice",
            key: "contactMarketPrice",
            align: "center",
            width: 100,
            render: (row, i) => {
                return (
                    <>
                        <p>{i.contactMarketPrice ? i.contactMarketPrice / 100 : 0}</p>
                    </>
                );
            },
        },
        {
            title: "渠道价(元)",
            dataIndex: "channelPrice",
            key: "channelPrice",
            align: "center",
            width: 100,
            render: (row, i) => {
                return (
                    <>
                        <p>{i.channelPrice ? i.channelPrice / 100 : 0}</p>
                    </>
                );
            },
        },
    ];

    const [showQrcode, setShowQrcode] = useState<boolean>(false);

    useEffect(() => {
        // let tk = sessionStorage.getItem("token");
        // setToken(tk || '')
    }, []);

    useEffect(() => {
        setIsSubmit(sessionStorage.getItem("functionLimits")?.includes("a6"));
        console.log(isSubmit,'a6a6')
        getTalentInfo();
    }, [showTalentDetail]);
    const customTag = JSON.parse( sessionStorage.getItem('ddlkPlatformSellerDTO') || '0');

    //查看达人信息
    let getTalentInfo = async () => {
        const url = `/mgr/ddlk/visitshop/getVisitShopTaskInvitedUser`;
        Request.get(EHostType.DDlk, url, {
            id: detailInfo.id,
        }).then((res) => {
            if (res.code == 0) {
                setDataSource(res.data);
                setTalentInfo(res.data.map(item => {
                    return {...item, isReplace: false}
                }))
            }
        }).catch(err => {
            console.log(err, "err")
        });
    };

    const doSave = () => {
        return new Promise((reslove, reject) => {
            const url = `/mgr/ddlk/visitshop/saveTask`;
            Request.jsonPost(EHostType.DDlk, url, {
                id: detailInfo.id,
                taskName: taskName,
                pubTimeFrom: pubTimeFrom.split(" ")[0],
                pubTimeTo: pubTimeTo.split(" ")[0],
                taskDesc: taskDesc,
            }).then((res) => {
                if (res.code == 0) {
                    message.success("保存成功");
                }
            });
        });
    };

    const doSubmit = async () => {
        const url = `/mgr/ddlk/visitshop/saveTask`;
        const {id} = detailInfo;
        const params = {
            id,
            taskName,
            taskDesc,
            groupbyBonusFee: groupbyBonusFee * 100,
        };
        setLoading(true);
        const resp = await Request.jsonPost(EHostType.DDlk, url, params);
        console.log(resp);
        if (resp.code == 0) {
            message.success("提交成功");
        }
        setLoading(false);
    };
    const onReplaceChange = (e: CheckboxChangeEvent, index: number) => {
        const currentData: any = [...talentInfo];
        if (currentData) {
            currentData[index].isReplace = e.target.checked;
            // currentData[index].tempPrice = currentData[index].channelPrice;
            currentData[index].channelPrice = e.target.checked ? 0 : currentData[index].contactMarketPrice;
            setTalentInfo(currentData);
        }
    };

    const onPriceIptChange = (e: any, index: number) => {
        const currentData: any = [...talentInfo];
        if (currentData) {
            // currentData[index].tempPrice = currentData[index].channelPrice;
            currentData[index].channelPrice = e.target.value * 100 > currentData[index].contactMarketPrice * 1 ? currentData[index].contactMarketPrice * 1 : e.target.value * 100;
            setTalentInfo(currentData);
        }
    };

    const onModifySubmit = async ()=>{
        console.log(talentInfo)
        let params={
            taskId:detailInfo.id,
        };
        params["subEdits"]=talentInfo.map(item=>{
            return {
                subTaskId: item.id,
                price: item.channelPrice,
                replaced: item.isReplace
            }
        })
        const resp = await Request.jsonPost(EHostType.DDlk, 'mgr/ddlk/visitshop/audit', {...params});
        if (resp.code == 0) {
            message.success("提交成功");
        }
    }

    return (
        <div>
            <Modal
                visible={showQrcode}
                onCancel={() => {
                    setShowQrcode(false);
                }}
                title="查看探店沟通群"
                footer={null}
                destroyOnClose>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                    <Image width={200} src={detailInfo?.wechatGroupUrl}/>
                </div>
            </Modal>

            <Modal
                visible={showTalentDetail}
                onCancel={() => {
                    setShowTalentDetail(false);
                }}
                title="查看选择达人信息"
                footer={null}
                destroyOnClose
                width={800}>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    loading={loading}
                    pagination={false}
                />
            </Modal>
            <Descriptions
                title=""
                bordered
                column={4}
                labelStyle={{width: "150px"}}
            >
                <Descriptions.Item label="活动id" span={2}>
                    {detailInfo.id}
                </Descriptions.Item>
                <Descriptions.Item label="关联商家id" span={2}>
                    {detailInfo.adviserReferSellerId}
                </Descriptions.Item>
                <Descriptions.Item label="任务名称" span={4}>
                    <Input
                        style={{width: "40%"}}
                        value={taskName}
                        disabled={detailInfo.status !== 1}
                        onChange={(e) => {
                            setTaskName(e.target.value);
                        }}
                    />
                </Descriptions.Item>
                {/* <Descriptions.Item label="" span={2}> */}
                {/* <RangePicker
            value={[
              pubTimeFrom
                ? moment(pubTimeFrom, "YYYY-MM-DD")
                : moment(new Date()),
              pubTimeTo ? moment(pubTimeTo, "YYYY-MM-DD") : moment(new Date()),
            ]}
            onChange={(e: any) => {
              setPubTimeFrom(e[0].format("YYYY-MM-DD"));
              setPubTimeTo(e[1].format("YYYY-MM-DD"));
            }}
          /> */}
                {/* </Descriptions.Item> */}
                {/* <Descriptions.Item label="任务类型" span={1}>
          {comboTypeMap[detailInfo.comboType]}
        </Descriptions.Item> */}
                <Descriptions.Item label="探店时间" span={2}>
                    {detailInfo.visitTimeFrom + "-" + detailInfo.visitTimeTo}
                </Descriptions.Item>
                <Descriptions.Item label="店铺地址" span={2}>
                    {detailInfo.fullAddressText}
                </Descriptions.Item>

                <Descriptions.Item label="任务类型" span={2}>
                    {customTag.visitShopCustName}
                </Descriptions.Item>
                <Descriptions.Item label="订单号" span={2}>
                    <a>{detailInfo.orderId}</a>
                </Descriptions.Item>
                <Descriptions.Item label="任务状态" span={2}>
                    <a>{verifyStatusMap[detailInfo.status]}</a>
                </Descriptions.Item>
            </Descriptions>

            <Descriptions
                title="任务配置"
                bordered
                style={{margin: "24px 0"}}
                column={4}
                labelStyle={{width: "150px"}}>
                <Descriptions.Item label="达人数量" span={2}>
                    {darenNum}
                    <Button
                        type="link"
                        style={{marginLeft: "16px"}}
                        onClick={() => {
                            setShowTalentDetail(true);
                        }}>
                        查看
                    </Button>
                </Descriptions.Item>

                <Descriptions.Item label="探店沟通群" span={2}>
                    <Button
                        type="link"
                        style={{marginLeft: "16px"}}
                        onClick={() => {
                            setShowQrcode(true);
                        }}>
                        查看
                    </Button>
                </Descriptions.Item>

                <Descriptions.Item label="累计金额" span={2}>
                    {detailInfo.totalPaidFee / 100}
                </Descriptions.Item>
                <Descriptions.Item label="团购佣金" span={2}>
                    <Input
                        type="number"
                        min={0}
                        value={groupbyBonusFee}
                        disabled={detailInfo.status !== 1}
                        addonAfter="元/单"
                        style={{width: "200px"}}
                        onChange={(e) => {
                            setGroupbyBonusFee(+e.target.value);
                        }}
                    />
                </Descriptions.Item>
            </Descriptions>

            <h3>修改{customTag.visitShopCustName || '达人探店'}佣金</h3>
            <h4>达人信息</h4>
            {talentInfo?.map((item: any, index) => {
                return (<>{item && <div>
                    <div>
                        <div style={{margin:"10px 0"}}>{item?.darenDyUserNick}</div>
                        <Input
                            type="number"
                            min={0}
                            value={item?.contactMarketPrice / 100}
                            disabled
                            addonBefore="市场价:"
                            addonAfter="元"
                            style={{width: "200px"}}
                        />
                        <Input
                            type="number"
                            min={0}
                            max={item?.contactMarketPrice / 100}
                            value={item?.channelPrice / 100}
                            addonBefore="渠道价:"
                            addonAfter="元"
                            disabled={item?.isReplace}
                            style={{width: "200px", marginLeft: "20px"}}
                            onChange={(e) => {
                                onPriceIptChange(e, index)
                            }}
                        />
                        <Checkbox style={{marginLeft:'20px'}} checked={item?.isReplace} onChange={(e) => {onReplaceChange(e, index);}}>选择置换</Checkbox>
                    </div>
                </div>}

                </>)
            })}
            {
                isSubmit ?  <div style={{width:"100%",textAlign:"right"}}>
                <Button type={"primary"} onClick={()=>{ onModifySubmit()}}>提交</Button>
            </div>:""
            }
           


            <h3>任务说明</h3>
            <div>
                <Input.TextArea
                    disabled={![1, 4].includes(detailInfo.status)}
                    onChange={(e) => {
                        setTaskDesc(e.target.value);
                    }}
                    rows={10}
                    value={taskDesc}
                />
                {/* <div>账号要求：本地生活类、探店类、生活类视频等。</div>
            <div>任务要求：。</div>
            <div>1、请使用指定素材进行创作，报名后素材可见</div>
            <div>2、抖音@指定账号“杭州小龙坎西湖店”。</div>
            <div>3、发布作品时需挂上商家地理位置信息，否则无效。</div>
            <div>4、达人视频需至少保存30天时间</div> */}
            </div>

            <Descriptions
                title="奖金分配"
                bordered
                column={4}
                style={{marginTop: "20px"}}
                labelStyle={{width: "150px"}}
            >
                <Descriptions.Item label="任务原始金额" span={2}>
                    {detailInfo.totalPaidFee / 100}
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
                    {/* {detailInfo.totalMoney ? detailInfo.totalMoney / 100 : 0}
            <RechargeTable
              id={detailInfo.id}
              onOpenRechargeDetail={onOpenRechargeDetail}
            /> */}
                    {/* </div> */}
                </Descriptions.Item>

                <Descriptions.Item label="累计服务费" span={2}>
                    {detailInfo.serviceFee ? detailInfo.serviceFee / 100 : 0}
                </Descriptions.Item>
                <Descriptions.Item label="累计发放金额" span={2}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        {detailInfo.spentMoney ? detailInfo.spentMoney / 100 : 0}
                        {/* <RewardsTable
              details={detailInfo}
              onOpenRewardRecords={onOpenRewardRecords}
            /> */}
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="剩余待发放金额" span={2}>
                    {(detailInfo.totalPaidFee - detailInfo.spentMoney) / 100}
                </Descriptions.Item>
            </Descriptions>
            <div
                style={{
                    marginTop: "30px",
                    display: [1, 4].includes(detailInfo.status) ? "block" : "none",
                }}
            >
                {/* <Button type="ghost" onClick={() => {doSave();}}>
          保存草稿
        </Button> */}
                <Button
                    type="primary"
                    style={{marginLeft: "20px"}}
                    loading={loading}
                    onClick={() => {
                        doSubmit();
                    }}
                >
                    确认
                </Button>
            </div>
        </div>
    );
};
