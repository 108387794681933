import * as Icon from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import "./index.css";

interface IButton {
  text: string;
  icon?: any;
  onClick: () => void;
}

export default function BottomContainer(props) {
  const btnList =
    props.btns &&
    props.btns.map((e: IButton, index: number) => {
      return (
        <Button
          type="primary"
          icon={<Icon.PlusCircleOutlined />}
          onClick={e.onClick}
          key={index}
        >
          {e.text}
        </Button>
      );
    });
  return (
    <div className="table-container">
      <div
        className="table-btn"
        style={{
          display: btnList && btnList.length ? "block" : "none",
          textAlign: "right",
        }}
      >
        {btnList}
      </div>
      <div className="tab-container">{props.children}</div>
    </div>
  );
}

export const a = {};
