import {Alert, Button, Modal, Radio, Space, Input, message, Image,} from "antd";
import { useState } from "react";
import { doAudit } from "../source/action";
import { darenLevel } from "../../helper";
const { TextArea } = Input;

interface IProps {
  // item: any;
  // onAuditSuccess: any;
  picUrl;
  fansCount;
  dyGroupbyLevelApplyNew;
  darenId: any;
  visible: boolean;
  setVisible(visible: boolean): void;
  getList;
  city;
  dyNo;
}

export const Audit = (props: IProps) => {
  const [value, setValue] = useState(1); // 单选框的值
  const [reason, setReason] = useState(""); // 设置驳回原因
  const [loading, setLoading] = useState(false);
  const [imgPrevVis, setImgPrevVis] = useState(false); // 预览图片显示隐藏
  const [imgPrevUrl, setImgPrevUrl] = useState(""); // 预览图片路径
  const setVisible = props.setVisible;
  const ControlImage = () => {
    return (
      <Image
        preview={{
          visible: imgPrevVis,
          src: imgPrevUrl,
          onVisibleChange: (value) => {
            setImgPrevVis(value);
          },
        }}
        style={{ display: "none" }}
        className="ddlktaskmaterialPic"
      />
    );
  };
  const doSubmit = async () => {
    if (value == 2 && !reason) {
      message.error("请输入不通过原因");
      return;
    }
    setLoading(true);
    const res = await doAudit({
      darenId: props.darenId,
      isApproved: value == 1 ? true : false,
      notApprovedReason: value == 1 ? undefined : reason,
    });
    if (res?.data) {
      message.success("操作成功");
      props.getList();
      // props.onAuditSuccess();
      // this.props.parent.getList({});
      setVisible(false);
    }

    setLoading(false);
    //   .then((res) => {
    //   console.log(res);
    //   if (res?.data) {
    //     message.success("审核通过");
    //     // props.onAuditSuccess();
    //     // this.props.parent.getList({});
    //     setVisible(false);
    //   }
    // });
  };
  return (
    <>
      <ControlImage />
      {/* <Button
        type="link"
        onClick={() => {
          setVisible(true);
        }}
      >
        任务审核
      </Button> */}
      <Modal
        visible={props.visible}
        onCancel={() => {
          setVisible(false);
        }}
        title="任务审核"
        footer={null}
        destroyOnClose
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {/* <Alert message="视频审核通过且达标后，奖励才可发布，否则奖励不予发放" type="warning" /> */}
          <Radio.Group
            onChange={(e: any) => {
              setValue(e.target.value);
            }}
            value={value}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Space direction="vertical">
              <Radio value={1}>审核通过</Radio>
              <Radio value={2}>审核驳回</Radio>
            </Space>
          </Radio.Group>
          <div>申请达人等级: {darenLevel[props.dyGroupbyLevelApplyNew]}</div>
          <div>粉丝数量(w): {props.fansCount}</div>
          <div>城市：{`${props.city.province}/${props.city.citytext}`}</div>
          <div>抖音号：{props.dyNo}</div>
          <div style={{ display: "flex" }}>
            带货等级图片:
            <div
              style={{
                position: "relative",
                width: 80,
                height: 120,
                marginLeft: 20,
              }}
            >
              <img
                src={`${props.picUrl}`}
                alt=""
                style={{ width: 80, height: 120 }}
              />
              <Button
                size={"small"}
                type={"primary"}
                style={{
                  position: "absolute",
                  right: 2,
                  bottom: 2,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setImgPrevVis(true);
                  setImgPrevUrl(`${props.picUrl}`);
                }}
              >
                预览
              </Button>
            </div>
          </div>
          {value == 2 && (
            <TextArea
              placeholder="请输入驳回原因"
              value={reason}
              style={{ width: "450px" }}
              onChange={(e: any) => {
                setReason(e.target.value);
              }}
            />
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              style={{ width: "100px" }}
              loading={loading}
              onClick={() => {
                doSubmit();
              }}
            >
              提交
            </Button>
          </div>
        </Space>
      </Modal>
    </>
  );
};
