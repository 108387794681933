import {Button, Collapse, Modal} from 'antd';
import React, {useEffect, useState} from 'react';
import Request, {EHostType} from "../../../../../utils/Request";

interface IProps {
    onShowJoined: boolean,
    taskId:string
}

const { Panel } = Collapse;

export const JoinedList = (props: IProps) => {
    const [onShowJoined, setOnShowJoined] = useState<boolean>(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [store,setStore]=useState([]);
    const customTag = JSON.parse( sessionStorage.getItem('ddlkPlatformSellerDTO') || '0');
    
    useEffect(() => {
        if(props.taskId){
            getTaskEmployeeList({pageNo:1});
        }
    }, [props.taskId]);
    
    const getTaskEmployeeList = async (params: any) => {
        let host:any=sessionStorage.getItem("host");
        let url = `/mgr/ddlk/consumertask/getTaskEmployeeList`;
        Request.jsonPost(EHostType.DDlk, url, { pageNo, pageSize, taskId: props.taskId }).then((res) => {
            setStore(res?.data);
            setTotal(res.totalCount);
        })
    };

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    return (
        <div>
            <Button type="link"   onClick={()=>{setOnShowJoined(true)}}>查看参与{customTag.employeeTaskCustNamePrefix}</Button>
            <Modal title={`邀请参与${customTag.employeeTaskCustNamePrefix}`} footer={null} width={600} visible={onShowJoined} onCancel={()=>{ setOnShowJoined(false) }} destroyOnClose>
                <div>共有{total}名{customTag.employeeTaskCustNamePrefix}参与</div>
                <div style={{margin:"24px 0"}}>
                    <Collapse defaultActiveKey={['1']} onChange={onChange}>
                        {store&&Object.keys(store)?.map((key,index)=>{
                            return (<Panel header={`${key}(${store&&store[key].length}人)`} key={index}>
                                {store&&store[key]?.map(item=>{ return (<p>{item.employeeName}</p>) })}
                            </Panel>)})}
                    </Collapse>
                </div>
            </Modal>
        </div>
    );
}
