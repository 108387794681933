import { Cascader, Form, Input, InputNumber, Select, Typography } from "antd";
import { Rule } from "rc-field-form/lib/interface";

import React, { useCallback, useEffect, useState } from "react";
const { Option } = Select;

enum EditStatus {
  normal,
  complete,
}
export default function EditCell(props: {
  value: any;
  handleSave: (e?: any, values?: any, name?: string) => any;
  name: string;
  rules?: Rule[] | undefined;
  options?: {
    type: string;
    options?: any[];
    label?: string;
    value?: any;
    showName?: string;
    fieldNames?;
    min?: number;
  };
}) {
  // console.log("我渲染了", props);
  let cascaderOption = [];
  const [form] = Form.useForm();
  const [editStatus, setEditStatus] = useState<EditStatus>(EditStatus.normal);
  const [value, setValue] = useState(props.value);
  const inputRef = React.useRef<any>(null);
  //   let eventFlag = false;
  const [eventFlag, setEventFlag] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean|undefined>(true);

  const [showName, setShowName] = useState(
    props.options && props.options.showName
  );
  useEffect(() => {
    setIsEdit(sessionStorage.getItem("functionLimits")?.includes("a6"));
    const initVal = typeof value === "object" ? value && value.length > 0 ? value : props.value : props.value;
    form.setFieldsValue({ [props.name]: initVal });
    setValue(initVal);
  }, [props.value]);

  useEffect(() => {
    const initName = showName ? showName : props.options?.showName;
    props.options &&
      // (showName?.includes("undefined") || !showName) &&
      setShowName(initName);
  }, [props.options]);

  const save = async () => {
    // eventFlag = true;
    setEventFlag(true);

    const values = await (form as any).validateFields();

    const code = await props.handleSave(values[props.name], values, props.name);
    // console.log(cascaderOption, "lkjljljljlj");
    if (code == 0) {
      if (props.options && props.options.type === "cascader") {
        const showName = cascaderOption.reduce((a, b) => {
          return a + (a ? "/" : "") + b[props.options?.label!];
        }, "");
        // console.log(showName, "99999");
        cascaderOption.length && setShowName(showName);
      }
      setEventFlag(false);
      setEditStatus(EditStatus.normal);
      setValue(values[props.name]);
    }
  };
  const onBlur = useCallback(
    (e) => {
      if (eventFlag) {
        return;
      }
      e.cancelBubble = true;
      setEditStatus(EditStatus.normal);
      form.resetFields();
    },
    [eventFlag]
  );
  const handelEdit = useCallback(() => {
    // console.log('');
    setEditStatus(EditStatus.complete);
  }, []);
  const cascaderChange = useCallback(
    (a, b) => {
      // console.log(a, b);
      cascaderOption = b;
    },
    [cascaderOption]
  );

  useEffect(() => {
    form.setFieldsValue({ [props.name]: props.value });
    setShowName(props.options && props.options.showName);
    setValue(props.value);
  }, []);

  useEffect(() => {
    if (editStatus === EditStatus.complete) {
      inputRef.current && inputRef.current.focus({ cursor: "end" });
    }
  }, [editStatus]);

  const renderOption = ({type, options, value, label,}: {
      type: string;
      options?: any[];
      value?: any;
      label?: string;
    },
    initData
  ) => {
    let Comp;
    switch (type) {
      case "select":
        const list = options?.map((option) => {
          return typeof option === "string" ? (<Option value={option}>{option}</Option>) : (<Option value={option[value]}>{option[label!]}</Option>);
        });
        Comp = (
          <Select style={{ width: 340 }} allowClear>
            {list}
          </Select>
        );
        break;
      case "cascader":
        Comp = (
          <Cascader
            style={{ width: 200 }}
            onChange={cascaderChange}
            defaultValue={props.value}
            options={options}
            placeholder="Please select"
            changeOnSelect
            fieldNames={props.options?.fieldNames || {}}
          />
        );
        break;
      case "inputnumber":
        // console.log("ljl", initData);
        Comp = <InputNumber min={props.options?.min || 0} />;
        break;
    }
    return Comp;
  };
  return (
    <>
      <Form
        form={form}
        style={{
          display: editStatus === EditStatus.normal ? "none" : "flex",
          alignItems: "center",
        }}
        initialValues={{ [props.name]: props.value }}
      >
        <Form.Item
          style={{
            margin: 0,
          }}
          name={props.name}
          rules={props.rules}
        >
          {props.options ? (
            renderOption(props.options, props.value)
          ) : (
            <Input
              ref={inputRef}
              value={value}
              style={{ width: '100%' }}
              onBlur={onBlur}
            />
          )}
        </Form.Item>
        <Typography.Link onMouseDown={save} style={{ marginLeft: 30 }} id="ui">
          完成
        </Typography.Link>
      </Form>
      <div
        style={{ display: editStatus === EditStatus.normal ? "flex" : "none" }}
      >
        <div style={{ width: 200 }}>
          {showName ? showName : value || "未填写"}
        </div>
        {
          isEdit?<Typography.Link onClick={handelEdit} style={{ marginLeft: 30 }}>
          修改
        </Typography.Link>:""
        }
        
      </div>
    </>
  );
}
