import React, { useCallback, useState, useEffect } from "react";
import { Button, Checkbox, Form, Input,Radio } from 'antd';
interface IProps {
    configInfo: Object;
}
export const TalentDouyinMiniproConfig=(props)=>{
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{...props.configInfo,...props.configInfo.darenMiniConfig}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Form.Item
                    label="APPID"
                    name="appid"
                    rules={[{ required: false, message: '请输入微信号' }]}>
                    <Input  style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="Secret"
                    name="secret"
                    rules={[{ required: false, message: '请输入手机号' }]}>
                    <Input  style={{width:'200px'}} />
                </Form.Item>
                <Form.Item label="是否已设置小程序代码上传白名单" name="isWhiteIp4CodeDeployedConfiged">
                    <Radio.Group>
                        <Radio value="true">是</Radio>
                        <Radio value="false">否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="是否已设置request域名" name="isRequestDomainConfiged">
                    <Radio.Group>
                        <Radio value="true">是</Radio>
                        <Radio value="false">否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="是否已设置upload域名" name="isUploadDomainConfiged">
                    <Radio.Group>
                        <Radio value="apple">是</Radio>
                        <Radio value="pear">否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="是否已设置download域名" name="isDownloadDomainConfiged">
                    <Radio.Group>
                        <Radio value="true">是</Radio>
                        <Radio value="false">否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="是否已设置业务域名" name="isBizDomainConfiged">
                    <Radio.Group>
                        <Radio value="apple">是</Radio>
                        <Radio value="pear">否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="是否已添加企业微信插件(2个)" name="isQyWechatPluginConfiged">
                    <Radio.Group>
                        <Radio value="true">是</Radio>
                        <Radio value="false">否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="企业微信联系人配置id"
                    name="qiyeConsultQyWechatConfigId"
                    rules={[{ required: false, message: '请输入企业微信联系人配置id' }]}>
                    <Input  style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="企业微信联系群url"
                    name="qiyeConsultQyWechatGroupUrl"
                    rules={[{ required: false, message: '请输入企业微信联系群url' }]}>
                    <Input  style={{width:'200px'}} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </>
    );
}
