import React from "react";
// import * as React from 'react';
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form, Image,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Upload,
} from "antd";
import "./index.css";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import address from "../../assets/js/area.json";
import Request, { EHostType } from "../../utils/Request";
import moment from "moment";

const { RangePicker } = DatePicker; // import {useState} from "react";

const { Option } = Select;
const { TextArea } = Input;
let host: any = sessionStorage.getItem("host");

interface page {
  tabState: boolean;
  previewVisible: boolean;
  previewImage: string;
  previewTitle: string;
  fileList: any;
  fileListShow: any;
  fileListBusinessLicense:any;
  fileListBusinessLicenseShow:any;
  province: string;
  city: string;
  county: string;
  agentData: any;
  uploading: boolean;
  parentState: number;
  options: any;
  storePic: any;
  storeIntroductionVideo: any;
  addNewMerchantVisiable: boolean;
  check0: boolean;
  check1: boolean;
  isAddSeller:boolean | undefined;
}
export default class MerchantAdd extends React.Component<
  { parent?: any; onRefresh: any; companyData: any[] },
  page
> {
  formRef: any = React.createRef();
  ddlkPlatformSellerDTO: any;
  constructor(props) {
    super(props);
    this.state = {
      isAddSeller:true,
      tabState: true,
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
      fileListShow: [],
      fileListBusinessLicense:[],
      fileListBusinessLicenseShow:[],
      province: "",
      city: "",
      county: "",
      agentData: null,
      uploading: false,
      parentState: 1,
      options: address,
      storePic: [],
      storeIntroductionVideo: null,
      addNewMerchantVisiable: false,
      check0: false,
      check1: false,
    };
    // 获取登录接口中的远程探店高级版开通权限和达人探店高级版开通权限
    this.ddlkPlatformSellerDTO = sessionStorage.getItem("ddlkPlatformSellerDTO")
      ? JSON.parse(sessionStorage.getItem("ddlkPlatformSellerDTO")!)
      : "";
  }
  componentDidMount() {
    this.setState({isAddSeller:sessionStorage.getItem("functionLimits")?.includes("a4")})
    this.getData();
  }

  /**快手/抖音配置切换 */
  tabChange = (value) => {
    if (value.target.value === "all") {
      this.setState({
        tabState: true,
      });
    } else {
      this.setState({
        tabState: false,
      });
    }
  };

  /**代理商查询*/
  private async getData() {
    let url = `/op/agent/findByName`;
    let ret = await Request.get(EHostType.XMGX, url);
    if (ret.code == 0) {
      this.setState({
        agentData: ret.data,
      });
    }
  }

  // handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => {
    this.setState({ fileListShow: fileList })
  };
  handleBusinessLicenseChange = ({ fileList }) => {
    this.setState({ fileListBusinessLicenseShow: fileList.slice(-1) })
  };
  handleStorePic = ({ fileList }) => this.setState({ storePic: fileList });

  onFinish = (value) => {
    // 商家续费  6 远程探店高级版  7 达人探店高级版
    if (value.check0 || value.check1) {
      value.serviceConfigListStr = [];
    }

    if (value.time0 && value.time0.length === 2 && value.check0) {
      value.serviceConfigListStr.push({
        serviceType: 6,
        validateFrom: moment(value.time0[0]).format("YYYY-MM-DD") + " 00:00:00",
        validateTo: moment(value.time0[1]).format("YYYY-MM-DD") + " 23:59:59",
      });
    }

    if (value.time1 && value.time1.length === 2 && value.check1) {
      value.serviceConfigListStr.push({
        serviceType: 7,
        validateFrom: moment(value.time0[0]).format("YYYY-MM-DD") + " 00:00:00",
        validateTo: moment(value.time0[1]).format("YYYY-MM-DD") + " 23:59:59",
      });
    }
    // --- 商家续费处理结束 ----
    for (let key in value) {
      if (value[key] === undefined || value[key] === "") {
        delete value[key];
      }
    }
    if (value.agentId === undefined || value.agentId === null) {
      value["agentId"] = 0;
    }
    value["startTime"] = moment(value.startTime).format("YYYY-MM-DD HH:mm");
    value["endTime"] = moment(value.endTime).format("YYYY-MM-DD HH:mm");
    value["province"] = value["options"][0];
    value["city"] = value["options"][1];
    value["county"] = value["options"][2];
    let picArr: Array<string> = [];
    for (let item of this.state.storePic) {
      picArr.push(JSON.parse(item.xhr.response).data);
    }
    value["storePic"] = picArr.join(",");

    const formData = new FormData();
    this.state.fileList.forEach((file) => {
      formData.append("logo", file);
    });
    this.state.fileListBusinessLicense.forEach((file) => {
      // console.log(file,'ffff');
      formData.append("businessLicenseUrl", file);
    });

    if (this.state.storeIntroductionVideo) {
      formData.append(
        "storeIntroductionVideo",
        this.state.storeIntroductionVideo
      );
    }

    this.setState({
      uploading: true,
    });
    for (let key in value) {
      if (typeof value[key] === "object") {
        formData.append(key, JSON.stringify(value[key]));
      } else {
        formData.append(key, value[key]);
      }
    }

    let _this = this;
    Modal.confirm({
      title: "是否确认提交",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认提交？</p>,
      async onOk() {
        let host: any = sessionStorage.getItem("host");
        let url = `/op/seller/saveOrUpdate`;
        formData.delete('loabcgo');
        Request.postFormData(EHostType.XMGX, url, formData).then((res) => {
          if (res.code == 0) {
            _this.setState({ addNewMerchantVisiable: false });
            _this.props.onRefresh();
            message.success("提交成功");
          }
        });
      },
    });
  };

  parentChange = (value) => {
    this.setState({
      parentState: value.target.value,
    });
  };
  onShow = () => {
    this.setState({
      addNewMerchantVisiable: true,
    });
  };
  onCancel = () => {
    this.setState({
      addNewMerchantVisiable: false,
    });
  };

  public render() {
    const { fileList,fileListBusinessLicense} = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
        {/*{this.props.parent.state.rowData.logo&&<div style={{ marginTop: 8 }}>*/}
        {/*  {this.props.parent.state.rowData.logo !== undefined && this.props.parent.state.rowData.logo !== "" ? "替换LOGO" : "上传LOGO"}*/}
        {/*</div>}*/}
        {/*{this.props.parent.state.rowData.businessLicense&&<div style={{ marginTop: 8 }} >*/}
        {/*  {this.props.parent.state.rowData.businessLicense !== undefined && this.props.parent.state.rowData.businessLicense !== "" ? "替换营业执照" : "上传营业执照"}*/}
        {/*</div>}*/}
      </div>
    );
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    const businessProps = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileListBusinessLicense.indexOf(file);
          const newFileList = state.fileListBusinessLicense.slice();
          newFileList.splice(index, 1);
          return {
            fileListBusinessLicense: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileListBusinessLicense: [...state.fileListBusinessLicense, file],
        }));
        return false;
      },
      fileListBusinessLicense,
    };
    const categoryMap = [
      { name: "所有", value: -1 },
      { name: "餐饮美食", value: 1 },
      { name: "休闲娱乐", value: 2 },
      { name: "美容美发", value: 3 },
      { name: "教育培训", value: 4 },
      { name: "酒店公寓", value: 5 },
      { name: "体育健身", value: 6 },
      { name: "景点乐园", value: 7 },
      { name: "洗浴足疗", value: 8 },
      { name: "生活服务", value: 9 },
      { name: "其他", value: 0 },
    ];
    const onValuesChange = (changedValues, allValues) => {
      // console.log(changedValues, allValues);
    };
    const getAction = () => {
      let host: any = sessionStorage.getItem("host");
      return `${JSON.parse(host)?.xmgx}`;
    };
    return (
      <>
      {
        this.state.isAddSeller ?  <Button
        type="primary"
        onClick={() => {
          this.onShow();
        }}
      >
        添加商户
      </Button>:""
      }
       
        <Modal
          visible={this.state.addNewMerchantVisiable}
          onCancel={() => {
            this.onCancel();
          }}
          destroyOnClose={true}
          width={1000}
          title="添加商户"
          footer={null}>
          <Form
            scrollToFirstError={true}
            name="form"
            className="form-container"
            onFinish={this.onFinish}
            ref={this.formRef}
          >
            <div className="form-content MerchantAdd">
              <div className="form-titlt">商家基本信息</div>

              <Form.Item
                label="商户名称"
                className="form-item ant-col-11"
                name="name"
                rules={[{ required: true, message: "商户名称不能为空！" }]}
              >
                <Input allowClear placeholder="最多显示20个字" />
              </Form.Item>

              <Form.Item
                label="所属分类"
                className="form-item ant-col-11"
                name="categoryId"
                rules={[{ required: true, message: "所属分类不能为空！" }]}
              >
                <Select>
                  {categoryMap?.map((e, i) => {
                    return (
                      <Option key={e.value + "categoryId"} value={e.value}>
                        {e.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="商户类型"
                className={
                  this.state.parentState === 3
                    ? "form-radio ant-col-11"
                    : "form-radio"
                }
                name="sellerType"
                rules={[{ required: true, message: "商户类型不能为空！" }]}
              >
                <Radio.Group onChange={this.parentChange}>
                  <Radio value={Number(1)}>普通商户</Radio>
                  <Radio value={Number(2)}>连锁总部</Radio>
                  <Radio value={Number(3)}>连锁门店</Radio>
                  {/* <Radio value={Number(4)}>超级门店</Radio> */}
                </Radio.Group>
              </Form.Item>

              {/*{this.state.parentState === 3 ? (*/}
              {/*  <Form.Item*/}
              {/*    label="关联总公司"*/}
              {/*    className="form-item ant-col-11"*/}
              {/*    name="parentId"*/}
              {/*    rules={[{ required: true, message: "关联总公司不能为空！" }]}*/}
              {/*  >*/}
              {/*    <Select*/}
              {/*      showSearch*/}
              {/*      filterOption={(input, option: any) =>*/}
              {/*        option.children*/}
              {/*          .toLowerCase()*/}
              {/*          .indexOf(input.toLowerCase()) >= 0*/}
              {/*      }*/}
              {/*    >*/}
              {/*      {this.props.companyData?.map((e) => {*/}
              {/*        return (*/}
              {/*          <Option key={e.id + "parentId"} value={e.id}>*/}
              {/*            {e.name}*/}
              {/*          </Option>*/}
              {/*        );*/}
              {/*      })}*/}
              {/*    </Select>*/}
              {/*  </Form.Item>*/}
              {/*) : null}*/}

              <Form.Item
                label="商户负责人"
                className="form-item ant-col-11"
                name="realName"
                rules={[{ required: true, message: "商户负责人不能为空！" }]}
              >
                <Input allowClear />
              </Form.Item>

              <Form.Item
                label="手机号码"
                className="form-item ant-col-11"
                name="phone"
                rules={[{ required: true, message: "手机号码不能为空！" }]}
              >
                <Input allowClear placeholder="将作为商户登录账号使用" />
              </Form.Item>

              <Form.Item
                label="所在地区"
                required={true}
                className="form-item ant-col-22 flex"
              >
                <Form.Item name="options" className="form-item ant-col-10">
                  <Cascader options={this.state.options} placeholder="请选择" />
                </Form.Item>
                <Form.Item
                  name="address"
                  className="ant-col-24"
                  rules={[{ required: true, message: "详细地址不能为空！" }]}
                >
                  <TextArea placeholder="详细地址" />
                </Form.Item>
              </Form.Item>

              {/*<Form.Item*/}
              {/*  label="商户营业时间"*/}
              {/*  className="form-item ant-col-22"*/}
              {/*  name="businessTime"*/}
              {/*>*/}
              {/*  <Input allowClear placeholder="如周一至周五，08:00～22:00" />*/}
              {/*</Form.Item>*/}

              <Form.Item label="登录密码" className="form-item ant-col-11">
                <Input disabled placeholder="账号创建后随机生成" />
              </Form.Item>

              {sessionStorage.getItem('isAgentEnabled')=='true'&&<Form.Item
                label="负责代理商"
                className="form-item ant-col-11"
                name="agentId"
                rules={[{ required: true, message: "负责代理商不能为空！" }]}
              >
                <Select
                  placeholder="负责代理商"
                  showSearch
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {this.state.agentData?.map((e: any) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {e.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>}

              <Form.Item
                label="商户有效期"
                required={true}
                className="form-item flex-time ant-col-22"
              >
                <Form.Item
                  name="startTime"
                  rules={[{ required: true, message: "开始时间不能为空！" }]}
                >
                  <DatePicker
                    placeholder="开始时间"
                    showTime
                    format="YYYY-MM-DD HH:mm"
                  />
                </Form.Item>
                <Form.Item
                  name="endTime"
                  rules={[{ required: true, message: "到期时间不能为空！" }]}
                >
                  <DatePicker
                    placeholder="到期时间"
                    showTime
                    format="YYYY-MM-DD HH:mm"
                  />
                </Form.Item>
              </Form.Item>

              {/* <Form.Item
                label="小程序App ID"
                className="form-item ant-col-22"
                name="wxAppid"
              >
                <Input placeholder="输入小程序App ID" />
              </Form.Item> */}

              <div>
                {/*<div className="form-titlt">商户图片</div>*/}

              <Form.Item label="商户logo" name="loabcgo" className="form-item img" required={true} rules={[{ required: true, message: "商户logo不能为空！" }]}>
                  <Upload
                    {...props}
                    listType="picture-card"
                    fileList={this.state.fileListShow}
                    onChange={this.handleChange}
                  >
                    {this.state.fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <Form.Item label="商户营业执照上传" className="form-item img">
                  <Upload
                      style={{ width: "calc(100% - 130px)" }}
                      {...businessProps}
                      listType="picture-card"
                      fileList={this.state.fileListBusinessLicenseShow}
                      onChange={this.handleBusinessLicenseChange}
                  >
                    {this.state.fileListBusinessLicense.length >= 1 ? null : uploadButton}
                    {/*{bueinessLicenseUploadButton}*/}
                  </Upload>
                </Form.Item>

                {/*<Form.Item label="商户相册" className="form-item ant-col-11">*/}
                {/*  <Upload*/}
                {/*    name="file"*/}
                {/*    accept="image/*"*/}
                {/*    headers={{*/}
                {/*      Authorization: sessionStorage.getItem("token") as string,*/}
                {/*    }}*/}
                {/*    data={{ sellerId: 0 }}*/}
                {/*    action={`${getAction()}/op/seller/uploadAlbum`}*/}
                {/*    listType="picture-card"*/}
                {/*    onChange={this.handleStorePic}*/}
                {/*    fileList={this.state.storePic}*/}
                {/*  >*/}
                {/*    <div>*/}
                {/*      <PlusOutlined />*/}
                {/*      <div style={{ marginTop: 8 }}>上传图片</div>*/}
                {/*    </div>*/}
                {/*  </Upload>*/}
                {/*  <br />*/}
                {/*  <br />*/}
                {/*  <span className="item-tip">*/}
                {/*    请上传门店门头、环境等照片,支持上传多张*/}
                {/*  </span>*/}
                {/*</Form.Item>*/}
              </div>

              {/*<div>*/}
              {/*  <div className="form-titlt">门店介绍</div>*/}
              {/*  <Form.Item*/}
              {/*    label="门店介绍"*/}
              {/*    className="form-item"*/}
              {/*    name="storeIntroductionText"*/}
              {/*  >*/}
              {/*    <TextArea rows={4} placeholder="请输入门店介绍" />*/}
              {/*  </Form.Item>*/}

              {/*  <Form.Item*/}
              {/*    label="门店电话"*/}
              {/*    className="form-item ant-col-11"*/}
              {/*    name="storeContactPhone"*/}
              {/*  >*/}
              {/*    <Input placeholder="输入门店电话" />*/}
              {/*  </Form.Item>*/}

              {/*  <Form.Item label="视频介绍" className="form-item">*/}
              {/*    <Input*/}
              {/*      accept="video/*"*/}
              {/*      type="file"*/}
              {/*      onChange={(e) =>*/}
              {/*        this.setState({*/}
              {/*          storeIntroductionVideo: e.target.files*/}
              {/*            ? e.target.files[0]*/}
              {/*            : null,*/}
              {/*        })*/}
              {/*      }*/}
              {/*    />*/}
              {/*  </Form.Item>*/}
              {/*</div>*/}

              {/*{this.state.parentState !== 2 ? (*/}
              {/*  <React.Fragment>*/}
              {/*    <div className="form-titlt">商户号配置</div>*/}

              {/*    <Radio.Group*/}
              {/*      defaultValue="all"*/}
              {/*      style={{ marginBottom: "20px" }}*/}
              {/*      onChange={this.tabChange}*/}
              {/*    >*/}
              {/*      <Radio.Button value="all">抖音配置</Radio.Button>*/}
              {/*      <Radio.Button value="all1">快手配置</Radio.Button>*/}
              {/*    </Radio.Group>*/}

              {/*    {this.state.tabState ? (*/}
              {/*      <div className="tab-main">*/}
              {/*        <Form.Item label="抖音主页链接" className="form-item">*/}
              {/*          <Form.Item name="douyinHref">*/}
              {/*            <Input*/}
              {/*              allowClear*/}
              {/*              className="ant-col-12"*/}
              {/*              placeholder="填入抖音的商户主页链接地址"*/}
              {/*            />*/}
              {/*          </Form.Item>*/}
              {/*          <span className="item-tip">*/}
              {/*            填入商户抖音门店的分享链接，可留空，格式参考如：https://v.douyin.com/JW1ApMP/*/}
              {/*          </span>*/}
              {/*        </Form.Item>*/}
              {/*        <Form.Item label="抖音商户名称" className="form-item">*/}
              {/*          <Form.Item name="douyinNikename">*/}
              {/*            <Input*/}
              {/*              allowClear*/}
              {/*              className="ant-col-12"*/}
              {/*              placeholder="填入抖音商户名称"*/}
              {/*            />*/}
              {/*          </Form.Item>*/}
              {/*          <span className="item-tip">*/}
              {/*            填入抖音商户名称，视频的商户名称跟该选项有关，格式参考：海底捞火锅*/}
              {/*          </span>*/}
              {/*        </Form.Item>*/}
                      <Form.Item label="抖音商户位置" className="form-item">
                        <Form.Item name="douyinPOI">
                          <Input
                            allowClear
                            className="ant-col-12"
                            placeholder="填入抖音商户地理位置页的分享链接"
                          />
                        </Form.Item>
                        <span className="item-tip c-red">
                          ！重要,宁可不填不要乱填，乱填视频不发
                          填入抖音商户地理位置页的分享链接，视频的地址跟该选项有关，可留空，抖音打开某个视频分享的位置，点击右上角转发，复制链接，将链接填写在此处，格式参考：https://v.douyin.com/JvcECpV/
                        </span>
                      </Form.Item>
                  {/*  </div>*/}
                  {/*) : (*/}
                  {/*  <div className="tab-main">*/}
                  {/*    <Form.Item*/}
                  {/*      label="商户快手主页链接"*/}
                  {/*      className="form-item"*/}
                  {/*      name="kuaishouHref"*/}
                  {/*    >*/}
                  {/*      <Input*/}
                  {/*        allowClear*/}
                  {/*        className="ant-col-12"*/}
                  {/*        placeholder="请填写商户的快手id"*/}
                  {/*      />*/}
                  {/*    </Form.Item>*/}
                  {/*    <Form.Item*/}
                  {/*      label="快手商户名称"*/}
                  {/*      className="form-item"*/}
                  {/*      name="kuaishouName"*/}
                  {/*    >*/}
                  {/*      <Input*/}
                  {/*        allowClear*/}
                  {/*        className="ant-col-12"*/}
                  {/*        placeholder="填入快手商户名称"*/}
                  {/*      />*/}
                  {/*    </Form.Item>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  <div style={{display: this.ddlkPlatformSellerDTO?.ddlkProductFeatures?.privilegyCanModifyAddress ? "block" : "none",}}>
                    <div className="form-titlt">权限</div>
                    <Form.Item
                      label="是否可修改店铺地址"
                      className={"form-radio"}
                      name="canModifyAddress"

                      // rules={[{ required: true, message: "商户类型不能为空！" }]}
                    >
                      <Radio.Group onChange={this.parentChange}>
                        <Radio value={Number(1)}>是</Radio>

                        <Radio value={Number(0)}>否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <div>
                    <div
                      className="form-titlt"
                      style={{
                        display:
                          this.ddlkPlatformSellerDTO?.ddlkProductFeatures
                            ?.sellerFeeAdvancedCloudVisitShop ||
                          this.ddlkPlatformSellerDTO?.ddlkProductFeatures
                            ?.sellerFeeAdvancedVisitShop
                            ? "block"
                            : "none",
                      }}
                    >
                      服务
                    </div>
                    {/* <div className="ant-row"> */}
                    <Row
                      style={{
                        display: this.ddlkPlatformSellerDTO?.ddlkProductFeatures
                          ?.sellerFeeAdvancedCloudVisitShop
                          ? "flex"
                          : "none",
                      }}
                    >
                      <Col>
                        <Form.Item
                          label="远程探店高级版"
                          name="check0"
                          valuePropName="checked"
                        >
                          <Checkbox
                            onChange={(e) => {
                              // if (!e.target.checked) {
                              //   this.formRef.current.setFieldsValue({
                              //     time0: [],
                              //   });
                              // }
                              this.setState({
                                check0: e.target.checked,
                              });
                            }}
                            style={{ width: 200 }}
                          >
                            开通
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label="服务生效时间"
                          name="time0"
                          rules={[
                            {
                              type: "array" as const,
                              required: this.state.check0,
                              message: "请选择服务生效时间",
                            },
                          ]}
                        >
                          <RangePicker />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      style={{
                        display: this.ddlkPlatformSellerDTO?.ddlkProductFeatures
                          ?.sellerFeeAdvancedVisitShop
                          ? "flex"
                          : "none",
                      }}
                    >
                      <Col>
                        <Form.Item
                          label="达人探店高级版"
                          name="check1"
                          valuePropName="checked"
                        >
                          <Checkbox
                            style={{ width: 200 }}
                            checked={true}
                            onChange={(e) => {
                              // if (!e.target.checked) {
                              //   this.formRef.current.setFieldsValue({
                              //     time1: [],
                              //   });
                              // }
                              this.setState({
                                check1: e.target.checked,
                              });
                            }}
                          >
                            开通
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label="服务生效时间"
                          name="time1"
                          rules={[
                            {
                              type: "array" as const,
                              required: this.state.check1,
                              message: "请选择服务生效时间",
                            },
                          ]}
                        >
                          <RangePicker />
                        </Form.Item>
                      </Col>
                    </Row>

                    {/* </div> */}
                  </div>
            {/*    </React.Fragment>*/}
            {/*  ) : null}*/}
            </div>

            <div className="form-btn">
              <Button type="primary" htmlType="submit">
                提交
              </Button>
              <Button onClick={() => this.onCancel()}>取消</Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}
