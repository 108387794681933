import * as React from "react";
import {Button, DatePicker, Form, Image, Input, message, Modal, Upload,} from "antd";
import "./index.css";
import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import moment from "moment";
import Request, {EHostType} from "../../utils/Request";

interface page {
  fileList: any;
  fileListShow: any;
  uploading: boolean;
}

export default class ConfigureAdd extends React.Component<
  { parent?: any },
  page
> {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      fileListShow: [],
      uploading: false,
    };
  }

  componentDidMount() {}

  handleChange = ({ fileList }) => this.setState({ fileListShow: fileList });

  onFinish = (value) => {
    for (let key in value) {
      if (value[key] === undefined || value[key] === "") {
        delete value[key];
      }
    }
    value["expiredTime"] = moment(value.expiredTime).format("YYYY-MM-DD");
    value["id"] = this.props.parent.state.rowData.id;
    const formData = new FormData();
    this.state.fileList.forEach((file) => {
      formData.append("businessLicense", file);
    });
    this.setState({
      uploading: true,
    });
    for (let key in value) {
      formData.append(key, value[key]);
    }
    let _this = this;
    Modal.confirm({
      title: "是否确认提交",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认提交？</p>,
      async onOk() {
        let url = `/op/operating-entity/saveOrUpdate`;
        let ret = await Request.post(EHostType.XMGX, url, formData);
        if (ret.code == 0) {
          _this.props.parent.visiblePop(false);
          _this.props.parent.getData({ size: 10, current: 1 });
          message.success("提交成功");
          _this.props.parent.setState({ loadings: false });
        }
      },
    });
  };

  /**密码重置 */
  resetPassword = () => {
    let _this = this;
    Modal.confirm({
      title: "密码重置提示",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认重置密码？</p>,
      async onOk() {
        let url = `/op/operating-entity/password/rest/${_this.props.parent.state.rowData.id}`;
        let ret = await Request.post(EHostType.XMGX, url, "");
        if (ret.code == 0) {
          message.success("重置成功");
        }
      },
    });
  };

  public render() {
    const { fileList } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    return (
      <Form
        name="form"
        className="form-container"
        initialValues={this.props.parent.state.rowData}
        onFinish={this.onFinish}>
        <div className="form-content">
          <Form.Item
            label="公司名称"
            className="form-item ant-col-23"
            name="name"
            rules={[{ required: true, message: "公司名称不能为空！" }]}>
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="管理员姓名"
            className="form-item ant-col-23"
            name="realName"
            rules={[{ required: true, message: "负责人姓名不能为空！" }]}>
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="管理员手机号码"
            className="form-item ant-col-23"
            name="phone"
            rules={[{ required: true, message: "负责人手机号码不能为空！" }]}>
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="管理员登录帐号"
            className="form-item ant-col-23"
            name="userName"
            rules={[{ required: true, message: "管理员账号不能为空！" }]}>
            <Input allowClear />
          </Form.Item>

          <Form.Item label="登录密码" className="form-item resetPassword ant-col-23">
            <Input disabled placeholder="******" className="ant-col-14" />
            <Button onClick={this.resetPassword}>密码重置</Button>
          </Form.Item>

          <Form.Item label="营业执照(选填)" className="form-item form-img ant-col-23">
            {this.props.parent.state.rowData.businessLicenseUrl !== undefined ? (<Image src={this.props.parent.state.rowData.businessLicenseUrl} />) : (<span style={{ color: "#b1b0b0" }}>暂无图片...</span>)}
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>图片上传</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="系统有效期"
            className="form-item form-img ant-col-23"
            name="expiredTime"
            rules={[{ required: true, message: "系统有效期不能为空！" }]}>
            <DatePicker />
          </Form.Item>
        </div>

        <div className="form-btn">
          <Button type="primary" htmlType="submit">提交</Button>
          <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
        </div>
      </Form>
    );
  }
}
