import {Button, Card, Cascader, Col, DatePicker, Form, Row, Select,} from "antd";
import address from "../../../../assets/js/area.json";

import getArea from "../../../../utils/areaTwoLevel";
import React, {useEffect, useState} from "react";

const { Option } = Select;
const { RangePicker } = DatePicker;
interface IFormProps {
  onFinish: any;
  onAdd: any;
}
export default function FormSearch(props: IFormProps) {

  const [areaList, setAreaList] = useState([]);


  useEffect(() => {
    //debugger;
    setAreaList(getArea(address));
  }, []);



  return (
    <Card className={"search-container"}>
      <Form onFinish={props.onFinish}>
        <Row gutter={24} >
          <Col span={5} className={"hidden"}>
            <Form.Item
              label="产品"
              name="productCode"
              // className="search-item"
            >
              <Select>
                <Select.Option value="ddlk">多多来客</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="所在地区" name="residence">
              <Cascader
                allowClear
                options={areaList}
                placeholder="请选择省市"
                style={{ width: "100%" }}
                fieldNames={{ children: "items" }}
                changeOnSelect
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="配置时间" name="date">
              <RangePicker showTime />
            </Form.Item>
          </Col>
          {/* <Col span={5}>
            <Form.Item
              label="等级"
              name="productCode"
              // className="search-item"
            >
              <Select>
                <Select.Option value="ddlk">lv1</Select.Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col span={2}>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
          </Col>
          <Col span={2}>
            <Button type="ghost" htmlType="reset">
              重置
            </Button>
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={props.onAdd}>
              新增
            </Button>
          </Col>
        </Row>
        {/*<Row gutter={24}>*/}
        {/*    <Col span={20}></Col>*/}
        {/*    <Col span={4}>*/}
        {/*        <Button type='primary' htmlType='submit'>搜索</Button>*/}
        {/*        <Button type='ghost' style={{marginLeft:'10px'}} htmlType='reset'>重置</Button>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
      </Form>
    </Card>
  );
}
