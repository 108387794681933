import {Button, DatePicker, Descriptions, Input, message} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import Request, {EHostType} from "../../../../../../utils/Request";
import {customerTaskStatusMap} from "../../../../helper";
import {RechargeTable} from "../../rechargeDetail";
import {RewardsTable} from "../../rewardRecords";
import {SubStore} from "../../subStore";

const { RangePicker } = DatePicker;

interface IProps {
  detailInfo: any;
}

export const TaskDetailInfo = (props: IProps) => {
  const { detailInfo } = props;
  const [taskName, setTaskName] = useState("");
  const [joinTimeFrom, setJoinTimeFrom] = useState<any>("");
  const [joinTimeTo, setJoinTimeTo] = useState("");
  const [onOpenRechargeDetail, setOnOpenRechargeDetail] =
    useState<boolean>(false);
  const [onOpenRewardRecords, setOnOpenRewardRecords] =
    useState<boolean>(false);
  const [onCheckSubStore, setOnCheckSubStore] = useState<boolean>(false);

  useEffect(() => {
    if (detailInfo) {
      setJoinTimeFrom(detailInfo.joinTimeFrom);
      setJoinTimeTo(detailInfo.joinTimeTo);
      setTaskName(detailInfo.taskName);
    }
  }, [detailInfo]);
  let host: any = sessionStorage.getItem("host");
  const customTag = JSON.parse( sessionStorage.getItem('ddlkPlatformSellerDTO') || '0');
  const doSave = () => {
    return new Promise((reslove, reject) => {
      let host: any = sessionStorage.getItem("host");
      const url = `/mgr/ddlk/saveTaskDraft`;
      Request.jsonPost(EHostType.DDlk, url, {
        id: detailInfo.id,
        taskName: taskName,
        joinTimeFrom: joinTimeFrom.split(" ")[0],
        joinTimeTo: joinTimeTo.split(" ")[0],
      }).then((res) => {
        if (res.code === 0) {
          message.success("保存成功");
        }
      });
    });
  };

  const doSubmit = async () => {
    let host: any = sessionStorage.getItem("host");
    const url = `/mgr/ddlk/consumertask/saveTask`;
    const { id, taskName, rewardConfig, examineDays, groupbyBonusFee, saleNo } =
      detailInfo;
    const resp = await Request.jsonPost(EHostType.DDlk, url, {
      id,
      taskName,
      rewardConfig,
      examineDays,
      groupbyBonusFee,
      saleNo,
    });
    if (resp.code == 0) {
      message.success("提交成功");
    }
  };

  return (
    <div>
      <Descriptions title="活动详情" bordered>
        <Descriptions.Item label="任务ID" span={2}>
          {detailInfo.id}
        </Descriptions.Item>
        <Descriptions.Item label="关联商家ID" span={2}>
          {detailInfo.adviserReferSellerId}
        </Descriptions.Item>
        <Descriptions.Item label="任务名称" span={2}>
          <Input
            value={taskName}
            onChange={(e) => {
              setTaskName(e.target.value);
            }}
            disabled={detailInfo.taskStatus !== 1}
          />
        </Descriptions.Item>
        <Descriptions.Item label="任务时间" span={2}>
          <RangePicker
            value={[
              joinTimeFrom
                ? moment(joinTimeFrom, "YYYY-MM-DD")
                : moment(new Date()),
              joinTimeTo
                ? moment(joinTimeTo, "YYYY-MM-DD")
                : moment(new Date()),
            ]}
            onChange={(e: any) => {
              setJoinTimeFrom(e[0].format("YYYY-MM-DD"));
              setJoinTimeTo(e[1].format("YYYY-MM-DD"));
            }}
            disabled={detailInfo.taskStatus !== 1}
          />
        </Descriptions.Item>
        <Descriptions.Item label="任务类型" span={2}>
          {customTag.customerTaskCustName}
        </Descriptions.Item>
        <Descriptions.Item label="任务状态" span={2}>
          <a>{customerTaskStatusMap[detailInfo.taskStatus]}</a>
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="任务配置" bordered style={{ margin: "24px 0" }}>
        <Descriptions.Item label={`${customTag.customerTaskCustNamePrefix}视频发布后`} span={2}>
          <Input
            value={detailInfo?.examineDays}
            disabled={detailInfo.taskStatus !== 1}
            addonAfter="天内"
            style={{ width: "200px" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="团购佣金" span={2}>
          <Input
            value={
              detailInfo?.groupbyBonusFee
                ? detailInfo?.groupbyBonusFee / 100
                : 0
            }
            disabled={detailInfo.taskStatus !== 1}
            addonAfter="元"
            style={{ width: "200px" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="播放视频达到" span={2}>
          <div style={{ display: "flex" }}>
            {detailInfo?.rewardConfig && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {Object.keys(detailInfo?.rewardConfig).map((item) => {
                  return <div>{item}</div>;
                })}
              </div>
            )}
            {detailInfo?.rewardConfig && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "8px",
                }}
              >
                {Object.values(detailInfo?.rewardConfig).map((item: any) => {
                  return <div>奖励现金 {item / 100} 元</div>;
                })}
              </div>
            )}
          </div>
        </Descriptions.Item>
        {detailInfo?.subStore?.length > 0 && (
          <Descriptions.Item label="参与子门店" span={2}>
            <SubStore onCheckSubStore={onCheckSubStore} details={detailInfo} />
          </Descriptions.Item>
        )}
      </Descriptions>

      <Descriptions title="奖金池配置" bordered>
        <Descriptions.Item label="累计奖金池金额" span={2}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {detailInfo.totalMoney ? detailInfo.totalMoney / 100 : 0}
            <RechargeTable
              id={detailInfo.id}
              onOpenRechargeDetail={onOpenRechargeDetail}
            />
          </div>
        </Descriptions.Item>

        <Descriptions.Item label="累计服务费" span={2}>
          {detailInfo.platformFee ? detailInfo.platformFee / 100 : 0}
        </Descriptions.Item>
        <Descriptions.Item label="累计发放金额" span={2}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {detailInfo.spentMoney ? detailInfo.spentMoney / 100 : 0}
            <RewardsTable
              details={detailInfo}
              onOpenRewardRecords={onOpenRewardRecords}
            />
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="剩余奖金池金额" span={2}>
          {detailInfo.leftMoney ? detailInfo.leftMoney / 100 : 0}
        </Descriptions.Item>
      </Descriptions>

      {/*<div style={{ marginTop: '30px' }}>*/}
      {/*    <Button type='ghost' onClick={() => { doSave() }}>保存草稿</Button>*/}
      {/*    <Button type='primary' style={{ marginLeft: '20px' }} onClick={() => { doSubmit() }} >确认提交</Button>*/}
      {/*</div>*/}

      <div
        style={{
          marginTop: "30px",
          display: detailInfo.taskStatus !== 1 ? "none" : "block",
        }}
      >
        {/* <Button
          type="ghost"
          onClick={() => {
            doSave();
          }}
        >
          
        </Button> */}
        <Button
          type="primary"
          style={{ marginLeft: "20px" }}
          onClick={() => {
            doSubmit();
          }}
        >
          确认
        </Button>
      </div>
    </div>
  );
};
