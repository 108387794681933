import { Button, Divider, Form, InputNumber, message, Modal, Popover, Table, Tabs, Typography, Upload, } from "antd";
import React, { useEffect, useState } from "react";
import Request, { EHostType } from "../../../../../utils/Request";
import { RefundAudit } from "./refundAudit";
import { TaskAudit } from "./taskAudit";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
const { TabPane } = Tabs;
let host: any = sessionStorage.getItem("host");

interface IProps {
  onOpenDetail: any;
  searchParams: any;
  tab: string;
  isDetail: boolean | undefined;
  isCustom: boolean | undefined;
  menuType: any;
  getQueryType: any
  isShowModal,
  flag: any


  // onOpenJoinList:any
}

const EditCell = (props) => {
  const [form] = Form.useForm();
  const save = async () => {
    form.setFieldsValue({
      saleNo: props.item.saleNo,
    });
    const values = await (form as any).validateFields();
    props.handleSave(props.item.key);
  };

  return (
    <Form form={form}>
      <Form.Item
        style={{ margin: 0 }}
        name="saleNo"
        rules={[{ required: true, message: `请输入销量.` }]}
      >
        <InputNumber
          min={0}
          value={props.item.saleNo}
          onChange={(value) => {
            props.item.saleNo = value;
          }}
        />
        <Typography.Link onClick={save}>完成</Typography.Link>
      </Form.Item>
    </Form>
  );
};

export const TaskListTable = (props: IProps) => {
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [queryType, setQueryType] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [tableHeight, setTableHeight] = useState(300);
  const [introduceValue, setIntroduceValue] = useState("");
  const [imageUrl, setImageUrl] = useState<string>();
  const oemId = sessionStorage.getItem('operatingEntityId');
  const isShowGoodTaskColumn = sessionStorage.getItem("functionLimits")?.includes("a15");
  let isShowOemTitle = false;
  if(oemId == "43" || oemId == "1"){
    isShowOemTitle = true;
  }else{
    isShowOemTitle = false;
  }

  /**表格高度自适应 */
  const tableAdapt = () => {
    let search: any = props.menuType ? 300 : document.getElementsByClassName("search-container" + props.tab)[0].clientHeight + 265 + 40;
    setTableHeight(search);
  };
  useEffect(() => {
    tableAdapt();
  }, []);


  const onChangeOderNo = (e) => {
  }
  const handleSave: (index: number) => void = (index: number) => {
    const dataSourceMap = [...dataSource];
    (dataSourceMap[index] as any).edit = false;
    salaSubmit(dataSourceMap[index]);
  };
  const salaSubmit = async (detailInfo) => {
    let host: any = sessionStorage.getItem("host");
    const url = `/mgr/ddlk/saveTaskDraft`;
    const { id, saleNo } = detailInfo;
    const resp = await Request.jsonPost(EHostType.DDlk, url, { id, saleNo: saleNo });
    if (resp.code == 0) {
      setDataSource([...dataSource]);
      message.success("提交成功");
    }
  };





  let columns: any = [
    {
      title: "任务id",
      dataIndex: props.menuType ? "taskId":"id",
      key: "id",
      width: 80,
      align: "center",
      fixed: "left",
    },
    {
      title: "任务标题",
      dataIndex: "taskName",
      key: "taskName",
      align: "center",
      width: 200,
      fixed: "left",
    },
    {
      title: "关联商家",
      dataIndex: "adviserUserName",
      key: "adviserUserName",
      align: "center",
      width: 150,
      fixed: "left",
    },
    {
      title: "OEM名称",
      dataIndex: "oemBrand",
      key: "oemBrand",
      align: "center",
      width: 150,
      fixed: "left",
    },
    {
      title: "关联代理商",
      dataIndex: "merchantAgentId",
      key: "merchantAgentId",
      align: "center",
      width: 200,
      render(text, record) {
        return (
          <>
            <p style={{ margin: 0 }}>id：{record.merchantAgentId}</p>
            <p style={{ margin: 0 }}>名称：{record.merchantAgentName}</p>
            <p style={{ margin: 0 }}>手机号：{record.merchantAgentPhone}</p>
          </>
        );
      },
    },
    {
      title: "达人类型",
      dataIndex: "darenType",
      key: "darenType",
      width: 100,
      align: "center",
      render(text) {
        return text === 1 ? "素人云剪" : (text === 2 ? "达人云剪" : "成片分发");
      },
    },
    {
      title: "订单详情",
      dataIndex: "address",
      key: "address",
      width: 200,
      align: "center",
      render: (row, i) => {
        return (
          <div>
            <div>达人单价:{i.pricePerDaren / 100}/人</div>
            <div>需求达人数量:{i.darenNum}人</div>
          </div>
        );
      },
    },
    {
      title: "付款总金额",
      dataIndex: "totalPaidFeeText",
      key: "totalPaidFeeText",
      width: 120,
      align: "center",
    },
    {
      title: "发布时间",
      dataIndex: "publishTime",
      key: "publishTime",
      align: "center",
      width: 120,
    },
    {
      title: "任务发布时间",
      dataIndex: "timeRange",
      key: "timerange",
      align: "center",
      width: 120,
    },
    {
      title: "任务状态",
      dataIndex: "taskStatusText",
      key: "taskStatusText",
      align: "center",
      width: 110,
      render: (row, i) => {
        return (
          <div>

            {(i?.taskStatus === 1 || i?.taskStatus === 2) && (
              <div style={{ color: "#FFAA33" }}>{calTaskStatusText(i?.taskStatus, queryType)}</div>
            )}
            {i?.taskStatus == 3 && (
              <div style={{ color: "#26BF4D" }}>{calTaskStatusText(i?.taskStatus, queryType)}</div>
            )}

            {i?.taskStatus == 4 && (
              <Popover
                placement="topLeft"
                title="驳回原因"
                content={i?.notApprovedReason}
                arrowPointAtCenter
              >
                <div style={{ color: "#ff1e1e" }}>{calTaskStatusText(i?.taskStatus, queryType)}</div>
              </Popover>
            )}
            {i?.taskStatus == 5 && (
              <div style={{ color: "#999" }}>{calTaskStatusText(i?.taskStatus, queryType)}</div>
            )}
            {i?.taskStatus == 6 && (
              <div style={{ color: "#999" }}>{calTaskStatusText(i?.taskStatus, queryType)}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "商家端首页展示",
      dataIndex: "isShow",
      key: "isShow",
      width: 100,
      render: (row, i) => {
        return (
          <div>
            <Button
              type="link"
              onClick={() => {
                props.isShowModal(true, i.id, JSON.stringify(i))
              }}
            >
              {i.isAppShow ? "编辑案例" : "展示图文案例"}
            </Button>
            {
              i.isAppShow ?
                <Button
                  type="link"
                  onClick={() => {
                    props.isShowModal(false, i.id, JSON.stringify(i))
                  }}
                >
                  取消展示
                </Button> : ''
            }
          </div>
        );
      },
    },
    //1， 待配置，2，待审核，3， 审核通过，4，审核不通过，待修改，5，已取消 6，待运营商确认
    {
      title: "操作",
      dataIndex: "operate",
      key: "operate",
      width: 100,
      render: (row, i) => {
        return (
          <div>
            {
              props.isDetail && !props.menuType ? <Button
                type="link"
                onClick={() => {
                  props.onOpenDetail(i);
                }}
              >
                详情
              </Button> : ""
            }
            {/*{i.taskStatus == 1 && i.comboType == 1 && <RefundAudit item={i} />}*/}
            {canBeAudit(i)  && !props.menuType && (
              <TaskAudit item={i} getDarenType={(e) => getDarenType(e)} onAuditSuccess={() => getList({})} />
            )}
            {i.taskStatus == 3 && (
              <Button
                type="link"
                onClick={() => {
                  props.onOpenDetail(i, "2");
                }}
              >
                参与列表
              </Button>
            )}

          </div>
        );
      },
    },


  ];


  const getColumns = () => {
    let isAgentEnabled = sessionStorage.getItem("isAgentEnabled");
    if (isAgentEnabled != 'true') {
      return columns.filter((item, index) => index != 3);
    } else if (!props.isCustom) {
      return columns.filter((item, index) => item.title != "OEM名称" && item.title != "发布时间" && item.title != "商家端首页展示");
    }else if (props.isCustom && !props.menuType) {
      return columns.filter((item, index) => item.title != "OEM名称" && item.title != "发布时间" && item.title != "商家端首页展示");
    } else if (props.menuType  && !isShowOemTitle ) {
      if(isShowGoodTaskColumn){
        return columns.filter((item, index) => item.title != "关联代理商" && item.title != "订单详情" && item.title != "任务发布时间" && item.title != "OEM名称");
      }else{
        return columns.filter((item, index) => item.title != "关联代理商" && item.title != "订单详情" && item.title != "任务发布时间" && item.title != "OEM名称" && item.title != "商家端首页展示");
      }
    } else if (props.menuType &&  isShowOemTitle ) {
      if(isShowGoodTaskColumn){
        return columns.filter((item, index) => item.title != "关联代理商" && item.title != "订单详情" && item.title != "任务发布时间");
      }else{
        return columns.filter((item, index) => item.title != "关联代理商" && item.title != "订单详情" && item.title != "任务发布时间"  && item.title != "商家端首页展示");
      }
    }else if (!props.menuType) {
      return columns.filter((item, index) => item.title != "关联代理商" && item.title != "订单详情" && item.title != "任务发布时间" && item.title != "OEM名称" && item.title != "商家端首页展示");
    } else {

      return columns;
    }
  }
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (props.searchParams || props.flag) {
      getList({ ...props.searchParams, pageNo: 1 });
    } else if (queryType) {
      getList({ queryType, pageNo: 1 });
    } else {
      getList({ pageNo: 1 });
    }
  }, [props.searchParams, props.flag, queryType]);

  const taskStatusTextMap = {
    "1": "待配置",
    "2": "待审核",
    "3": "审核通过",
    "4": "审核未通过",
    "5": "已取消",
    "6": "待运营商确认"
  };
  const queryTypeTextMap = {
    "undergoing": "进行中",
    "under-review": "待审核",
    "confing": "待配置",
    "isover": "已结束"

  }

  const getDarenType = (childrenType) => {
    setQueryType(childrenType);
  }

  const calTaskStatusText = (taskStatus, queryType) => {

    let statusText = null;
    if (queryType) {
      statusText = queryTypeTextMap[queryType];
    }
    if (statusText == null) {
      statusText = taskStatusTextMap[taskStatus];
    }
    return statusText;
  }


  const canBeAudit = (item) => {
    let b1 = [2, 6].includes(item.taskStatus);
    return b1;
  }
  const setShowOrconceal = (i) => {
    // if(i.isShow){
    //   setIsShow(0)
    // }else{
    //   setIsShow(1)
    // };
    let isShow = i.isShow ? 0 : 1;
    let params = {
      taskType: 1,
      darenType: props.searchParams.darenType
    };
    Request.jsonPost(EHostType.DDlk, `/mgr/ddlk/displayExcellentTask?taskId=${i.id}&isShow=${isShow}`, { ...params }).then(res => {
      if (res.data) {
        let listParams = {
          pageNo: pageNo,
          darenType: props.searchParams.darenType
        };
        message.open({
          type: 'success',
          content: '操作成功',
        });
        getList(listParams)
      }
    })
  };

  const getList = async (params: any) => {
    if (props.menuType) {
      setQueryType("");
    }
    if (params.pageNo) {
      setPageNo(params.pageNo);
    }
    let host: any = sessionStorage.getItem("host");
    let url = props.menuType ? `mgr/ddlk/excellentTask/queryExcellentTaskV2` : `/mgr/ddlk/queryAllTask`;
    setLoading(true);
    Request.jsonPost(EHostType.DDlk, url, {
      pageNo: params.pageNo ? params.pageNo : pageNo,
      pageSize: pageSize,
      ...params,
      queryType,
      distributorType: props.tab,
      taskType: props.menuType ? 1 : ''
    })
      .then((res) => {
        if (res.data) {
          setDataSource(
            res?.data?.map((item, index) => {
              const startTime = moment(
                item.pubTimeFrom,
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD");
              const endTime = moment(
                item.pubTimeTo,
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD");
              if (props.menuType || queryType == 'excellentTask') {
                item.adviserUserName = item.adviserUserName;
                item.oemBrand = item.oemOperatingEntityName
              };
              return {
                ...item,
                key: index,
                totalPaidFeeText: `${item.totalPaidFee / 100}元`,
                comboTypeText: { "1": "普通套餐", "2": "省心套餐" }[
                  item.comboType
                ],
                timeRange:
                  item?.pubTimeFrom && item?.pubTimeFrom
                    ? `${startTime}-${endTime}`
                    : "-",
                taskStatusText: calTaskStatusText(item.taskStatus, queryType)
              };
            })

          );

          setTotal(res.totalCount);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onTabsChange = (key: string) => {
    setQueryType(`${key}`);
    props.getQueryType(key)
  };
  return (
    <div style={{ marginTop: "10px", background: "#fff" }}>

      <Tabs
        defaultActiveKey=""
        type="card"
        size="small"
        activeKey={queryType}
        onChange={(key) => onTabsChange(key)}
      >
        {!props.menuType && <TabPane tab="全部" key=""></TabPane>}
        {!props.menuType && <TabPane tab="进行中" key="undergoing"></TabPane>}
        {!props.menuType && <TabPane tab="待审核" key="under-review"></TabPane>}
        {!props.menuType && <TabPane tab="待配置" key="confing"></TabPane>}
        {!props.menuType && <TabPane tab="已结束" key="isover"></TabPane>}
        {!props.menuType && sessionStorage.getItem('isAgentEnabled') && <TabPane tab="待运营商确认" key="waitMerchant"></TabPane>}
        {/* {!props.menuType&&<TabPane tab="优秀案例" key="excellentTask"></TabPane>} */}

      </Tabs>
      <Table
        dataSource={dataSource}
        columns={getColumns()}
        scroll={{ y: `calc(100vh - ${tableHeight}px)`, x: 1600 }}
        size={"small"}
        loading={loading}
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: total,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: (page: number) => {
            getList({ pageNo: page, ...props.searchParams });
          },
        }}
      />

    </div>
  );
};
