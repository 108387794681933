import * as React from "react";
import * as Icon from "@ant-design/icons";
import { Input, Form, Table, Button, Modal, message, DatePicker, Select, Row, Col, Popconfirm } from "antd";
import Request, { EHostType } from "../../utils/Request";
import "./index.css";
import AgentAdd from "./AgentAdd";
import AgentShow from "./AgentShow";
import AgentEdit from "./AgentEdit";
import moment from "moment";
import getArea from "../../utils/areaTwoLevel";
import area from "../../assets/js/area.json";

const { RangePicker } = DatePicker;
let host: any = sessionStorage.getItem("host");

/**代理商管理 */
interface page {
  columns: any;
  dataSource: any;
  visible: boolean;
  modelTitle: string;
  rowData: any;
  loadings: boolean;
  total: number;
  size: number;
  current: number;
  tableHeight: number;
  detailShow: boolean;
  dataInfo: any;
  isAdd: boolean | undefined;
  remove: boolean | undefined;
}

export default class AgentManagement extends React.Component<
  { match: { params: any }; history: any },
  page
> {
  private formSearch: any = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isAdd: true,
      remove: true,
      detailShow: false,
      dataInfo: null,
      columns: [
        {
          title: "用户id",
          dataIndex: "id",
          key: "id",
          align: "center",
          width: "100px",
        },
        {
          title: "昵称",
          dataIndex: "name",
          key: "name",
          align: "center",
          width: "110px",
        },
        {
          title: "推广员手机号码",
          dataIndex: "phone",
          key: "phone",
          align: "center",
          width: "130px",
        },
        {
          title: "等级",
          dataIndex: "level",
          key: "level",
          align: "center",
          width: "105px",
          render: (text, record, index) => {
            if (text === "2:1") return <span>推广员</span>;
            else if (text === "2:2") return <span>城市代理商</span>;
          },
        },
        {
          title: "负责商家数",
          key: "statManageSellerNo",
          dataIndex: "statManageSellerNo",
          align: "center",
          width: "105px",
        },
        {
          title: "累计消费",
          key: "statTotalPaidFee",
          dataIndex: "statTotalPaidFee",
          align: "center",
          width: "100px",
          render: (text, record, index) => {
            return text ? text / 100 : 0;
          },
        },
        {
          title: "推广员介绍数",
          key: "statInvitedAgentNo",
          dataIndex: "statInvitedAgentNo",
          align: "center",
          width: "100px",
        },
        {
          title: "注册时间",
          key: " registerSuccTime",
          dataIndex: "registerSuccTime",
          align: "center",
          width: "180px",
        },
        {
          title: "总佣金",
          key: "statCommissionFee",
          dataIndex: "statCommissionFee",
          align: "center",
          width: "180px",
          render: (text, record, index) => {
            // return text > 0 ? text / 100 : text;
            return text / 100;
          },
        },
        {
          title: "成为时间",
          key: "createdTime",
          dataIndex: "createdTime",
          align: "center",
          width: "180px",
        },
        {
          title: "有效期",
          key: "expiredTime",
          dataIndex: "expiredTime",
          align: "center",
          width: "180px",
        },
        {
          title: "状态",
          key: "status",
          dataIndex: "status",
          align: "center",
          width: "180px",
          render: (text, record, index) => {
            return text === 0 ? "关闭" : "正常";
          },
        },
        {
          title: "操作",
          key: "room_code8",
          dataIndex: "room_code8",
          width: "180px",
          align: "center",
          fixed: "right",
          render: (text, record) => {
            return (
              <div className="table-col-btn">
                <span onClick={() => this.getDetail(record.id)} style={{ marginRight: '8px' }}>详情</span>
                {
                  this.state.remove ? <Popconfirm
                    title="确认删除该代理商吗?"
                    onConfirm={(id: any) => { this.onDelete(record.id); }}
                    okText="确认"
                    cancelText="取消">
                    <a href="#">删除</a>
                  </Popconfirm> : ""
                }

              </div>
            );
          },
        },
      ],
      dataSource: [
        {
          name: "Agent",
        },
      ],
      visible: false,
      modelTitle: "",
      rowData: {},
      loadings: false,
      total: 0,
      size: 10,
      current: 1,
      tableHeight: 300,
    };
  }
  getDetail = (id) => {
    let host: any = sessionStorage.getItem("host");
    Request.jsonPost(EHostType.XMGX, `/op/agent/ddlkAgentDetail?agentId=${id}`, {}).then((res) => {
      if (!res.error) {
        this.setState(
          {
            dataInfo: { ...res.data },
          },
          () => {
            this.showDetail(true);
          }
        );
      }
    });
  };
  onDelete = async (id) => {
    const res = await Request.jsonPost(EHostType.XMGX, `/op/agent/delDdlkAgent?agentId=${id}`, {});
    if (res.code === 0) {
      message.success("删除成功!");
      this.getData({ size: 10, current: 1 });
    } else {
      message.success("删除失败，请重试!");
      this.getData({ size: 10, current: 1 });
    }
  };
  showDetail = (visible) => {
    this.setState({ detailShow: visible });
  };
  componentDidMount() {
    this.setState({ isAdd: sessionStorage.getItem("functionLimits")?.includes("a2"), remove: sessionStorage.getItem("functionLimits")?.includes("a3") })
    console.log()
    this.getData({ size: 10, current: 1 });
    this.tableAdapt();
    window.addEventListener("resize", this.tableAdapt);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.tableAdapt);
  }

  /**表格高度自适应 */
  tableAdapt = () => {
    let search: any =
      document.getElementsByClassName("search-container")[0].clientHeight + 265;
    this.setState({ tableHeight: search });
  };

  /**获取数据 */
  private async getData(data, searchData?) {
    let host: any = sessionStorage.getItem("host");
    let res = await Request.jsonPost(EHostType.XMGX, `/op/agent/ddlkAgentQuery`,
      {
        ...searchData,
        pageSize: data.size,
        pageNo: data.current,
      }
    );
    console.log(res, "resp");
    if (res.code === 0) {
      res.data?.map((e) => {
        e["expiredTimes"] = e.expiredTime;
        return true;
      });
      this.setState({
        dataSource: res.data,
        total: res.totalCount,
        // size: res.data.size,
        current: data.current,
      });
    }
  }

  /**分页切换*/
  handleTableChange = (pagination) => {
    console.log(pagination, "pagination");
    this.setState(
      {
        current: pagination.current,
        size: pagination.pageSize,
      },
      () => {
        this.getData({ size: this.state.size, current: this.state.current });
      }
    );
  };

  /**显示弹窗 */
  visiblePop(state, tit?) {
    if (tit === "新增") {
      this.setState({
        rowData: {},
      });
    }
    this.setState({
      visible: state,
      modelTitle: tit,
    });
  }

  /**代理商家 */
  goAgent = (id) => {
    this.props.history.push("/shop/business/" + id);
    // this.props.parent.isData(true, { type: 'agentId', id: id });
  };

  /**判断增删改查页面 */
  listContent = (text) => {
    if (text === "新增") return <AgentAdd parent={this} />;
    else if (text === "编辑") return <AgentEdit parent={this} />;
  };

  /**查询 */
  onSearch = (value) => {
    for (let key in value) {
      if (value[key] === undefined || value[key] === "") delete value[key];
    }
    if (value["email"]) {
      value["createTimeFrom"] = moment(value.email[0]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      value["createTimeTo"] = moment(value.email[1]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    this.getData({ size: 10, current: 1 }, value);
  };

  /**重置方法 */
  onReset = () => {
    this.formSearch.current.resetFields();
    // this.getData({ size: 10, current: 1 });
  };

  public render() {
    return (
      <div className="container">
        <AgentShow parent={this} state={{ ...this.state }} dataInfo={{ ...this.state.dataInfo }} />
        {/* 搜索区域 */}
        <Form
          name="search"
          className="search-container"
          style={{ padding: "16px" }}
          ref={this.formSearch}
          onFinish={this.onSearch}
          initialValues={{ productCode: "ddlk" }}
        >
          <Row gutter={24}>
            <Col lg={12} xl={8} xxl={5} className="align-center hidden">
              <Form.Item
                label="产品"
                name="productCode"
              // className="search-item"
              >
                <Select>
                  <Select.Option value="ddlk">多多来客</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} xl={8} xxl={5}>
              <Form.Item label="代理商姓名" name="name">
                {/* className="search-item" */}
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col lg={12} xl={8} xxl={5}>
              <Form.Item label="手机号码" name="phone">
                {/* className="search-item" */}
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col lg={12} xl={12} xxl={7}>
              <Form.Item
                label="成为时间"
                name="email"
              // className="search-item range"
              >
                <RangePicker showTime />
              </Form.Item>
            </Col>
            <Col lg={12} xl={6} xxl={5}>
              <Form.Item label="状态" name="status">
                {/* className="search-item" */}
                <Select allowClear>
                  <Select.Option value={0}>关闭</Select.Option>
                  <Select.Option value={1}>正常</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4} xl={3} xxl={2}>
              <div className="search-btn-group ">
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<Icon.SearchOutlined />}
                >
                  查询
                </Button>
                <Button
                  icon={<Icon.ReloadOutlined />}
                  onClick={this.onReset}
                  className="ml-16"
                >
                  重置
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        {/* 表格区域 */}
        <div className="table-container">
          <div className="table-btn">{
            this.state.isAdd ? <Button
              type="primary"
              icon={<Icon.PlusCircleOutlined />}
              onClick={() => this.visiblePop(true, "新增")}
            >
              新增
            </Button> : ""
          }

          </div>
          <div className="tab-container">
            <Table
              columns={this.state.columns}
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              className="virtual-table"
              pagination={{
                showSizeChanger: false,
                pageSize: this.state.size,
                current: this.state.current,
                total: this.state.total,
                showTotal: (total) => `共 ${total} 条数据`,
              }}
              onChange={this.handleTableChange}
              scroll={{
                y: `calc(100vh - ${this.state.tableHeight}px)`,
                x: 1200,
              }}
            />
          </div>
        </div>
        <Modal
          destroyOnClose={true}
          title={this.state.modelTitle}
          centered={true}
          visible={this.state.visible}
          maskClosable={false}
          width="500px"
          className="from-modal"
          onCancel={() => {
            this.visiblePop(false);
          }}
          footer={null}
        >
          {this.listContent(this.state.modelTitle)}
        </Modal>
      </div>
    );
  }
}
