import {Alert, Button, Modal, Radio, Space, Input, message, DatePicker, Form,Select, InputNumber, Cascader,} from "antd";
import React, { useEffect, useState } from "react";
import address from "../../../../assets/js/area.json";
import getArea from "../../../../utils/areaTwoLevel";
import { dataUpdate } from "../source/action";

const { TextArea } = Input;
interface IProps {
  // item: any;
  // onAuditSuccess: any;
  id?: any;
  visible: boolean;
  setVisible(visible: boolean): void;
  getList: any;
  dataInfo: any;
}
export const Add = (props: IProps) => {
  const [value, setValue] = useState(1);
  const [reason, setReason] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [areaList, setAreaList] = useState([]);

  const setVisible = props.setVisible;

  useEffect(() => {
    setAreaList(getArea(address));
  }, []);

  const doSubmit = async (values) => {
    let obj;

    // if (values.residence.length > 0) {
    const [adviserProvince, adviserCity] = values.residence;
    obj = Object.assign({}, values, { adviserProvince, adviserCity });
    // }
    // props.id && (obj.id = props.id);
    if (props.dataInfo && props.dataInfo.id) {
      obj.id = props.dataInfo.id;
    }
    try {
      obj.priceForAdviser = obj.priceForAdviser * 100;
      const res = await dataUpdate(obj);
      if (res.code === 0) {
        message.success("操作成功");
        props.getList();
        setVisible(false);
      }

      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
    }
    //  .then((res) => {
    //      console.log(res);
    //      if (res?.data) {
    //        message.success("添加成功");
    //        // props.onAuditSuccess();
    //        props.getList();
    //        // // this.props.parent.getList({});
    //        setVisible(false);
    //      }
    //    })
    //    .finally(() => {
    //      setButtonLoading(false);
    //    })

    // setButtonLoading(true);
  };

  return (
    <>
      {/* <Button
        type="link"
        onClick={() => {
          setVisible(true);
        }}
      >
        任务审核
      </Button> */}
      <Modal visible={props.visible} onCancel={() => {setVisible(false);}} title="金额配置" footer={null} destroyOnClose>
        <Form
          name="form"
          className="form-container"
          onFinish={doSubmit}
          initialValues={props.dataInfo}
        >
          <div className="form-content">
            <Form.Item
              label="任务类型"
              className="form-radio"
              name="darenType"
              rules={[{ required: true, message: "探店类型不能为空！" }]}
            >
              <Radio.Group>
                <Radio value={1} disabled={props.dataInfo.id}>
                  素人云剪
                </Radio>
                <Radio value={2} disabled={props.dataInfo.id}>
                  达人云剪
                </Radio>
                <Radio value={3} disabled={props.dataInfo.id}>
                  成片分发
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="所在地区"
              name="residence"
              rules={[
                {
                  type: "array",
                  required: true,
                  message: "所在地区不能为空！",
                },
              ]}
              className="form-item ant-col-23 flex"
            >
              <Cascader
                options={areaList}
                placeholder="请选择省市区"
                style={{ width: "100%" }}
                changeOnSelect
                fieldNames={{ children: "items" }}
                disabled={props.dataInfo.id}
              />
            </Form.Item>
            <Form.Item
              label="对商家的售卖价格"
              className="form-item ant-col-23"
              name="priceForAdviser"
              rules={[{ required: true, message: "金额不能为空！" }]}
            >
              <InputNumber min={0} placeholder="请输入金额" />
            </Form.Item>

            <Form.Item
              label="最低参与人数"
              className="form-item ant-col-23"
              name="minDarenNum"
              rules={[{ required: true, message: "人数不能为空！" }]}>
              <InputNumber max={100} placeholder="请输入最低参与人数"/>
            </Form.Item>
          </div>

          <div className="form-btn">
            <Button type="primary" htmlType="submit" loading={buttonLoading}>
              提交
            </Button>
            <Button
              onClick={() => {
                setVisible(false);
              }}
            >
              {/* this.props.parent.visiblePop(false) */}
              取消
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
