import { Button, DatePicker, Image, message, Spin, Tabs, Upload, Modal,Progress} from "antd";
import { CloseCircleOutlined, CloseOutlined, PlusOutlined, } from "@ant-design/icons";

import React, { useEffect, useRef, useState } from "react";
import { isVideos } from "../../../../../../../utils/Utils";
import Request, { EHostType } from "../../../../../../..//utils/Request";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
let host: any = sessionStorage.getItem("host");

interface IProps {
  //所有素材数组（图片OR视频）
  dataSource: string[];
  filePrefix: string;
  tabIndex?: string;
  uploadVideOnly: boolean;
  maxMaterialNumPermit: number;
  requireMaterialNumPermit: number;
  onChange: (e: IDataItem[]) => any;
}

export interface IDataItem {
  status?: "uploading" | "done" | "error";
  //1是视频，2是图片
  type?: 1 | 2;
  path?: string;
  uid?: string;
  percent?: number;
  filePrefix?: string;
}

const debounce = () => {
  let timer;
  return (callback) => {
    if (timer) {
      clearTimeout(timer);
    }
    const callNow = !timer;

    timer = setTimeout(() => {
      timer = null;
    }, 1000);
    if (callNow) {
      callback();
    } else {
      message.info("点击频率过高");
    }
  };
};
const handler = debounce();
// const handlerPrev = debounce();
export const TabMaterial = (props: IProps) => {
  //视频素材每一页展示个数
  const showNum = 5;
  const { dataSource, filePrefix, onChange, uploadVideOnly, maxMaterialNumPermit, requireMaterialNumPermit } = props;
  //info当前所有视频素材数组
  const [info, setInfo] = useState<IDataItem[]>([]);
  const [imgPrevVis, setImgPrevVis] = useState(false);
  const [imgPrevUrl, setImgPrevUrl] = useState("");
  //当前
  const source = useRef<any>();
  const [initIndex, setInitIndex] = useState(0);
  const [videoTotalList, setVideoTotalList] = useState<any[]>([]);

  const [currentMaxCount, setCurrentMaxCont] = useState(0);
  const [fileList, setFileList] = useState([
    { uid: "fds", path: "fsdfs", status: "done" },
  ]);
  let ossUrl = "";

  const [fileToUploadNum, setFileToUploadNum] = useState(0);
  const [fileUploadedNum, setFileUploadedNum] = useState(0);
  const [fileUploadCancelled, setFileUploadCancelled] = useState(false);



  // const handler = debounce(() => {
  //   // console.log(999, videoTotalList, videoIndex);
  //   videoIndex + showNum <= videoTotalList.length - 1 &&
  //     setInitIndex(videoIndex + showNum);
  // });
  useEffect(() => {
    if (info.length > 0) {
      // 有效上传的数量  排除上传中和错误等情况
      const arr = info.filter((item) => item.type === 1);

      //   if (currentValid) {
      //     // setCurrentMaxCont(30-currentValid.length);
      //   }
      setVideoTotalList([...arr]);
    } else {
      setVideoTotalList([]);
    }
    // setInfo(info);
  }, [info]);

  useEffect(() => {
    if (dataSource) {
      const arr: any[] = [];
      //把素材转换成IDataItem类型
      const result: IDataItem[] = dataSource.map((item: any) => {
        if (isVideos(item.path)) {
          arr.push(item);
        }
        return {
          status: "done",
          path: item.path,
          type: isVideos(item.path) ? 1 : 2,
          uid: item.path,
          percent: item.percent || 0,
        };
      });
      source.current = result;
      setInfo([...result]);
      // setVideoTotalList([...videoTotalList, ...arr]);
    }
  }, [dataSource]);

  //antd上传文件 组件的属性,
  const uploadProps: any = {
    // action: `${upOssData.value}`,
    accept: uploadVideOnly ? "video/*" : "image/*||video/*",
    // headers: {
    //   Authorization: sessionStorage.getItem("token"),
    //   "Content-Type": "application/octet-stream",
    // },
    // method: "PUT",
    // name: "birnary",
    listType: "picture-card",
    multiple: true,
    maxCount: currentMaxCount,
    showUploadList: true,
    fileList: [],
    // defaultFileList: info,
    beforeUpload: (file, fileList) => {
      let isFirstFileToUpload=true;
      if(fileList[0].name != file.name){
        isFirstFileToUpload=false;
      }
      if(isFirstFileToUpload){
        console.log('curFileNo=0:')
        setFileToUploadNum(fileList.length);
        setFileUploadedNum(0);
      }
      if (file.size > 1024 * 1024 * 150) {
        message.info("上传文件不能大于150M");
        return false;
      }
      // var blob = new Blob([file], { type: "application/octet-stream" });
      return new Promise((resolve, reject) => {
        Request.get(EHostType.DDlk, `/mgr/ddlk/getOssTemporaryAuthorizationUrl?fileName=${file.name}`).then((res) => {
          if (res.code === 0) {
            if (source.current.length >= maxMaterialNumPermit && !["4"].includes(props.tabIndex!)) {
              message.info("最多可以上传" + maxMaterialNumPermit + "个");
              return false;
            }
            ossUrl = res.data.key.filePath;
            let filePrefix = res.data.key.filePrefix;
            let result: any = [
              { status: "uploading", uid: file.uid, percent: 0 },
            ];

            result[result.length - 1].type = isVideos(ossUrl) ? 1 : 2;

            source.current = source.current.concat(result);
            // let arr = source.current.concat(result);
            // console.log(arr, "hkh");

            // if (source.current && source.current.length > 0) {
            //   result = [...source.current, ...result];
            // }

            setInfo([...source.current]);
            // setVideoTotalList([...source.current]);

            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = () => {
              Request.putOss(EHostType.NONE, res.data.value.url, reader.result, res.data.value.contentType, (percent) => {
                result[result.length - 1].percent = percent;
                if (percent === 100) {
                  setTimeout(() => {
                    result[result.length - 1].path = res.data.key.filePath;
                    result[result.length - 1].filePrefix = res.data.key.filePrefix;
                    result[result.length - 1].status = "done";
                    doEmit();
                    setInfo([...source.current]);
                    //素材上传成功后，把素材添加到info数组中.
                    setInfo([...source.current]);
                    inscreaseFileUploadedNum();
                  }, 1500);
                }
                // result[result.length - 1].path = ossUrl;
                // result[result.length - 1].filePrefix = filePrefix;
                doEmit();

              }
              )
                .then((res) => {
                  // source.current = source.current.concat(result);

                  // doEmit();
                  // setInfo([...source.current]);
                  reject();
                })
                .catch((err) => {
                  // doEmit();
                  // source.current = source.current.filter(
                  //   (item) => item.status === "done"
                  // );
                  // setInfo([...source.current]);
                  // message.error("上传失败");
                });
            };
          }
        });
      });
      //   setInfo([...result]);
    },
    onChange: (e) => {
      const newData = source.current;
      e.fileList.forEach((item) => {
        const newDataIndex = newData.findIndex((fitem) => {
          return fitem.uid == item.uid;
        });
        const currentFile = newData[newDataIndex];
      });
    },
  };

  const getUploadedNum= () => {
    if (source && source.current && source.current.length > 0) {
      const result = source.current.filter((item) => {
        return item.status == "done";
      });
      if(result) {
        return result.length;
      }else{
        return 0;
      }
    } else {
      return 0;
    }
  };

  const doEmit = () => {
    if (source && source.current.length > 0) {
      const result = source.current.filter((item) => {
        return item.status == "done";
      });
      onChange(result);
    } else {
      onChange([]);
    }
  };

  const doRemove = (path?: string) => {
    Request.jsonPost(EHostType.DDlk, '/mgr/ddlk/cloudVisitShopTask/materials/delete', { key: `https://shws-public.oss-accelerate.aliyuncs.com/${path}` }).then(res => {
      if (res.data) {
        message.open({
          type: 'success',
          content: '删除成功',
        });
      }
    });
    let newData = source.current;
    newData = newData.filter((item) => {
      return item.path != path;
    });
    source.current = newData;
    doEmit();
    setInfo(newData);
  };
  const ControlImage = () => {
    return (
      <Image
        preview={{
          visible: imgPrevVis,
          src: imgPrevUrl,
          onVisibleChange: (value) => {
            setImgPrevVis(value);
          },
        }}
        style={{ display: "none" }}
        className="ddlktaskmaterialPic"
      />
    );
  };

  const shouldShowUploadProgress=()=>{

    if(fileUploadCancelled){
      return false;
    }
    let percentage = getUploadedPercentage();
    if( fileToUploadNum >0 &&  percentage <100 ){
      return  true;
    }
    return false;
  }

  const getUploadedPercentage=()=>{
    let i= fileToUploadNum;
    let j= fileUploadedNum;
    let result=1;
    if(i==0){
      result=100;
    }else{
      result= Math.trunc(j*100/i);
    }
    console.log('getUploadedPercentage result:'+result);
    return result;
  }
  const cancelUpload=()=>{
    setFileUploadCancelled(true) ;
  }
  const inscreaseFileUploadedNum=()=>{
    // console.log('fileUploadedNum:'+fileUploadedNum)
    setFileUploadedNum(preNum=>{
      return preNum+1;
    });
  }


  let videoIndex = initIndex;
  // const getVideoList = () => {
  //   const arr = [];
  //   for (let i = 0; i < showNum; i++) {
  //     arr.push()
  //   }
  // }
  //获取视频素材列表
  const getList = (videoList) => {
    //videoList是待展示的视频素材
    videoList = [];
    let i = videoIndex;
    for (i; i < videoIndex + showNum; i++) {
      videoTotalList[i] && videoList.push(videoTotalList[i]);
    }
    return videoList.map((item) => {
      return (
        // 暂时保存
        <div key={item.uid}>
          {item.status == "uploading" && (
            <div className="uploading-span">
              <Spin tip={`正在上传${item.percent}%`}></Spin>
            </div>
          )}
          {item.status == "error" && (
            <div className="ddlktaskmaterialfail">
              <CloseCircleOutlined style={{ color: "red" }} />
              <div style={{ color: "red" }}>上传失败</div>
            </div>
          )}
          {item.status == "done" && (
            <div>
              {/* {item.type == 1 && ( */}
              <div
                key={item.path}
                className="ddlktaskmaterialvideo"
                style={{ position: "relative", display: "inline-block" }}
              >
                <span
                  className="materialClear"
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer",
                    zIndex: 9999,
                  }}
                  onClick={() => {
                    doRemove(item.path);
                  }}
                >
                  <CloseOutlined />
                </span>
                <video
                  width={100}
                  height={100}
                  className="ddlktaskmaterialvideo"
                  src={`${item.filePrefix || props.filePrefix}${item.path}`}
                  controls
                ></video>
              </div>
              {/* // )} */}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>

        <ControlImage />
        {info
          .filter((item: IDataItem) => {
            //图片素材
            return item.type === 2;
          })
          .map((item: IDataItem) => {
            return (
              <div key={item.uid}>
                {item.status == "uploading" && (
                  <div className="uploading-span">
                    <Spin tip={`正在上传${item.percent}%`}></Spin>
                  </div>
                )}
                {item.status == "error" && (
                  <div className="ddlktaskmaterialfail">
                    <CloseCircleOutlined style={{ color: "red" }} />
                    <div style={{ color: "red" }}>上传失败</div>
                  </div>
                )}
                {item.status == "done" && (
                  <div>
                    {item.type == 2 && (
                      <div
                        key={item.path}
                        className="ddlktaskmaterialPic"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <div
                          className="materialClear"
                          onClick={() => {
                            doRemove(item.path);
                          }}
                        >
                          <CloseOutlined />
                        </div>

                        <img
                          src={`${item.filePrefix || props.filePrefix}${item.path
                            }`}
                          className="ddlktaskmaterialPic"
                        />
                        <Button
                          size={"small"}
                          type={"primary"}
                          style={{
                            position: "absolute",
                            right: 2,
                            bottom: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setImgPrevVis(true);
                            setImgPrevUrl(
                              `${item.filePrefix || props.filePrefix}${item.path
                              }`
                            );
                          }}
                        >
                          预览
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}

        <div style={{ display: "inline-block" }}>
          {currentMaxCount >= 0 && (
            <Upload {...uploadProps}>
              <Button icon={<PlusOutlined />} type="text">
                上传素材
              </Button>
            </Upload>
          )}
          {requireMaterialNumPermit != -1 ? <span>您需要上传 {requireMaterialNumPermit}个视频, 已上传{getUploadedNum()}个视频。不能少传，也无需多传</span> : <span>已上传{getUploadedNum()}个视频/图片</span>}
        </div>

        {  shouldShowUploadProgress() &&
        <Modal
            visible={true}
            title="文件上传中"
            onOk={cancelUpload}
            onCancel={cancelUpload}
            footer={[
              <Button key="取消上传" onClick={cancelUpload}>
                取消上传
              </Button>
            ]} >

          <div style={{textAlign:"center"}}>
            <Progress type="circle" percent={ getUploadedPercentage()} width={80} />
            <p><br />文件上传中，请等待，不要关闭浏览器 <br /></p>
          </div>
        </Modal>
        }



      </div>
      <div
        style={{
          display: videoTotalList.length > 0 ? "flex" : "none",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <p
          style={{ cursor: "pointer", marginRight: "10px" }}
          onClick={() => {
            handler(() => {
              videoIndex -= showNum;
              if (videoIndex < 0) videoIndex = 0;
              setInitIndex(videoIndex);
            });
          }}
        >
          上一页
        </p>
        {getList([])}
        <p
          style={{ cursor: "pointer" }}
          onClick={() => {
            handler(() => {
              // console.log(999, videoTotalList, videoIndex);
              videoIndex + showNum <= videoTotalList.length - 1 &&
                setInitIndex(videoIndex + showNum);
            });
          }}
        >
          下一页
        </p>
      </div>
    </div>
  );
};
