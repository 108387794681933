import {Button, Form, Input, message, Radio} from "antd";
import React, {Component} from "react";
import BottomContainer from "../BottomContainer";
import Editor from "../Editor";
import {FormInstance} from "antd/es/form";
import Request, {EHostType} from "../../../../utils/Request";
import PicturesWall from "../../Advertise/PicturesWall";

export default class Add extends Component<
  {
    onRefresh: () => void;
    id?: string | undefined;
    onCancel: () => void;
    getTableData: () => void;
    rowData?: any;
  },
  {}
> {
  state = {
    content: "",
    fileList: [],
  };
  id: any = null;
  // articleCotent: string = "";
  fromRef = React.createRef<FormInstance>();
  // editRef = React.createRef<Editor>();
  uploadFile = (values) => {
    let host: any = sessionStorage.getItem("host");
    const url = `/mgr/ddlk/article/uploadArticle`,
      params = new FormData();
    params.append("articleId", "0");
    params.append("fullArticle", "true");
    params.append("file", (this.state.fileList[0] as any).originFileObj);
    return Request.postFormData(EHostType.DDlk, url, params).then((res) => {
      return res.data;
    });
  };
  onFinish: (values: any) => void = async (values) => {
    let host: any = sessionStorage.getItem("host");
    let url = `/mgr/ddlk/article/saveOrUpdate`;

    if ((this.state.fileList[0] as any).originFileObj) {
      const covertPicUrl = await this.uploadFile(values);
      values.covertPicUrl = covertPicUrl;
    }
    Request.jsonPost(EHostType.DDlk, url, {
      ...values,
      id: this.id,
      // articleCotent: this.articleCotent,
    }).then((res) => {
      if (!res.error) {
        message.success("操作成功");
        this.props.onCancel();
        this.props.onRefresh();
      }
    });
  };
  handleChange: (info: any) => void = (info) => {
    this.fromRef.current!.setFieldsValue({ covertPicUrl: info.fileList[0] });
    this.setState({
      fileList: info.fileList,
    });
  };
  componentDidMount() {
    console.log(this.props, "oo");
    if (this.props.rowData) {
      const { articleTitle, articleCotent, articleFor, id, covertPicUrl } =
        this.props.rowData;
      this.id = id;
      const obj = {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: this.props.rowData.covertPicUrl,
      };
      this.fromRef.current?.setFieldsValue({
        articleTitle,
        articleCotent,
        articleFor: articleFor + "",
        covertPicUrl,
      });
      console.log(articleCotent, "mmm");
      this.setState({
        fileList: [obj],
        content: articleCotent,
      });
    }
  }
  componentDidUpdate() {
    // if (this.props.rowData) {
    //   const { articleTitle, articleCotent, articleFor, id, covertPicUrl } =
    //     this.props.rowData;
    //   this.id = id;
    //   const obj = {
    //     uid: "-1",
    //     name: "image.png",
    //     status: "done",
    //     url: this.props.rowData.covertPicUrl,
    //   };
    //   this.fromRef.current?.setFieldsValue({
    //     articleTitle,
    //     articleCotent,
    //     articleFor: articleFor + "",
    //     covertPicUrl,
    //   });
    //   console.log(articleCotent, "mmm");
    //   // this.setState({
    //   //   fileList: [obj],
    //   //   content: articleCotent,
    //   // });
    // }
  }
  componentWillUnmount() {
    // this.fromRef.current!.resetFields(["articleTitle"]);
  }

  editorChange: (content: any) => void = (content) => {
    // this.articleCotent = content;
    this.fromRef.current?.setFieldsValue({
      articleCotent: content,
    });
  };

  render() {
    return (
      <div className="container">
        <BottomContainer>
          <Form
            ref={this.fromRef}
            name="basic"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            autoComplete="off"
            className="form-container"
          >
            <div className="form-content">
              <Form.Item
                label="文章标题"
                name="articleTitle"
                rules={[{ required: true, message: "请输入文章标题" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="articleFor"
                label="所属分类"
                rules={[{ required: true, message: "请选择分类" }]}
              >
                <Radio.Group>
                  <Radio value="1">达人端</Radio>
                  <Radio value="0">商家端</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="covertPicUrl"
                label="封面图"
                rules={[{ required: true, message: "请选择封面图" }]}
              >
                <PicturesWall
                  maxCount={1}
                  fileList={this.state.fileList}
                  handleChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                label="文章内容"
                name="articleCotent"
                rules={[{ required: true, message: "请输入文章内容" }]}
              >
                <Editor
                  content={this.state.content}
                  // value={this.state.content}
                  editorChange={this.editorChange}
                ></Editor>
              </Form.Item>
            </div>
            <div className="form-btn">
              <Button type="primary" htmlType="submit">
                提交
              </Button>
              <Button onClick={() => this.props.onCancel()}>取消</Button>
            </div>
          </Form>
        </BottomContainer>
      </div>
    );
  }
}
