import FormSearch from "../FormSearch";
import TableC from "../TableC";
import { useState, useEffect, useMemo, useCallback } from "react";
import { getList } from "../source/action";
import { getColumns } from "../source/column";
import { Audit } from "../Audit";
import Detail from "../Detail";
import { log } from "console";
import Request, { EHostType } from "../../../../utils/Request";
import { message, Modal } from "antd";

// import 

export default function DarenManage() {
  const [visible, setVisible] = useState(false);
  const [withDrawerVisible, setwithDrawerVisible] = useState<any>(false);
  const [darenId, setdarenId] = useState("");
  const [searchParams, setSearchParams] = useState({});
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [record, setRecord] = useState<any>({});
  const [tableHeight, setTableHeight] = useState(0);
  const audit = (darenId, record) => {
    setVisible(true);
    setdarenId(darenId);
    setRecord(record);
  };
  // 打开详情页
  const goDetail = (darenId) => {
    setdarenId(darenId);
    setwithDrawerVisible(true);
  };
  // 关闭详情页
  const closeDetail = () => {
    setwithDrawerVisible(false);
  };
  const setShow = async (isShow, darenId) => {
     let obj = { darenId: darenId, isShow: isShow };
    let url = `/mgr/ddlk/dareninfo/updateDarenDetail`;
    let res = await Request.jsonPost(EHostType.DDlk, url, obj);
    // message.open({
    //   key,
    //   type: 'loading',
    //   content: 'Loading...',
    // });
    if (res.code === 0) {
      message.open({
        type: 'success',
        content: '修改成功'
      });
      setTableData()
    }
   
    // if (res.code === 0) {
    //   message.open({
    //     type: 'success',
    //     content: 'This is a success message',
    //   });
    // }
    // 
  }
  const [columns, setColumns] = useState(getColumns(audit, goDetail, setShow));
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  // 列表分页查询
  const handleTableChange = (val) => {
    setTableData({}, val);
  };
  /**表格高度自适应 */
  const tableAdapt = () => {
    let search: any =
      document.getElementsByClassName("search-container")[0].clientHeight + 265;
    setTableHeight(search);
  };
  // 列表页组件
  const TableCom = useMemo(
    () => (
      <TableC
        data={data}
        columns={columns}
        handleTableChange={handleTableChange}
        tableHeight={tableHeight}
        pagination={pagination}
        loading={tableLoading}
      ></TableC>
    ),
    [data, pagination, columns, tableLoading]
  );

  const setList = (data) => {
    setData(data);
  };
  // 设置列表数据
  const setTableData = useCallback(async (values = {}, page = {}) => {
    setTableLoading(true);
    const data = await getList(
      setList,
      Object.assign(searchParams, values),
      Object.assign(pagination, page)
    );
    setTableLoading(false);
    setList(data.data);
    setPagination({ ...pagination, total: data.totalCount });
  }, []);

  useEffect(() => {
    setTableData();
    tableAdapt();
  }, []);
  // 列表页搜索
  const onSearch = async (values) => {
    values.city = values.city?.[1];
    setSearchParams(values);
    setTableData(values);
  };
  // 详情页组件
  const DetailCom = useMemo(() => {
    return (
      <Detail
        visible={withDrawerVisible}
        onClose={closeDetail}
        darenId={darenId}
        setTableData={setTableData}
      />
    );
  }, [withDrawerVisible, darenId, setTableData]);
  return (
    <>
      <FormSearch onSearch={onSearch}></FormSearch>
      {/* <TableC
        data={data}
        columns={columns}
        handleTableChange={handleTableChange}
        tableHeight={0}
        pagination={{}}
      ></TableC> */}
      
      {TableCom}
      <Audit
        visible={visible}
        setVisible={setVisible}
        darenId={darenId}
        picUrl={record.filePrefix + record.dyGroupbyLevelPictUrl}
        dyGroupbyLevelApplyNew={record.dyGroupbyLevelApplyNew}
        fansCount={record.fansCount}
        getList={setTableData}
        city={{ province: record.provinceText, citytext: record.cityText }}
        dyNo={record.dyNo}
      />
     
      {DetailCom}
    </>
  );
}
