import axios from "axios";
import { Modal, Button, Space, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { EHostType } from "./Request";

const { confirm } = Modal;

const AxiosWrap: any = (type: EHostType) => {
  const request: any = axios.create({
    baseURL: "",
    timeout: 30000,
  });
  // 请求拦截器
  request.interceptors.request.use(
    function (config) {
      // 在发送请求之前做些什么
      let token = sessionStorage.getItem("sellerToken");
      config.headers.Authorization = token;
      let host = sessionStorage.getItem("host");
      host = host && JSON.parse(host);
      if (type == EHostType.NONE) {
        return config;
      } else {
        if (host && host[type]) {
          config.baseURL = host[type];
          return config;
        } else {
          let currentHost = window.location.origin;
          let xmgxHost = process.env.REACT_APP_REMOTE_URL_NEW;
          return new Promise((reslove, reject) => {
            fetch(`${xmgxHost}/operatingentity/getServerDomain?domain=${currentHost}`).then((res) => res.json()).then((res) => {
                if (res.code == 0) {
                  let hostOrg = {
                    ddlk: `https://${res.data.ddlk}`,
                    xmgx: `https://${res.data.xmgx}`,
                  };
                  sessionStorage.setItem("host", JSON.stringify(hostOrg));
                  config.baseURL = hostOrg[type];
                  reslove(config);
                }
              });
          });
        }
      }
      // return config;
    },
    function (error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    }
  );
  // 响应拦截器
  request.interceptors.response.use(
    function (response: any) {
      if (response.status === 200 && response?.headers["content-length"] === "0") {
        response.data = {};
        return response;
      }
      if (response.data.code === 0) {
        return response;
      } else {
        if (response.data.code == "401") {
          confirm({
            title: `${response.data.message}`,
            content: ``,
            okText: "去登录",
            onOk() {
              window.location.href = "/";
            },
            onCancel() {
              Modal.destroyAll();
            },
          });
        } else {
          response.data.message&&message.error(response.data.message);
        }
      }
      return response;
    },
    function (error: any) {
      console.log(error);
      return Promise.reject(error);
    }
  );

  return request;
};

export default class Request {
  public static async get(
    hostType: EHostType,
    url: string,
    params?: any,
    responseType?: string
  ) {
    try {
      let res = await AxiosWrap(hostType).get(url, params);
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }
  public static async getBlob(hostType: EHostType, url: string, params?: any) {
    try {
      let res = await AxiosWrap(hostType)({
        url: url,
        method: "get",
        params: params,
        responseType: "blob",
      });
      // let res = await AxiosWrap()({url:url, method: 'get',data:params,responseType: 'blob'});
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }

  public static async postBlob(hostType: EHostType, url: string, params?: any) {
    try {
      let res = await AxiosWrap(hostType).post(url, params, {
        responseType: "blob",
        headers: { "Content-Type": "application/json" },
      });
      return res.data;
    } catch (err:any) {
      console.log(err,"err")
      return { success: false, message: err.message };
    }
  }

  public static async delete(hostType: EHostType, url: string) {
    try {
      let res = await AxiosWrap(hostType).delete(url);
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }
  public static async post(hostType: EHostType, url: string, params: any) {
    try {
      let res = await AxiosWrap(hostType).post(url, params, {
        "Content-Type": "application/x-www-form-urlencoded",
      });
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }
  public static async jsonPost(hostType: EHostType, url: string, params: any) {
    try {
      let res = await AxiosWrap(hostType).post(url, params, {
        "Content-Type": "application/json",
      });
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }
}
