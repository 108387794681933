/*
 * @Date: 2022-07-06 10:50:17
 * @LastEditors: litj
 * @LastEditTime: 2022-08-06 17:19:59
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/module/page/DDLK/DarenManage/Detail/index.tsx
 */
import "../index.css";
import { Button, Descriptions, Drawer, Tabs } from "antd";
import EditCell from "../../../AgentManagement/EditCell";
import { TabPane } from "rc-tabs";
import BaseInfo from "./BaseInfo";
import GradeRecord from "./GradeRecord";
import { useCallback, useEffect, useState } from "react";
import { getDetail } from "../source/action";
import { darenLevel } from "../../helper";
import { dataUpdate } from "../source/action";
export default function Detail(props) {
  const [dataInfo, setDataInfo] = useState<any>({});
  // 请求详情页
  useEffect(() => {
    setDataInfo({});
    (async () => {
      if (!props.darenId) {
        return;
      }
      try {
        const res = await getDetail(props.darenId);
        setDataInfo(res.data);
      } catch (err) {
        console.log(err, "kkk");
      }
    })();
  }, [props.darenId]);
  // 修改单项
  const handleSave = useCallback(
    async (val, data, name) => {
      let params: any = {};
      if (name === "dyGroupbyLevel") {
        params[name] = darenLevel.indexOf(val);
      } else {
        params = data;
      }
      params.darenId = dataInfo.darenId;
      // console.log(params, "jljljljlk");
      // console.log(val, name, data);
      const res = await dataUpdate(params);
      props.setTableData();
      return res.code;
    },
    [dataInfo]
  );
  // const levelOptions = ["LV0", "LV1", "LV2", "LV3", "LV4", "LV5", "LV6"];
  return (
    <Drawer
      title="达人管理详情"
      placement="right"
      onClose={props.onClose}
      visible={props.visible}
      width={"80%"}
      contentWrapperStyle={{
        backgroundColor: "#e1e1e1",
        height: "100%",
        padding: "0px",
      }}
      destroyOnClose
    >
      <div className="flex-box">
        <Descriptions
          title="账户信息"
          bordered
          style={{ margin: "24px 0" }}
          column={4}
          labelStyle={{ width: "150px" }}
        >
          <Descriptions.Item label="注册手机" span={2}>
            {/* {dataInfo.mobile} */}
            <EditCell
              value={dataInfo?.mobile}
              name="mobile"
              handleSave={handleSave}
              rules={[{ required: true, message: "请输入手机号" }]}
            ></EditCell>
          </Descriptions.Item>
          <Descriptions.Item label="用户昵称" span={2}>
            {dataInfo?.darenNick}
          </Descriptions.Item>

          <Descriptions.Item label="等级" span={4}>
            <EditCell
              value={darenLevel[dataInfo?.dyGroupbyLevel]}
              name="dyGroupbyLevel"
              handleSave={handleSave}
              rules={[{ required: true, message: "请选择等级" }]}
              options={{ type: "select", options: darenLevel }}
            ></EditCell>
            {/* {levelEnum[this.props.dataInfo.level]} */}
          </Descriptions.Item>
        </Descriptions>
        <div className="tab-wrapper">
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="small"
            style={{ height: "100%" }}
          >
            <TabPane tab="基本信息" key="1">
              <BaseInfo dataInfo={{ ...dataInfo }} />
            </TabPane>
            <TabPane tab="等级变更记录" key="2" style={{ height: "100%" }}>
              {/* <TeamRelation {...this.props.dataInfo} /> */}
              <GradeRecord data={dataInfo?.dyGroupbyLevelChangelogList} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Drawer>
  );
}
