import {message, Table, Tabs,} from "antd";
import React, {useEffect, useState} from "react";
import Request, {EHostType} from "../../../utils/Request";
// import { verifyStatusMap } from "../../../helper";
import type {ColumnsType} from "antd/es/table";

const { TabPane } = Tabs;

interface IProps {
  onOpenDetail: any;
  searchParams: any;
  tab: string;
  // onOpenJoinList:any
}
let host: any = sessionStorage.getItem("host");

export const TaskListTable = (props: IProps) => {
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [queryType, setQueryType] = useState("");
  const [dataSource, setDataSource] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const handleSave: (index: number) => void = (index: number) => {
    const dataSourceMap = [...dataSource];
    (dataSourceMap[index] as any).edit = false;
    salaSubmit(dataSourceMap[index]);
  };
  const [tableHeight, setTableHeight] = useState(300);
  const customTag = JSON.parse( sessionStorage.getItem('ddlkPlatformSellerDTO') || '0');
  const taskTypeMap = {
    1: customTag.cloudVisitShopCustName,
    2: customTag.customerTaskCustName,
    3: customTag.visitShopCustName
  };

  const distributorType = {
    1: customTag.customerTaskCustName,
    2: customTag.employeeTaskCustName, 
    3: "视频挂载",
  };

  useEffect(() => {
    if (props.searchParams) {
      props.searchParams.queryType = queryType;
      getList({ ...props.searchParams, pageNo: 1 });
    } else {
      getList({ pageNo: 1 });
    }
  }, [props.searchParams, queryType]);

  const adminColumn: ColumnsType<any> = [
    {
      title: "0EM名称",
      dataIndex: "oemOperatingEntityName",
      key: "oemOperatingEntityName",
      // width: 80,
      align: "center",
      // fixed: "left",
    },
  ];
  const columns: any = [
    // {
    //   title: "0EM名称",
    //   dataIndex: "oemOperatingEntityName",
    //   key: "oemOperatingEntityName",
    //   width: 80,
    //   align: "center",
    //   fixed: "left",
    //   onCell: (_, index) => {
    //     if (index === 0) {
    //       return { rowSpan: 6 };
    //     }
    //     if (index < 6) {
    //       return { rowSpan: 0 };
    //     }
    //   },
    // },
    {
      title: "类型",
      dataIndex: "taskType",
      key: "taskType",
      width: 120,
      align: "center",
      fixed: "left",
      render: (row, i) => {
        return (
          <div>
            {i.taskType
              ? i.taskType === 2
                ? distributorType[i.distributorType]
                : taskTypeMap[i.taskType]
              : "合计"}
          </div>
        );
      },
    },
    {
      title: "时间",
      dataIndex: "billTimeRange",
      key: "billTimeRange",
      align: "center",
      width: 200,
      fixed: "left",
    },
    {
      title: "数量",
      dataIndex: "videoNum",
      key: "videoNum",
      width: 100,
      align: "center",
    },
    {
      title: "单价(分）",
      dataIndex: "price",
      key: "price",
      width: 80,
      align: "center",
    },
    {
      title: "费用(分)",
      dataIndex: "expense",
      key: "expense",
      width: 100,
      align: "center",
    },
    //1， 待审核，2， 审核通过，3，审核不通过
    // {
    //   title: "操作",
    //   dataIndex: "operate",
    //   key: "operate",
    //   width: 100,
    //   fixed: 'right',
    //   render: (row, i) => {
    //
    //     return (
    //       <div>
    //         <Button type="link" onClick={() => {props.onOpenDetail(i);}}>
    //           详情
    //         </Button>
    //         {(i.status == 1||i.status == 4) && (
    //           <TaskAudit item={i} sellerType={i.adviserReferSellerType} onAuditSuccess={() => getList({})} />
    //         )}
    //         {i.status == 2 && (
    //           <Button type="link" onClick={() => {props.onOpenDetail(i, "2");}}>
    //             参与列表
    //           </Button>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  const onTabsChange = (key: string) => {
    setQueryType(key);
  };
  /**表格高度自适应 */
  const tableAdapt = () => {
    let search: any =
      document.getElementsByClassName("search-container" + props.tab)[0]
        .clientHeight +
      265 +
      40;
    setTableHeight(search);
  };

  useEffect(() => {
    tableAdapt();
  }, []);

  const salaSubmit = async (detailInfo) => {
    let host: any = sessionStorage.getItem("host");
    const url = `/mgr/ddlk/visitshop/saveTask`;
    const { id, saleNo } = detailInfo;
    const resp = await Request.jsonPost(EHostType.DDlk, url, { id, saleNo: saleNo });
    if (resp.code == 0) {
      setDataSource([...dataSource]);
      message.success("提交成功");
    }
  };

  const getList = async (params: any) => {
    if (params.pageNo) {
      setPageNo(params.pageNo);
    }
    let url = `/mgr/ddlk/oemVideoBill/queryAllOemVideoBill`;
    setLoading(true);

    Request.jsonPost(EHostType.DDlk, url, {
      operatingEntityId: sessionStorage.getItem("operatingEntityId"),
      pageNo: params.pageNo ? params.pageNo : pageNo,
      pageSize,
      ...params,
    })
      .then((res) => {
        // console.log(Object?.values(res?.data),"res");
        if (res.code == 0) {
          // let data=Object?.values(res?.data);
          // console.log(data,"data");
          // setDataSource(data?.flat());
          // oemOperatingEntityName;
          let obj = res.data || {};
          let data = Object.keys(obj).map((key, i) => {
            return {
              oemOperatingEntityName: key,
              list: obj[key],
              key: i.toString(),
            };
          });
          setDataSource(data);
          setTotal(res?.totalCount);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginTop: "10px", background: "#fff" }}>
      {/*<Tabs*/}
      {/*  defaultActiveKey=""*/}
      {/*  type="card"*/}
      {/*  size="small"*/}
      {/*  activeKey={queryType}*/}
      {/*  onChange={(key) => onTabsChange(key)}>*/}
      {/*  <TabPane tab="全部" key=""></TabPane>*/}
      {/*  <TabPane tab="进行中" key="undergoing"></TabPane>*/}
      {/*  <TabPane tab="已结束" key="isover"></TabPane>*/}
      {/*</Tabs>*/}

      <Table
        dataSource={dataSource}
        columns={adminColumn}
        scroll={{ x: 700, y: 400 }}
        loading={loading}
        size={"small"}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Table
                dataSource={record.list}
                columns={columns}
                pagination={false}
                // scroll={{ y: 0 }}
              />
            );
          },
        }}
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: total,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: (page: number) => {
            getList({ ...props.searchParams, pageNo: page });
          },
          style: { background: "#fff" },
        }}
      />
    </div>
  );
};
