import { Button, Form, InputNumber, message, Popover, Table, Tabs, Typography, } from "antd";
import React, { useEffect, useState } from "react";
import Request, { EHostType } from "../../../../../utils/Request";
import { TaskAudit } from "./taskAudit";
import moment from "moment";
import { customerTaskStatusMap } from "../../../helper";

const { TabPane } = Tabs;
interface IProps {
  onOpenDetail: any;
  searchParams: any;
  tab: string;
  isDetail: boolean | undefined;
  isLimits: boolean | undefined;
  menuType: any;
  getQueryType: any;
  isShowModal: any;
  flag: any;
  // onOpenJoinList:any
}

const EditCell = (props) => {
  const [form] = Form.useForm();
  const save = async () => {
    form.setFieldsValue({
      saleNo: props.item.saleNo,
    });
    // console.log((inputRef.current! as any).state.value);
    const values = await (form as any).validateFields();
    props.handleSave(props.item.key);
  };
  return (
    <Form form={form}>
      <Form.Item
        style={{
          margin: 0,
        }}
        name="saleNo"
        rules={[
          {
            required: true,
            message: `请输入销量.`,
          },
        ]}
      >
        <InputNumber
          min={0}
          value={props.item.saleNo}
          onChange={(value) => {
            props.item.saleNo = value;
          }}
        />
        <Typography.Link onClick={save}>完成</Typography.Link>
      </Form.Item>
    </Form>
  );
};

export const TaskListTable = (props: IProps) => {
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(6);
  const [total, setTotal] = useState<number>(0);
  const [queryType, setQueryType] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const oemId = sessionStorage.getItem('operatingEntityId');
  const isShowGoodTaskColumn = sessionStorage.getItem("functionLimits")?.includes("a15");
  let isShowOemTitle = false;
  if (oemId == "43" || oemId == "1") {
    isShowOemTitle = true;
  } else {
    isShowOemTitle = false;
  }
  const handleSave: (index: number) => void = (index: number) => {
    const dataSourceMap = [...dataSource];
    (dataSourceMap[index] as any).edit = false;
    consumerSubmit(dataSourceMap[index]);
  };
  let host: any = sessionStorage.getItem("host");
  const consumerSubmit = async (detailInfo) => {
    let host: any = sessionStorage.getItem("host");
    const url = `/mgr/ddlk/consumertask/saveTask`;
    const { id, saleNo } = detailInfo;
    const resp = await Request.jsonPost(EHostType.DDlk, url, { id, saleNo: saleNo });
    if (resp.code == 0) {
      setDataSource([...dataSource]);
      message.success("提交成功");
    }
  };
  const [tableHeight, setTableHeight] = useState(300);
  /**表格高度自适应 */
  const tableAdapt = () => {

    let search: any = props.menuType ? 300 : document.getElementsByClassName("search-container" + props.tab)[0].clientHeight + 265 + 40;
    setTableHeight(search);


    // let search: any = props.menuType ? 300 : document.getElementsByClassName("search-container" + props.tab)[0].clientHeight +265 +40 + 32 + 52 + 6;
    // setTableHeight(search);

  };

  useEffect(() => {
    tableAdapt();
  }, []);

  let columns: any = [
    {
      title: "任务id",
      dataIndex: props.menuType ? "taskId" : "id",
      key: "id",
      width: 80,
      align: "center",
    },
    {
      title: "任务标题",
      dataIndex: "taskName",
      key: "taskName",
      align: "center",
      width: 150,
    },
    {
      title: "关联商家",
      dataIndex: "adviserUserName",
      key: "adviserUserName",
      align: "center",
      width: 200,
      render: (row, i) => {
        return (
          <div>
            {
              !props.menuType ? <><div>商家ID：{i?.adviserReferSellerId}</div>
              </> : ""
            }
            <div>{!props.menuType ? '商家名称:' : ''} {i?.adviserUserName}</div>
          </div>
        );
      },
    },
    {
      title: "OEM名称",
      dataIndex: "oemOperatingEntityName",
      key: "oemOperatingEntityName",
      width: 150,
      align: "center",
    },
    {
      title: "任务类型",
      dataIndex: "taskTypeText",
      key: "taskTypeText",
      width: 150,
      align: "center",
    },
    {
      title: "任务规则",
      dataIndex: "rewardConfig",
      key: "rewardConfig",
      width: 200,
      align: "center",
      render: (row, i) => {
        return (
          <div style={{ display: "flex" }}>
            {i?.rewardConfig && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {Object.keys(i?.rewardConfig).map((item, index) => {
                  return <div key={index}>播放量 {item}</div>;
                })}
              </div>
            )}
            {i?.rewardConfig && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "8px",
                }}
              >
                {Object.values(i?.rewardConfig).map((item: any, index) => {
                  return <div key={index}>奖励 {item / 100} 元</div>;
                })}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "奖金池金额",
      dataIndex: "totalMoney",
      key: "totalMoney",
      width: 150,
      // align:'center',
      render: (row, i) => {
        return (
          <div>
            <div>累计充值：{i?.totalMoney / 100}</div>
            <div>累计发放: {i?.spentMoney / 100}</div>
            <div>剩余奖金池: {i?.leftMoney / 100}</div>
          </div>
        );
      },
    },
    {
      title: "累计服务费",
      dataIndex: "platformFee",
      key: "platformFee",
      width: 110,
      align: "center",
      render: (row, i) => {
        return <div>{i?.platformFee ? i?.platformFee / 100 : 0}</div>;
      },
    },
    {
      title: "累计支付金额",
      dataIndex: "totalPaidFee",
      key: "totalPaidFee",
      width: 120,
      align: "center",
      render: (row, i) => {
        return <div>{i?.totalPaidFee ? i?.totalPaidFee / 100 : 0}</div>;
      },
    },
    {
      title: "退款金额",
      dataIndex: "refundFee",
      key: "refundFee",
      width: 100,
      align: "center",
      render: (row, i) => {
        return <div>{i?.refundFee ? i?.refundFee / 100 : 0}</div>;
      },
    },
    {
      title: "任务时间",
      dataIndex: "joinTimeFrom",
      key: "joinTimeFrom",
      align: "center",
      width: 180,
      render: (row, i) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{i?.joinTimeFrom}</span>
            <span>~</span>
            <span>{i?.joinTimeTo}</span>
          </div>
        );
      },
    },
    {
      title: "发布时间",
      dataIndex: "publishTime",
      key: "publishTime",
      align: "center",
      width: 180,
      render: (row, i) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{i?.publishTime}</span>
          </div>
        );
      },
    },

    {
      title: "任务状态",
      dataIndex: "taskStatusText",
      key: "taskStatusText",
      align: "center",
      width: 110,
      render: (row, i) => {
        return (
          <div>
            {(i?.taskStatus === 1 || i?.taskStatus === 4) && (
              <div style={{ color: "#FFAA33" }}>{i.taskStatusText}</div>
            )}
            {i?.taskStatus == 2 && (
              <div style={{ color: "#26BF4D" }}>{i.taskStatusText}</div>
            )}
            {i?.taskStatus == 3 && (
              <Popover
                placement="topLeft"
                title="驳回原因"
                content={i?.notApproveReason}
                arrowPointAtCenter
              >
                <div style={{ color: "#ff1e1e" }}>{i.taskStatusText}</div>
              </Popover>
            )}
            {i?.taskStatus == 5 && (
              <div style={{ color: "#999" }}>{i.taskStatusText}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "商家端首页展示",
      dataIndex: "isShow",
      key: "isShow",
      width: 100,
      render: (row, i) => {
        return (
          <div>
            <Button
              type="link"
              onClick={() => {
                props.isShowModal(true, i.id, JSON.stringify(i))
              }}
            >
              {i.isAppShow ? "编辑案例" : "展示图文案例"}
            </Button>
            {
              i.isAppShow ?
                <Button
                  type="link"
                  onClick={() => {
                    props.isShowModal(false, i.id, JSON.stringify(i))
                  }}
                >
                  取消展示
                </Button> : ''
            }

          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "operate",
      key: "operate",
      align: "center",
      width: 120,
      render: (row, i) => {
        return (
          <div style={{ display: "flex" }}>
            {
              props.isDetail && !props.menuType ? <Button
                type="link"
                onClick={() => {
                  props.onOpenDetail(i);
                }}
              >
                详情
              </Button> : ""
            }
            {i.taskStatus == 1 && (
              <TaskAudit item={i} onAuditSuccess={() => getList({})} />
            )}
            {i.taskStatus == 2 && (
              <Button
                type="link"
                onClick={() => {
                  props.onOpenDetail(i, "2");
                }}
                style={{ marginLeft: "-16px" }}
              >
                参与列表
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const customTag = JSON.parse(sessionStorage.getItem('ddlkPlatformSellerDTO') || '0');
  useEffect(() => {
    if (props.searchParams || props.flag) {
      getList({ ...props.searchParams, pageNo: 1 });
    } else if (queryType) {
      getList({ queryType, pageNo: 1 });
    } else {
      getList({ pageNo: 1 });
    }
  }, [props.searchParams, props.flag, queryType]);

  const onTabsChange = (key: string) => {
    setQueryType(`${key}`);
    props.getQueryType(key);
  };

  const setShowOrconceal = (i) => {
    // if(i.isShow){
    //   setIsShow(0)
    // }else{
    //   setIsShow(1)
    // };
    let isShow = i.isShow ? 0 : 1;
    let params = {
      taskType: 2,
      darenType: [1]
    };
    Request.jsonPost(EHostType.DDlk, `/mgr/ddlk/displayExcellentTask?taskId=${i.id}&isShow=${isShow}`, { ...params }).then(res => {
      if (res.data) {
        let listParams = {
          pageNo: pageNo,
        };
        message.open({
          type: 'success',
          content: '操作成功',
        });
        getList(listParams)
      }
    })
  };
  const getList = async (params: any) => {
    if (props.menuType) {
      setQueryType("");
    };
    if (params.pageNo) {
      setPageNo(params.pageNo);
    }
    let host: any = sessionStorage.getItem("host");
    let url = props.menuType ? `mgr/ddlk/excellentTask/queryExcellentTaskV2` : `/mgr/ddlk/consumertask/queryAllTask`;
    setLoading(true);
    Request.jsonPost(EHostType.DDlk, url, {
      taskType: props.menuType ? 2 : '',
      pageNo: params.pageNo ? params.pageNo : pageNo,
      queryType: props.menuType ? null : queryType,
      pageSize,
      ...params,
      darenType: props.menuType ? [props.tab] : '',
      distributorType: props.menuType ? '' : props.tab,
    })
      .then((res) => {
        if (res.data) {
          setDataSource(
            res?.data?.map((item, index) => {
              const startTime = moment(
                item.pubTimeFrom,
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD");
              const endTime = moment(
                item.pubTimeTo,
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD");
              return {
                ...item,
                key: index,
                totalPaidFeeText: `${item.totalPaidFee / 100}元`,
                timeRange:
                  item?.pubTimeFrom && item?.pubTimeFrom
                    ? `${startTime}-${endTime}`
                    : "-",
                taskStatusText: customerTaskStatusMap[item.taskStatus],
                taskTypeText: customTag.customerTaskCustName,
              };
            })
          );
          setTotal(res.totalCount);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getCloumns = () => {
    if (!props.isLimits) {
      return columns.filter(item => item.title != "OEM名称" && item.title != "发布时间" && item.title != "商家端首页展示");
    } else if (props.isLimits && !props.menuType) {
      return columns.filter(item => item.title != "OEM名称" && item.title != "发布时间" && item.title != "商家端首页展示");
    } else if (!props.isLimits && !props.menuType) {
      return columns.filter(item => item.title != "OEM名称" && item.title != "发布时间" && item.title != "商家端首页展示");
    } else if (props.menuType && !isShowOemTitle) {
      if (isShowGoodTaskColumn) {
        return columns.filter(item => item.title != "任务规则" && item.title != "奖金池金额" && item.title != "累计服务费" && item.title != "退款金额" && item.title != "任务时间" && item.title != "OEM名称");
      } else {
        return columns.filter(item => item.title != "任务规则" && item.title != "奖金池金额" && item.title != "累计服务费" && item.title != "退款金额" && item.title != "任务时间" && item.title != "OEM名称" && item.title != "商家端首页展示");
      }
    } else if (props.menuType && isShowOemTitle) {
      if (isShowGoodTaskColumn) {
        return columns.filter(item => item.title != "任务规则" && item.title != "奖金池金额" && item.title != "累计服务费" && item.title != "退款金额" && item.title != "任务时间");
      } else {
        return columns.filter(item => item.title != "任务规则" && item.title != "奖金池金额" && item.title != "累计服务费" && item.title != "退款金额" && item.title != "任务时间" && item.title != "商家端首页展示");
      }
    } else {
      return columns
    };
  }
  return (

    <div style={{ marginTop: "10px", background: "#fff" }}>
      <Tabs
        defaultActiveKey=""
        activeKey={queryType}
        type="card"
        size="small"
        onChange={(key) => onTabsChange(key)}
      >
        {!props.menuType && <TabPane tab="全部" key=""></TabPane>}
        {!props.menuType && <TabPane tab="进行中" key="undergoing"></TabPane>}
        {!props.menuType && <TabPane tab="已关闭" key="isclosed"></TabPane>}

      </Tabs>
      <Table
        tableLayout={"auto"}
        dataSource={dataSource}
        columns={getCloumns()}
        scroll={{ y: `calc(100vh - ${tableHeight}px)`, x: 1600 }}
        loading={loading}
        size={"small"}
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: total,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: (page: number) => {
            getList({ pageNo: page, ...props.searchParams });
          },
        }}
      />
    </div>
  );
};
