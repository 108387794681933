import React, { Component } from "react";
import { Button, Form, Input } from "antd";

export default class Search extends Component<
  {
    onFinish: (params: object) => void;
  },
  {}
> {
  private formSearch: any = React.createRef();
  render() {
    return (
      <Form
        className="search-container"
        ref={this.formSearch}
        name="customized_form_controls"
        layout="inline"
        onFinish={this.props.onFinish}
        style={{ paddingLeft: "10px" }}
      >
        <Form.Item name="articleTitle" label="文章标题：">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
