import React, { useState, useEffect, useRef } from "react";
import {Button, Form, Input, Select, Table, Tabs, Row, Col, DatePicker, Modal, Tag, Popover, Spin, Popconfirm, message,} from "antd";
import Request, { EHostType } from "../../utils/Request";
import moment from "moment";
import SettingService from "./SettingService"; //服务配置
import MerchantAdd from "./MerchantAdd"; //添加商户
import MerchantShow from "./MerchantShow"; //商户信息
import MaterialLibrary from "./MaterialLibrary"; //素材管理
import address from "../../assets/js/area.json";
import AccountRecharge from "./AccountRecharge";

// @ts-ignore
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface ITabProps {
  status?: any;
}

interface IModal {
  text: string;
  children?: any;
}

export const { Provider, Consumer } = React.createContext<any>("");

const ModalWrap = (props: IModal) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        type="link"
        onClick={() => {
          setVisible(true);
        }}
      >
        {props.text}
      </Button>
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        title={props.text}
        width={1000}
        footer={null}
      >
        <Provider value={{ setVisible }}>{props.children}</Provider>
      </Modal>
    </>
  );
};

const BusinessTabItem = (props: ITabProps) => {
  const [isAddSeller,setIsAddSeller] = useState<boolean|undefined>(true)

  const { status } = props;

  const [searchForm, setSearchForm] = useState({
    province: "",
    agentName: "",
    categoryId: undefined,
    date: [],
    name: "",
    parentId: undefined,
    phone: "",
  });
  const [pageNum, setPageNum] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(5);
  const [total, setTotal] = useState<number>(0);

  const [form] = Form.useForm();
  const [notApproveReason, setNotApproveReason] = useState<string>("");
  const [auditStatus, setAuditStatus] = useState<number>(0);
  //搜索
  const onFinish = (e) => {
    console.log(e);
    let params = { ...searchForm, ...e };
    if (e.date) {
      params.startDate = moment(e?.date[0]).format("YYYY-MM-DD HH:mm:ss");
      params.endDate = moment(e?.date[1]).format("YYYY-MM-DD HH:mm:ss");
      delete params.date;
    }
    setSearchForm({ ...params });
  };
  const categoryMap = [
    { name: "所有", value: -1 },
    { name: "餐饮美食", value: 1 },
    { name: "休闲娱乐", value: 2 },
    { name: "美容美发", value: 3 },
    { name: "教育培训", value: 4 },
    { name: "酒店公寓", value: 5 },
    { name: "体育健身", value: 6 },
    { name: "景点乐园", value: 7 },
    { name: "洗浴足疗", value: 8 },
    { name: "生活服务", value: 9 },
    { name: "其他", value: 0 },
  ]; //商户分类
  const merchantStatusMap = [
    { name: "全部", value: -1 },
    { name: "过期", value: 0 },
    { name: "正常", value: 1 },
    { name: "待审核", value: 2 },
    { name: "已驳回", value: 3 },
  ]; //商户状态
  const [componey, setComponey] = useState([]); //总公司

  const [auditVisible, setAuditVisible] = useState<any>(false); //驳回审核
  const tab0 = [
    {
      title: "商家ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 80,
    },
    {
      title: "店铺名称",
      key: "name",
      align: "center",
      width: "240px",
      render: (text, record, index) => (
        <div>
          {record.sellerType === 1 && <div>{text?.name}</div>}
          {record.sellerType === 2 && (
            <div>
              <Tag color="blue">连锁总部</Tag>
              {text?.name}
            </div>
          )}
          {record.sellerType === 3 && (
            <div>
              <Tag color="orange">连锁门店</Tag>
              {text?.name}
            </div>
          )}
          {record.sellerType === 4 && (
            <div>
              <Tag color="pink">超级门店</Tag>
              {text?.name}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "负责人",
      key: "realName",
      align: "center",
      width: "120px",
      render: (text, record, index) => (
        <div>
          <div>{text?.realName}</div>
          <div>{text?.phone}</div>
        </div>
      ),
    },
    {
      title: "所在地区",
      key: "region",
      dataIndex: "address",
      align: "center",
      width: "240px",
      render: (text, record, index) => {
        let province, city, county;
        // console.log(address, "uuuuu");
        address.map((e) => {
          if (e.value === record.province) {
            province = e.label;
            e.children?.map((e) => {
              if (e.value === record.city) {
                city = e.label;
                e.children?.map((e) => {
                  if (e.value === record.county) {
                    county = e.label;
                  }
                  return true;
                });
              }
              return true;
            });
          }
          return true;
        });
        return (
          <span>
            {province}
            {city}
            {county}
            {record.address}
          </span>
        );
      },
    },
    {
      title: "商户分类",
      key: "categoryId",
      align: "center",
      width: 100,
      render: (text, record, index) => (
        <div>
          {categoryMap.find((item) => item.value === text.categoryId * 1)?.name}
        </div>
      ),
    },
    {
      title: "代理商信息",
      key: "agent",
      align: "center",
      width: 120,
      render: (text, record, index) => (
        <div>
          <div>{text?.agent?.name}</div>
          <div>{text?.agent?.phone}</div>
        </div>
      ),
    },
    // {
    //   title: "账户点数",
    //   dataIndex: "totalPoints",
    //   key: "totalPoints",
    //   align: "center",
    //   width: 100,
    //   render: (text, record, index) => (
    //     <div>
    //       <div>累计充点:{record?.totalPoints}</div>
    //       <div>剩余点数:{record?.points}</div>
    //     </div>
    //   ),
    // },
    {
      title: "服务时间",
      key: "startDate",
      align: "center",
      width: "120px",
      render: (text, record, index) => (
        <div>
          <div>{record?.startDate}</div>
          <div>~</div>
          <div>{record?.expired}</div>
        </div>
      ),
    },
    {
      title: "商户状态",
      key: "status",
      align: "center",
      width: 80,
      render: (text, record, index) => (
        <div>
          {
            merchantStatusMap.find((item) => item.value === text.status * 1)
              ?.name
          }
        </div>
      ),
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      width: 250,
      render: (text, record, index) => (
        <div>
          <ModalWrap text={"商家信息"}>
            <MerchantShow
              parent={{
                state: {
                  rowData: {
                    ...record,
                    agentId: record.agent?.id,
                    startTime:
                      record.createdTime &&
                      moment(record.createdTime, "YYYY-MM-DD hh:mm:ss"),
                    endTime:
                      record.expired &&
                      moment(record.expired, "YYYY-MM-DD hh:mm:ss"),
                  },
                  id: record.id,
                  categoryData: categoryMap,
                  companyData: componey,
                },
                onCancel: () => {
                  // setVisible(false);
                },
                onSuccess: () => {
                  initTable(pageNum);
                },
              }}
            />
          </ModalWrap>
          {/* <Button
            type="link"
            size={"small"}
            onClick={() => {
              window.location.href = `#/shop/activity?sellerId=${text.id}`;
            }}
          >
            关联活动
          </Button> */}
          {/* <AccountRecharge
            rowData={record}
            onSuccess={() => {
              initTable(pageNum);
            }}
          /> */}
          {/* <ModalWrap text={"素材库"}>
            <MaterialLibrary
              parent={{ state: { rowData: record, id: record.id } }}
            />
          </ModalWrap>
          <SettingService
            id={text.id}
            onRefresh={() => {
              initTable(pageNum);
            }}
          /> */}
        </div>
      ),
    },
  ];
  const tab1 = [
    {
      title: "商家ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 80,
    },
    {
      title: "店铺名称",
      key: "name",
      align: "center",
      render: (text, record, index) => (
        <div>
          {record.sellerType === 1 && <div>{text?.name}</div>}
          {record.sellerType === 2 && (
            <div>
              <Tag color="blue">连锁总部</Tag>
              {text?.name}
            </div>
          )}
          {record.sellerType === 3 && (
            <div>
              <Tag color="orange">连锁门店</Tag>
              {text?.name}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "负责人",
      key: "realName",
      align: "center",
      render: (text, record, index) => (
        <div>
          <div>{text?.realName}</div>
          <div>{text?.phone}</div>
        </div>
      ),
    },
    {
      title: "所在地区",
      key: "region",
      dataIndex: "address",
      align: "center",
      width: "240px",
      render: (text, record, index) => {
        let province, city, county;
        address.map((e) => {
          if (e.value === record.province) {
            province = e.label;
            e.children?.map((e) => {
              if (e.value === record.city) {
                city = e.label;
                e.children?.map((e) => {
                  if (e.value === record.county) {
                    county = e.label;
                  }
                  return true;
                });
              }
              return true;
            });
          }
          return true;
        });
        return (
          <span>
            {province}
            {city}
            {county}
            {record.address}
          </span>
        );
      },
    },
    {
      title: "商户分类",
      key: "categoryId",
      align: "center",
      width: 100,
      render: (text, record, index) => (
        <div>
          {categoryMap.find((item) => item.value === text.categoryId * 1)?.name}
        </div>
      ),
    },
    {
      title: "代理商信息",
      key: "agent",
      align: "center",
      width: 150,
      render: (text, record, index) => (
        <div>
          <div>{text?.agent?.name}</div>
          <div>{text?.agent?.phone}</div>
        </div>
      ),
    },
    {
      title: "账户点数",
      dataIndex: "totalPoints",
      key: "totalPoints",
      align: "center",
      render: (text, record, index) => (
        <div>
          <div>累计充点:{record?.totalPoints}</div>
          <div>剩余点数:{record?.points}</div>
        </div>
      ),
    },
    {
      title: "申请时间",
      key: "createdTime",
      align: "center",
      render: (text, record, index) => <div>{record?.createdTime}</div>,
    },
    {
      title: "商户状态",
      key: "status",
      align: "center",
      width: 100,
      render: (text, record, index) => (
        <>
          {text?.status !== 3 && (
            <div>
              {
                merchantStatusMap.find((item) => item.value === text.status * 1)
                  ?.name
              }
            </div>
          )}
          {text?.status === 3 && (
            <Popover
              placement="topLeft"
              title="驳回原因"
              content={text?.notApproveReason}
              arrowPointAtCenter
            >
              <div style={{ color: "#ff1e1e" }}>
                {
                  merchantStatusMap.find(
                    (item) => item.value === text?.status * 1
                  )?.name
                }
              </div>
            </Popover>
          )}
        </>
      ),
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      render: (text, record, index) => (
        <>
          <ModalWrap text={"商家信息"}>
            <MerchantShow
              parent={{
                state: {
                  rowData: record,
                  id: record.id,
                  categoryData: categoryMap,
                },
                onSuccess: () => {
                  initTable(pageNum);
                },
              }}
            />
          </ModalWrap>
          {text.status !== 3 && (
            <Popconfirm
              title="确认该商家审核吗?"
              onConfirm={(record: any) => {
                onAudit(text);
                setAuditStatus(1);
              }}
              okText="确认"
              cancelText="取消"
            >
              <a href="#">审核通过</a>
            </Popconfirm>
          )}
          {text.status !== 3 && (
            <Button
              type={"link"}
              size={"small"}
              onClick={() => {
                setAuditVisible(true);
                setAuditStatus(3);
              }}
            >
              驳回申请
            </Button>
          )}
          <Modal
            title="驳回申请"
            width={600}
            visible={auditVisible}
            onCancel={() => {
              setAuditVisible(false);
            }}
            footer={null}
          >
            <Form
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              onFinish={(record: any) => onAuditFinish(text)}
              onValuesChange={(current, all) => {
                console.log(all);
                if (all.notApproveReason) {
                  setNotApproveReason(all.notApproveReason);
                }
              }}
            >
              <Form.Item
                label="申请驳回原因"
                rules={[{ required: true, message: "请输入驳回原因" }]}
                name="notApproveReason"
              >
                <TextArea placeholder="请输入驳回原因" rows={2} />
              </Form.Item>
              <Form.Item
                wrapperCol={{ offset: 12, span: 8 }}
                style={{ margin: "100px 0 40px" }}
              >
                <Button
                  type="default"
                  onClick={() => {
                    setAuditVisible(false);
                  }}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ margin: "20px" }}
                >
                  保存
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </>
      ),
    },
  ];

  //审核驳回
  const onAuditFinish = (e) => {
    console.log(e);
    setNotApproveReason(e.notApproveReason);
    onAudit(e);
  };
  //审核
  const onAudit = async (e) => {
    const params =
      auditStatus === 3
        ? { status: 3, notApproveReason: notApproveReason }
        : { status: 1 };
    const res = await Request.jsonPost(
      EHostType.XMGX,
      `/op/auditSeller/${e.id}`,
      params
    );
    if (auditStatus === 3 && res.code === 0) {
      message.success("驳回申请!");
      setAuditVisible(false);
      initTable(1);
    } else {
      message.success("审核通过!");
      initTable(1);
    }
  };

  useEffect(() => {
    setIsAddSeller(sessionStorage.getItem("functionLimits")?.includes("a4"))
    console.log(isAddSeller,'权限')
    initTable(1);
    
  }, [status, searchForm]);

  const getFields = () => {
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    const children: any = [];
    children.push(
      <Col lg={8} xl={6} xxl={4}>
        <Form.Item
          label="商家名称"
          name="name"
          rules={[{ required: false, message: "请输入商家名称!" }]}>
          <Input placeholder="请输入商家名称" />
        </Form.Item>
      </Col>,
      <Col lg={8} xl={6} xxl={4}>
        <Form.Item
          label="手机号"
          name="phone"
          rules={[{ required: false, message: "请输入手机号!" }]}>
          <Input placeholder="请输入手机号" />
        </Form.Item>
      </Col>,
       <Col lg={8} xl={6} xxl={4}>
       <Form.Item
          label="代理商名称"
          name="agentName"
          rules={[{ required: false, message: "请输入代理商名称!" }]}>
          <Input placeholder="请输入代理商名称" />
        </Form.Item>
      </Col>,
      <Col lg={8} xl={6} xxl={4}>
        <Form.Item
          label="关联总公司"
          name="parentId"
          rules={[{ required: false, message: "请选择总公司" }]}
        >
          <Select
            showSearch={false}
            placeholder="请选择总公司"
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            notFoundContent={null}
          >
            {componey.map((item: any) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>,
      <Col lg={16} xl={12} xxl={8}>
        {status === "0" && (
          <Form.Item
            label="开通时间"
            name="date"
            rules={[{ required: false, message: "请选择开通时间!" }]}
          >
            <RangePicker format={dateFormat} showTime />
          </Form.Item>
        )}
        {status === "1" && (
          <Form.Item
            label="申请时间"
            name="date"
            rules={[{ required: false, message: "请选择开通时间!" }]}
          >
            <RangePicker format={dateFormat} showTime />
          </Form.Item>
        )}
      </Col>,

      <Col lg={8} xl={6} xxl={4}>
        <Form.Item label="所在地区" name="province">
          <Select allowClear placeholder="请选择所在地区">
            {address.map((e) => {
              return (
                <Option key={e.value} value={e.value}>
                  {e.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>,
      <Col lg={8} xl={6} xxl={4}>
        <Form.Item
          label="商户分类"
          name="categoryId"
          rules={[{ required: false, message: "请选择商户分类" }]}
        >
          <Select
            showSearch={false}
            placeholder="请选择商户分类"
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            notFoundContent={null}
          >
            {categoryMap.map((item: any) => (
              <Option key={item.value} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    );
    return children;
  };

  let column: any = status === "0" ? tab0 : tab1;
  const getColumns=()=>{
    let   isAgentEnabled= sessionStorage.getItem("isAgentEnabled");
    if(isAgentEnabled!='true'){
      return column.filter((item,index)=>index!=5);
    }else{
      return column;
    }
  }
  const [dataSource, setDataSource] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  //获取列表信息
  const initTable = async (e: number) => {
    setPageNum(e);
    setLoading(true);
    setDataSource([]);
    let componeyRes = await Request.get(
      EHostType.XMGX,
      `/op/findParentSellerByName`
    ); //关联总公司
    if (componeyRes.code == 0) {
      setComponey(componeyRes?.data);
    }

    let res = await Request.get(
      EHostType.XMGX, `/op/auditSellerList/${pageSize}/${e}/${status}`,
      {
        name: searchForm?.name,
        province: searchForm?.province,
        agentName: searchForm?.agentName,
        categoryId: searchForm?.categoryId,
        startDate: searchForm?.date && searchForm?.date[0],
        endDate: searchForm?.date && searchForm?.date[1],
        parentId: searchForm?.parentId,
        phone: searchForm?.phone,
      }
    );
    setLoading(false);
    if (res.code == 0) {
      setDataSource(res.data?.records);
      setTotal(res.data?.total);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={(value) => onFinish(value)}
        autoComplete="on"
        style={{ padding: "16px 0" }}
      >
        <Row gutter={24}>
          {getFields()}
          <Col xl={2} xxl={1}>
            <Button
              onClick={() => {
                form.resetFields();
              }}
            >
              重置
            </Button>
          </Col>
          <Col xl={2} xxl={1}>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
          </Col>
        </Row>
        <Row justify="end">
          <Col span={2}>
            <MerchantAdd
              onRefresh={() => {
                initTable(status);
              }}
              companyData={componey}
            />
          </Col>
        </Row>
      </Form>
      <div className="businessSettingWrap">
        <Table
          size={"middle"}
          dataSource={dataSource}
          columns={getColumns()}
          rowKey={"id"}
          scroll={{ y: 1500 }}
          pagination={{
            pageSize: pageSize,
            showQuickJumper: true,
            showSizeChanger:false,
            total: total,
            onChange: (page: number) => {
              initTable(page);
            },
          }}
        />
      </div>
    </Spin>
  );
};

const BusinessManagement = () => {
  const [status, setStatus] = useState<string>("0"); //tabs
  const onTabs = (key: any) => {
    setStatus(key); //tabs
  };
  return (
    <div style={{ background: "#fff", padding: "20px" }}>
      <Tabs
        type="card"
        defaultActiveKey={status}
        onChange={(e) => onTabs(e)}
        tabBarGutter={0}
        tabBarStyle={{ color: "#ff443" }}
      >
        <TabPane tab="已审核" key="0">
          <BusinessTabItem status={status} key={1} />
        </TabPane>
        <TabPane tab="待审核" key="1">
          <BusinessTabItem status={status} key={2} />
        </TabPane>
      </Tabs>
    </div>
  );
};
export default BusinessManagement;
