import {Descriptions, message, Typography} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import EditCell from "./EditCell";
import Request, {EHostType} from "../../utils/Request";
import address from "../../assets/js/area.json";
import getArea from "../../utils/areaTwoLevel";

function useHandel(props) {
  const handleSave = useCallback(async (value, data, key) => {
    const params = {
      id: props.id,
      phone: props.phone,
    };
    if (key === "area") {
      params["province"] = value[0];
      params["city"] = value[1];
    } else {
      params[key] = value;
    }
    let url = `/op/agent/saveOrUpdate`;
    const res = await Request.jsonPost(EHostType.XMGX,url, params);
    if (res.code === 0) {
      message.success("提交成功");
      props.getData({ size: 10, current: 1 });
    }
    return res.code;
  }, []);
  return handleSave;
}
export default function BaseInfo(props: any) {
  const handleSave = useHandel(props);
  // let areaList = [];
  const [areaList, setareaList] = useState([]);
  useEffect(() => {
    setareaList(getArea(address, 2));
  }, []);
  return (
    <Descriptions
      title=""
      bordered
      style={{ margin: "24px 0" }}
      column={4}
      labelStyle={{ width: "150px" }}
    >
      <Descriptions.Item label="真实姓名" span={4}>
        <EditCell
          value={props.name}
          handleSave={handleSave}
          name="name"
          rules={[{ required: true, message: "请输入姓名" }]}
        />
        {/* {"fdsfsd"} */}
        {/* <Input
            type="number"
            min={0}
            value={darenNum}
            disabled={detailInfo.status !== 1}
            addonAfter="人"
            style={{ width: "200px" }}
            onChange={(e) => {
              setDarenNum(e.target.value);
            }}
          /> */}
      </Descriptions.Item>
      {/* {detailInfo?.groupbyBonusFee > 0 && ( */}
      <Descriptions.Item label="购买时间" span={2}>
        {props.orderTime}
        {/* <Input
            type="number"
            min={0}
            value={eachDarenPrice}
            disabled={detailInfo.status !== 1}
            addonAfter="元/人"
            style={{ width: "200px" }}
            onChange={(e) => {
              setEachDarenPrice(+e.target.value);
            }}
          /> */}
      </Descriptions.Item>
      {/* )} */}
      <Descriptions.Item label="订单号" span={2}>
        {props.orderId}
      </Descriptions.Item>
      <Descriptions.Item label="最近登录" span={2}>
        {props.lastLoginTime}
      </Descriptions.Item>

      <Descriptions.Item label="最近登录IP" span={2}>
        {props.lastLoginIp}
      </Descriptions.Item>
      <Descriptions.Item label="所在地区" span={4}>
        <EditCell
          value={[props.province, props.city].filter((e) => e)}
          name="area"
          handleSave={handleSave}
          rules={[{ required: true, message: "请输入" }]}
          options={{
            type: "cascader",
            options: areaList,
            label: "label",
            showName: props.provinceText ? props.provinceText + (props.cityText ? "/" + props.cityText : "") : "",
            fieldNames: { children: "items" },
          }}
        ></EditCell>
      </Descriptions.Item>
      <Descriptions.Item label="累计推广佣金" span={4}>
        {props.statCommissionFee / 100}
        <Typography.Link style={{ marginLeft: 30 }}>查询明细</Typography.Link>
      </Descriptions.Item>
    </Descriptions>
  );
}
