import * as React from "react";
import {Button, Cascader, Checkbox, Col, DatePicker, Form, Image, Input, message, Modal, Radio, Row, Select, Upload,} from "antd";
import "./index.css";
import {DeleteOutlined, ExclamationCircleOutlined, PlusOutlined,} from "@ant-design/icons";
import address from "../../assets/js/area.json";
import Request, { EHostType } from "../../utils/Request";
import moment from "moment";
import { Consumer } from "./index";

const { Option } = Select;
const { TextArea } = Input;
let host: any = sessionStorage.getItem("host");

const { RangePicker } = DatePicker;
interface page {
  tabState: boolean;
  previewVisible: boolean;
  previewImage: string;
  previewTitle: string;
  fileList: any;
  fileListShow: any;
  fileListBusinessLicense:any;
  fileListBusinessLicenseShow:any;
  province: string;
  city: string;
  county: string;
  agentData: any;
  uploading: boolean;
  parentState: number;
  options: any;
  storePic: any;
  exisingStorePic: any;
  storeIntroductionVideo: any;
  check0: boolean;
  check1: boolean;
  videoCount: number;
  isEdit:boolean | undefined;
  isLimits:boolean|undefined;
  isService:boolean|undefined;
}
export default class MerchantAdd extends React.Component<
  { parent?: any },
  page
> {
  formRef: any = React.createRef();
  ddlkPlatformSellerDTO: any;

  constructor(props) {
    super(props);
    this.state = {
      isLimits:true,
      isService:true,
      isEdit:true,
      tabState: true,
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
      fileListShow: [],
      fileListBusinessLicense:[],
      fileListBusinessLicenseShow:[],
      province: "",
      city: "",
      county: "",
      agentData: null,
      uploading: false,
      parentState: 1,
      options: address,
      exisingStorePic: this.props.parent.state.rowData.storePic
        ? this.props.parent.state.rowData.storePic.split(";")
        : [],
      storePic: [],
      storeIntroductionVideo: null,
      check0: false,
      check1: false,
      videoCount: 0,
    };

    // 获取登录接口中的远程探店高级版开通权限和达人探店高级版开通权限
    this.ddlkPlatformSellerDTO = sessionStorage.getItem("ddlkPlatformSellerDTO") ? JSON.parse(sessionStorage.getItem("ddlkPlatformSellerDTO")!) : "";
  }
  DtoList: any[] = [];
  componentDidMount() {
    this.setState({isEdit:sessionStorage.getItem("functionLimits")?.includes("a4"),
    isLimits:sessionStorage.getItem("functionLimits")?.includes("a9"),
    isService:sessionStorage.getItem("functionLimits")?.includes("a10")
    })
    this.getData();
    this.getDtoList();
    this.getVideoCount();
    let parentData = this.props.parent.state.rowData;
    if (parentData.sellerType !== undefined || parentData.sellerType !== null) {
      this.setState({
        parentState: parentData.sellerType,
      });
    }
    this.setState(
      {
        province: parentData.province.toString(),
        city: parentData.city.toString(),
        county: parentData.county.toString(),
        storeIntroductionVideo: parentData.storeIntroductionVideo,
      },
      () => {
        this.formRef.current.setFieldsValue({
          options: [this.state.province, this.state.city, this.state.county],
        });
      }
    );
  }

  /**快手/抖音配置切换 */
  tabChange = (value) => {
    if (value.target.value === "all") {
      this.setState({
        tabState: true,
      });
    } else {
      this.setState({
        tabState: false,
      });
    }
  };
  // 获取视频点数
  private async getVideoCount() {
    let url = `/mgr/seller/point?sellerId=${this.props.parent.state.rowData.id}`;
    let ret = await Request.get(EHostType.DDlk, url);
    // console.log(ret, "ljlj");
    if (ret.code === 0) {
      this.setState({
        videoCount: ret.data,
      });
    }
  }

  /**代理商查询*/
  private async getData() {
    let host: any = sessionStorage.getItem("host");
    let url = `/op/agent/findByName`;
    let ret = await Request.get(EHostType.XMGX, url);
    if (ret.code == 0) {
      this.setState({
        agentData: ret.data,
      });
    }
  }

  private getDtoList() {
    let url = `/op/seller/${this.props.parent.state.rowData.id}`;
    Request.get(EHostType.XMGX, url).then((res) => {
      this.DtoList = res.data.serviceOpenedRecordDTOList;
      const data1 = this.DtoList.find((e) => e.serviceType === 6);
      const data2 = this.DtoList.find((e) => e.serviceType === 7);
      this.formRef.current.setFieldsValue({
        canModifyAddress: res.data.canModifyAddress,
        check0: data1,
        check1: data2,
        time0: data1 && [moment(data1.validityFrom), moment(data1.validityTo)],
        time1: data2 && [moment(data2.validityFrom), moment(data2.validityTo)],
      });
      this.setState({
        check0: data1,
        check1: data2,
      });
    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => {
    // console.log(fileList);
    this.setState({ fileListShow: fileList.slice(-1) })
  };
  handleBusinessLicenseChange = ({ fileList }) => {
    // console.log(fileList);
    this.setState({ fileListBusinessLicenseShow: fileList.slice(-1) })
  };
  handleStorePic = ({ fileList }) => this.setState({ storePic: fileList });
  deleteStorePic = (index) => {
    let storePic = this.state.exisingStorePic;
    storePic.splice(index, 1);
    this.setState({ exisingStorePic: storePic });
  };

  onFinish = (value) => {
    // console.log(value);

    // 商家续费  6 远程探店高级版  7 达人探店高级版
    if (value.check0 || value.check1) {
      value.serviceConfigListStr = [];
    }

    if (value.time0 && value.time0.length === 2 && value.check0) {
      value.serviceConfigListStr.push({
        serviceType: 6,
        validateFrom: moment(value.time0[0]).format("YYYY-MM-DD") + " 00:00:00",
        validateTo: moment(value.time0[1]).format("YYYY-MM-DD") + " 23:59:59",
      });
      if (!value.check0) {
      }
    }

    if (value.time1 && value.time1.length === 2 && value.check1) {
      value.serviceConfigListStr.push({
        serviceType: 7,
        validateFrom: moment(value.time1[0]).format("YYYY-MM-DD") + " 00:00:00",
        validateTo: moment(value.time1[1]).format("YYYY-MM-DD") + " 23:59:59",
      });
    }

    // --- 商家续费处理结束 ----
    for (let key in value) {
      if (value[key] === undefined || value[key] === "") {
        delete value[key];
      }
    }
    if (value.agentId === undefined || value.agentId === null) {
      value["agentId"] = 0;
    }
    value["id"] = this.props.parent.state.rowData.id;
    value["startTime"] = moment(value.startTime).format("YYYY-MM-DD HH:mm");
    value["endTime"] = moment(value.endTime).format("YYYY-MM-DD HH:mm");
    value["province"] = value["options"][0];
    value["city"] = value["options"][1];
    value["county"] = value["options"][2];
    const formData = new FormData();
    this.state.fileList.forEach((file) => {
      console.log(file,'ffflogo');
      formData.append("logo", file);
    });
    this.state.fileListBusinessLicense.forEach((file) => {
      // console.log(file,'ffff');
      formData.append("businessLicenseUrl", file);
    });



    let picArr: Array<string> = this.state.exisingStorePic;
    for (let item of this.state.storePic) {
      picArr.push(JSON.parse(item.xhr.response).data);
    }
    value["storePic"] = picArr.join(",");

    if (this.state.storeIntroductionVideo && typeof this.state.storeIntroductionVideo != "string") {
      formData.append("storeIntroductionVideo", this.state.storeIntroductionVideo);
    }

    this.setState({uploading: true,});
    for (let key in value) {
      if (typeof value[key] === "object") {
        formData.append(key, JSON.stringify(value[key]));
      } else {
        formData.append(key, value[key]);
      }
      // formData.append(key, value[key]);
    }
    let _this = this;
    Modal.confirm({
      title: "是否确认提交",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认提交？</p>,
      async onOk() {
        let host: any = sessionStorage.getItem("host");
        const res = await Request.postFormData(EHostType.XMGX, `/op/seller/saveOrUpdate`, formData);
        if (res.code == 0) {
          message.success("提交成功");
          _this.props.parent.onSuccess();
        }
      },
    });
  };

  /**密码重置 */
  resetPassword = () => {
    let _this = this;
    Modal.confirm({
      title: "密码重置提示",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认重置密码？</p>,
      async onOk() {
        let url = `/op/seller/password/rest/${_this.props.parent.state.rowData.id}`;
        let ret = await Request.post(EHostType.XMGX, url, "");
        if (ret.code == 0) {
          message.success("重置成功");
        }
      },
    });
  };

  parentChange = (value) => {
    this.setState({
      parentState: value.target.value,
    });
  };
  getAction = () => {
    let host: any = sessionStorage.getItem("host");
    return `${JSON.parse(host)?.xmgx}`;
  };
  public render() {
    const { fileList,fileListBusinessLicense } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        {this.props.parent.state.rowData.logo&&<div style={{ marginTop: 8 }}>
          {this.props.parent.state.rowData.logo !== undefined && this.props.parent.state.rowData.logo !== "" ? "替换LOGO" : "上传LOGO"}
        </div>}
        {this.props.parent.state.rowData.businessLicense&&<div style={{ marginTop: 8 }} >
          {this.props.parent.state.rowData.businessLicense !== undefined && this.props.parent.state.rowData.businessLicense !== "" ? "替换营业执照" : "上传营业执照"}
        </div>}
      </div>
    );
    const bueinessLicenseUploadButton = (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }} >
            {this.props.parent.state.rowData.businessLicenseUrl !== undefined && this.props.parent.state.rowData.businessLicenseUrl !== "" ? "替换营业执照" : "上传营业执照"}
          </div>
        </div>
    );
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    const businessProps = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileListBusinessLicense.indexOf(file);
          const newFileList = state.fileListBusinessLicense.slice();
          newFileList.splice(index, 1);
          return {
            fileListBusinessLicense: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileListBusinessLicense: [...state.fileListBusinessLicense, file],
        }));
        return false;
      },
      fileListBusinessLicense,
    };
    return (
      <>
        {/*<Button type="link" size={"small"} onClick={()=>{  setVisiable(true)}}>服务配置</Button>*/}
        {/*<Modal visible={visiable} onCancel={()=>{ setVisiable(false) }} width={1000} title='商家服务配置'>*/}
        <Form
          name="form"
          
          ref={this.formRef}
          className="form-container"
          initialValues={{ ...this.props.parent.state.rowData }}
          onFinish={this.onFinish}
        
        >
          <div className="form-content MerchantAdd">
            <div className="form-titlt">商家基本信息</div>
            <Form.Item
              label="商户名称"
              className="form-item ant-col-11"
              name="name"
              rules={[{ required: true, message: "商户名称不能为空！" }]}
            >
              <Input disabled={!this.state.isEdit} allowClear placeholder="最多显示20个字" />
            </Form.Item>
            <Form.Item
              label="所属分类"
              className="form-item ant-col-11"
              name="categoryId"
              rules={[{ required: true, message: "所属分类不能为空！" }]}
            >
              <Select disabled={!this.state.isEdit}>
              
                {this.props.parent.state.categoryData?.map((e, i) => {
                  return (
                    <Option key={e.value + "categoryId"} value={e.value}>
                      {e.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="商户类型"
              className={this.state.parentState === 3 ? "form-radio ant-col-11" : "form-radio"}
              name="sellerType"
              rules={[{ required: true, message: "商户类型不能为空！" }]}
            >
              <Radio.Group onChange={this.parentChange} disabled>
                <Radio value={Number(1)}>普通商户</Radio>
                <Radio value={Number(2)}>连锁总部</Radio>
                <Radio value={Number(3)}>连锁门店</Radio>
                {/* <Radio value={Number(4)}>超级门店</Radio> */}
              </Radio.Group>
            </Form.Item>
            {/*{this.state.parentState === 3 ? (*/}
            {/*  <Form.Item*/}
            {/*    label="关联总公司"*/}
            {/*    className="form-item ant-col-11"*/}
            {/*    name="parentId"*/}
            {/*    rules={[{ required: true, message: "关联总公司不能为空！" }]}*/}
            {/*  >*/}
            {/*    <Select*/}
            {/*      showSearch*/}
            {/*      filterOption={(input, option: any) =>*/}
            {/*        option.children*/}
            {/*          .toLowerCase()*/}
            {/*          .indexOf(input.toLowerCase()) >= 0*/}
            {/*      }*/}
            {/*    >*/}
            {/*      {this.props.parent.state.companyData?.map((e) => {*/}
            {/*        return (*/}
            {/*          <Option*/}
            {/*            disabled={*/}
            {/*              this.props.parent.state.rowData.name === e.name*/}
            {/*                ? true*/}
            {/*                : false*/}
            {/*            }*/}
            {/*            key={e.id + "parentId"}*/}
            {/*            value={e.id}*/}
            {/*          >*/}
            {/*            {e.name}*/}
            {/*          </Option>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </Select>*/}
            {/*  </Form.Item>*/}
            {/*) : null}*/}
            <Form.Item
              label="商户负责人"
              className="form-item ant-col-11"
              name="realName"
              rules={[{ required: true, message: "商户负责人不能为空！" }]}
            >
              <Input disabled={!this.state.isEdit} allowClear />
            </Form.Item>
            <Form.Item
              label="手机号码"
              className="form-item ant-col-11"
              name="phone"
              rules={[{ required: true, message: "手机号码不能为空！" }]}
            >
              <Input disabled={!this.state.isEdit} allowClear placeholder="将作为商户登录账号使用" />
            </Form.Item>
            <Form.Item
              label="所在地区"
              required={true}
              className="form-item ant-col-22 flex"
            >
              <Form.Item name="options" className="form-item ant-col-10">
                <Cascader disabled={!this.state.isEdit} options={this.state.options} placeholder="请选择" />
              </Form.Item>
              <Form.Item
                name="address"
                className="ant-col-24"
                rules={[{ required: true, message: "详细地址不能为空！" }]}
              >
                <TextArea disabled={!this.state.isEdit} placeholder="详细地址" />
              </Form.Item>
            </Form.Item>
            {/*<Form.Item*/}
            {/*  label="商户营业时间"*/}
            {/*  className="form-item ant-col-22"*/}
            {/*  name="businessTime"*/}
            {/*>*/}
            {/*  <Input allowClear placeholder="如周一至周五，08:00～22:00" />*/}
            {/*</Form.Item>*/}
            <Form.Item
              label="登录密码"
              className="form-item resetPassword ant-col-11">
              <Input
                disabled
                placeholder="账号创建后随机生成"
                className="ant-col-14"
              />
              <Button disabled={!this.state.isEdit} onClick={this.resetPassword}>密码重置</Button>
            </Form.Item>

            {sessionStorage.getItem('isAgentEnabled')=='true'&&<Form.Item
              label="负责代理商"
              className="form-item ant-col-11"
              name="agentId"
              rules={[{ required: true, message: "负责代理商不能为空！" }]}
            >
              <Select
                disabled={!this.state.isEdit}
                placeholder="负责代理商"
                showSearch
                filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
              >
                {this.state.agentData?.map((e: any) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>}

            <Form.Item
              label="商户有效期"
              required={true}
              className="form-item flex-time ant-col-22"
            >
              <Form.Item
                name="startTime"
                rules={[{ required: true, message: "开始时间不能为空！" }]}
              >
                <DatePicker
                  disabled={!this.state.isEdit}
                  placeholder="开始时间"
                  showTime
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>
              <Form.Item
                name="endTime"
                rules={[{ required: true, message: "到期时间不能为空！" }]}
              >
                <DatePicker
                  disabled={!this.state.isEdit}
                  placeholder="到期时间"
                  showTime
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>
            </Form.Item>
            {/* <Form.Item
              label="小程序App ID"
              className="form-item ant-col-22"
              name="wxAppid"
            >
              <Input placeholder="输入小程序App ID" />
            </Form.Item> */}
            { this.ddlkPlatformSellerDTO?.ddlkProductFeatures?.sellerFeeVideoNum&& <p className="video-space"> 视频点数: {this.state.videoCount} </p> }
            <div>
              <div className="form-titlt">商户图片</div>
              <Form.Item label="商户logo" className="form-item img form-img">
                {this.state.fileListShow.length==0&&<>{(this.props.parent.state.rowData.logo )? (
                    <Image src={this.props.parent.state.rowData.logo} />
                ) : (
                  <span className="logo-tip">暂无LOGO</span>
                )}
                  </>}
                <Upload
                  disabled={!this.state.isEdit}
                  style={{ width: "calc(100% - 130px)" }}
                  {...props}
                  listType="picture-card"
                  fileList={this.state.fileListShow}
                  onChange={this.handleChange}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>

              <Form.Item label="商户营业执照上传" className="form-item img form-img">
                {this.state.fileListBusinessLicenseShow.length==0&&<>
                  {this.props.parent.state.rowData.businessLicenseUrl ?
                (<Image src={this.props.parent.state.rowData.businessLicenseUrl} />) :
                (<span className="logo-tip">暂无营业执照</span>)}
                </>}
                <Upload
                    disabled={!this.state.isEdit}
                    style={{ width: "calc(100% - 130px)" }}
                    {...businessProps}
                    listType="picture-card"
                    fileList={this.state.fileListBusinessLicenseShow}
                    onChange={this.handleBusinessLicenseChange}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>

              {/*<Form.Item label="商户相册" className="form-item ant-col-11">*/}
              {/*  {this.state.exisingStorePic.map((src, index) => (*/}
              {/*    <span>*/}
              {/*      <img*/}
              {/*        src={*/}
              {/*          this.props.parent.state.rowData.publicPreUrl + "/" + src*/}
              {/*        }*/}
              {/*        alt="none"*/}
              {/*      />*/}
              {/*      <Button*/}
              {/*        type="link"*/}
              {/*        onClick={() => this.deleteStorePic(index)}*/}
              {/*      >*/}
              {/*        <DeleteOutlined />*/}
              {/*      </Button>*/}
              {/*    </span>*/}
              {/*  ))}*/}
              {/*  <Upload*/}
              {/*    name="file"*/}
              {/*    accept="image/*"*/}
              {/*    headers={{*/}
              {/*      Authorization: sessionStorage.getItem("token") as string,*/}
              {/*    }}*/}
              {/*    data={{ sellerId: this.props.parent.state.rowData.id }}*/}
              {/*    action={`${this.getAction()}/op/seller/uploadAlbum`}*/}
              {/*    listType="picture-card"*/}
              {/*    onChange={this.handleStorePic}*/}
              {/*    fileList={this.state.storePic}*/}
              {/*  >*/}
              {/*    <div>*/}
              {/*      <PlusOutlined />*/}
              {/*      <div style={{ marginTop: 8 }}>上传图片</div>*/}
              {/*    </div>*/}
              {/*  </Upload>*/}
              {/*  <br />*/}
              {/*  <br />*/}
              {/*  <span className="item-tip">*/}
              {/*    请上传门店门头、环境等照片,支持上传多张*/}
              {/*  </span>*/}
              {/*</Form.Item>*/}
            </div>
            {/*<div className="form-titlt">门店介绍</div>*/}
            {/*<Form.Item*/}
            {/*  label="门店介绍"*/}
            {/*  className="form-item"*/}
            {/*  name="storeIntroductionText"*/}
            {/*>*/}
            {/*  <TextArea rows={4} placeholder="请输入门店介绍" />*/}
            {/*</Form.Item>*/}
            {/*<Form.Item*/}
            {/*  label="门店电话"*/}
            {/*  className="form-item ant-col-11"*/}
            {/*  name="storeContactPhone"*/}
            {/*>*/}
            {/*  <Input placeholder="输入门店电话" />*/}
            {/*</Form.Item>*/}
            {/*<Form.Item label="视频介绍" className="form-item">*/}
            {/*  {this.state.storeIntroductionVideo &&*/}
            {/*    typeof this.state.storeIntroductionVideo == "string" && (*/}
            {/*      <>*/}
            {/*        <video*/}
            {/*          controls*/}
            {/*          style={{ maxWidth: "200px", maxHeight: "200px" }}*/}
            {/*        >*/}
            {/*          <source*/}
            {/*            src={*/}
            {/*              this.props.parent.state.rowData.publicPreUrl +*/}
            {/*              this.props.parent.state.rowData.storeIntroductionVideo*/}
            {/*            }*/}
            {/*          />*/}
            {/*        </video>*/}
            {/*        <Button*/}
            {/*          type="link"*/}
            {/*          onClick={() =>*/}
            {/*            this.setState({ storeIntroductionVideo: null })*/}
            {/*          }*/}
            {/*        >*/}
            {/*          <DeleteOutlined />*/}
            {/*        </Button>*/}
            {/*      </>*/}
            {/*    )}*/}
            {/*  <Input*/}
            {/*    type="file"*/}
            {/*    accept="video/*"*/}
            {/*    onChange={(e) =>*/}
            {/*      this.setState({*/}
            {/*        storeIntroductionVideo: e.target.files*/}
            {/*          ? e.target.files[0]*/}
            {/*          : null,*/}
            {/*      })*/}
            {/*    }*/}
            {/*  />*/}
            {/*</Form.Item>*/}
            {this.state.parentState !== 2 ? (
              <React.Fragment>
                {/*<div className="form-titlt">商户号配置</div>*/}

                {/*<Radio.Group*/}
                {/*  defaultValue="all"*/}
                {/*  style={{ marginBottom: "20px" }}*/}
                {/*  onChange={this.tabChange}*/}
                {/*>*/}
                {/*  <Radio.Button value="all">抖音配置</Radio.Button>*/}
                {/*  /!*<Radio.Button value="all1">快手配置</Radio.Button>*!/*/}
                {/*</Radio.Group>*/}

                {/*{this.state.tabState ?*/}
                {/*    (*/}
                <div className="tab-main">
                    {/*<Form.Item label="抖音主页链接" className="form-item">*/}
                    {/*  <Form.Item name="douyinHref">*/}
                    {/*    <Input*/}
                    {/*      allowClear*/}
                    {/*      className="ant-col-12"*/}
                    {/*      placeholder="填入抖音的商户主页链接地址"*/}
                    {/*    />*/}
                    {/*  </Form.Item>*/}
                    {/*  <span className="item-tip">*/}
                    {/*    填入商户抖音门店的分享链接，可留空，格式参考如：https://v.douyin.com/JW1ApMP/*/}
                    {/*  </span>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="抖音商户名称" className="form-item">*/}
                    {/*  <Form.Item name="douyinNickname">*/}
                    {/*    <Input*/}
                    {/*      allowClear*/}
                    {/*      className="ant-col-12"*/}
                    {/*      placeholder="填入抖音商户名称"*/}
                    {/*    />*/}
                    {/*  </Form.Item>*/}
                    {/*  <span className="item-tip">*/}
                    {/*    填入抖音商户名称，视频的商户名称跟该选项有关，格式参考：海底捞火锅*/}
                    {/*  </span>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="抖音商户位置" className="form-item">
                      <Form.Item name="douyinPOI">
                        <Input
                        disabled={!this.state.isEdit}
                          allowClear
                          className="ant-col-12"
                          placeholder="填入抖音商户地理位置页的分享链接"
                        />
                      </Form.Item>
                      <span className="item-tip c-red">
                        ！重要,宁可不填不要乱填，乱填视频不发
                        填入抖音商户地理位置页的分享链接，视频的地址跟该选项有关，可留空，抖音打开某个视频分享的位置，点击右上角转发，复制链接，将链接填写在此处，格式参考：https://v.douyin.com/JvcECpV/
                      </span>
                    </Form.Item>

                  </div>
                {/*)*/}
                {/*: (*/}
                {/*  <div className="tab-main">*/}
                {/*    <Form.Item*/}
                {/*      label="商户快手主页链接"*/}
                {/*      className="form-item"*/}
                {/*      name="kuaishouHref"*/}
                {/*    >*/}
                {/*      <Input*/}
                {/*        allowClear*/}
                {/*        className="ant-col-12"*/}
                {/*        placeholder="请填写商户的快手id"*/}
                {/*      />*/}
                {/*    </Form.Item>*/}
                {/*    <Form.Item*/}
                {/*      label="快手商户名称"*/}
                {/*      className="form-item"*/}
                {/*      name="kuaishouName"*/}
                {/*    >*/}
                {/*      <Input*/}
                {/*        allowClear*/}
                {/*        className="ant-col-12"*/}
                {/*        placeholder="填入快手商户名称"*/}
                {/*      />*/}
                {/*    </Form.Item>*/}
                {/*  </div>*/}
                {/*)*/}
                {/*}*/}
              </React.Fragment>
            ) : null}
            <div
              style={{
                display: this.ddlkPlatformSellerDTO?.ddlkProductFeatures?.privilegyCanModifyAddress && this.state.isLimits
                  ? "block"
                  : "none",
              }}
            >
              <div className="form-titlt">权限</div>
              <Form.Item
                label="是否可修改店铺地址"
                className={"form-radio"}
                name="canModifyAddress"

                // rules={[{ required: true, message: "商户类型不能为空！" }]}
              >
                <Radio.Group onChange={this.parentChange}>
                  <Radio value={Number(1)}>是</Radio>

                  <Radio value={Number(0)}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div
              style={{
                display:
                  (this.ddlkPlatformSellerDTO?.ddlkProductFeatures
                    ?.sellerFeeAdvancedCloudVisitShop ||
                  this.ddlkPlatformSellerDTO?.ddlkProductFeatures
                    ?.sellerFeeAdvancedVisitShop) && this.state.isService
                    ? "block"
                    : "none",
              }}
            >
              
              <div className="form-titlt">服务</div>
              {/* <div className="ant-row"> */}
              <Row
                style={{
                  display: this.ddlkPlatformSellerDTO?.ddlkProductFeatures
                    ?.sellerFeeAdvancedCloudVisitShop
                    ? "flex"
                    : "none",
                }}
              >
                <Col>
                  <Form.Item
                    label="远程探店高级版"
                    name="check0"
                    valuePropName="checked"
                  >
                    <Checkbox
                      style={{ width: 200 }}
                      onChange={(e) => {
                        this.setState({
                          check0: e.target.checked,
                        });
                      }}
                    >
                      开通
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="服务生效时间"
                    name="time0"
                    rules={[
                      {
                        type: "array" as const,
                        required: this.state.check0,
                        message: "请选择服务生效时间",
                      },
                    ]}
                  >
                    <RangePicker />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                style={{
                  display: this.ddlkPlatformSellerDTO?.ddlkProductFeatures
                    ?.sellerFeeAdvancedVisitShop
                    ? "flex"
                    : "none",
                }}
              >
                <Col>
                  <Form.Item
                    label="达人探店高级版"
                    name="check1"
                    valuePropName="checked"
                  >
                    <Checkbox
                      style={{ width: 200 }}
                      onChange={(e) => {
                        this.setState({
                          check1: e.target.checked,
                        });
                      }}
                    >
                      开通
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="服务生效时间"
                    name="time1"
                    rules={[
                      {
                        type: "array" as const,
                        required: this.state.check1,
                        message: "请选择服务生效时间",
                      },
                    ]}
                  >
                    <RangePicker />
                  </Form.Item>
                </Col>
              </Row>

              {/* </div> */}
            </div>
          </div>
          <Consumer>
            {(obj) => {
              return (
                <div className="form-btn">
                  {
                    this.state.isEdit ?  <Button type="primary" htmlType="submit">
                    提交
                  </Button>:""
                  }
                 
                  <Button onClick={() => obj.setVisible(false)}>取消</Button>
                </div>
              );
            }}
            {/* <div className="form-btn">
              <Button type="primary" htmlType="submit">
                提交
              </Button>
              <Button onClick={this.props.parent.onCancel}>取消</Button>
            </div> */}
          </Consumer>
        </Form>
        {/*</Modal>*/}
      </>
    );
  }
}
