import React, { useCallback, useState, useEffect } from "react";
import { Button, Checkbox, Form, Input,Radio } from 'antd';
interface IProps {
    configInfo: Object;
}
export const CreateSubject=(props)=>{
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    return (
        <>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={props.configInfo}
                onFinish={onFinish}
                autoComplete="off">
                <Form.Item
                    label="运营主体ID"
                    name="operatingEntityId"
                    rules={[{ required: false, message: '请输入运营主体ID' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="管理员用户名"
                    name="adminName"
                    rules={[{ required: false, message: '请输入管理员用户名' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="管理员密码"
                    name="adminPwd"
                    rules={[{ required: false, message: '请输入管理员密码' }]}>
                    <Input.Password style={{width:'200px'}} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </>
    );
}
