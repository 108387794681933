import React, { useCallback, useState, useEffect } from "react";
import {Button, Checkbox, Form, Input, message, Radio, Upload} from 'antd';
import {CameraOutlined} from "@ant-design/icons";
interface IProps {
    configInfo: Object;
}
export const AlipayConfig=(props)=>{
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const [newFileList, setNewFileList] = useState<any>([]);
    const [brandLogoUrl, setBrandLogoUrl] = useState<string>(`${props.configInfo.brandLogoUrl}`);

    let arr:any[] = [];
    let host:any=sessionStorage.getItem("host");

    // @ts-ignore
    const uploadProps:any= {
        action: `${JSON.parse(host)?.xmgx}/op/operating-entity/uploadMaterial`,
        headers: {
            Authorization: sessionStorage.getItem("token")
        },
        name: "file",
        accept: "image/*",
        listType: "picture-card",
        multiple: false,
        maxCount:1,
        showUploadList: true,
        fileList:newFileList,
        data:{
            operatingEntityId: props.configInfo.operatingEntityId,
            isPrivate:false
        },
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(`上传成功`);
                setNewFileList({
                    uid: info.file.uid,
                    url: `${info.file.url}`
                })
                setBrandLogoUrl(newFileList[0].url);
            } else if (info.file.status === 'error') {
                message.error(`上传失败，请重试！`);
            }
        },
        // beforeUpload: (file, fileList) => {
        //     return new Promise((resolve, reject) => {
        //         Request.postFormData(`${JSON.parse(host)?.xmgx}/op/operating-entity/uploadMaterial`,{
        //             operatingEntityId: props.configInfo.operatingEntityId,
        //             isPrivate:false,
        //             file:file
        //         }).then((res) => {
        //             console.log(res,"res");
        //             let ossUrl;
        //             if (res.code === 0) {
        //                 arr.push({
        //                     uid: file.uid,
        //                     url: `${res.data}`
        //                 })
        //                 // ossUrl = res.data;
        //                 // ossUrl = res.data.key.filePath;
        //                 // let filePrefix = res.data.key.filePrefix;
        //                 // let result: any = [
        //                 //     {status: "uploading", uid: file.uid, percent: 0},
        //                 // ];
        // setBrandLogoUrl(...arr);
        //             }
        //         });
        //     });
        //
        // }
    };

    return (
        <>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{...props.configInfo,...props.configInfo.alipayWalletConfig}}
                onFinish={onFinish}
                autoComplete="off">
                <Form.Item
                    label="appId"
                    name="appId"
                    rules={[{ required: false, message: '请输入mchPublicKeyFile' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="privateKey"
                    name="privateKey"
                    rules={[{ required: false, message: '请输入mchPublicKeyFile' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="appId"
                    name="appId"
                    rules={[{ required: false, message: '请输入mchPublicKeyFile' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>

                <Form.Item
                    label="serverUrl"
                    name="serverUrl"
                    rules={[{ required: false, message: '请输入platformKeyFile' }]}>
                    <Upload {...uploadProps}>
                        <CameraOutlined style={{fontSize:"24px"}}/>
                        {/*<div>{newFileList.length >= 1 ? null : <CameraOutlined style={{fontSize:"24px"}}/>}</div>*/}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="appCertPath"
                    name="appCertPath"
                    rules={[{ required: false, message: '请输入mchPublicKeyFile' }]}>
                    <Upload {...uploadProps}>
                        <CameraOutlined style={{fontSize:"24px"}}/>
                        {/*<div>{newFileList.length >= 1 ? null : <CameraOutlined style={{fontSize:"24px"}}/>}</div>*/}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="alipayCertPath"
                    name="alipayCertPath"
                    rules={[{ required: false, message: '请输入mchPublicKeyFile' }]}>
                    <Upload {...uploadProps}>
                        <CameraOutlined style={{fontSize:"24px"}}/>
                        {/*<div>{newFileList.length >= 1 ? null : <CameraOutlined style={{fontSize:"24px"}}/>}</div>*/}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="alipayRootCertPath"
                    name="alipayRootCertPath"
                    rules={[{ required: false, message: '请输入mchPublicKeyFile' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>

                <Form.Item label="是否启用" name="canPayToAlipayMember">
                    <Radio.Group>
                        <Radio value="true">是</Radio>
                        <Radio value="false">否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </>
    );
}
