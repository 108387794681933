import {Checkbox, Input, Popover, Table, Tabs} from "antd";
import Button from "antd/es/button";
import React, {useEffect, useState} from "react";
import Request, {EHostType} from "../../../../../../utils/Request";
import {verifyStatusMap} from "../../../../helper";
import {AuditModal} from "./audit";
import {CheckboxChangeEvent} from "antd/es/checkbox/Checkbox";

const { TabPane } = Tabs;

interface IProps {
  currenItem?: any;
  isLimits:boolean|undefined;
  queryType:any;
  menuType:any;
}
let host: any = sessionStorage.getItem("host");

export const TalentList = (props: IProps) => {
  let darenPhone;
  const darenPhoneChange = function (e) {
    darenPhone = e.target.value;
  };
  const onSearch = function () {
    getList({ darenPhone, pageNo: 1 });
  };

  let columns = [
    {
      title: "达人ID",
      dataIndex: "darenUserId",
      key: "darenUserId",
      // width: "100px",
      // ellipsis: true,
    },
    {
      title: "达人信息",
      // dataIndex: 'darenUserNick',
      key: "darenUserNick",
      render: (row, i) => {
        return (
          <div>
            <div>昵称:{i.darenUserNick ? i.darenUserNick : "-"}</div>
            {props.queryType != 'excellentTask' ?  <div>手机号:{i.darenPhone}</div>:""}
           
          </div>
        );
      },
    },
    {
      title: "报名时间",
      dataIndex: "orderTime",
      key: "orderTime",
      // align: 'center',
    },
    {
      title: "作品上传时间",
      dataIndex: "returnDyVideoTime",
      key: "returnDyVideoTime",
      // align: 'center',
    },
    {
      title: "作品预览",
      dataIndex: "address",
      key: "address",
      // align: 'center',
      width: "120px",
      render: (row, i) => {
        return (
          <div>
            <img
              src={i.dyVideoCoverUrl}
              style={{ width: "100px", height: "100px", cursor: "pointer" }}
              onClick={() => {
                window.open(i.dyVideoUrl);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "作品数据",
      dataIndex: "summery",
      key: "summery",
      width: "120px",
      render: (row, i) => {
        return (
          <div>
            <div>播放量:{i.dyVideoPlayCount}</div>
            <div>点赞:{i.dyVideoDiggCount}</div>
            <div>评论量:{i.dyVideoCommentCount}</div>
            <div>转发量:{i.dyVideoShareCount}</div>
          </div>
        );
      },
    },
    {
      title: "是否达标",
      dataIndex: "upToStandardText",
      key: "upToStandardText",
      width: "100px",
    },
    {
      title: "审核状态",
      dataIndex: "verifyStatusText",
      key: "verifyStatusText",
      width: "120px",
      render: (row, i) => {
        return (
          <div>
            {i?.verifyStatus === 1 && (
              <div style={{ color: "#FFAA33" }}>{i.verifyStatusText}</div>
            )}
            {i?.verifyStatus == 2 && (
              <div style={{ color: "#26BF4D" }}>{i.verifyStatusText}</div>
            )}
            {i?.verifyStatus == 3 && (
              <Popover
                placement="topLeft"
                title="驳回原因"
                content={i?.notApproveReason}
                arrowPointAtCenter
              >
                <div style={{ color: "#ff1e1e" }}>{i.verifyStatusText}</div>
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "operate",
      key: "operate",
      width: "100px",
      render: (row, i) => {
        return (
          <div>
            {i.verifyStatus == 1 && (
              <AuditModal
                id={i.id}
                onAuditSuccess={() => getList({ pageNo: pageNo })}
              />
            )}
            {i.verifyStatus != 1 && (
              <Button type="link" disabled>
                作品审核
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const bmColumns = [
    {
      title: "达人ID",
      dataIndex: "darenUserId",
      key: "darenUserId",
      // width: "100px",
      // ellipsis: true,
    },
    {
      title: "达人信息",
      dataIndex: "darenUserId",
      key: "darenUserId",
      // width: "100px",
      // ellipsis: true,
    },
    {
      title: "报名时间",
      dataIndex: "darenUserId",
      key: "darenUserId",
      // width: "100px",
      // ellipsis: true,
    },
    {
      title: "审核时间",
      dataIndex: "darenUserId",
      key: "darenUserId",
      // width: "100px",
      // ellipsis: true,
    },
    {
      title: "达人状态",
      dataIndex: "darenUserId",
      key: "darenUserId",
      // width: "100px",
      // ellipsis: true,
    },
    {
      title: "操作/说明",
      dataIndex: "darenUserId",
      key: "darenUserId",
      // width: "100px",
      // ellipsis: true,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(4);
  const [total, setTotal] = useState<number>(0);
  const [summery, setSummery] = useState<any>({});
  const [toVerify, setToVerify] = useState(false);

  const [dmData, setDmData] = useState([]);
  const [dmPageNo, setDmPageNo] = useState<number>(1);
  const [dmPageSize, setDmPageSize] = useState<number>(4);
  const [dmTotal, setDmTotal] = useState<number>(0);

  //list
  const getList = async (params: any) => {
    let host: any = sessionStorage.getItem("host");
    let url = `/mgr/ddlk/queryAllClipPubSubTask`;
    Request.jsonPost(EHostType.DDlk, url, {
      pageNo: params.pageNo ? params.pageNo : pageNo,
      pageSize,
      darenPhone: params.darenPhone,
      taskId:props.menuType ? props.currenItem.taskId:props.currenItem.id,
      videoSubmit: true,
      toVerify,
    }).then((res) => {
      if (params)
        setDataSource(
          res?.data?.map((item) => {
            return {
              ...item,
              verifyStatusText: verifyStatusMap[item.verifyStatus],
              upToStandardText: item.upToStandard ? "是" : "否",
            };
          })
        );
      setTotal(res?.totalCount);
    });
  };

  //统计
  const getSummery = () => {
    let host: any = sessionStorage.getItem("host");
    let url = `/mgr/ddlk/getTaskSummary?id=${props.menuType ? props.currenItem.taskId : props.currenItem.id}`;
    Request.jsonPost(EHostType.DDlk, url, { id: props.menuType ? props.currenItem.taskId : props.currenItem.id}).then((res) => {
      setSummery(res.data);
    });
  };
  const onCheckChange = (e: CheckboxChangeEvent) => {
    setToVerify(e.target.checked);
  };

  const getColumns  = () => {
    if(!props.isLimits){
      return columns.filter(item => item.title !="操作")
    }else if(props.menuType){
      return columns.filter(item => item.title !="操作")
    }else{
      return columns
    }
  }
  useEffect(() => {
     
    getList({});
    getSummery();
  }, []);

  return (
    <div style={{ marginTop: "10px" }}>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <div style={{ flex: 1 }}>
            {summery?.joinedDarenNum}人<br />
            <span>报名人数</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.videoNum}人<br />
            <span>提交作品数</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.qualifiedDarenNum}人<br />
            <span>达标人数</span>
          </div>

          <div style={{ flex: 1 }}>
            {summery.totalPlayCount}
            <br />
            <span>总播放量</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.totalDiggCount}
            <br />
            <span>总点赞量</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.totalCommentCount}
            <br />
            <span>总评论量</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.totalShareCount}
            <br />
            <span>总转发量</span>
          </div>
        </div>
      </div>
      {/* <Tabs defaultActiveKey="1">
        <TabPane tab="报名审核" key="1">
          <p>报名人数： 20人 所需达人数： 5人 已审核： 3人 剩余达人数： 2人</p>
          <Table
            dataSource={dataSource}
            columns={bmColumns}
            scroll={{ y: 600 }}
            pagination={{
              pageSize: pageSize,
              total: total,
              showSizeChanger: false,
              showTotal: (total) => `共 ${total} 条数据`,
              onChange: (page: number) => {
                getList({ pageNo: page });
              },
            }}
          />
        </TabPane> */}
      {/* <TabPane tab="作品审核" key="2"> */}
      {
        props.queryType != 'excellentTask' ?  <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "10px",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <span>达人手机号：</span>
        <Input
          style={{ width: "300px", marginRight: "30px" }}
          onChange={darenPhoneChange}
        ></Input>
        <Checkbox onChange={onCheckChange} value={toVerify}>
          仅看待审核
        </Checkbox>
        <Button type="primary" onClick={onSearch}>
          搜索
        </Button>
      </div>:""
      }
      <Table
        dataSource={dataSource}
        columns={getColumns()}
        scroll={{ y: 600 }}
        pagination={{
          pageSize: pageSize,
          total: total,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: (page: number) => {
            setPageNo(page);
            getList({ pageNo: page });
          },
        }}
      />
      {/* </TabPane>
      </Tabs> */}
    </div>
  );
};
