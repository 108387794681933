/*
 * @Date: 2022-09-29 14:52:11
 * @LastEditors: litj
 * @LastEditTime: 2022-10-20 15:13:33
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/module/page/OperationConfiguration/verbConfig/index.tsx
 */
import { Modal, Tabs } from "antd";
import { useEffect, useState } from "react";
import { VerbForms } from "./VerbForms";
import Request, { EHostType } from "../../../utils/Request";

let host: any = sessionStorage.getItem("host");

export const VerbConfig = (props) => {
const customTag = JSON.parse( sessionStorage.getItem('ddlkPlatformSellerDTO') || '0');
  const data = [
    {
      name: "运营主体配置",
      paramName: "",
      formData: {},
      formList: [
        {
          label: "OEM类型",
          key: "oemType",
          type: "radio",
          options: [
            {
              label: "共有部署",
              value: 1,
            },
            {
              label: "私有部署",
              value: 2,
            },
          ],
        },
        {
          label: "OEM客户联系微信",
          key: "oemWechat",
          type: "input",
        },
        {
          label: "OEM客户联系手机号",
          key: "oemPhone",
          type: "input",
        },
        {
          label: "是否启用支付宝零钱提现",
          key: "canPayToAlipayMember",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否启用微信零钱提现",
          key: "canPayToWechatMember",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
      ],
    },
    {
      name: "域名注册/备案",
      paramName: "",
      formData: {},
      formList: [
        {
          label: "域名",
          key: "oemDomain",
          type: "input",
        },
        {
          label: "出口IP",
          key: "outIp",
          type: "input",
        },
        {
          label: "备案是否完成",
          key: "isDomainApproved",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
      ],
    },
    {
      name: "创建运营主体",
      paramName: "",
      formData: {},
      formList: [
        {
          label: "运营主体ID",
          key: "operatingEntityId",
          type: "input",
        },
        {
          label: "管理员用户名",
          key: "adminName",
          type: "input",
        },
        // {
        //   label: "管理员密码",
        //   key: "adminPwd",
        //   type: "password",
        // },
      ],
    },
    {
      name: "创建平台代理商/商家",
      paramName: "",
      formData: {},
      formList: [
        {
          label: "平台官方代理商ID",
          key: "agentId",
          type: "input",
        },
        {
          label: "平台官方商家ID",
          key: "sellerId",
          type: "input",
        },
        {
          label: "多多来客品牌名称",
          key: "brandText",
          type: "input",
        },
        {
          label: "多多来客品牌logo",
          key: "brandLogoUrl",
          type: "upload",
          accept: "image/*",
          fileList: [],
        },
      ],
    },
    {
      name: "配置商家端小程序",
      paramName: "sellerMiniConfig",
      formData: {},
      formList: [
        {
          label: "APPID",
          key: "appId",
          type: "input",
        },
        {
          label: "Secret",
          key: "secret",
          type: "input",
        },
        {
          label: "是否已设置小程序代码上传白名单",
          key: "isWhiteIp4CodeDeployedConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置request域名",
          key: "isRequestDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置upload域名",
          key: "isUploadDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置download域名",
          key: "isDownloadDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置业务域名",
          key: "isBizDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已添加企业微信插件(2个)",
          key: "isQyWechatPluginConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        // {
        //   label: "企业微信联系人配置id",
        //   key: "qiyeConsultQyWechatConfigId",
        //   type: "input",
        // },
        // {
        //   label: "企业微信联系群url",
        //   key: "qiyeConsultQyWechatGroupUrl",
        //   type: "input",
        // },
      ],
    },
    {
      name: "配置达人端小程序",
      paramName: "darenMiniConfig",
      formData: {},
      formList: [
        {
          label: "AppID",
          key: "appId",
          type: "input",
        },
        {
          label: "Secret",
          key: "secret",
          type: "input",
        },
        {
          label: "是否已设置小程序代码上传白名单",
          key: "isWhiteIp4CodeDeployedConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置request域名",
          key: "isRequestDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置upload域名",
          key: "isUploadDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置download域名",
          key: "isDownloadDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置业务域名",
          key: "isBizDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已添加企业微信插件(2个)",
          key: "isQyWechatPluginConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        // {
        //   label: "企业微信联系人配置id",
        //   key: "qiyeConsultQyWechatConfigId",
        //   type: "input",
        // },
        // {
        //   label: "企业微信联系群url",
        //   key: "qiyeConsultQyWechatGroupUrl",
        //   type: "input",
        // },
      ],
    },
    {
      name: "配置达人端小程序(抖音)",
      paramName: "dyDarenMiniConfig",
      formData: {},
      formList: [
        {
          label: "AppID",
          key: "appId",
          type: "input",
        },
        {
          label: "Secret",
          key: "secret",
          type: "input",
        },
        {
          label: "是否已设置小程序代码上传白名单",
          key: "isWhiteIp4CodeDeployedConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置request域名",
          key: "isRequestDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置upload域名",
          key: "isUploadDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置download域名",
          key: "isDownloadDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置业务域名",
          key: "isBizDomainConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已添加企业微信插件(2个)",
          key: "isQyWechatPluginConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        // {
        //   label: "企业微信联系人配置id",
        //   key: "qiyeConsultQyWechatConfigId",
        //   type: "input",
        // },
        // {
        //   label: "企业微信联系群url",
        //   key: "qiyeConsultQyWechatGroupUrl",
        //   type: "input",
        // },
      ],
    },
    {
      name: "配置公众号",
      paramName: "mpConfig",
      formData: {},
      formList: [
        {
          label: "AppID",
           key: "appId",
          type: "input",
        },
        {
          label: "Secret",
          key: "secret",
          type: "input",
        },
        {
          label: "token",
          key: "token",
          type: "input",
        },
        {
          label: "aesKey",
          key: "aesKey",
          type: "input",
        },
        {
          label: "模版ID",
          key: "visitShopTaskNotifyDarenTemplateId",
          type: "input",
        },
        {
          label: "是否已启用",
          key: "isServerConfigEnabled",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已关联商家端/达人端小程序",
          key: "isMiniProgramRelated",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
        {
          label: "是否已设置开发白名单IP",
          key: "isWhiteIpConfiged",
          type: "radio",
          options: [
            {
              label: "是",
              value: true,
            },
            {
              label: "否",
              value: false,
            },
          ],
        },
      ],
    },
    {
      name: "配置微信支付",
      paramName: "wxpayConfig",
      isPrivate: true,
      formData: {},
      formList: [
        {
          label: "商户号",
          key: "mchId",
          type: "input",
        },
        {
          label: "apiv2密钥",
          key: "mchKey",
          type: "input",
        },
        {
          label: "微信支付证书",
          key: "keyPath",
          type: "upload",
          fileList: [],
        },
        // {
        //   label: "是否已关联商家端/达人端小程序",
        //   key: "isMiniProgramRelated",
        //   type: "radio",
        //   options: [
        //     {
        //       label: "是",
        //       value: "true",
        //     },
        //     {
        //       label: "否",
        //       value: "false",
        //     },
        //   ],
        // },
      ],
    },
    {
      name: "配置付款到零钱(微信)",
      paramName: "wxpayWalletConfig",
      isPrivate: true,
      formData: {},
      formList: [
        {
          label: "微信支付apiV3Key",
          key: "apiV3Key",
          type: "input",
          fileList: [],
        },
        {
          label: "mchPublicKeyFile",
          key: "mchPublicKeyFile",
          type: "upload",
          fileList: [],
        },
        {
          label: "mchPrivateKeyFile",
          key: "mchPrivateKeyFile",
          type: "upload",
          fileList: [],
        },
        {
          label: "merchantId",
          key: "merchantId",
          type: "input",
        },
        {
          label: "serialNo",
          key: "serialNo",
          type: "input",
        },
      ],
    },
    {
      name: "配置付款到零钱(支付宝)",
      paramName: "alipayWalletConfig",
      isPrivate: true,
      formData: {},
      formList: [
        {
          label: "appId",
          key: "appId",
          type: "input",
        },
        {
          label: "privateKey",
          key: "privateKey",
          type: "input",
        },
        // {
        //   label: "serverUrl",
        //   key: "serverUrl",
        //   type: "input",
        // },
        {
          label: "appCertPath",
          key: "appCertPath",
          type: "upload",
          fileList: [],
        },
        {
          label: "alipayCertPath",
          key: "alipayCertPath",
          type: "upload",
          fileList: [],
        },
        {
          label: "alipayRootCertPath",
          key: "alipayRootCertPath",
          type: "upload",
          fileList: [],
        },
      ],
    },
    {
      name: "配置注册协议合同",
      paramName: "",
      formData: {},
      formList: [
        {
          label: "商家注册协议合同",
          key: "sellerRegistrationContractUrl",
          type: "upload",
          fileList: [],
        },
        {
          label: "推广员注册协议合同",
          key: "agentRegistrationContractUrl",
          type: "upload",
          fileList: [],
        },
      ],
    },
    {
      name: "配置代理商招募二维码",
      paramName: "",
      formData: {},
      formList: [
        {
          label: "代理商招募二维码",
          key: "payConsultQRCodeUrl",
          type: "upload",
          accept: "image/*",
          fileList: [],
        },
      ],
    },
    {
      name: "配置费用/率",
      paramName: "",
      formData: {},
      formList: [
        {
          label: "普通推广员注册的金额",
          key: "agentRegisterFee",
          type: "inputNumber",
          isFormatter:1,
          addonAfter: "元",
        },
        {
          label: `${customTag.customerTaskCustNamePrefix}(${customTag.employeeTaskCustNamePrefix})活动最低充值金额`,
          key: "consumerMinRechargeFee",
          type: "inputNumber",
          isFormatter:1,
          addonAfter: "元"
        },
        {
          label: "邀请一般推广员奖励金额",
          key: "invitOrdinaryAgentBonus",
          type: "inputNumber",
          isFormatter:1,
          addonAfter: "元",
        },
        {
          label: "邀请城市运营商奖励金额",
          key: "invitOperationMerchantAgentBonus",
          type: "inputNumber",
          isFormatter:1,
          addonAfter: "元",
        },
        {
          label: `${customTag.customerTaskCustNamePrefix}活动技术服务费`,
          key: "pubConsumerTaskTechServiceFeePerVideo",
          type: "inputNumber",
          isFormatter:1,
          addonAfter: "元/视频",
        },
        {
          label: `${customTag.customerTaskCustNamePrefix}活动技术服务费成本`,
          key: "pubConsumerTaskCostFeePerVideo",
          type: "inputNumber",
          isFormatter:1,
          addonAfter: "元/视频",
        },
        {
          label:`${customTag.visitShopCustName}活动技术服务费`,
          key: "pubVisitShopTaskTechServiceFeePerVideo",
          type: "inputNumber",
          isFormatter:1,
          addonAfter: "元/视频",
        },
        {
          label: `${customTag.cloudVisitShopCustName}活动技术服务费`,
          key: "pubCloudTaskTechServiceFeePerVideo",
          type: "inputNumber",
          isFormatter:1,
          addonAfter: "元/视频",
        },
        {
          label: `${customTag.cloudVisitShopCustName}作品达标最低播放量`,
          key: "darenMinPlayCount",
          type: "inputNumber",
          addonAfter: "次",
        },
      ],
    },
    {
      name: "配置云片",
      paramName: "yunPianConfig",
      formData: {},
      ownObjectProterties: [
        "VerifyCode",
        "PasswordReset",
        "OperatorCreate",
        "AgentCreate",
        "SellerCreate",
        "VisitShopTaskNotifyDaren",
        "CustomerTaskJoinedNotify",
        "ConsumerTaskNotifyDaren",
        "MgrNotifyOemAdmin",
        "TaskApprovedNotifySeller",
        "LevelApprovedNotifyDaren"
      ],
      proterty: "templateId",
      formList: [
        {
          label: "key",
          key: "key",
          type: "input",
        },
        {
          label: "验证码",
          key: "VerifyCode",
          type: "input",
        },
        {
          label: "重置密码",
          key: "PasswordReset",
          type: "input",
        },
        {
          label: "新建运营人员",
          key: "OperatorCreate",
          type: "input",
        },
        {
          label: "新建代理商",
          key: "AgentCreate",
          type: "input",
        },
        {
          label: "新建商家",
          key: "SellerCreate",
          type: "input",
        },
        {
          label: `${customTag.visitShopCustName}接单通知`,
          key: "VisitShopTaskNotifyDaren",
          type: "input",
        },
        {
          label: `${customTag.customerTaskCustNamePrefix}提供作品成功`,
          key: "CustomerTaskJoinedNotify",
          type: "input",
        },
        {
          label:"达人提现通知",
          key: "ConsumerTaskNotifyDaren",
          type: "input",
        },
        {
          label: "探店沟通群不足短信通知模板ID",
          key: "MgrNotifyOemAdmin",
          type: "input",
        },
        {
          label: "任务审核通过短信通知模板ID",
          key: "TaskApprovedNotifySeller",
          type: "input",
        },
        {
          label: "达人等级通过短信通知模板ID",
          key: "LevelApprovedNotifyDaren",
          type: "input",
        },
        {
          label: "是否启用",
          key: "status",
          type: "radio",
          options: [
            {
              label: "是",
              value: 1,
            },
            {
              label: "否",
              value: 0,
            },
          ],
        },
      ],
    },
    {
      name: "其他",
      paramName: "",
      formData: {},
      formList: [
        {
          label: "企业微信联系人配置id",
          key: "qiyeConsultQyWechatConfigId",
          type: "input",
        },
        {
          label: "企业微信联系群url",
          key: "qiyeConsultQyWechatGroupUrl",
          type: "input",
        },
        {
          label: "远程探店个性化名称",
          key: "cloudVisitShopCustName",
          type: "input",
          placeholder:'格式：xxxx探店或xxxx推广，前缀最多4个字，后缀固定2个字'
        },
        {
          label: "消费者推广个性化名称",
          key: "customerTaskCustName",
          type: "input",
          placeholder:'格式：xxxx探店或xxxx推广，前缀最多4个字，后缀固定2个字'
        },
        {
          label: "员工推广个性化名称",
          key: "employeeTaskCustName",
          type: "input",
          placeholder:'格式：xxxx探店或xxxx推广，前缀最多4个字，后缀固定2个字'
        },
        {
          label: "达人探店个性化名称",
          key: "visitShopCustName",
          type: "input",
          placeholder:'格式：xxxx探店或xxxx推广，前缀最多4个字，后缀固定2个字'
        }
      ],
    },
    {
      name: "多多来客产品功能开通",
      paramName: "ddlkProductFeatures",
      formData: {},
      formList: [
        {
          label: `商家续费--${customTag.customerTaskCustNamePrefix}/${customTag.employeeTaskCustNamePrefix}/视频挂载`,
          key: "sellerFeeVideoNum",
          type: "singleCheck",
          optionName: "开通",
          valuePropName: "checked",
        },
        {
          label: `商家续费--${customTag.cloudVisitShopCustName}高级版`,
          key: "sellerFeeAdvancedCloudVisitShop",
          type: "singleCheck",
          optionName: "开通",
          valuePropName: "checked",
        },
        {
          label: `商家续费--${customTag.visitShopCustName}高级版`,
          key: "sellerFeeAdvancedVisitShop",
          type: "singleCheck",
          optionName: "开通",
          valuePropName: "checked",
        },
        {
          label: "权限--商家是否可以修改店铺地址",
          key: "privilegyCanModifyAddress",
          type: "singleCheck",
          optionName: "开通",
          valuePropName: "checked",
        },
        {
          label: "推广员是否可自主升级为城市运营商",
          key: "agentCanUpgradeMannualy",
          type: "singleCheck",
          optionName: "开通",
          valuePropName: "checked",
        },
        {
          label: "视频作品是否需要POI检测",
          key: "videoPoiVideoCheck",
          type: "singleCheck",
          optionName: "开通",
          valuePropName: "checked",
        },
        {
          label: "是否需要推广（代理商）",
          key: "isAgentEnabled",
          type: "singleCheck",
          optionName: "开通",
          valuePropName: "checked",
        }
        // {
        //   label:"是否开通达人入驻",
        //   key: "darenRegEnabled",
        //   type: "singleCheck",
        //   optionName: "开通",
        //   valuePropName: "checked",
        // }
      ],
    },
  ];
  //   const [visible, setVisible] = useState<any>(`${false}`);
  const dto = [...data];
  const [visible, setVisible] = useState(props.configPop);
  const [configInfo, setConfigInfo] = useState(data);
  const [preFixUrl, setPreFixUrl] = useState("");
  const [info, setInfo] = useState<any>({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.configPop) {
      init();
    }
    setVisible(props.configPop);
  }, [props.configPop]);
  // console.log(props.configPop);

  function onClose() {
    props.onClose();
    setConfigInfo(dto);
  }
  function onOk() {
    let host: any = sessionStorage.getItem("host");
    let url = `/op/operating-entity/submitOperEntityConf?operatingEntityId=${props.dataId}`;
    let param = {};
    for (let i = 0; i < configInfo.length; i++) {
      
      if (configInfo[i].paramName) {
        param[configInfo[i].paramName] = configInfo[i].formData;
      } else {
        Object.assign(param, configInfo[i].formData);
      }

      if (configInfo[i].proterty) {
        param[configInfo[i].proterty!] = {};
        for (let j = 0; j < configInfo[i].ownObjectProterties!.length; j++) {
          param[configInfo[i].proterty!][
            configInfo[i].ownObjectProterties![j]
          ]! = configInfo[i].formData[configInfo[i].ownObjectProterties![j]];
        }
      }
    }
    // console.log(configInfo,'====>>>')
    setLoading(true);
    Request.jsonPost(EHostType.XMGX, url, {}).then((res) => {
        // console.log(res,'====>>>')
        onClose();
    }).finally(() => {
        setLoading(false);
    });
  }

  function showModal() {
    setVisible(true);
  }
  const init = () => {
    // console.log(props.dataId);
    //${props.operatingEntityId}
    let url = `/op/operating-entity/getOperEntityConf?operatingEntityId=${props.dataId}`;
    Request.jsonPost(EHostType.XMGX, url, {}).then((res) => {
      // console.log(res, "config");
      if (res.code == 0) {
        const data = res.data || {
          operatingEntityId: props.dataId,
        };
          // data["consumerMinRechargeFee"] = data["consumerMinRechargeFee"] / 100;
          // data["pubCloudTaskTechServiceFeePerVideo"] = data["pubCloudTaskTechServiceFeePerVideo"] / 100;
        configInfo.forEach((item) => {
          // console.log(item,'item')
          item.formList.forEach((formItem) => {
            const key = formItem?.key;
            if (key in data) {
              // console.log(data,'返回的data')
              // 处理最外层数据
              item.formData[key] = data[key];
            } else if (
              item.paramName && data[item.paramName] && key in data[item.paramName]
            ) {
              // 处理对象
              item.formData[key] = data[item.paramName][key];
            } else if (
              item.proterty && data[item.paramName] && item.proterty in data[item.paramName]
            ) {
              // 例如 单独处理yunpianConfig.templateId  是一个对象 用于两层对象取值
              // 向服务端发送数据时还需要单独处理yunpianConif数据
              item.formData = {
                ...item.formData,
                ...data[item.paramName][item.proterty],
              };
            }
            // 处理上传文件的初始值
            if (formItem.type === "upload") {
              if (!item.paramName && data[key]) {
                formItem.fileList.push({
                  uid: "",
                  url: data[key],
                });
              } else if (
                item.paramName && data[item.paramName] && data[item.paramName][key]
              ) {
                formItem.fileList.push({
                  uid: "",
                  url: data[item.paramName][key],
                });
              }
            }
          });
        });
        setConfigInfo([...configInfo]);
        setInfo(data);
      }
    });
    url = `/common/oss/getDefaultFilePrefix`;
    Request.get(EHostType.XMGX, url, {}).then((res) => {
      if (res.code == 0) {
        setPreFixUrl(res.data);
      }
    });
  };
  const panes = configInfo.map((item, index) => {
    return (
      <>
        <Tabs.TabPane tab={item.name} key={index}>
          <VerbForms
            {...item}
            operatingEntityId={props.dataId}
            preFixUrl={preFixUrl}
          />
        </Tabs.TabPane>
      </>
    );
  });

  return (
    <>
      <Modal
        width={1000}
        visible={visible}
        onCancel={onClose}
        title={"配置(" + props.brandText + ")"}
        onOk={onOk}
        okText="提交"
        confirmLoading={loading}
        destroyOnClose>
        <div>
          <Tabs defaultActiveKey="0" tabPosition={"left"}>
            {panes}
          </Tabs>
        </div>
      </Modal>
    </>
  );
};
