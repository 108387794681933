import * as React from 'react';
import { Input, Form, Button, Radio, Select, DatePicker } from 'antd';
import './index.css';

const { Option } = Select;
const { RangePicker } = DatePicker

interface page {
    radioValue:any,
}

export default class AgentAdd extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {
            radioValue:0
        }
    }
    

    public render() {
      
        return (
            <React.Fragment>

                <div className="form-content">

                    <Form.Item label="负责人姓名" className="form-item ant-col-23" name="name" rules={[{ required: true, message: '负责人姓名不能为空！' }]}>
                        <Input allowClear />
                    </Form.Item>

                    <Form.Item label="负责人手机号码" className="form-item ant-col-23" name="phone" rules={[{ required: true, message: '负责人姓名不能为空！' }]}>
                        <Input allowClear />
                    </Form.Item>

                    <Form.Item label="登录账号" className="form-item ant-col-23" name="userName" rules={[{ required: true, message: '登录账号不能为空！' }]}>
                        <Input allowClear placeholder="作为商户登录账号使用" />
                    </Form.Item>

                    <Form.Item label="登录密码" className="form-item ant-col-23">
                        <Input readOnly placeholder="账号创建后随机生成" />
                    </Form.Item>

                    <Form.Item label="帐号角色" className="form-item ant-col-23" name="role" rules={[{ required: true, message: '运营人员不能为空！' }]}>
                        <Select placeholder="请选择">
                            {/* <Option value={Number(0)}>超级管理员</Option> */}
                            {/* <Option value={Number(1)}>管理员</Option> */}
                            <Option value={Number(2)}>运营</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="账号状态" className="form-radio" name="status" rules={[{ required: true, message: '账号状态不能为空！' }]}>
                        <Radio.Group>
                            <Radio value={Number(1)}>正常</Radio>
                            <Radio value={Number(0)}>关闭</Radio>
                        </Radio.Group>
                    </Form.Item>
                   
                    {/*<Form.Item label="测试账号" initialValue={this.state.radioValue} className="form-radio" name="istest" rules={[{ required: true, message: '请确定否为测试账号！' }]}>*/}
                    {/*    <Radio.Group onChange={this.changeRadio} >*/}
                    {/*        <Radio  value={1}>是</Radio>*/}
                    {/*        <Radio  value={0}>否</Radio>*/}
                    {/*    </Radio.Group>*/}
                    {/*</Form.Item>*/}
                    {
                        this.state.radioValue ?   <Form.Item label="测试结束时间" className='test-timepicker' name="testEndTime" 
                        rules={[{ required: this.state.radioValue ? true : false  , message: this.state.radioValue ? '请选择结束日期！' : '' }]}
                        >
                            <DatePicker showTime  format="YYYY-MM-DD HH:mm"/>
                        </Form.Item>:''
                    }
                  

                </div>

                <div className="form-btn">
                    <Button type="primary" htmlType="submit" loading={this.props.parent.loadings}>提交</Button>
                    <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
                </div>

            </React.Fragment>
        );
    }

    changeRadio = (e) => {
        this.setState({radioValue:e.target.value},() => {
        })
    }
}
