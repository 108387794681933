/*
 * @Date: 2022-07-05 10:21:31
 * @LastEditors: litj
 * @LastEditTime: 2022-07-21 15:54:19
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\DarenManage\TableC\index.tsx
 */
import { Table } from "antd";
import React from "react";
interface IProps {
  data: any[];
  columns: any[];
  tableHeight: number;
  handleTableChange?: any;
  pagination: any;
  loading?: boolean;
}
export default function TableC(props: IProps) {
  return (
    <Table
      columns={props.columns}
      dataSource={props.data}
      rowKey={(record) => record.id}
      className="virtual-table"
      pagination={props.pagination}
      loading={props.loading}
      onChange={props.handleTableChange}
      scroll={{x: 800,y: `calc(100vh - ${props.tableHeight}px)`}}
    />
  );
}
