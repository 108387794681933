import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Radio,
  Upload,
  UploadProps,
} from "antd";
import { CameraOutlined } from "@ant-design/icons";
import Request from "../../../utils/Request";
import { isVideos } from "../../../utils/Utils";
const { Dragger } = Upload;
interface IProps {
  configInfo: Object;
  x;
}
export const CreateAgent = (props) => {
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const [newFileList, setNewFileList] = useState<any>([]);
  const [brandLogoUrl, setBrandLogoUrl] = useState<string>(
    `${props.configInfo.brandLogoUrl}`
  );

  let arr: any[] = [];
  let host: any = sessionStorage.getItem("host");
  const getAction = () => {
    let host: any = sessionStorage.getItem("host");
    return `${JSON.parse(host)?.xmgx}`;
  };
  // @ts-ignore
  const uploadProps: any = {
    action: `${getAction()}/op/operating-entity/uploadMaterial`,
    headers: {
      Authorization: sessionStorage.getItem("token"),
    },
    name: "file",
    accept: "image/*",
    listType: "picture-card",
    multiple: false,
    maxCount: 1,
    showUploadList: true,
    fileList: newFileList,
    data: {
      operatingEntityId: props.configInfo.operatingEntityId,
      isPrivate: false,
    },
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`上传成功`);
        setNewFileList({
          uid: info.file.uid,
          url: `${info.file.url}`,
        });
        setBrandLogoUrl(newFileList[0].url);
      } else if (info.file.status === "error") {
        message.error(`上传失败，请重试！`);
      }
    },
    // beforeUpload: (file, fileList) => {
    //     return new Promise((resolve, reject) => {
    // let host:any=sessionStorage.getItem("host");
    //         Request.postFormData(`${JSON.parse(host)?.xmgx}/op/operating-entity/uploadMaterial`,{
    //             operatingEntityId: props.configInfo.operatingEntityId,
    //             isPrivate:false,
    //             file:file
    //         }).then((res) => {
    //             console.log(res,"res");
    //             let ossUrl;
    //             if (res.code === 0) {
    //                 arr.push({
    //                     uid: file.uid,
    //                     url: `${res.data}`
    //                 })
    //                 // ossUrl = res.data;
    //                 // ossUrl = res.data.key.filePath;
    //                 // let filePrefix = res.data.key.filePrefix;
    //                 // let result: any = [
    //                 //     {status: "uploading", uid: file.uid, percent: 0},
    //                 // ];
    // setBrandLogoUrl(...arr);
    //             }
    //         });
    //     });
    //
    // }
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={props.configInfo}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="平台官方代理商ID"
          name="agentId"
          rules={[{ required: false, message: "请输入平台官方代理商ID" }]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item
          label="平台官方商家ID"
          name="sellerId"
          rules={[{ required: false, message: "请输入平台官方商家ID" }]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item
          label="多多来客品牌名称"
          name="brandText"
          rules={[{ required: false, message: "请输入多多来客品牌名称" }]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item
          label="多多来客品牌logo"
          name="brandLogoUrl"
          rules={[{ required: false, message: "请输入多多来客品牌logo" }]}
        >
          <Upload {...uploadProps}>
            <CameraOutlined style={{ fontSize: "24px" }} />
            {/*<div>{newFileList.length >= 1 ? null : <CameraOutlined style={{fontSize:"24px"}}/>}</div>*/}
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            保存
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
