import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Divider, Empty, Form, Input, message, Modal, Radio, Row, Select, Space, Table, Tabs,} from "antd";
import Request from "../../utils/SellerRequest";
import {Link} from "react-router-dom";
import "./index.css";
import {EHostType} from "../../utils/Request";

let host: any = sessionStorage.getItem("host");
const { RangePicker } = DatePicker;
const formRef: any = React.createRef();
const userTypeArr: any = [
  {
    label: "顾客提现",
    value: 1,
  },
  {
    label: "异业商家提现",
    value: 2,
  },
];
const userMap= [
  {
    label: "达人提现",
    value: 3,
  },
  {
    label: "商家提现",
    value: 4,
  },
  {
    label: "推广员提现",
    value: 5,
  },
  {
    label: "运营商提现",
    value: 6,
  },
];
const userMaps= [
  {
    label: "达人提现",
    value: 3,
  },
  {
    label: "商家提现",
    value: 4,
  }
];

const typeAll = [
  {
    label: "微信零钱自动转帐 ",
    value: 1,
  },
  {
    label: "支付宝零钱自动转帐",
    value: 2,
  },
  {
    label: "个人转帐",
    value: 3,
  },
  {
    label: "对公转账转帐",
    value: 4,
  },
];

export default class WithdrawApproval extends React.Component<
  { match?: any },
  {
    data: any;
    total: number;
    selectedRowKeys: any;
    searchForm: any;
    visible: boolean;
    visible1: boolean;
    visible2: boolean;
    checkedForm: any;
    isSame: boolean;
    tableHeight:any;
  }
> {
  pageSize =10;
  statusArr = [
    {
      label: "待审核",
      value: "1",
    },
    {
      label: "已审核待打款",
      value: "2",
    },
    {
      label: "已驳回",
      value: "3",
    },
    {
      label: "已完成",
      value: "4",
    },
    {
      label: "打款失败",
      value: "5",
    },
  ];
  activeTabKey = "1";

  constructor(props) {
    super(props);
    let obj = {};
    if (props.location.search) {
      let str = props.location.search;
      str = str.substr(1, str.length);
      str = str.split("&");
      str.map((e) => {
        let temp = e.split("=");
        obj[temp[0]] = temp[1];
      });
      obj["withdrawStatus"] = obj["activeTabKey"];
      this.activeTabKey = obj["activeTabKey"];
    }
    this.state = {
      data: "",
      total: 0,
      selectedRowKeys: [],
      isSame: false,
      searchForm: Object.assign(
        {
          flowId: "",
          userId: "",
          phone: "",
          withdrawStatus: "1",
          withdrawUserType: undefined,
          applyTimeFrom: "",
          applyTimeTo: "",
          pageNum: 1,
          pageSize: this.pageSize,
        },
        obj
      ),
      visible: false,
      visible1: false,
      visible2: false,
      checkedForm: {
        flowIds: [],
        whetherPass: false,
        rejectReason: "",
        transferWay: undefined,
        withdrawUserType: undefined,
      },
      tableHeight:300
    };
  }

  componentDidMount() {
    this.init();
    this.tableAdapt();
  }
  /**表格高度自适应 */
  async tableAdapt () {
    let search: any = document.getElementsByClassName("container" )[0].clientHeight + 265 + 40;
    this.setState({ tableHeight : search});
  };
  async init() {
    let info = await Request.get(EHostType.XMGX, `/seller/getSellerData`, {});
    let type;
    const hasSearch = this.props["location"].search;
    if (hasSearch) {
      type = this.state.searchForm.transferWay;
    }
    this.setState(
      {
        isSame: info.data?.id == info.data?.platformSellerId,
        searchForm: {
          ...this.state.searchForm,
          transferWay: type,
          withdrawUserType: this.props["location"].search ? this.state.searchForm.withdrawUserType / 1 : info.data?.id == info.data?.platformSellerId ? 3 : 1,
        },
      },
      () => {
        formRef.current?.setFieldsValue({
          withdrawUserType: this.state.searchForm.withdrawUserType,
          transferWay: this.state.searchForm.transferWay,
        });
        this.getList(this.state.searchForm.pageNum);
      }
    );
  }

  async getSellerData() {
    let host: any = sessionStorage.getItem("host");
    let info = await Request.get(EHostType.XMGX, `/seller/getSellerData`, {});
    this.setState({
      isSame: info.data?.id == info.data?.platformSellerId,
    });
  }

  async getList(currentPage?: number, type?: number) {
    let formData = {
      ...this.state.searchForm,
      transferWay: this.state.searchForm.transferWay,
      withdrawUserType: type ? type : this.state.searchForm.withdrawUserType,
    };
    if (currentPage) {
      formData.pageNum = currentPage;
    }

    let res = await Request.jsonPost(EHostType.XMGX, `/seller/balance/withdraw/listWithDrawInCondition`, formData);
    this.setState({
      data: res?.data?.records,
      total: res?.data?.total,
      searchForm: { ...this.state.searchForm, pageNum: currentPage },
    });
  }

  changePage = (currentPage) => {
    this.getList(currentPage);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  submit = async (values) => {
    this.setState(
      { searchForm: { ...this.state.searchForm, ...values } },
      () => {
        this.getList(1);
      }
    );
  };

  onDateChange = (dates, dateArr) => {
    let searchForm = this.state.searchForm;
    searchForm.applyTimeFrom = dateArr[0];
    searchForm.applyTimeTo = dateArr[1];
    this.setState({ searchForm: searchForm });
  };

  onChangeTab = (key) => {
    this.activeTabKey = key;
    let searchForm = this.state.searchForm;
    searchForm.withdrawStatus = this.activeTabKey;
    formRef.current.setFieldsValue(searchForm);
    this.setState({ searchForm: searchForm });
    this.getList(1);
  };

  openModal = (flowId, withdrawUserType) => {
    if (this.activeTabKey === "1" || this.activeTabKey === "3") {
      this.setState({
        visible: true,
        checkedForm: {
          flowIds: [flowId],
          whetherPass: false,
          withdrawUserType,
        },
      });
    } else if (this.activeTabKey === "2") {
      this.setState({
        visible1: true,
        checkedForm: {
          flowIds: [flowId],
          whetherPass: false,
          withdrawUserType,
        },
      });
    } else if (this.activeTabKey === "4") {
      this.setState({
        visible2: true,
        checkedForm: {
          flowIds: [flowId],
          whetherPass: false,
          withdrawUserType,
        },
      });
    }
  };

  closeModal = () => {
    if (this.activeTabKey === "1" || this.activeTabKey === "3") {
      this.setState({ visible: false });
    } else if (this.activeTabKey === "2") {
      this.setState({ visible1: false });
    } else if (this.activeTabKey === "4") {
      this.setState({ visible2: false });
    }
  };

  onCheckChange = (e) => {
    let checkedForm = this.state.checkedForm;
    checkedForm.whetherPass = e.target.value;
    this.setState({ checkedForm: checkedForm });
  };

  //批量审核通过
  doBatchPass = async () => {
    let url = `/seller/balance/withdraw/checkWithdrawApply`;
    let params = {
      flowIds: this.state.selectedRowKeys,
      withdrawUserType: this.state.searchForm.withdrawUserType,
      whetherPass: true,
    };
    let res = await Request.jsonPost(EHostType.XMGX, url, params);
    if (res.code == 0) {
      message.success("批量审核通过");
      this.getList(1);
    }
  };

  doBatchPay = () => {
    let host: any = sessionStorage.getItem("host");
    let url = `${JSON.parse(host)?.xmgx}/seller/balance/withdraw/confirmWithdrawPay`;
    let checkedForm = this.state.checkedForm;
    if (checkedForm.whetherPass) {
      checkedForm.rejectReason = null;
    }
    checkedForm = {
      flowIds: this.state.selectedRowKeys,
      whetherSuccess: checkedForm.whetherPass,
      failedReason: checkedForm.rejectReason,
    };
    this.doChangeStatus(url, checkedForm);
  };

  doPass = () => {
    let checkedForm = this.state.checkedForm;
    if (checkedForm.whetherPass) {
      checkedForm.rejectReason = null;
    }

    let url = "";
    let host: any = sessionStorage.getItem("host");
    if (this.activeTabKey === "1" || this.activeTabKey === "3") {
      url = `${JSON.parse(host)?.xmgx}/seller/balance/withdraw/checkWithdrawApply`;
    } else if (this.activeTabKey === "2") {
      url = `${JSON.parse(host)?.xmgx}/seller/balance/withdraw/confirmWithdrawPay`;
      checkedForm = {
        flowIds: checkedForm.flowIds,
        whetherSuccess: checkedForm.whetherPass,
        failedReason: checkedForm.rejectReason,
      };
    } else if (this.activeTabKey === "4") {
      // 接口待确认
      url = `/seller/balance/withdraw/checkWithdrawApply`;
      checkedForm = {
        flowIds: checkedForm.flowIds,
        whetherSuccess: checkedForm.whetherPass,
      };
    }

    this.doChangeStatus(url, checkedForm);
  };

  doChangeStatus = async (url, checkedForm) => {
    let data = await Request.jsonPost(EHostType.XMGX, url, checkedForm);
    const _that = this;
    if (data.code == 0) {
      Modal.success({
        content: "操作成功",
        onOk: () => {
          _that.closeModal();
          _that.getList(1);
        },
      });
    }
  };

  onChangeRejectReason = (e) => {
    let checkedForm = this.state.checkedForm;
    checkedForm.rejectReason = e.target.value;
    this.setState({ checkedForm: checkedForm });
  };

  onWidthdrawChange = (e) => {
    
    // this.onChangeTab(e + "");
  };

  //提现记录下载
  download = async () => {
    let host: any = sessionStorage.getItem("host");
    let res = await Request.postBlob(EHostType.XMGX, `/seller/balance/withdraw/exportWithDrawlist`,
      {
        withdrawStatus: this.activeTabKey,
        applierType: this.state.searchForm?.withdrawUserType,
      }
    );
    if(res){
      let blob = new Blob([res], { type: "application/vnd.ms-excel" }); // 获取请求返回的response对象中的blob 设置文件类型excel
      let downloadElement: any = document.createElement("a");
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.href = href;
      downloadElement.download = "提现记录.xlsx"; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    }
  };

  render() {
    let smallWidth = { style: { width: "100px" } };
    return (
      <div>
        <h1>提现审核</h1>

        <Divider />
        <Form className={"container"}
          initialValues={this.state.searchForm}
          onFinish={this.submit}
          ref={formRef}
          onValuesChange={(current, all) => {
            if (all.transferWay) {
              this.setState({
                searchForm: {
                  ...this.state.searchForm,
                  transferWay: all.transferWay,
                },
              });
            }
            if (all.withdrawUserType) {
              this.setState({
                searchForm: {
                  ...this.state.searchForm,
                  withdrawUserType: all.withdrawUserType,
                },
              });
            }
          }}>
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item label="提现流水号" name="flowId">
                <Input {...smallWidth} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="用户ID" name="userId">
                <Input {...smallWidth} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="手机号码" name="phone">
                <Input {...smallWidth} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="提现状态" name="withdrawStatus">
                <Select options={this.statusArr} onChange={this.onChangeTab}/>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="提现类型" name="transferWay">
                <Select options={typeAll} {...smallWidth} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="提现人类型" name="withdrawUserType">
                <Select options={sessionStorage?.getItem('isAgentEnabled')=='true'? userMap : userMaps } {...smallWidth} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label="申请时间" style={{ width: "260px" }}>
                <RangePicker onChange={this.onDateChange} style={{ width: "260px" }} showTime/>
              </Form.Item>
            </Col>

            <Form.Item style={{ paddingLeft: "20px" }}>
              <Button type="primary" htmlType="submit">查询</Button>
            </Form.Item>

            {this.state.searchForm?.withdrawUserType * 1 > 2 && (
                <Form.Item label="" style={{ width: "60px",paddingLeft: "20px" }}>
                  <Button type="primary" onClick={() => this.download()}>
                    个人转账下载
                  </Button>
                </Form.Item>
            )}
          </Row>

        </Form>
        <div>
          <Tabs activeKey={this.activeTabKey} onTabClick={this.onChangeTab}>
            <Tabs.TabPane tab="待审核" key="1">
              <div style={{ marginBottom: "15px", textAlign: "right" }}>
                <Button type="primary" size={"small"} onClick={this.doBatchPass} disabled={!this.state.selectedRowKeys || this.state.selectedRowKeys.length === 0} style={{ marginRight: "8px" }}>
                  批量审核通过
                </Button>
              </div>
              <TableList total={this.state.total} data={this.state.data} selectedRowKeys={this.state.selectedRowKeys} onChange={this.onSelectChange} activeTabKey={this.activeTabKey}
                doCheck={this.openModal} searchForm={this.state.searchForm} onPageChange={this.changePage} tableHeight={this.state.tableHeight}/>
            </Tabs.TabPane>

            <Tabs.TabPane tab="已审核待打款" key="2">
              <div style={{ marginBottom: "15px", textAlign: "right" }}>
                <Button type="primary" onClick={this.doBatchPay} disabled={!this.state.selectedRowKeys ||  this.state.selectedRowKeys.length === 0}>
                  批量打款完成
                </Button>
              </div>
              <TableList total={this.state.total} data={this.state.data} selectedRowKeys={this.state.selectedRowKeys} onChange={this.onSelectChange} activeTabKey={this.activeTabKey}
                doCheck={this.openModal} searchForm={this.state.searchForm} onPageChange={this.changePage} tableHeight={this.state.tableHeight}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="审核未通过" key="3">
              <TableList total={this.state.total} data={this.state.data} selectedRowKeys={this.state.selectedRowKeys} onChange={this.onSelectChange} activeTabKey={this.activeTabKey}
                doCheck={this.openModal} searchForm={this.state.searchForm} onPageChange={this.changePage} tableHeight={this.state.tableHeight}/>
            </Tabs.TabPane>

            <Tabs.TabPane tab="已完成" key="4">
              <TableList
                total={this.state.total} data={this.state.data} selectedRowKeys={this.state.selectedRowKeys} onChange={this.onSelectChange} activeTabKey={this.activeTabKey}
                doCheck={this.openModal} searchForm={this.state.searchForm} onPageChange={this.changePage} tableHeight={this.state.tableHeight}/>
            </Tabs.TabPane>

            <Tabs.TabPane tab="打款失败" key="5">
              <TableList
                total={this.state.total} data={this.state.data} selectedRowKeys={this.state.selectedRowKeys} onChange={this.onSelectChange} activeTabKey={this.activeTabKey} doCheck={this.openModal}
                searchForm={this.state.searchForm} onPageChange={this.changePage} tableHeight={this.state.tableHeight}/>
            </Tabs.TabPane>
          </Tabs>
        </div>
        <br />

        <Modal title="提现审核" centered visible={this.state.visible} width={400} onCancel={this.closeModal} onOk={this.doPass} okText="保存" cancelText="返回">
          <Space>
            <Form>
              <Form.Item>
                <Radio.Group onChange={this.onCheckChange} value={this.state.checkedForm.whetherPass}>
                  <Radio value={true}>审核通过</Radio>
                  <Radio value={false}>审核拒绝</Radio>
                </Radio.Group>
              </Form.Item>
              {this.state.checkedForm.whetherPass == false && (
                <Form.Item>
                  <Input placeholder="请填写拒绝原因" value={this.state.checkedForm.rejectReason}  onChange={this.onChangeRejectReason}></Input>
                </Form.Item>
              )}
            </Form>
          </Space>
        </Modal>

        <Modal title="打款确认" centered visible={this.state.visible1} width={400} onCancel={this.closeModal} onOk={this.doPass} okText="保存" cancelText="返回">
          <Space>
            <Form>
              <Form.Item>
                <Radio.Group onChange={this.onCheckChange} value={this.state.checkedForm.whetherPass}>
                  <Radio value={true}>已确认打款</Radio>
                  <Radio value={false}>打款失败</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Input placeholder="请填写失败原因" disabled={this.state.checkedForm.whetherPass} value={this.state.checkedForm.rejectReason} onChange={this.onChangeRejectReason}></Input>
              </Form.Item>
            </Form>
          </Space>
        </Modal>

        <Modal title="提现状态回退" centered visible={this.state.visible2} width={400} onCancel={this.closeModal} onOk={this.doPass} okText="保存" cancelText="返回">
          <Space>
            <Form>
              <Form.Item>
                <Radio.Group onChange={this.onCheckChange} value={this.state.checkedForm.whetherPass}>
                  <Radio value={true}>提现待审核</Radio>
                  <Radio value={false}>已审核待打款</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Space>
        </Modal>
      </div>
    );
  }
}

function TableList(props: {
  data: any;
  selectedRowKeys: any;
  onChange: any;
  activeTabKey: string;
  doCheck: any;
  searchForm: any;
  total: any;
  onPageChange: any;
  tableHeight:any
}) {
  const activeTabKey = props.activeTabKey;
  const searchForm = props.searchForm;
  const rowSelection = {
    selectedRowKeys: props.selectedRowKeys,
    onChange: props.onChange,
  };
  const typeMap = {
    1: "微信零钱自动转帐",
    2: "支付宝零钱自动转帐",
    3: "个人转帐",
    4: "对公转账",
  };
  const idMap = {
    1: "消费者",
    2: "异业商家",
    3: "达人提现",
    4: "商家提现",
    5: "推广员提现",
    6: "运营商提现",
  };

  const columns: any = [
    {
      title: "流水号",
      dataIndex: "flowId",
      align: "center",
    },
    {
      title: "提现人",
      dataIndex: "userNick",
      align: "center",
    },
    {
      title: "提现人类型",
      render: (record) => <div>{idMap[record.withdrawUserType]}</div>,
    },
    {
      title: "提现类型",
      width: 150,
      align: "center",
      render: (record, i) => (
        <div>{i.transferWay ? typeMap[i.transferWay] : "-"}</div>
      ),
    },
    {
      title: "申请日期",
      dataIndex: "createdTime",
      align: "center",
    },
    {
      title: "提现金额（元）",
      dataIndex: "amount",
      align: "center",
    },
    {
      title: "手续费（元）/费率",
      align: "center",
      render: (record) => (
        <span>
          {(record.amount * record?.withdrawConf?.serviceFee4customer) / 100} (
          {record.withdrawConf.serviceFee4customer}%)
        </span>
      ),
    },
    {
      title: "到账金额",
      align: "center",
      dataIndex: "arriveAmount",
    },
    {
      title: "更新时间",
      align: "center",
      dataIndex: "modifiedTime",
    },
    {
      title: "操作",
      align: "center",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Link to={"/shop/finance/view/" + record.flowId + "?activeTabKey=" + activeTabKey + "&pageNum=" + searchForm.pageNum + "&withdrawUserType=" + searchForm.withdrawUserType}>
            查看
          </Link>
          {(activeTabKey == "1" || activeTabKey == "2") && <>|</>}
          {activeTabKey === "1" && (
            <Button onClick={() => { props.doCheck(record.flowId, record.withdrawUserType);}} type="link" style={{ padding: "4px 0px" }}>
              审核
            </Button>
          )}
          {activeTabKey === "2" && (
            <Button onClick={() => {props.doCheck(record.flowId, record.withdrawUserType);}} type="link" style={{ padding: "4px 0px" }}>
              打款确认
            </Button>
          )}
        </Space>
      ),
    },
  ];

  if (props?.data?.length === 0) {
    return <Empty />;
  }

  return (
    <Table
      rowKey="flowId"
      size="small"
      dataSource={props.data}
      columns={columns}
      rowSelection={rowSelection}
      scroll={{ y: `calc(100vh - ${props.tableHeight}px)`, x: 1200 }}
      pagination={{
        current: props?.data?.pageNo,
        pageSize: 10,
        total: props.total,
        showTotal: (total) => `共 ${props.total} 条数据`,
        onChange: (page: number) => {props.onPageChange(page);},
        style: {background: "#fff", margin: 0, padding: "10px 0", overflow:"scroll"},
      }}
    />
  );
}
