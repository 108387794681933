/*
 * @Date: 2022-07-05 09:59:15
 * @LastEditors: litj
 * @LastEditTime: 2022-07-05 11:00:49
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\DarenManage\index.tsx
 */
import React from "react";
import FormSearch from "./FormSearch";
import TableC from "./TableC";
import Method from "./Method";

export default function DarenManage() {
  return <Method />;
}

// let columns: any[] = [];
// export default function DarenManage() {
//   return (
//     <>
//       <FormSearch
//         onSearch={() => {
//           setTimeout(() => {
//             columns = [
//               {
//                 title: "姓名",
//                 dataIndex: "name",
//                 key: "name",
//               },
//               {
//                 title: "年龄",
//                 dataIndex: "age",
//                 key: "age",
//               },
//               {
//                 title: "住址",
//                 dataIndex: "address",
//                 key: "address",
//               },
//             ];
//           });
//         }}
//       ></FormSearch>
//       <TableC
//         data={[]}
//         columns={columns}
//         handleTableChange={() => {}}
//         tableHeight={0}
//         pagination={{}}
//       ></TableC>
//     </>
//   );
// }
