import {Button, Form, FormInstance, Input, InputNumber, message, Radio,} from "antd";
import React, {Component} from "react";
import Request, {EHostType} from "../../../../utils/Request";
import PicturesWall from "../PicturesWall";

interface IAdd {
  onFinish: (values) => void;
  formRef;
}
export default class Add
  extends Component<
    {
      rowData?: any;
      id?: any;
      getList: () => void;
      advertiseFor: any;
      onCancel: () => void;
    },
    {}
  >
  implements IAdd
{
  state = {
    fileList: [],
  };
  id: any;
  onFinish: (values) => void = (values) => {
    // if (this.state.fileList.length < 1) {
    //   message.error("图片内容不能为空");
    //   return;
    // }

    const formdata = new FormData();
    Object.keys(values).forEach((key) => {
      key !== "fileList" && formdata.append(key, values[key]);
    });
    // 添加图片参数
    this.state.fileList.length > 0 &&
      (this.state.fileList[0] as any).originFileObj &&
      formdata.append(
        "adveriseImageUrl",
        (this.state.fileList[0] as any).originFileObj
      );
    formdata.append("advertiseFor", this.props.advertiseFor);
    // 如果无连接跳转 默认id为0
    values.group === "b" && formdata.append("linkedArticleId", "0");
    // 编辑的时候传id
    this.id && formdata.append("id", this.id);
    const url = `/mgr/ddlk/advertisement/saveOrUpdate`;
    Request.postFormData(EHostType.DDlk, url, formdata).then((res) => {
      if (!res.error) {
        message.success("操作成功");
        this.props.onCancel();
        this.props.getList();
      }
    });
  };
  handleChange: (info: any) => void = (info) => {
    this.formRef.current!.setFieldsValue({ fileList: info.fileList });
    this.setState({ fileList: info.fileList });
  };
  upLoadFile: (file: any) => void = (file) => {
    this.setState({file,});
  };
  formRef = React.createRef<FormInstance>();
  componentDidMount() {
    if (this.props.rowData.id) {
      const { id, adveriseTitle, orderNo, linkedArticleId, adveriseImageUrl } =
        this.props.rowData;

      this.id = id;
      const obj = {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: adveriseImageUrl,
      };
      this.formRef.current!.setFieldsValue({
        adveriseTitle,
        orderNo,
        group: linkedArticleId === 0 ? "b" : "a",
        fileList: [obj],
        linkedArticleId,
      });
      this.setState({
        fileList: [obj],
      });
    }
  }
  render() {
    return (
      <div className="container">
        <Form
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          autoComplete="off"
          className="form-container"
          ref={this.formRef}
        >
          <div className="form-content">
            <Form.Item
              label="广告标题"
              name="adveriseTitle"
              rules={[{ required: true, message: "请输入图片标题" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="图片内容"
              name="fileList"
              rules={[{ required: true, message: "请上传图片" }]}
            >
              <PicturesWall
                maxCount={1}
                fileList={this.state.fileList}
                handleChange={this.handleChange}
              ></PicturesWall>
            </Form.Item>
            <Form.Item
              label="排序值"
              name="orderNo"
              rules={[{ required: true, message: "请输入排序值" }]}
              label-align="left"
            >
              <InputNumber
                min={1}
                max={100}
                name="inputnumber"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="group"
              label="跳转地址"
              rules={[{ required: true, message: "请选择跳转地址" }]}
            >
              <Radio.Group>
                <Radio value="a">自定义文章页面</Radio>
                <Radio value="b">无跳转地址</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.group !== currentValues.group
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("group") === "a" ? (
                  <Form.Item
                    label=""
                    name="linkedArticleId"
                    rules={[{ required: true, message: "请输入输入文章id" }]}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input placeholder="输入文章id" />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </div>
          <div className="form-btn">
            <Button type="primary" htmlType="submit">
              提交
            </Button>
            <Button onClick={() => this.props.onCancel()}>取消</Button>
          </div>
        </Form>
      </div>
    );
  }
}
