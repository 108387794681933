/*
 * @Date: 2022-07-09 15:29:44
 * @LastEditors: litj
 * @LastEditTime: 2022-10-15 10:11:04
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/module/page/DDLK/PromotionMange/Cloud/detail/shareList/index.tsx
 */
import {Button, Col, Form, Input, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import Request, {EHostType} from "../../../../../../utils/Request";

export default function ShareList(props) {
  const { detailInfo, currenItem } = props;
  const [searchParams, setSearchParams] = useState({
    pubVideoTaskId: null,
    pageNo: 1,
    pageSize: 10,
  });
  const [tableHeight, setTableHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [shareInfo, setshareInfo] = useState<any>({});
  const statusArr = ["分享中", "成功", "失败"];
  // 获取列表数据
  const getList = async () => {
    let url = `/mgr/ddlk/getTaskShareList`;
    setLoading(true);
    const res = await Request.jsonPost(EHostType.DDlk, url, searchParams);
    if (res.code === 0) {
      setTotal(res.totalCount);
      setDataSource(res.data);
    }
    setLoading(false);
  };
  // 获取分享数据
  const getShareInfo = async () => {
    let host: any = sessionStorage.getItem("host");
    let url = `/mgr/ddlk/getTaskShareSummary?id=${detailInfo.id}&taskType=${1}`;
    const res = await Request.jsonPost(EHostType.DDlk, url, {});

    setshareInfo(res.data);
  };
  useEffect(() => {
    setSearchParams({
      ...searchParams,
      pubVideoTaskId: currenItem && currenItem.id,
    });
  }, [currenItem]);
  useEffect(() => {
    if (!searchParams.pubVideoTaskId) return;
    getList();
  }, [searchParams]);

  useEffect(() => {
    getShareInfo();
  }, [props.detailInfo.id]);
  /**表格高度自适应 */
  const tableAdapt = () => {
    let search: any =
      document.getElementsByClassName("search-container")[0].clientHeight +
      265 +
      40;
    setTableHeight(search);
  };
  useEffect(() => {
    tableAdapt();
  }, []);
  // 搜索
  const onFinish = (values) => {
    const params = {};
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        if (values[key]) {
          params[key] = values[key];
        } else {
          params[key] = undefined;
        }
      }
    }
    setSearchParams({ ...searchParams, ...params, pageNo: 1 });
  };
  const columns: any = [
    {
      title: "分享人id",
      dataIndex: "id",
      key: "id",
      width: 80,
      align: "center",
    },
    {
      title: "分享人信息",
      dataIndex: "taskName",
      key: "taskName",
      align: "center",
      width: 100,
      render(text, record) {
        return (
          <>
            <p style={{ margin: 0 }}>昵称：{record.inviterUserNick}</p>
            <p style={{ margin: 0 }}>抖音号：{record.inviterDyNo}</p>
            <p style={{ margin: 0 }}>手机号码：{record.inviterPhone}</p>
          </>
        );
      },
    },
    {
      title: "分享时间",
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      width: 120,
    },
    {
      title: "被分享者",
      dataIndex: "adviserUserName",
      key: "adviserUserName",
      align: "center",
      width: 120,
      render(text, record) {
        return (
          <>
            <p style={{ margin: 0 }}>昵称：{record.beInvitedUserNick}</p>
            <p style={{ margin: 0 }}>抖音号：{record.beInvitedDyNo}</p>
            <p style={{ margin: 0 }}>手机号码：{record.beInvitedPhone}</p>
          </>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "inviteStatus",
      key: "inviteStatus",
      align: "center",
      width: 120,
      render(text) {
        return statusArr[text];
      },
    },
    {
      title: "原因",
      dataIndex: "failReason",
      key: "failReason",
      align: "center",
      width: 120,
    },
  ];
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div>
          <p style={{ margin: 0 }}>{shareInfo.inviterNum || 0}人</p>
          <p>分享人数</p>
        </div>
        <div style={{ marginLeft: 20 }}>
          <p style={{ margin: 0 }}>{shareInfo.inviteSuccessNum || 0}人</p>
          <p>分享成功</p>
        </div>
        <div style={{ marginLeft: 20 }}>
          <p style={{ margin: 0 }}>{shareInfo.inviteFailNum || 0}人</p>
          <p>分享失败</p>
        </div>
      </div>
      <Form onFinish={onFinish} className="search-container">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="邀请人手机" name="inviterPhone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="被邀请人手机" name="beInvitedPhone">
              <Input />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
            <Button
              type="ghost"
              style={{ marginLeft: "10px" }}
              htmlType="reset"
            >
              重置
            </Button>
          </Col>
        </Row>
        {/*<Row gutter={24}>*/}
        {/*    <Col span={20}></Col>*/}
        {/*    <Col span={4}>*/}
        {/*        <Button type='primary' htmlType='submit'>搜索</Button>*/}
        {/*        <Button type='ghost' style={{marginLeft:'10px'}} htmlType='reset'>重置</Button>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
      </Form>
      <Table
        dataSource={dataSource}
        columns={columns}
        scroll={{ y: `calc(100vh - ${tableHeight}px)` }}
        loading={loading}
        pagination={{
          pageSize: searchParams.pageSize,
          current: searchParams.pageNo,
          total: total,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: (page: number) => {
            setSearchParams({ ...searchParams, pageNo: page });
            // getList({ pageNo: page, ...props.searchParams });
          },
        }}
      />
    </div>
  );
}
