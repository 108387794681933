/*
 * @Date: 2022-07-05 11:03:19
 * @LastEditors: litj
 * @LastEditTime: 2022-07-05 11:12:30
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\DarenManage\source\column.ts
 */

import {  Space, Typography, Popconfirm } from "antd";
// 获取列数据 audit 审核  handleDelete 删除
export const getColumns = function (audit, handleDelete) {
  return [
  {
    title: "序号",
      key: "index",
     dataIndex: "index",
    align: "center",
      width: "100px",
      render(text, record, index) {
        return index + 1;
    }
  },
  {
    title: "地址",
    dataIndex: "name",
    key: "name",
    align: "center",
    width: "110px",
    render: (text, record) => { 
      return record.adviserProvinceText + (record.adviserCityText ? '/' + record.adviserCityText : '');
    }
  },
  {
    title: "任务类型",
    dataIndex: "darenType",
    key: "darenType",
    align: "center",
    width: "130px",
    render: (text) => {
      return text === 1 ? "素人云剪" : (text === 2 ? "达人云剪" : "成片分发");
    }
  },
  {
    title: "对商家的售卖价格(元)",
    dataIndex: "priceForAdviser",
    key: "priceForAdviser",
    align: "center",
    width: "150px",
    render(text) {
      return text / 100;
    }
  },
  {
    title: "最低参与人数(人)",
    dataIndex: "minDarenNum",
    key: "level",
    align: "minDarenNum",
    width: "125px",
  },
  {
    title: "配置时间",
    key: "modifiedTime",
    dataIndex: "modifiedTime",
    align: "center",
    width: "180px",
  },
  {
    title: "操作",
    key: "room_code8",
    dataIndex: "room_code8",
    width: "180px",
    align: "center",
    fixed: "right",
    render: (text, record, index) => {
      const data = { ...record };
      data.priceForAdviser = (data.priceForAdviser / 100).toFixed(2);
      return <div>
         <Space size="middle">
          <Typography.Link onClick={audit.bind(null, record.id, data)}> 修改</Typography.Link>
           <Popconfirm title="确定要删除吗?" onConfirm={() => handleDelete(record.id)}>
              <Typography.Link>删除</Typography.Link>
          </Popconfirm>
      </Space>
       </div>
    },
  },
]
}

