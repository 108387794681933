/*
 * @Date: 2022-09-29 14:01:48
 * @LastEditors: litj
 * @LastEditTime: 2022-09-29 18:36:29
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/module/page/OperationConfiguration/config/subjectConfig.tsx
 */
import React, { useCallback, useState, useEffect } from "react";
import { Button, Checkbox, Form, Input, Radio } from "antd";
import Request from "../../../utils/Request";
import Item from "antd/lib/list/Item";

interface IProps {
  configInfo: Object;
}
export const SubjectConfig = (props) => {
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={props?.configInfo}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="OEM类型" name="oemType">
          <Radio.Group>
            <Radio value={1}>共有部署</Radio>
            <Radio value={2}>私有部署</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="OEM客户联系微信"
          name="oemWechat"
          rules={[{ required: false, message: "请输入微信号" }]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item
          label="OEM客户联系手机号"
          name="oemPhone"
          rules={[{ required: false, message: "请输入手机号" }]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            保存
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
