import {Button, Input, Modal, Tabs} from "antd";
import {useEffect, useState} from "react";
import Request, {EHostType} from "../../../utils/Request";
import "./index.css";
import {SubjectConfig} from "./subjectConfig";
import {DomainRegistration} from "./domainRegistration";
import {CreateSubject} from "./createSubject";
import {CreateAgent} from "./createAgent";
import {SellerMiniproConfig} from "./sellerMiniproConfig";
import {TalentMiniproConfig} from "./talentMiniproConfig";
import {TalentDouyinMiniproConfig} from "./talentDouyinMiniproConfig";
import {PublicConfig} from "./publicConfig";
import {WechatPayConfig} from "./wechatPayConfig";
import {WechatWithdrowConfig} from "./wechatWithdrowConfig";
import {AlipayConfig} from "./alipayConfig";
import {AgreementConfig} from "./agreementConfig";
import {AgentQrcode} from "./agentQrcode";
import {YunPianConfig} from "./yunpianConfig";
import {FeeConfig} from "./feeConfig";

const { TextArea } = Input;

interface IProps {
  operatingEntityId: string;
  onRefresh: any;
}

export const DomainConfig = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [configInfo, setConfigInfo] = useState({});
  const [preFixUrl, setPreFixUrl] = useState("");
  useEffect(() => {
    if (visible == true && props.operatingEntityId) {
      init();
    }
  }, [visible]);
  let host:any=sessionStorage.getItem("host");

  const init = () => {
    //${props.operatingEntityId}
    let url = `/op/operating-entity/getOperEntityConf?operatingEntityId=2`;
    Request.jsonPost(EHostType.XMGX, url, {}).then((res) => {
      if (res.code == 0) {
        setConfigInfo(res.data);
      }
    });
    url = `/common/oss/getDefaultFilePrefix`;
    Request.get(EHostType.XMGX, url, {}).then((res) => {
      if (res.code == 0) {
        setPreFixUrl(res.data);
      }
    });
  };

  const onChange = (value: number) => {
    setCurrentStep(value);
  };

  return (
    <>
      <Button type="link" onClick={() => {setVisible(true);}}>
        配置
      </Button>
      <Modal width={1000} visible={visible} onCancel={() => {setVisible(false);}} title="配置" onOk={() => {console.log(configInfo);}} destroyOnClose>
        <div>
          <Tabs defaultActiveKey="1" tabPosition={"left"}>
            <Tabs.TabPane tab="运营主体配置" key="1">
              <SubjectConfig configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="域名注册/备案" key="2">
              <DomainRegistration configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="创建运营主体" key="3">
              <CreateSubject configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="创建平台代理商/商家" key="4">
              <CreateAgent configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置商家端小程序" key="5">
              <SellerMiniproConfig configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置达人端小程序" key="6">
              <TalentMiniproConfig configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置达人端小程序(抖音)" key="6">
              <TalentDouyinMiniproConfig configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置公众号" key="7">
              <PublicConfig configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置微信支付" key="8">
              <WechatPayConfig configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置付款到零钱(微信)" key="9">
              <WechatWithdrowConfig configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置付款到零钱(支付宝)" key="10">
              <AlipayConfig configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置注册协议合同" key="11">
              <AgreementConfig configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置代理商招募二维码" key="12">
              <AgentQrcode configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置费用/率" key="13">
              <FeeConfig configInfo={configInfo} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="配置云片" key="14">
              <YunPianConfig configInfo={configInfo} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Modal>
    </>
  );
};
