/*
 * @Date: 2022-04-11 14:41:03
 * @LastEditors: litj
 * @LastEditTime: 2022-10-18 13:19:06
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/module/root/Login.tsx
 */
import { Button, Divider, Form, Input, message } from "antd";
import React, { useState ,useRef,MutableRefObject,useEffect} from "react";
import Request, { EHostType } from "../utils/Request";
import { useHistory } from "react-router-dom"
import { PoweroffOutlined } from '@ant-design/icons';
import "./Login.css";

//登录页面

const Login: React.FC = () => {
  let history = useHistory();
  // const [loadings, setLoadings] = useState([]);
  const [buttenText, setButtenText] = useState<number | string>("获取验证码");
  const [navState, setNavState] = useState<number>(0);
  const [userAccount, setUserAccount] = useState<string | number | null>("");
  const [userPhone, setUserPhone] = useState<string | number | null>("");
  const [userPassWord, setUserPassWord] = useState<string | number | null>("");
  const [userVerifyCode, setUserVerifyCode] = useState<string | number | null>("");
  const [verifyCodeDisabled, setVerifyCodeDisabled] = useState<boolean>(true);
  const [passWordType, setPassWordType] = useState<string>("");
  // const inputEl: MutableRefobject<any> = useRef(nul1)
  // const [accountRef,setAccountRef] = useState<MutableRefObject<any>>("")
  const accountRef: MutableRefObject<any> = useRef("")


  useEffect(() => {
    
  })

  const changeLogingNav = (e) => {
    if (e.target.className == "logo-nav") {
      return
    }  
    setNavState(e.target.dataset.value * 1)
    setUserAccount("");
    setUserPassWord("");
    setUserVerifyCode("");
    setUserPhone("");
    accountRef.current.state.value = "";
    accountRef.current.state.value ? setVerifyCodeDisabled(false) : setVerifyCodeDisabled(true)

  }

  const getHost = async () => {
    // let currentHost = window.location.origin;
    // let xmgxHost = process.env.REACT_APP_REMOTE_URL_NEW;
    // let host: any = sessionStorage.getItem("host");
    // let res = await Request.get(`${xmgxHost}/operatingentity/getServerDomain`, {
    //   domain: currentHost,
    // });

    // if (res.code == 0) {
    //   let host = {
    //     ddlk: `https://${res.data.ddlk}`,
    //     xmgx: `https://${res.data.xmgx}`,
    //   };
    //   sessionStorage.setItem("host", JSON.stringify(host));
    //   sessionStorage.setItem(" ", `https://${res.data.xmgx}`);
    //   sessionStorage.setItem("ddlkHost", `https://${res.data.ddlk}`);
    // }
    // return res;
    // setXmgxHost(`https://${res.data.xmgx}`);
    // setDdlkHost(`https://${res.data.ddlk}`);
  }

  const login = async () => {
    let host: any = sessionStorage.getItem("host");
    // while (!sessionStorage.getItem("host")) {}
    // this.setState({ loadings: true });
    let xmgxHost: any = sessionStorage.getItem("xmgxHost");

    let data = {
      userName: userAccount,
      password: userPassWord,
      phone: userPhone,
      verifyCode: userVerifyCode
    };
    if (!host) {
      await getHost();
      host = sessionStorage.getItem("host");
    }
    // let ret = await Request.jsonPost(`${xmgxHost}/op/login`, JSON.stringify(data));
    let ret = await Request.jsonPost(EHostType.XMGX, `/op/loginv2`, JSON.stringify(data));
    if (!ret.error) {
      sessionStorage.setItem("token", ret.data?.token);
      sessionStorage.setItem("sellerToken", ret.data?.sellerToken);
      sessionStorage.setItem("mainName", ret.data?.operatingEntityDTO?.name);
      sessionStorage.setItem("brand", ret.data?.ddlkPlatformSellerDTO?.brandText);
      sessionStorage.setItem("role", ret.data?.role);
      sessionStorage.setItem("userName", ret.data?.userName);
      sessionStorage.setItem("realName", ret.data?.realName);
      sessionStorage.setItem("operatingEntityId", ret.data?.operatingEntityDTO?.id || ret.data?.ddlkPlatformSellerDTO?.operatingEntityId);//   保存新建商家时是否显示开通远程探店高级版和达人探店高级版字段
      sessionStorage.setItem("ddlkPlatformSellerDTO", ret.data?.ddlkPlatformSellerDTO ? JSON.stringify(ret.data.ddlkPlatformSellerDTO) : "");
      sessionStorage.setItem("isAgentEnabled", ret.data?.ddlkPlatformSellerDTO ? JSON.stringify(ret.data.ddlkPlatformSellerDTO["ddlkProductFeatures"]?.isAgentEnabled) : "");
      sessionStorage.setItem("bossLoginUser", JSON.stringify(ret.data?.bossLoginUser));
      if (ret.data.privilegCodes) {
        let functionLimits:any = [];
        let menuLimits:any = [];
        ret.data.privilegCodes.forEach(item => {
          if(item.includes('a')){
            functionLimits.push(item)
          }else{
            menuLimits.push(item)
          };
        })
        // let menuLimits = 
        // let index = ret.data.privilegCodes.findIndex(item => {
        //   return item.includes('a');
        // })
        // console.log(index)

        // let functionLimits = ret.data.privilegCodes.slice(index);
        // let menuLimits = ret.data.privilegCodes.slice(index - index, index);
        // console.log(functionLimits, '功能权限')
        // console.log(menuLimits, '菜单权限');
        sessionStorage.setItem("menuLimits", menuLimits);
        sessionStorage.setItem("functionLimits", functionLimits);
      }

      // data.ddlkPlatformSellerDTO.ddlkProductFeatures.sellerFeeVideoNum 为true就显示 上面的视频点数。
      // props.history.push("shop/");
      history.push("shop/")
    } else {
      // message.error(ret.errroMsg)
      // this.setState({ loadings: false });
    }

  }

  const inputUserAccount = (e) => {
    if (e.target.className.includes("phone-input")) {
      setUserPhone(e.target.value)
    } else {
      setUserAccount(e.target.value);
    };
    // console.log(testPhoneNumber(e.target.value * 1),testPhoneNumber15(e.target.value * 1))
    e.target.value &&(testPhoneNumber(e.target.value * 1) || testPhoneNumber15(e.target.value * 1)) && buttenText == "获取验证码" ? setVerifyCodeDisabled(false) : setVerifyCodeDisabled(true);
  }

  const enterLoading = async (index: number) => {
    let number = 61
    let res = await Request.get(EHostType.XMGX, '/ddlk/common/getVerifyCode?phone=' + userPhone, "");
    if (res.data == "验证码发送成功") {
      message.open({
        type: 'success',
        content: res.data,
      });
    }
    let timer = setInterval(() => {
      setVerifyCodeDisabled(true)
      number--
      setButtenText(number + '后重新获取验证码')
      if (number == 0) {
        clearInterval(timer)
        setVerifyCodeDisabled(false)
        setButtenText('获取验证码')
      }
    }, 1000)

  }

  const inputUserPassword = (e) => {
    if (e.target.className.includes("passWord")) {
      setUserPassWord(e.target.value);
    } else if (e.target.className.includes("verifyCode")) {
      setUserVerifyCode(e.target.value);
    };
  }

  const testPhoneNumber = (phoneNumber) => {
    let reg_phoneNumebr =  /^1[3456789]\d{9}$/;
    return reg_phoneNumebr.test(phoneNumber)
  }

  const testPhoneNumber15 =  (phoneNumber) => {
    let reg_phoneNumebr =  /^1[3456789]\d{13}$/;
    return reg_phoneNumebr.test(phoneNumber)
  }


  
  return (
    <div className="loginContent">
      <div className="logo-form">
        <div className="logo-nav" onClick={(event) => changeLogingNav(event)}>
          <div data-value={0} className={navState == 0 ? "nav-password--active" : "nav-password"}>密码登录</div>
          <div data-value={1} className={navState == 1 ? "nav-verifycode--active" : "nav-verifycode"}>验证码登录</div>
        </div>
        <div>
          <div className="userName-input">
            <Input ref={accountRef} className={navState == 1 ? " verifyCode-input phone-input" : " verifyCode-input account-input"} placeholder={navState == 1 ? "请输入手机号" : "请输入用户名/手机号"} onChange={(event) => inputUserAccount(event)} />
          </div>
          <div className="verifyCode-inputbox" onChange={(event) => inputUserPassword(event)}>
            {
              navState ? <><Input className="verifyCode-input verifyCode" placeholder="请输入验证码" /><Button
                disabled={verifyCodeDisabled}
                className="verifyCode-button"
                type="primary"
                onClick={() => enterLoading(1)}
              >
                {buttenText}
              </Button></> : <div><Input type="password" className="verifyCode-input passWord" placeholder="请输入密码" /></div>
            }
          </div>
        </div>
        <div className="submit" onClick={() => login()}>
          <Button disabled={(userAccount && userPassWord) || (userPhone && userVerifyCode) ? false : true} type="primary" block >
            登录
          </Button>
        </div>
        {/* <div className="logo-tit">
            <span>运营端</span>
          </div>
          <Form
            onFinish={this.onFinish.bind(this)}
            initialValues={{ remember: true }}
            className="formContent"
          >
            <Form.Item className="logo-item" label="用户名" name="userName">
              <Input placeholder="请输入用户名" />
            </Form.Item>
            <Form.Item
              className="logo-item"
              label="密&nbsp;&nbsp;&nbsp;码"
              name="password"
            >
              <Input placeholder="请输入密码" type="password" />
            </Form.Item>
            <div className="logo-btn">
              <Button
                type="primary"
                htmlType="submit"
                className="formButton"
                loading={loadings}
              >
                登录
              </Button>
            </div>
          </Form> */}
      </div>
    </div>
  )
}

// export default class Login extends React.Component<{
//   form: any;
//   history: any;
// }> {
//   state = {
//     loadings: false,
//   };

//   componentDidMount() {
//     sessionStorage.removeItem("token");
//     sessionStorage.removeItem("role");
//     sessionStorage.removeItem("userName");
//     sessionStorage.removeItem("realName");
//   }


//   enterLoading = (params) =>{

//   }

//   public render() {
//     const { loadings } = this.state;
//     return (

//     );
//   }
// }

var LoginInfo={

  getBossLoginUser: function (){
    // @ts-ignore
    return JSON.parse(sessionStorage.getItem("bossLoginUser"));

  },

  isOperatiorUser:function(){
    let bossLoginUser=this.getBossLoginUser();
    if( bossLoginUser &&  bossLoginUser!.refOperatorId	>0){
      return true;
    }
    return false;
   },
  isAgentUser:function(){
    let bossLoginUser=this.getBossLoginUser();
    if(bossLoginUser &&  bossLoginUser!.refAgentId	>0){
      return true;
    }
    return false;
  },
  isSellerUser:function(){
    let bossLoginUser=this.getBossLoginUser();
    if(bossLoginUser &&  bossLoginUser!.refSellerId	>0){
      return true;
    }
    return false;
  }

};

export   {Login,LoginInfo};
