import React, { useCallback, useState, useEffect } from "react";
import { Button, Checkbox, Form, Input,InputNumber,Radio } from 'antd';
import Request from "../../../utils/Request";

interface IProps {
    configInfo: Object;
}
export const FeeConfig=(props) =>{
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };
    const customTag = JSON.parse( sessionStorage.getItem('ddlkPlatformSellerDTO') || '0');
    return (
        <div>

            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={props?.configInfo}
                onFinish={onFinish}
                autoComplete="off">
                <Form.Item
                    label="普通推广员注册的金额"
                    name="agentRegisterFee"
                    rules={[{ required: false, message: '请输入普通推广员注册的金额' }]}>
                    <InputNumber style={{width:'200px'}} addonAfter="分"  />
                </Form.Item>
                <Form.Item
                    label={`${customTag.customerTaskCustNamePrefix}(${customTag.employeeTaskCustNamePrefix})活动最低充值金额`}
                    name="consumerMinRechargeFee"
                    rules={[{ required: false, message: `请输入${customTag.customerTaskCustNamePrefix}(${customTag.employeeTaskCustNamePrefix})活动最低充值金额` }]}>
                    <InputNumber style={{width:'200px'}} addonAfter="分"  />
                </Form.Item>
                <Form.Item
                    label="邀请一般推广员奖励金额"
                    name="invitOrdinaryAgentBonus"
                    rules={[{ required: false, message: '请输入邀请一般推广员奖励金额' }]}>
                    <InputNumber style={{width:'200px'}} addonAfter="分"  />
                </Form.Item>
                <Form.Item
                    label="邀请城市运营商奖励金额"
                    name="invitOperationMerchantAgentBonus"
                    rules={[{ required: false, message: '请输入邀请城市运营商奖励金额' }]}>
                    <InputNumber style={{width:'200px'}} addonAfter="分/每视频"  />
                </Form.Item>
                <Form.Item
                    label={`${customTag.customerTaskCustNamePrefix}活动技术服务费`}
                    name="pubConsumerTaskTechServiceFeePerVideo"
                    rules={[{ required: false, message: `请输入${customTag.customerTaskCustNamePrefix}活动技术服务费` }]}>
                    <InputNumber style={{width:'200px'}} addonAfter="分/每视频" />
                </Form.Item>
                <Form.Item
                    label={`${customTag.customerTaskCustNamePrefix}活动技术服务费成本`}
                    name="pubConsumerTaskCostFeePerVideo"
                    rules={[{ required: false, message: `请输入${customTag.customerTaskCustNamePrefix}活动技术服务费成本` }]}>
                    <InputNumber style={{width:'200px'}} addonAfter="分" />
                </Form.Item>
                <Form.Item
                    label={`${customTag.visitShopCustName}活动技术服务费`}
                    name="pubVisitShopTaskTechServiceFeePerVideo"
                    rules={[{ required: false, message: `请输${customTag.visitShopCustName}活动技术服务费` }]}>
                    <InputNumber style={{width:'200px'}} addonAfter="分"  />
                </Form.Item>
                <Form.Item
                    label={`${customTag.cloudVisitShopCustName}活动技术服务费`}
                    name="pubCloudTaskTechServiceFeePerVideo"
                    rules={[{ required: false, message: `请输入${customTag.cloudVisitShopCustName}活动技术服务费` }]}>
                    <InputNumber style={{width:'200px'}} addonAfter="分/视频" />
                </Form.Item>
                <Form.Item
                    label={`${customTag.cloudVisitShopCustName}作品达标最低播放量`}
                    name="darenMinPlayCount"
                    rules={[{ required: false, message: `请输入${customTag.cloudVisitShopCustName}作品达标最低播放量` }]}>
                    <InputNumber style={{width:'200px'}} addonAfter="次" />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </div>
    );
}
