/*
 * @Date: 2022-09-29 14:51:56
 * @LastEditors: litj
 * @LastEditTime: 2022-10-19 10:50:06
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/module/page/OperationConfiguration/verbConfig/VerbForms.tsx
 */
import React, {useEffect, useState} from "react";
import {Button, Modal,Checkbox, Form, Input, InputNumber, message, Radio, Upload} from "antd";
import Request, {EHostType} from "../../../utils/Request";
import {FormInstance} from "antd/es/form/hooks/useForm";
import {CameraOutlined} from "@ant-design/icons/lib/icons";
import { Item } from "rc-menu";
import { string } from "yargs";


interface IProps {
  configInfo: Object;
}
let host: any = sessionStorage.getItem("host");

export const VerbForms = (props) => {
  // console.log(props,'props');
  
  const onFinish = (values: any) => {
    // console.log(values,'===>>>')
    let host: any = sessionStorage.getItem("host");
    let url = `/op/operating-entity/saveOperEntityConf`;
    let param = { ...values };
    const assignValue = { ...values };
    // for (let key in param) {
    //   if (typeof param[key] === "object" && param[key].file) {
    //     param[key] = param[key].file.response.data;
    //   }
    // }
    if (props.proterty) {}
    props.formList.forEach((item) => {
      if (item.type === "upload" && item.key in assignValue) {
        assignValue[item.key] = item.fileList[0].url.replace(
          new RegExp(`${props.preFixUrl}`, "g"),
          ""
        );
      }
    });
    if (props.paramName) {
      param = {};
      if (props.proterty) {
        param[props.paramName] = {};
        param[props.paramName] = { ...assignValue };
        param[props.paramName][props.proterty] = {};

        for (let key of props.ownObjectProterties) {
          param[props.paramName][props.proterty][key] = assignValue[key];
          delete param[props.paramName][key];
        }
      } else {
        param[props.paramName] = { ...assignValue };
      }
    } else {
      param = { ...assignValue };
    }

    param.operatingEntityId = props.operatingEntityId;
    
    // console.log("Success:", param);
    setLoading(true);
    Request.jsonPost(EHostType.XMGX, url, param).then((res) => {
        if (res.code === 0) {
          message.success("保存成功");
        }
    }).finally(() => {
        setLoading(false);
    });
  };
  const formRef = React.createRef<FormInstance>();
  const [formData, setFormData] = useState(props.formData);

  useEffect(() => {
    for (let i = 0; i < props.formList.length; i++) {
      if (props.formList[i].type === "upload" && props.formList[i].fileList[0]) {
        const url = `${props.formList[i].fileList[0].url}`;
        let list = [
          {
            uid: "",
            url: `${props.preFixUrl}${url}`,
          },
        ];
        props.formList[i].fileList = list;
        // console.log(`${props.preFixUrl}${props.formList[i].fileList[0].url}`, "mmmmmmm");
        setNewFileList(list);
      }
    }
    formRef.current!.setFieldsValue(props.formData);
  }, [props]);
  const onFormatter = (arg):any  =>  {
    return arg ? Number.parseInt(arg) / 100 : ""
    // if(arg !== ""){
    //   return String(arg / 100)
    // }else{
    //   return ""
    // }
    // return String(arg ?  : "") ;
  }
  const onParser = (arg)  => {
    return arg ?  (arg *100).toFixed(0) : "";
  }
  const [newFileList, setNewFileList] = useState<any>([]);
  const [brandLogoUrl, setBrandLogoUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const getProps: () => any = () => {
    let host: any = sessionStorage.getItem("host");
    return {
      action: `${JSON.parse(host)?.xmgx}/op/operating-entity/uploadMaterial`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      name: "file",
      accept: "*",
      listType: "picture-card",
      multiple: false,
      maxCount: 1,
      showUploadList: false,
      // fileList: newFileList,
      data: {
        operatingEntityId: props.operatingEntityId,
        isPrivate: false,
      },
    };
  };
  // @ts-ignore
  const uploadProps: any = {
    action: `${JSON.parse(host)?.xmgx}/op/operating-entity/uploadMaterial`,
    headers: {
      Authorization: sessionStorage.getItem("token"),
    },
    name: "file",
    accept: "*",
    listType: "picture-card",
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    // fileList: newFileList,
    data: {
      operatingEntityId: props.operatingEntityId,
      isPrivate: false,
    }
  };
  // function getList() {
  const renderFormList = props.formList.map((formItem, index) => {
    return (
      <Form.Item
        label={formItem.label}
        name={formItem.key}
        rules={[{ required: false, message: "请输入微信号" }]}
        key={formItem.key + index}
        valuePropName={formItem.valuePropName}
      >
        {formItem.type === "radio" ? (
          <Radio.Group
            value={props.formData[formItem.key]}
            onChange={(e) => {
              props.formData[formItem.key] = e.target.value;
            }}>
            {formItem.options.map((item, i) => (
              <Radio value={item.value} key={item.label + i}>
                {item.label}
              </Radio>
            ))}
          </Radio.Group>
        ) : ["input", "password"].includes(formItem.type) ? (
          <Input
            style={{ width: "400px" }}
            addonAfter={formItem.addonAfter}
            value={props.formData[formItem.key]}
            placeholder={formItem.placeholder?formItem.placeholder:''}
            type={formItem.type === "password" ? "password" : ""}
            onChange={(e) => {
              props.formData[formItem.key] = e.target.value;
            }}
          />
        ) : formItem.type === "inputNumber" ? (
            <InputNumber style={{width:'200px'}} addonAfter={formItem.addonAfter} formatter={formItem.isFormatter ?  onFormatter : (arg) => {return arg }} parser={formItem.isFormatter ?  onParser : (arg) => {return arg}}  value={props.formData[formItem.key]} onChange={(e) => {props.formData[formItem.key] = e?.target?.value;}} min={0} />
        ) : formItem.type === "singleCheck" ? (
          <Checkbox style={{ width: 200 }}>{formItem.optionName}</Checkbox>
        ) : formItem.type === "upload" ? (
          <>
            <Upload
              {...getProps()}
              accept={formItem.accept || "*"}
              fileList={formItem.fileList || []}
              data={{
                ...uploadProps.data,
                isPrivate: props.isPrivate ? true : false,
              }}
              showUploadList={formItem.accept && formItem.accept.includes("image") ? true : false}
              // listType={
              //   formItem.accept && formItem.accept.includes("image")
              //     ? "picture-card"
              //     : "text"
              // }
              onChange={(info) => {
                formItem.fileList = [
                  {
                    uid: info.file.uid,
                    // url: `${props.preFixUrl}${info.file.response.data}`,
                  },
                ];
                setNewFileList([
                  {
                    uid: info.file.uid,
                    // url: `${props.preFixUrl}${info.file.response.data}`,
                  },
                ]);

                // console.log(`${props.preFixUrl}${info.file.url}`);
                if (info.file.status === "done") {
                  message.success(`上传成功`);
                  // console.log(`${props.preFixUrl}${info.file.response.data}`);
                  props.formData[formItem.key] = info.file.response.data;
                  formItem.fileList = [
                    {
                      uid: info.file.uid,
                      url: `${props.preFixUrl}${info.file.response.data}`,
                    },
                  ];
                  formRef.current?.setFieldsValue({
                    [props.key]: formItem.fileList,
                  });
                  setNewFileList([
                    {
                      uid: info.file.uid,
                      url: `${props.preFixUrl}${info.file.response.data}`,
                    },
                  ]);
                  setBrandLogoUrl(`${info.file.response.data}`);
                } else if (info.file.status === "removed") {
                  formItem.fileList = [];
                  props.formData[formItem.key] = undefined;
                  setNewFileList([]);
                } else if (info.file.status === "error") {
                  message.error(`上传失败，请重试！`);
                  formItem.fileList = [];
                  setNewFileList([]);
                }
              }}
            >
              <CameraOutlined style={{ fontSize: "24px" }} />
              {/*<div>{newFileList.length >= 1 ? null : <CameraOutlined style={{fontSize:"24px"}}/>}</div>*/}
            </Upload>
            <p>
              {formItem.fileList && formItem.fileList.length && (!formItem.accept || formItem.accept === "*") ? "已上传" : ""}
            </p>
          </>
        ) : (
          ""
        )}
        {/* <Input style={{ width: "200px" }} addonAfter="分" /> */}
      </Form.Item>
    );
  });
  // return renderFormList;
  // }
  const [isModalOpen, setIsModalOpen] = useState(false);

  //是否显示设置公众菜单确认框
  const showModal = () => {
    setIsModalOpen(true);
  };
  // 设置公众菜单确认
  const handleOk = () => {
    setIsModalOpen(false);
    setMPMenu();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const setMPMenu= async  function () {
    let res = await Request.post(
        EHostType.XMGX,
        `/op/operating-entity/setDdlkDefaultMpMenu?operatingEntityId=`+props.operatingEntityId,
        null
    );
    if (!res.error) {
      message.success("操作成功");
    }

  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
        ref={formRef}>
        {renderFormList}
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            保存
          </Button> &nbsp;&nbsp;&nbsp;



          {props.name=="配置公众号" &&

              <>
              <Button   type="primary" onClick={showModal}>
                设置公众号菜单
              </Button>

            <Modal title="二次确认" visible={isModalOpen}  onOk={handleOk} onCancel={handleCancel}>
            <p>此操作将会覆盖公众号现有菜单，请务必跟运营主体确认后再操作!</p>
            </Modal>
              </>

          }

        </Form.Item>


      </Form>



    </div>
  );
};
