/*
 * @Date: 2022-04-29 21:58:51
 * @LastEditors: litj
 * @LastEditTime: 2022-07-26 14:49:38
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\PromotionMange\Cloud\filter\index.tsx
 */
import * as React from "react";
import "../index.css";
import { Form, Input, DatePicker, Row, Col, Card, Button, Select } from "antd";
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  onSearch?: any;
  tab: string;
  queryType: string;
}

export const TaskListFilter = (props: IProps) => {
  return (
    <div>{
      props.queryType != 'goodExamples' ?  <Card className={"taskListFilterWrap search-container" + props.tab}>
      <Form onFinish={props.onSearch}>
        <Row gutter={24}>
          <Col span={5}>
            <Form.Item label="商家名称" name="adviserUserName">
              <Input />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="任务名称" name="taskName">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="任务时间" name="date">
              <RangePicker showTime />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
            <Button
              type="ghost"
              style={{ marginLeft: "10px" }}
              htmlType="reset"
            >
              重置
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>:""
      }
     
    </div>
  );
};
