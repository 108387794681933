/*
 * @Date: 2022-04-11 14:41:03
 * @LastEditors: litj
 * @LastEditTime: 2022-05-11 19:44:47
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\PromotionMange\Talent\detail\index.tsx
 */
import { Drawer, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Request, { EHostType } from "../../../../../utils/Request";
import { TaskDetailInfo } from "./info";
import { TalentList } from "./talentList";
import TaskIsShow from "../../components/taskIsShow";
const isShowGoodTaskTab = sessionStorage.getItem("functionLimits")?.includes("a15");
const { TabPane } = Tabs;

interface IProps {
  currenItem?: any;
  onClear?: any;
  tabActive?: any;
  isLimits: boolean | undefined;
  queryType: any;
  menuType: any;
}

export const TaskListDetail = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const [detailInfo, setDetailInfo] = useState({});
  const { currenItem, tabActive } = props;
  const [tabItemActive, setTabItemActive] = useState<string>("1");
  const [taskType,setTaskType] = useState("");
  useEffect(() => {
    if (currenItem) {
      getDetail();
    }
  }, [currenItem]);

  useEffect(() => {
    setTabItemActive(tabActive);
  }, [tabActive]);

  const getDetail = () => {
    if (!props.isLimits) {
      setVisible(true);
      return
    }
    let url = `/mgr/ddlk/visitshop/getVideoTaskDetail?id=${props.menuType ? currenItem.taskId : currenItem.id}`;
    Request.jsonPost(EHostType.DDlk, url, {}).then((res) => {
      setDetailInfo(res.data);
      setVisible(true);
      setTaskType(res.data.status)
    });

  };

  return (
    <Drawer
      title="详情"
      visible={visible}
      width={"80%"}
      contentWrapperStyle={{
        backgroundColor: "#e1e1e1",
        height: "100%",
        padding: "0px",
      }}
      destroyOnClose
      onClose={() => {
        setVisible(false);
        props.onClear();
      }}
    >
      <div>
        <Tabs
          activeKey={tabItemActive}
          onChange={(i) => {
            setTabItemActive(i);
          }}
          type="card"
        >
          {
            props.isLimits && !props.menuType ? <TabPane tab="任务详情" key="1">
              <TaskDetailInfo detailInfo={detailInfo} />
            </TabPane> : ""
          }
          <TabPane tab="参与列表" key="2">
            <TalentList queryType={props.queryType} menuType={props.menuType} currenItem={currenItem}  isAudit={props.isLimits} />
          </TabPane>
          {
            !props.menuType && taskType == "2" && isShowGoodTaskTab ? <TabPane tab="案例展示" key="3">
              <TaskIsShow detailInfo={detailInfo} taskType={3} />
            </TabPane> : ""
          }

        </Tabs>
      </div>
    </Drawer>
  );
};
