/**
 * @Date: 2022-07-05 11:03:35
 * @LastEditors: litj
 * @LastEditTime: 2022-07-05 11:04:56
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\DarenManage\source\query.js
 */
import moment from "moment";
import Request, {EHostType} from "../../../../utils/Request";
import {darenLevel} from "../../helper";
// 获取列表页数据

export const getList = (fn, params, pagination) => {
  const obj = { ...params };
  const page = { ...pagination };
  if (params.date) {
    obj.createTimeTo = moment(params.date[1]).format("YYYY-MM-DD HH:mm:ss");
    obj.createTimeFrom = moment(params.date[0]).format("YYYY-MM-DD HH:mm:ss");
    delete obj.date;
  }
  if (params.dyGroupbyLevel) {
    obj.dyGroupbyLevel = darenLevel.indexOf(params.dyGroupbyLevel);
  }
  page.pageNo = pagination.current;
  delete page.current;
  if (!obj.mobile) {
    delete obj.mobile;
  }
  if (page.total) {
    delete page.total;
  }
  let url = `/mgr/ddlk/dareninfo/queryAllDaren`;
  return Request.jsonPost(EHostType.DDlk,url, {
    ...obj,
    ...page,
    // darenId: props.darenId,
    // isApproved: value == 1 ? true : false,
    // notApprovedReason: value == 1 ? undefined : reason,
  });
  //   .then((res) => {
  //   console.log(res);
  //   if (res?.data) {
  //     //  message.success("审核通过");
  //     // props.onAuditSuccess();
  //     // this.props.parent.getList({});
  //     //  setVisible(false);
  //   }
  // });
};
// 获取详情数据
export const getDetail = (darenId: number) => {
  let url = `/mgr/ddlk/dareninfo/getDarenDetail?darenId=${darenId}`;
  return Request.jsonPost(EHostType.DDlk,url, {});
  //   .then((res) => {
  //   console.log(res);
  //   if (res?.data) {
  //     //  message.success("审核通过");
  //     // props.onAuditSuccess();
  //     // this.props.parent.getList({});
  //     //  setVisible(false);
  //   }
  // });
};

export const doAudit = (obj) => {
  let url = `/mgr/ddlk/dareninfo/doAudit`;
  return Request.jsonPost(EHostType.DDlk,url, obj);
};
// 审核
export const dataUpdate = (obj) => {
  let url = `/mgr/ddlk/dareninfo/updateDarenDetail`;
  return Request.jsonPost(EHostType.DDlk,url, obj);
};
// 上传图片
export const upLoadFile = (file) => {
  if (!file) return;
  return new Promise((resolve, reject) => {
    Request.get(EHostType.DDlk,`/mgr/ddlk/getOssTemporaryAuthorizationUrl?fileName=${file.name}`).then((res) => {
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => {
        Request.putOss(
            EHostType.NONE,
          res.data.value.url,
          reader.result,
          res.data.value.contentType,
          () => {}
        ).then(() => {
          resolve(res);
        });
      };
    });
  });
};
