import * as React from "react";
import {Descriptions, Drawer, message, Tabs} from "antd";
import "./index.css";
import {TabPane} from "rc-tabs";
import BaseInfo from "./BaseInfo";
import TeamRelation from "./TeamRelation";
import EditCell from "./EditCell";
import Request, {EHostType} from "../../utils/Request";

interface page {}
enum levelEnum {
  "2:1" = "推广员",
  "2:2" = "城市运营商",
}

export default class AgentShow extends React.Component<
  { parent?: any; state?: any; dataInfo: any },
  {}
> {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }
  onClose: (event: any) => void = (event) => {
    this.props.parent.showDetail(false);
  };
  handleSave = async (value: any) => {
    const _this = this;
    const params = {
      id: this.props.dataInfo.id,
      phone: value,
      name: this.props.dataInfo.name,
    };
    let url = `/op/agent/saveOrUpdate`;
    const res = await Request.jsonPost(EHostType.XMGX, url, params);
    if (res.code === 0) {
      message.success("提交成功");
      _this.props.parent.getData({ size: 10, current: 1 });
    }
    return res.code;
  };
  public render() {
    return (
      <Drawer
        title="代理商管理/用户详情"
        visible={this.props.state.detailShow}
        width={"80%"}
        contentWrapperStyle={{
          backgroundColor: "#e1e1e1",
          height: "100%",
          padding: "0px",
        }}
        destroyOnClose
        onClose={this.onClose}
      >
        <div className="flex-box">
          <Descriptions
            title="账户信息"
            bordered
            style={{ margin: "24px 0" }}
            column={4}
            labelStyle={{ width: "150px" }}
          >
            <Descriptions.Item label="注册手机" span={2}>
              <EditCell
                value={this.props.dataInfo.phone}
                name="phone"
                handleSave={this.handleSave}
                rules={[{ required: true, message: "请输入手机号" }]}
              ></EditCell>
              {/* {"fdsfsd"} */}
              {/* <Input
            type="number"
            min={0}
            value={darenNum}
            disabled={detailInfo.status !== 1}
            addonAfter="人"
            style={{ width: "200px" }}
            onChange={(e) => {
              setDarenNum(e.target.value);
            }}
          /> */}
            </Descriptions.Item>
            {/* {detailInfo?.groupbyBonusFee > 0 && ( */}
            <Descriptions.Item label="推荐人" span={2}>
              {`${
                this.props.dataInfo.invitedByAgentId
                  ? `
  ID: ${this.props.dataInfo.invitedByAgentId + "   "} 姓名：
              ${this.props.dataInfo.invitedByAgentName}
              ${
                this.props.dataInfo.parentAgentId
                  ? "所属城市运营商: ID:  " +
                    this.props.dataInfo.parentAgentId +
                    "  " +
                    "名称： " +
                    this.props.dataInfo.parentAgentName
                  : ""
              }
              `
                  : ""
              }`}

              {/* <Input
            type="number"
            min={0}
            value={eachDarenPrice}
            disabled={detailInfo.status !== 1}
            addonAfter="元/人"
            style={{ width: "200px" }}
            onChange={(e) => {
              setEachDarenPrice(+e.target.value);
            }}
          /> */}
            </Descriptions.Item>
            {/* )} */}
            <Descriptions.Item label="姓名" span={2}>
              {this.props.dataInfo.name}
            </Descriptions.Item>
            <Descriptions.Item label="身份" span={2}>
              {levelEnum[this.props.dataInfo.level]}
            </Descriptions.Item>
          </Descriptions>
          <div className="tab-wrapper">
            <Tabs
              defaultActiveKey="1"
              type="card"
              size="small"
              style={{ height: "100%" }}
            >
              <TabPane tab="基本信息" key="1">
                <BaseInfo
                  {...this.props.dataInfo}
                  getData={this.props.parent.getData.bind(this.props.parent)}
                />
              </TabPane>
              <TabPane tab="团队关系" key="2" style={{ height: "100%" }}>
                <TeamRelation {...this.props.dataInfo} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Drawer>

      // <Form name="form" className="form-container" initialValues={this.props.parent.state.rowData}>

      //     <div className="form-content">

      //         <Form.Item label="代理商姓名" className="form-item ant-col-23" name="name">
      //             <Input disabled />
      //         </Form.Item>

      //         <Form.Item label="代理商手机号码" className="form-item ant-col-23" name="phone" >
      //             <Input disabled />
      //         </Form.Item>

      //         <Form.Item label="所在机构（选填）" className="form-item ant-col-23" name="organization" >
      //             <Input disabled />
      //         </Form.Item>

      //         <Form.Item label="营业执照（选填）" className="form-item ant-col-23" >
      //             {this.props.parent.state.rowData.businessLicenseUrl !== undefined ? <Image src={this.props.parent.state.rowData.businessLicenseUrl} /> : <span style={{ color: '#b1b0b0' }}>暂无图片...</span>}
      //         </Form.Item>

      //         <Form.Item label="代理商等级" className="form-radio" name="level" >
      //             <Radio.Group disabled>
      //                 <Radio value={Number(1)}>金牌代理</Radio>
      //                 <Radio value={Number(2)}>银牌代理</Radio>
      //                 <Radio value={Number(3)}>铜牌代理</Radio>
      //             </Radio.Group>
      //         </Form.Item>

      //         <Form.Item label="代理有效期" className="form-item ant-col-23" name="expiredTime">
      //             <DatePicker disabled/>
      //         </Form.Item>

      //     </div>

      //     <div className="form-btn">
      //         <Button onClick={() => this.props.parent.visiblePop(false)}>取消</Button>
      //     </div>

      // </Form>
    );
  }
}
