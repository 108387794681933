import {
    Button,
    DatePicker,
    Descriptions,
    Input,
    message,
    Select,
    Tabs,
    Modal,
    InputNumber,
    Radio,
    Cascader,
    Typography,
    Tag
} from "antd";
import moment from "moment";
import React, {
    useCallback, useEffect, useRef
    , useState
} from "react";
import Request, {EHostType} from "../../../../../../utils/Request";
import {comboTypeMap, darenLevel, taskStatusMap} from "../../../../helper";
import {IDataItem, TabMaterial} from "./components/TabUpload";
import TaskConfig from "./components/TaskConfig";
import "./index.css";
import EditCell from "../../../../../AgentManagement/EditCell";
import address from "../../../../../../assets/js/area.json";
import {LoginInfo} from "../../../../../../root/Login";

import Utils from "../../../../../../utils/Utils";
const {TabPane} = Tabs;
const {RangePicker} = DatePicker;
const {Option} = Select;

interface IProps {
    detailInfo: any;
}

interface ITaskConfig {
    key: number;
    configKey?: string;
    value?: string;
    isEditTask: boolean;
}

//是否有任务配置权限
const hasTaskConfigPermit = () => {

    //代理商，运营有权限
    return LoginInfo.isAgentUser() || LoginInfo.isOperatiorUser();


}
const get2LevelAddress = () => {
    let newAddres = [...address];
    // @ts-ignore
    for (let level1Adress of newAddres) {
        // @ts-ignore
        for (let level2Adress of level1Adress.children) {
            // @ts-ignore
            level2Adress.children = null;
        }
    }
    return newAddres;
}


//获取某个省下面的所有市code
const getCityCodes = (provinceCode) => {

    let result = [];
    // @ts-ignore
    for (let level1Adress of address) {
        if (level1Adress.value == provinceCode) {
            for (let level2Adress of level1Adress.children) {
                // @ts-ignore
                result.push(level2Adress.value);
            }
        }
    }
    return result;
}

export const getConfig = (taskConfigList) => {
    const rewardConfig = {};
    taskConfigList.forEach((config) => {
        config.configKey >= 0 &&
        (rewardConfig[config.configKey] = config.value * 100);
    });
    return Object.keys(rewardConfig).length ? rewardConfig : undefined;
};
//默认的素材分类
const defaultMaterial={
    门头: [],
    门店环境: [],
    全部套餐: [],
    单品: [],
    其他素材: [],
};
//默认的素材分类（成片分发专用）
const defaultMaterial4Darentype3={
    成片分发视频: []
};


export const TaskDetailInfo = (props: IProps) => {
    const {detailInfo} = props;
    const [currentTab, setCurrentTab] = useState("0");
    const darenType= detailInfo.darenType;


    const [picMaterial,setPicMaterial] =useState(darenType===3?defaultMaterial4Darentype3:defaultMaterial);
    const [videoMaterial,setVideoMaterial] =useState(darenType===3?defaultMaterial4Darentype3:defaultMaterial);

    //当前素材(图片/视频)映射表.key是素材分类名称,value是素材的地址
    const material = useRef<any>(darenType===3?defaultMaterial4Darentype3:defaultMaterial);

    //素材名称映射表(用于记录素材分类改名前后的分类名称)
    const catNameMap = useRef(new Map());

    //成片推广发布视频保存时间
    const [douyinVideoSaveTime, setDouyinVideoSaveTime] = useState(3);
    //成片推广发布视频奖励金额
    const [douyinVideoRewardFee, setDouyinVideoRewardFee] = useState(5);

    const [rewardConfigMapFormat, setRewardConfigMapFormat] = useState(
        detailInfo.rewardConfigMapFormat
    );

    const [wordMaterial, setWordMaterial] = useState(detailInfo.wordsMaterial);

    const [taskDesc, setTaskDesc] = useState("");
    const [taskName, setTaskName] = useState("");
    const [pubTimeFrom, setPubTimeFrom] = useState<any>("");
    const [pubTimeTo, setPubTimeTo] = useState("");
    // const [darenLevel, setDarenLevel] = useState("");
    const [rewardNum, setRewardNum] = useState(0);
    const [isEditTask, setIsEditTask] = useState(false);
    const [taskConfigList, setTaskConfigList] = useState<any>([]);
    const [configIndex, setConfigIndex] = useState(0);
    const [configKeyLevel, setConfigKeyLevel] = useState(-1);
    const [currentConfig, setCurrentConfig] = useState({});
    const [darftLoading, setDarftLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [shareBonusFee, setShareBonusFee] = useState(0);
    const [configKey, setConfigKey] = useState();
    //tabs是素材分类的名称
    const [tabs, setTabs] = useState<any>([]);
    const [showChangeCatModal, setShowChangeCatModal] = useState(false);
    const [taskCityArray, setTaskCityArray] = useState([]);
    //debugger;
    const [showDarenCitySelect, setshowDarenCitySelect] = useState(false);
    const [isSameCityRequired, setIsSameCityRequired] = useState(true);

    const getPicMaterial = (key) => {
        if (detailInfo?.picMaterial && detailInfo?.picMaterial[key]) {
            return detailInfo?.picMaterial[key];
        } else {
            return [];
        }
    };
    // 等级配置列表
    const generateConfigList = () => {
        // const taskConfigList: any[] = [];
        let taskConfigList: ITaskConfig[] = [];
        let count = detailInfo.darenType === 1 ? 1 : 3;
        for (let i = 0; i < count; i++) {
            taskConfigList.push({
                key: i,
                configKey: undefined,
                value: undefined,
                isEditTask: false,
            });
        }
        let i = 0;
        if (detailInfo.rewardConfigMapFormat) {
            for (let key in detailInfo.rewardConfigMapFormat) {
                taskConfigList[i] = {
                    configKey: key,
                    key: i,
                    value:
                        detailInfo.rewardConfigMapFormat[key] &&
                        (detailInfo.rewardConfigMapFormat[key] / 100).toFixed(2),
                    isEditTask: false,
                };
                i++;
            }
        }
        setTaskConfigList(taskConfigList);
    };
    const getVideoMaterial = (key) => {
        if (detailInfo?.videoMaterial && detailInfo?.videoMaterial[key]) {
            return detailInfo?.videoMaterial[key];
        } else {
            return [];
        }
    };

    //重置素材变量(包含了视频素材，图片素材)
    const resetMaterial=(videoMaterial,picMaterial,darenType)=>{
        let materialCatNames = Object.keys(videoMaterial || picMaterial);
        if(materialCatNames.length ==0){
            materialCatNames= Object.keys(darenType===3?defaultMaterial4Darentype3:defaultMaterial);
        }
        let tabList = materialCatNames;
        //计算material, 把视频素材和图片素材合并起来
        let newMaterial={};
        tabList.forEach((key) => {
            // debugger;
            let picts=picMaterial[key] ||   [];
            let videos=videoMaterial[key] || [];
            newMaterial[key] = [
                ...picts.map((item) => {
                    return {path: item, type: 2, status: "done"};
                }),
                ...videos.map((item) => {
                    return {path: item, type: 1};
                }),
            ];
        });
        material.current = newMaterial;
        setTabs(tabList);
    }



    useEffect(() => {
        if (detailInfo.wordsMaterial) {
            setWordMaterial(detailInfo.wordsMaterial);
        }
        if (detailInfo.rewardConfigMapFormat) {
            setRewardConfigMapFormat(detailInfo.rewardConfigMapFormat);
        }

        if (detailInfo.pubTimeFrom) {
            setPubTimeFrom(detailInfo.pubTimeFrom);
        }
        if (detailInfo.pubTimeTo) {
            setPubTimeTo(detailInfo.pubTimeTo);
        }
        setTaskName(props.detailInfo.taskName);
        setDouyinVideoSaveTime(detailInfo.douyinVideoSaveTime);
        if (detailInfo.taskDesc) {
            if (detailInfo.douyinVideoSaveTime) {
                setTaskDesc(detailInfo.taskDesc.replace(/视频需至少保存.*天/g, '视频需至少保存' + detailInfo.douyinVideoSaveTime + '天'))
            }else{
                setTaskDesc(detailInfo.taskDesc)
            }
        }
        if (detailInfo!.darenType == 3) {
            //成片分发类型
            if (detailInfo!.rewardConfig) {
                //成片推广的奖励金额存放在rewardConfig字段中,key是0,
                let fee = JSON.parse(detailInfo.rewardConfig)[0];
                if (fee) {
                    setDouyinVideoRewardFee( fee / 100);
                }
            }
        }
        if (detailInfo.taskCity) {
            let cityArray= detailInfo.taskCity.split(",");
            cityArray.length >= 1 ? setIsSameCityRequired(false) : setIsSameCityRequired(true);
            setTaskCityArray(cityArray);
        }else{
            setIsSameCityRequired(false);
        }
        if (detailInfo.id) {
             //设置素材默认值
            if(!Utils.isEmpty(detailInfo.videoMaterial)){
                setVideoMaterial(detailInfo.videoMaterial);
            }
            if(!Utils.isEmpty(detailInfo.picMaterial)){
                setPicMaterial(detailInfo.picMaterial);
            }
            resetMaterial(detailInfo.videoMaterial ||videoMaterial ,detailInfo.picMaterial || picMaterial,darenType);
            // debugger;
            generateConfigList();
        }
        if(detailInfo.shareBonusFee) {
            setShareBonusFee(detailInfo.shareBonusFee/100);
        }
    },[props.detailInfo]);

    let list: any[] = [...taskConfigList];


    // const tabs = ["门头", "门店环境", "全部套餐", "单品", "其他素材"];

    // 文件上传成功后的回调, key是素材分类名称，  data是上传成功素材的信息
    const onTabpanelChange = (key: string, data: IDataItem[]) => {
        if (data.length == material.current[key].length) {
            return;
        }
        setTimeout(() => {
            const newMaterial = material.current;
            newMaterial[key] = data;
            material.current = newMaterial;
            doSaveMaterial();
        }, 0);
    };

    // 修改等级下拉
    function handleLevelChange(e, key) {
        let levelNumber = e.split('V')[1];
        setConfigKey(levelNumber);
        setConfigKeyLevel(Number(levelNumber));
    }

    function handelRewardChange(e) {
        setRewardNum(e);
    }

    // 子组件 点击修改
    function handelEditClick(rewardCount, index, editFlag, currentConfig) {
        list[index] && (list[index].isEditTask = editFlag);
        let hasEdit;
        // 如果是清空 没有等级 也 没有 奖励金额 直接retur
        if (
            !rewardCount &&
            !list[index].configKey &&
            !editFlag &&
            !(configKeyLevel >= 0)
        ) {
            setIsEditTask(!list.every((e) => !e.isEditTask));
            return;
        }
        // if (configKeyLevel >= 0 && !rewardCount) {
        //   message.info("请输入奖励金额");
        //   return;
        // }
        // 最后一个点击 完成  ---》 所有的都是修改完成状态
        if (list.every((e) => !e.isEditTask)) {
            if (!currentConfig) {
                configKeyLevel >= 0 && (list[index].configKey = configKeyLevel + "");
            } else {
                let keyS;
                for (let key in currentConfig) {
                    keyS = key;
                }
                list[index].configKey = currentConfig[keyS];
            }
            setIsEditTask(editFlag);
        } else if ((hasEdit = list.some((e) => e.isEditTask))) {
            // 有待完成状态
            let keyS;
            for (let key in currentConfig) {
                keyS = key;
            }
            !editFlag &&
            configKeyLevel >= 0 &&
            (list[index].configKey = configKeyLevel + "");
            list[index].value = rewardCount;
            index = keyS;
            setIsEditTask(true);
            setConfigKeyLevel(currentConfig[keyS]);
        } else {
            setIsEditTask(true);
        }
        if (editFlag) {
        }
        if (!hasEdit) {
            list[index].value = rewardCount;
        }
        setTaskConfigList([...list]);
        setConfigIndex(index);
    }

    function handelEditClear(index, currentConfig) {
        list[index].configKey = undefined;
        list[index].value = undefined;
        currentConfig = {};
        setConfigKeyLevel(-1);
        setTaskConfigList([...list]);
    }

    // 获取素材列表()
    const getMaterialList = () => {
        return tabs.map((item, index) => {
            return (
                <TabPane tab={item} key={index}>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                        <TabMaterial
                            dataSource={[...material.current[item]]}
                            filePrefix={detailInfo.filePrefix}
                            tabIndex={index + ""}
                            onChange={(data: IDataItem[]) => {
                                onTabpanelChange(item, data);
                            }}
                            requireMaterialNumPermit={darenType == 3 ? detailInfo.darenNum : -1}
                            uploadVideOnly={darenType == 3 ? true : false}
                            maxMaterialNumPermit={darenType == 3 ? 500 : 30}
                        />
                    </div>
                </TabPane>
            );
        });
    };

    // 保存到草稿箱
    const doSave = () => {
        return new Promise(async (resolve, reject) => {

            let boolhasTaskConfigPermit = hasTaskConfigPermit();

            if (!boolhasTaskConfigPermit) {
                message.info("没有配置任务权限");
                return reject("没有配置任务权限");
            }

            const url = `/mgr/ddlk/saveTaskDraft`;
            let videoMaterial = {};
            let picMaterial = {};
            let ErrorMessage: any = [];
            let tips = "";
            let videoTotalNum=0;
            tabs.forEach((key) => {
                const videoPath =
                    material.current[key]
                        .filter((item) => item.type == 1)
                        .map((mitem) => mitem.path) || [];
                const picPath =
                    material.current[key]
                        .filter((item) => item.type == 2)
                        .map((mitem) => mitem.path) || [];
                videoMaterial[key] = Array.from(new Set(videoPath));
                picMaterial[key] = Array.from(new Set(picPath));
                videoTotalNum+= videoMaterial[key].length;
                const count = videoMaterial[key].length + picMaterial[key].length;
                let isValidMaterial =
                    videoMaterial[key].length > picMaterial[key].length;
                // if (!tips && key !== "其他素材") {
                //   if (count < 9 || count > 31) {
                //     tips += "素材数量需要在10-30张 ";
                //   }
                //   if (!isValidMaterial) {
                //     tips += " 视频素材须多于图片素材 ";
                //   }
                //   if (tips) {
                //     tips = `${key} ${tips}`;
                //   }
                // }
            });

            // const rewardConfig = {};
            // taskConfigList.forEach((config) => {
            //   config.configKey >= 0 &&
            //     (rewardConfig[config.configKey] = config.value * 100);
            // });
            let rewardConfig = getConfig(taskConfigList);
            if (darenType == 3) {
                rewardConfig = {};
                //成片推广，奖励金KEY指定为0（与服务端的约定 )
                rewardConfig[0] = douyinVideoRewardFee * 100;
                //成片推广视频个数检验
                if(detailInfo.darenNum<videoTotalNum){
                    message.info("需求视频个数为"+detailInfo.darenNum+"个.只需上传"+detailInfo.darenNum+"个视频即可");
                    return ;
                }
            }
            setDarftLoading(true);

            if(!pubTimeFrom || !pubTimeTo){
                message.info("任务时间必填!");
                setDarftLoading(false);
                return ;
            }

            let taskCity = detailInfo.taskCity;
            if (isSameCityRequired) {
                taskCity = [detailInfo.adviserCity].join(",");
            } else {
                
                if (taskCityArray.length > 30) {
                    message.info("最多只能选择30个城市.请重新选择");
                    setDarftLoading(false);
                    return;
                } else {
                    taskCity = taskCityArray.join(",");
                }
            }
            let res;
            try {
                res = await Request.jsonPost(EHostType.DDlk, url, {
                    id: detailInfo.id,
                    taskName: taskName,
                    pubTimeFrom: pubTimeFrom.split(" ")[0],
                    pubTimeTo:  pubTimeTo.split(" ")[0] ,
                    videoMaterial: videoMaterial,
                    wordsMaterial: wordMaterial,
                    picMaterial: picMaterial,
                    taskDesc: taskDesc,
                    rewardConfig,
                    shareBonusFee: shareBonusFee * 100,
                    douyinVideoSaveTime,
                    taskCity
                });
            } catch (err) {
                setDarftLoading(false);
                return reject(false);
            }
            setDarftLoading(false);
            if (res.code == 0) {
                message.success("保存成功");
            }else{
                setDarftLoading(false);
                return reject(false);
            }
            setDarftLoading(false);
            return resolve(true);
        });
    };

    // 保存素材
    const doSaveMaterial = () => {
        let boolhasTaskConfigPermit = hasTaskConfigPermit();

        if (!boolhasTaskConfigPermit) {
            message.info("没有配置任务权限");
        }

        const url = `/mgr/ddlk/saveTaskDraft`;
        let videoMaterial = {};
        let picMaterial = {};
        let videoTotalNum=0;
        tabs.forEach((key) => {
            const videoPath =
                material.current[key]
                    .filter((item) => item.type == 1)
                    .map((mitem) => mitem.path) || [];
            const picPath =
                material.current[key]
                    .filter((item) => item.type == 2)
                    .map((mitem) => mitem.path) || [];
            videoMaterial[key] = Array.from(new Set(videoPath));
            picMaterial[key] = Array.from(new Set(picPath));
            videoTotalNum+= videoMaterial[key].length;

        });

        let rewardConfig = getConfig(taskConfigList);
        if (darenType == 3) {
            rewardConfig = {};
            //成片推广，奖励金KEY指定为0（与服务端的约定 )
            rewardConfig[0] = douyinVideoRewardFee * 100;
            //成片推广视频个数检验
            if(detailInfo.darenNum<videoTotalNum){
                message.info("需求视频个数为"+detailInfo.darenNum+"个.只需上传"+detailInfo.darenNum+"个视频即可");
                return ;
            }
        }
        try {
           Request.jsonPost(EHostType.DDlk, url, {
                id: detailInfo.id,
                videoMaterial: videoMaterial,
                picMaterial: picMaterial
            });
        } catch (err) {
            console.log(err);
        }

    };



    const getTaskConfig = (detailInfo) => {
        return taskConfigList.map((config, index) => {
                return <TaskConfig
                    darenType={detailInfo.darenType}
                    configKey={config.configKey}
                    configValue={config.value}
                    isEditTask={config.isEditTask}
                    handleLevelChange={handleLevelChange}
                    handelEditClick={handelEditClick}
                    handelEditClear={handelEditClear}
                    configIndex={index}
                    configKeyLevel={configKeyLevel}
                    taskConfigList={[...taskConfigList]}
                    key={index}
                    taskId={detailInfo.id}
                    shareBonusFee={detailInfo.shareBonusFee}
                />
            }
        );
    };
    // 保存并发送
    const doSubmit = async () => {
        let saveResult=await doSave();
        if(!saveResult){
            return ;
         }
        const url = `/mgr/ddlk/pubVideoTask?id=${detailInfo.id}`;
        let videoMaterial = {};
        let picMaterial = {};
        let ErrorMessage: any = [];
        let tips = "";
        tabs.forEach((key) => {
            const videoPath =
                material.current[key]
                    .filter((item) => item.type == 1)
                    .map((mitem) => mitem.path) || [];
            const picPath =
                material.current[key]
                    .filter((item) => item.type == 2)
                    .map((mitem) => mitem.path) || [];
            videoMaterial[key] = Array.from(new Set(videoPath));
            picMaterial[key] = Array.from(new Set(picPath));
            const count = videoMaterial[key].length + picMaterial[key].length;
            let isValidMateial = videoMaterial[key].length > picMaterial[key].length;
            if (!tips && key !== "其他素材" && darenType !==3) {
                if (count < 9 || count > 31) {
                    tips += "素材数量需要在10-30张 ";
                }
                if (!isValidMateial) {
                    tips += " 视频素材须多于图片素材 ";
                }
                if (tips && key !== "其他素材") {
                    tips = `${key} ${tips}`;
                }
            }
        });
        if (tips) {
            message.error(tips);
            return;
        }
        setSaveLoading(true);
        let resp;
        try {
            resp = await Request.jsonPost(EHostType.DDlk, url, {
                id: detailInfo.id,
                taskName: taskName,
                pubTimeFrom:  pubTimeFrom.split(" ")[0],
                pubTimeTo:  pubTimeTo.split(" ")[0],
                videoMaterial: videoMaterial,
                wordsMaterial: wordMaterial,
                picMaterial: picMaterial,
                taskDesc: taskDesc,
            });
        } catch (error) {
            setSaveLoading(false);
        }

        if (resp.code == 0) {
            message.success("提交成功");
        }
        setSaveLoading(false);
    };
    const handleSave = (value) => {
        setShareBonusFee(value);
        return Promise.resolve(0);
    };

    const onChangeCatOk = () => {
        // debugger;
        //遍历catNamemap
         let picMaterialJson=JSON.stringify(picMaterial);
         let videoMaterialJson=JSON.stringify(videoMaterial);
        catNameMap.current.forEach(function (newName, oldName) {
            picMaterialJson = picMaterialJson.replaceAll(oldName, newName);
            videoMaterialJson = videoMaterialJson.replaceAll(oldName, newName);

        });
        let picObj=JSON.parse(picMaterialJson);
        let videoObj=JSON.parse(videoMaterialJson);
        resetMaterial(videoObj,picObj,darenType);

        setPicMaterial(picObj);
        setVideoMaterial(videoObj);
        setShowChangeCatModal(false);

        catNameMap.current.clear();
    };

    const onChangeCatCancel = () => {
        setShowChangeCatModal(false);
    };

    //修改素材分类
    const changeCatName = (oldName, e) => {
        let newName = e.target.value;
        if (newName != null && newName.trim().length > 0) {
            catNameMap.current.set(oldName, newName);
        }

    };

    //达人城市选择
// @ts-ignore
    const changeDarenCity = (value, selectedOptions) => {
        //把省自动换成市
        let finalCities = [];
        if (value) {
            value.forEach(function (address, index, self) {
                let finalChoosedAddress = null;
                if (address.length == 1) {
                    // @ts-ignore
                    finalChoosedAddress = address[0]
                } else {
                    // @ts-ignore
                    finalChoosedAddress = address[address.length - 1];
                }
                // @ts-ignore
                if (finalChoosedAddress.endsWith("0000")) {
                    // @ts-ignore
                    finalCities.push(...getCityCodes(finalChoosedAddress));
                } else {
                    // @ts-ignore
                    finalCities.push(finalChoosedAddress);
                }
            });
            if (finalCities.length >= 30 || taskCityArray.length >=30) {
                message.info("最多只能选择30个城市.请重新选择");
            } else {
                //debugger;
                let cityArray = [...taskCityArray, ...finalCities];
                var newArr = cityArray.filter(function (item, index) {
                    return cityArray.indexOf(item) === index;  // 因为indexOf 只能查找到第一个
                });
                setTaskCityArray(newArr);
            }
        }
    };


    const onDarenCityRadioChange = (e) => {
        setIsSameCityRequired(e.target.value)
    };

    const getCityName = (cityCode) => {

        //直辖市 特殊 处理一下
        if (cityCode == "110100") {
            return "北京市";
        }
        if (cityCode == "120100") {
            return "天津市";
        }
        if (cityCode == "500100") {
            return "重庆市";
        }
        if (cityCode == "310100") {
            return "上海市";
        }
        for (let level1Adress of address) {
            // @ts-ignore
            for (let level2Adress of level1Adress.children) {
                // @ts-ignore
                if (level2Adress.value == cityCode) {
                    return level2Adress.label;
                }
            }
        }
        return '';
    };

    const delCity = (city) => {
        let newTaskCityArry = taskCityArray.filter(item => item != city);
        // let  new_set = new Set(taskCityArray)
        // // @ts-ignore
        // new_set.delete(city);
        // @ts-ignore
        //debugger;
        setTaskCityArray(newTaskCityArry);
    };


    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (

        <div>

            <Descriptions title="" bordered>
                <Descriptions.Item label="任务id" span={2}>
                    {detailInfo.id}
                </Descriptions.Item>
                <Descriptions.Item label="关联商家id" span={2}>
                    {detailInfo.adviserReferSellerId}
                </Descriptions.Item>
                <Descriptions.Item label="任务名称" span={2}>
                    <Input
                        value={taskName}
                        onChange={(e) => {
                            setTaskName(e.target.value);
                        }}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="任务时间" span={2}>
                    <RangePicker
                        value={[
                            pubTimeFrom
                                ? moment(pubTimeFrom, "YYYY-MM-DD")
                                : null,
                            pubTimeTo ? moment(pubTimeTo, "YYYY-MM-DD") : null,
                        ]}
                        onChange={(e: any) => {
                            setPubTimeFrom(e[0].format("YYYY-MM-DD"));
                            setPubTimeTo(e[1].format("YYYY-MM-DD"));
                        }}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="任务类型" span={1}>
                    {darenType === 1 ? "素人云剪" : (darenType === 2 ? "达人云剪" : "成片分发")}
                </Descriptions.Item>
                <Descriptions.Item label="任务单价" span={1}>
                    {detailInfo.comboPrice / 100}元
                </Descriptions.Item>
                <Descriptions.Item label="需求达人数量" span={2}>
                    {detailInfo.darenNum}人
                </Descriptions.Item>
                {darenType != 3 ? (
                    <Descriptions.Item label="单个作品最低播放量要求" span={1}>
                        {detailInfo.playCountAtLeast / detailInfo.darenNum}
                    </Descriptions.Item>):null
                }
                <Descriptions.Item label="任务金额" span={1}>
                    {detailInfo.totalPaidFee / 100}元
                </Descriptions.Item>
                <Descriptions.Item label="任务状态" span={2}>
                    <a>{taskStatusMap[detailInfo.taskStatus]}</a>
                </Descriptions.Item>
                {hasTaskConfigPermit() ? (
                    <>
                        <Descriptions.Item label="分享佣金" span={1}>
                            <EditCell
                                value={
                                    detailInfo.shareBonusFee == null
                                        ? 0
                                        : (detailInfo.shareBonusFee / 100).toFixed(2)
                                }
                                name="count"
                                handleSave={handleSave}
                                rules={[{required: true, message: "请输入"}]}
                                options={{
                                    type: "inputnumber",
                                }}
                            ></EditCell>
                            {detailInfo.shareBonusFee == null &&
                            (detailInfo.shareBonusFee / 100).toFixed(2) + "  元"}
                        </Descriptions.Item>

                        <Descriptions.Item label="达人可参与的城市" span={4}>

                            <div>
                                {
                                     taskCityArray.map((city, index) => {
                                        return (
                                            <Tag closable key={city} onClose={() => delCity(city)}>
                                                {getCityName(city)}
                                            </Tag>
                                        );
                                    })
                                }
                                {

                                        (<Typography.Link onClick={() => setshowDarenCitySelect(true)}
                                                          style={{marginLeft: 30}}>
                                            添加城市{isSameCityRequired ? "" : "(不选即代表全国可参加)"}
                                        </Typography.Link>)
                                }

                                {
                                    showDarenCitySelect ?
                                        <Modal title="添加城市" visible={showDarenCitySelect}
                                               onOk={() => setshowDarenCitySelect(false)}
                                               onCancel={() => setshowDarenCitySelect(false)}>
                                            <Cascader
                                                // showCheckedStrategy={"Cascader.SHOW_CHILD"}
                                                options={get2LevelAddress()}
                                                placeholder="请选择城市"
                                                multiple
                                                expandTrigger="hover"
                                                onChange={changeDarenCity}/>
                                        </Modal>
                                        : null

                                }
                            </div>
                        </Descriptions.Item>
                    </>
                ) : null
                }
            </Descriptions>
            <h3 style={{margin: "10px 0"}}>任务素材</h3>
            <div style={{minHeight: "200px",position:"relative"}}>
                <Tabs defaultActiveKey={currentTab} onChange={(e) => {
                    setCurrentTab(e);
                }}>
                    {getMaterialList()}
                    {darenType != 3 ? (<TabPane tab="文字素材" key="7">
                        <Input.TextArea
                            defaultChecked={detailInfo.wordsMaterial}
                            value={wordMaterial}
                            onChange={(e) => {
                                setWordMaterial(e.target.value);
                            }}
                            rows={10}
                        />
                    </TabPane>) : null
                    }
                </Tabs>

                {hasTaskConfigPermit() && darenType != 3 ? (
                    <div style={{position:"absolute",top:"5px",left:"600px"}}>

                        <Button type="primary" onClick={() => setShowChangeCatModal(true)}>修改素材分类名称</Button>

                        <Modal title="修改素材分类名称" visible={showChangeCatModal} onOk={onChangeCatOk}
                               onCancel={onChangeCatCancel}>

                            {
                                tabs.map((item, index) => {

                                        return (
                                            <p key={index}>{item} 修改为: <Input maxLength={10} style={{width: "100px"}} onChange={(e) => {
                                                changeCatName(item, e);
                                            }}/></p>)

                                    }
                                )

                            }

                        </Modal>

                    </div>) : null
                }

            </div>


            <h3 style={{margin: "10px 0"}}>任务配置</h3>
            <div className="configWrapper">
                {hasTaskConfigPermit() && darenType != 3 ? (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {taskConfigList.length && getTaskConfig(detailInfo)}
                    </div>
                ) : (<div>

                    发布视频保存<InputNumber value={douyinVideoSaveTime} min={1} max={7}
                                       style={{width: "50px"}} step="1" precision={0} onChange={(value: any) => {
                    setDouyinVideoSaveTime(value);
                    setTaskDesc(taskDesc.replace(/视频需至少保存.*天/g,'视频需至少保存'+value+'天'))
                }}/> 天后 ，
                    奖励金额<InputNumber value={douyinVideoRewardFee} min={0.01} max={9999} style={{width: "80px"}}
                                     precision={2} stringMode  step={1}
                                     formatter={value => {
                                         return (
                                             `${value}`
                                                 .replace(/\B(?=(\d{3})+(?!\d))/g, "")
                                                 .replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
                                         );
                                     }}
                                     onChange={(value: any) => { setDouyinVideoRewardFee(value);}}/>元
                </div>)
                }
            </div>

            <h3 style={{margin: "10px 0"}}>任务说明</h3>
            <div>
                <Input.TextArea
                    onChange={(e) => {
                        setTaskDesc(e.target.value);
                    }}
                    rows={10}
                    value={taskDesc}
                />
                {/* <div>账号要求：本地生活类、探店类、生活类视频等。</div>
            <div>任务要求：。</div>
            <div>1、请使用指定素材进行创作，报名后素材可见</div>
            <div>2、抖音@指定账号“杭州小龙坎西湖店”。</div>
            <div>3、发布作品时需挂上商家地理位置信息，否则无效。</div>
            <div>4、达人视频需至少保存30天时间</div> */}
            </div>

            {hasTaskConfigPermit() ? (
                <>
                    <div style={{marginTop: "30px"}}>
                        <Button
                            type="ghost"
                            loading={darftLoading}
                            onClick={() => {
                                doSave();
                            }}
                        >
                            保存修改
                        </Button>
                        <Button
                            type="primary"
                            style={{marginLeft: "20px"}}
                            loading={saveLoading}
                            onClick={() => {
                                doSubmit();
                            }}
                        >
                            确认提交
                        </Button>
                    </div>
                </>) : null
            }

        </div>
    );
};
