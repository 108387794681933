/*
 * @Date: 2022-04-11 14:41:03
 * @LastEditors: litj
 * @LastEditTime: 2022-07-01 11:47:23
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\PromotionMange\Consumer\detail\index.tsx
 */
import { Drawer, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Request, { EHostType } from "../../../../../utils/Request";
import TaskIsShow from "../../components/taskIsShow";
import { TaskDetailInfo } from "./info";
import { TalentList } from "./talentList";

const { TabPane } = Tabs;
interface IProps {
  currenItem?: any;
  onClear?: any;
  tabActive?: any;
  isDetail: boolean | undefined;
  worksAudit: boolean | undefined;
  querType: any;
  menuType: any
}

export const TaskListDetail = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const [detailInfo, setDetailInfo] = useState({});
  const { currenItem, tabActive } = props;
  const [tabItemActive, setTabItemActive] = useState<string>("1");
  const [subStore, setSubStore] = useState<any[]>([]);
  const [taskType,setTaskType] = useState("");
  const isShowGoodTaskTab = sessionStorage.getItem("functionLimits")?.includes("a15");
  useEffect(() => {
    if (currenItem) {
      setVisible(true);
      if (props.isDetail) {
        getDetail();
      }
    }
  }, [currenItem]);

  useEffect(() => {
    setTabItemActive(tabActive);
  }, [tabActive]);

  const getDetail = () => {
    let host: any = sessionStorage.getItem("host");
    let url = `/mgr/ddlk/consumertask/getVideoTaskDetail?id=${props.menuType ? currenItem.taskId :currenItem.id}`;
    Request.jsonPost(EHostType.DDlk, url, {}).then((res) => {
      const data = res.data;
      let subStore: any[] = [];
      let str = `${data.adviserReferSellerId},${data.adviserUserName},${data.id}`;
      data.subStore && (subStore = [str, ...data.subStore]);
      setDetailInfo(data);
      setSubStore([...subStore]);
      setTaskType(data.taskStatus);
    });
  };

  return (
    <Drawer
      title="详情"
      visible={visible}
      width={"80%"}
      contentWrapperStyle={{
        backgroundColor: "#e1e1e1",
        height: "100%",
        padding: "0px",
      }}
      destroyOnClose
      onClose={() => {
        setVisible(false);
        props.onClear();
      }}
    >
      <div>
        <Tabs
          activeKey={tabItemActive}
          onChange={(i) => {
            setTabItemActive(i);
          }}
          type="card"
        >
          {
            props.isDetail && !props.menuType ? <TabPane tab="任务详情" key="1">
              <TaskDetailInfo detailInfo={detailInfo} />
            </TabPane> : ""
          }
          <TabPane tab="参与列表" key="2">
            <TalentList queryType={props.querType} menuType={props.menuType} worksAudit={props.worksAudit} currenItem={currenItem} subStore={subStore} />
          </TabPane>
          {
            !props.menuType && isShowGoodTaskTab && taskType == "2" ? <TabPane tab="案例展示" key="3">
              <TaskIsShow detailInfo={detailInfo} taskType={2} />
            </TabPane> : ""
          }

        </Tabs>
      </div>
    </Drawer>
  );
};
