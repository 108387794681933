import * as React from 'react';
import { Input, Form, Button, Image } from 'antd';
import './index.css';

interface page {
}

export default class ConfigureShow extends React.Component<{ parent?: any }, page> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    public render() {
        return (
            <Form name="form" className="form-container" initialValues={this.props.parent.state.rowData}>

                <div className="form-content">

                    <Form.Item label="公司名称" className="form-item ant-col-23" name="name" rules={[{ required: true, message: '公司名称不能为空！' }]}>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item label="管理员姓名" className="form-item ant-col-23" name="realName" rules={[{ required: true, message: '负责人姓名不能为空！' }]}>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item label="管理员手机号码" className="form-item ant-col-23" name="phone" rules={[{ required: true, message: '负责人手机号码不能为空！' }]}>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item label="管理员登录帐号" className="form-item ant-col-23" name="userName" rules={[{ required: true, message: '管理员账号不能为空！' }]}>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item label="登录密码" className="form-item ant-col-23" >
                        <Input placeholder="账号创建后随机生成" disabled />
                    </Form.Item>

                    <Form.Item label="营业执照(选填)" className="form-item ant-col-23" >
                        {this.props.parent.state.rowData.businessLicenseUrl !== undefined ? <Image src={this.props.parent.state.rowData.businessLicenseUrl} /> : <span style={{ color: '#b1b0b0' }}>暂无图片...</span>}
                    </Form.Item>

                    <Form.Item label="系统有效期(选填)" className="form-item ant-col-23" name="expiredTimes">
                        <Input disabled />
                    </Form.Item>
                </div>

                <div className="form-btn">
                    <Button onClick={() => this.props.parent.visiblePop(false)} >取消</Button>
                </div>
            </Form>
        );
    }
}
