/*
 * @Date: 2022-10-07 15:54:40
 * @LastEditors: litj
 * @LastEditTime: 2022-10-18 13:46:12
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/App.tsx
 */
import React, { useEffect, useState } from "react";
import "./App.css";
import zhCN from "antd/es/locale/zh_CN";
import { HashRouter, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import {Login} from "./module/root/Login";
import MainFrame from "./module/root/MainFrame";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import Request from "./module/utils/Request";

export const App = (props) => {
  const [xmgxHost, setXmgxHost] = useState<string>("https://xmgx.shwshang.com");
  const [ddlkHost, setDdlkHost] = useState<string>("https://ddlk.shwshang.com");
  const getError = () => {
    window.onerror = (msg, url, l) => {
      let txt = "There was an error on this page.\n\n";
      txt += "Error: " + msg + "\n";
      txt += "URL: " + url + "\n";
      txt += "Line: " + l + "\n\n";
      txt += "Click OK to continue.\n\n";
      console.error(txt, "txt");
      return true;
    };
  };

  // const getHost = async () => {
  //   let currentHost = window.location.origin;
  //   let host: any = sessionStorage.getItem("host");
  //   let xmgxHost = process.env.REACT_APP_REMOTE_URL_NEW;
  //   let res = await Request.get(`${xmgxHost}/operatingentity/getServerDomain`, {
  //     domain: currentHost,
  //   });
  //
  //   if (res.code == 0) {
  //     let host = {
  //       ddlk: `https://${res.data.ddlk}`,
  //       xmgx: `https://${res.data.xmgx}`,
  //     };
  //     sessionStorage.setItem("host", JSON.stringify(host));
  //     sessionStorage.setItem("xmgxHost", `https://${res.data.xmgx}`);
  //     sessionStorage.setItem("ddlkHost", `https://${res.data.ddlk}`);
  //   }
  //
  //   setXmgxHost(`https://${res.data.xmgx}`);
  //   setDdlkHost(`https://${res.data.ddlk}`);
  // };
  useEffect(() => {
    getError();
    // getHost();
  }, []);

  return (
    <ErrorBoundary>
      <HashRouter>
        <Switch>
          <ConfigProvider locale={zhCN}>
            <Route exact path="/" component={Login} />
            <Route path="/shop/" component={MainFrame} />
          </ConfigProvider>
          {/* <Route exact path="/MainFrame/:token/" component={MainFrame} /> */}
        </Switch>
      </HashRouter>
    </ErrorBoundary>
  );
};
