import {Button, Input, message, Modal, Radio, Space} from "antd";
import Item from "antd/lib/list/Item";
import {useState,useEffect} from "react";
import Request, {EHostType} from "../../../../../../utils/Request";

const { TextArea } = Input;

interface IProps {
  item: any;
  onAuditSuccess: any;
  getDarenType:any
}

export const TaskAudit = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(0);
  const [reason, setReason] = useState("");
  const [isAudit, setAudit] = useState<boolean|undefined>(false);


  const doSubmit = (itemType) => {
    if ([1, 2].includes(value) && !reason) {
      message.error("请输入不通过原因");
    }
    let host:any=sessionStorage.getItem("host");
    let url = `/mgr/ddlk/doAudit`;
    Request.jsonPost(EHostType.DDlk, url, {
      id: props.item.id,
      adviserUserId: props.item.adviserReferSellerId,
      isApproved: value == 0 ? true : false,
      notApprovedReason: value == 0 ? undefined : reason,
      auditType: value == 0 ? undefined : value,
    }).then((res) => {
      if (res?.data) {
        message.success("操作成功");
        props.onAuditSuccess();
        props.getDarenType(itemType)
        // this.props.parent.getList({}); setReason("");
        setReason("");
        setValue(0);
        setVisible(false);
      }
    });
  };  

  useEffect(() => {
    setAudit(sessionStorage.getItem("functionLimits")?.includes("a5"))
  })

  return (
    <>
    {
      isAudit ? <Button
      type="link"
      onClick={() => {
        setVisible(true);
      }}
    >
      任务审核
    </Button>:""
    }
      <Modal
        visible={visible}
        onCancel={() => {
          setReason("");
          setValue(0);
          setVisible(false);
        }}
        title="任务审核"
        footer={null}
        destroyOnClose
      >
        <Space direction="vertical">
          {/* <Alert message="视频审核通过且达标后，奖励才可发布，否则奖励不予发放" type="warning" /> */}
          <Radio.Group
            onChange={(e: any) => {
              setValue(e.target.value);
            }}
            value={value}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Space direction="vertical">
              <Radio value={0}>审核通过</Radio>
              <Radio value={1}>审核驳回商家</Radio>
              {(sessionStorage.getItem('isAgentEnabled')=='true' && props?.item.isAdvanceVersion ==0 )&&<Radio value={3}>审核驳回运营商</Radio>}
            </Space>
          </Radio.Group>
          {[1, 2].includes(value) && (
            <TextArea
              placeholder="请输入驳回原因"
              value={reason}
              style={{ width: "450px" }}
              onChange={(e: any) => {
                setReason(e.target.value);
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              style={{ width: "100px" }}
              onClick={() => {
                doSubmit(props.item.darenType);
              }}
            >
              提交
            </Button>
          </div>
        </Space>
      </Modal>
    </>
  );
};
