import React from "react";
import { gradeColumns } from "../source/column";
import TableC from "../TableC";

export default function GradeRecord(props) {
  return (
    <TableC
      columns={gradeColumns}
      data={props.data}
      tableHeight={0}
      pagination={false}
    />
  );
}
