/*
 * @Date: 2022-04-11 14:41:03
 * @LastEditors: litj
 * @LastEditTime: 2022-07-19 20:37:45
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\PromotionMange\Cloud\detail\index.tsx
 */
import { Drawer, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Request, { EHostType } from "../../../../../utils/Request";
import { TaskDetailInfo } from "./info";
import ShareList from "./shareList";
import { TalentList } from "./talentList";
import TaskIsShow from "../../components/taskIsShow";

const { TabPane } = Tabs;
interface IProps {
  currenItem?: any;
  onClear?: any;
  tabActive?: any;
  isShareList: boolean | undefined;
  isDetail: boolean | undefined;
  isLimits: boolean | undefined;
  isTalentList: boolean | undefined;
  queryType: any;
  menuType:any
}
function isEmptyObj(obj) {
  console.log(obj, 'obj')
  return JSON.stringify(obj) === '{}'
}


export const TaskListDetail = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const [detailInfo, setDetailInfo] = useState({});
  const [taskType,setTaskType] = useState("");
  const { currenItem, tabActive } = props;
  const [tabItemActive, setTabItemActive] = useState<string>("1");
  const isShowGoodTaskTab = sessionStorage.getItem("functionLimits")?.includes("a15");
  useEffect(() => {
    if (currenItem) {
      getDetail();
      setVisible(true);
    }
  }, [currenItem]);

  useEffect(() => {
    setTabItemActive(tabActive);
  }, [tabActive]);
  const getDetail = () => {
    if(props.isDetail){
      let host: any = sessionStorage.getItem("host");
      let url = `/mgr/ddlk/getVideoTaskDetail?id=${ props.menuType ? currenItem.taskId:currenItem.id}`;
      Request.jsonPost(EHostType.DDlk, url, {}).then((res) => {
        const data = res.data;
        if(!res.data?.wordsMaterial || Object.keys(res.data.wordsMaterial).length < 1){
            data.wordsMaterial = "套餐名称：多多小店嗨吃（2-3人餐）\n\n门店名称：多多小店\n\n地址：浙江省杭州市上城区星云大厦\n\n皮皮虾 3只基围虾 6只 娃娃菜（锅内）"+
            "金针菇（锅内）\n\n总价 xxx\n\n活动价 xxx\n\n抖音团购链接： 门店特色：多多小店超多特色锅底。有香辣锅，香辣荔枝锅（套餐图的锅底），番茄锅，蒜香锅，糟粕醋锅。香辣荔枝锅鲜咸甜辣，锅底放的四川辣椒，海鲜都是在海口海鲜大世界和东门广场进的货，没有速冻，都非常鲜甜。开业两年，营业时间下午六点到凌晨一点。椰子冻是用斑斓叶做的，锅是铜的"
          setDetailInfo(data || {});
        }else{
          setDetailInfo(res.data || {});
        }
        setTaskType(data.taskStatus);
      });
    }
  };
  return (
    <Drawer
      title="详情"
      visible={visible}
      width={"80%"}
      contentWrapperStyle={{
        backgroundColor: "#e1e1e1",
        height: "100%",
        padding: "0px",
      }}
      destroyOnClose
      onClose={() => {
        setVisible(false);
        props.onClear();
      }}
    >
      <div>
        <Tabs
          activeKey={tabItemActive}
          onChange={(i) => {
            setTabItemActive(i);
          }}
          type="card"
        >
          {props.isDetail && !props.menuType ? <TabPane tab="任务详情" key="1">
            <TaskDetailInfo detailInfo={{ ...detailInfo }} />
          </TabPane> : ""
          }

          <TabPane tab="参与列表" key="2">
            <TalentList queryType={props.queryType} isLimits={props.isLimits} currenItem={currenItem} menuType={props.menuType} />
          </TabPane>

          {
            props.isShareList  && !props.menuType ? <TabPane tab="分享列表" key="3">
              <ShareList detailInfo={detailInfo} currenItem={currenItem} />
            </TabPane> : ""
          }
          {
            
            !props.menuType  && taskType == "3" && isShowGoodTaskTab  ?  <TabPane tab="案例展示" key="4">
            <TaskIsShow detailInfo={detailInfo} taskType={1}/>
            {/* <TalentList queryType={props.queryType} isLimits={props.isLimits} currenItem={currenItem} /> */}
          </TabPane>:null
          }
          

        </Tabs>
      </div>
    </Drawer>
  );
};
