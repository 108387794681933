import {  Space, Typography } from "antd";
import React from "react";
import { dyGroupbyLevelVerifyStatus, darenLevel } from '../../helper.ts'
/**
 * @Date: 2022-07-05 11:03:19
 * @LastEditors: litj
 * @LastEditTime: 2022-07-07 19:58:48
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\DarenManage\source\column.js
 */
// 主列表页数据
export const getColumns = function (audit, goDetail,setShow) {
  return [
  {
    title: "用户id",
    dataIndex: "darenId",
    key: "darenId",
    align: "center",
    width: "230px",
    fixed: 'left'
  },
  {
    title: "昵称",
    dataIndex: "darenNick",
    key: "darenNick",
    align: "center",
    width: "160px",
    fixed: 'left'
  },
  {
    title: "达人手机号码",
    dataIndex: "mobile",
    key: "mobile",
    align: "center",
    width: "110px",
  },
  {
    title: "微信号",
    dataIndex: "weixin",
    key: "weixin",
    align: "center",
    width: "130px",
  },
  {
    title: "等级",
    dataIndex: "dyGroupbyLevel",
    key: "dyGroupbyLevel",
    align: "center",
    width: "80px",
    render: (text, record, index) => {
      return darenLevel[text];
    },
  },
  {
    title: "粉丝数",
    key: "fansCount",
    dataIndex: "fansCount",
    align: "center",
    width: "100px",
  },
  {
    title: "成为时间",
    key: "createdTime",
    dataIndex: "createdTime",
    align: "center",
    width: "150px",
  },
  {
    title: "邀请人",
    key: "inviterName",
    dataIndex: "inviterName",
    align: "center",
    width: "150px",
  },
  {
      title: "状态",
    key: "dyGroupbyLevelVerifyStatus",
    dataIndex: "dyGroupbyLevelVerifyStatus",
    align: "center",
    width: "120px",
    render: (text, record, index) => {
      return dyGroupbyLevelVerifyStatus[text];
    },
  },
  {
    title: "操作",
    key: "room_code8",
    dataIndex: "room_code8",
    width: "100px",
    align: "center",
    fixed: "right",
    render: (text, record, index) => {
      return <div>
         <Space size="middle">
          <Typography.Link  onClick={() => {setShow(record.isShow ? 0 : 1,record.darenId)}}  >{record.isShow ? '隐藏':'展示'}</Typography.Link>
          <Typography.Link onClick={() => goDetail(record.darenId)}> 详情</Typography.Link>
          {record.dyGroupbyLevelVerifyStatus === 1 ? <Typography.Link onClick={() => audit(record.darenId, record)}>审核</Typography.Link> : ''}
        </Space>
      </div>
    },
  },
]
}

// 详情页列表数据
export const gradeColumns = [
   {
    title: "变更时间",
    dataIndex: "changeTime",
    key: "changeTime",
    align: "center",
    width: "100px",
  },
  {
    title: "操作人",
    dataIndex: "operatorName",
    key: "operatorName",
    align: "center",
    width: "110px",
  },
  {
    title: "变更记录",
    dataIndex: "changeContent",
    key: "changeContent",
    align: "center",
    width: "130px",
  },
]

