import {Button, DatePicker, Form, Input, message, Modal, Radio, Space} from "antd";
import {useState} from "react";
import Request, {EHostType} from "../../utils/Request";
import * as React from "react";

// const { DatePicker } = DatePicker;

interface IProps {
    item: any;
    onAuditSuccess: any;
}
const formRef: any = React.createRef();

export const TestAccount = (props: IProps) => {
    const [form] = Form.useForm();
    const [value, setValue] = useState(0);
    const onReset = () => {
        form.resetFields();
    };
    const submit = async(values) => {
        let formData = {...values,testEndTime:values.testEndTime.format("YYYY-MM-DD hh:mm:ss")};

        let host:any=sessionStorage.getItem("host");
        let url = `/op/makeTestAccountTag?accountType=${values.accountType}&&accountId=${values.accountId}&&testEndTime=${values.testEndTime.format("YYYY-MM-DD hh:mm:ss")}`;

        Request.jsonPost(EHostType.XMGX, url,{}).then((res) => {
            if (res.code==0) {
                message.success("操作成功");
            }
        });
    };

    return (
        <div style={{background:"#fff",padding:'40px',height:'100%'}}>
            <Form name="form" onFinish={submit} form={form}>
                    <Form.Item
                        label="账号类型"
                        name="accountType"
                        rules={[{ required: true, message: "请选择账号类型！" }]}>
                        <Radio.Group
                            onChange={(e: any) => {setValue(e.target.value);}}
                            value={value}
                            style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Radio value={0}>运营</Radio>
                                <Radio value={1}>代理商</Radio>
                                <Radio value={2}>商家</Radio>
                                <Radio value={3}>用户(达人)</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="账号ID"
                        name="accountId"
                        rules={[{ required: true, message: "账号ID不能为空！" }]}>
                        <Input allowClear style={{width:'200px'}} />
                    </Form.Item>

                    <Form.Item label="测试结束时间" name="testEndTime" rules={[{ required: true, message: "测试结束时间不能为空！" }]}>
                        <DatePicker style={{width:'300px'}} format="YYYY-MM-DD hh:mm:ss" />
                    </Form.Item>

                    {/*<div*/}
                    {/*    style={{*/}
                    {/*        display: "flex",*/}
                    {/*        flexDirection: "row",*/}
                    {/*        justifyContent: "flex-end",*/}
                    {/*    }}>*/}
                        <Form.Item>
                            <Button type="primary" style={{ width: "120px" }} htmlType="button" onClick={onReset}>
                                取消测试标记
                            </Button>
                            <Button type="primary" style={{ width: "120px",marginLeft:'20px' }} htmlType="submit" >
                                打测试标记
                            </Button>
                        </Form.Item>
                    {/*</div>*/}
            </Form>
        </div>
    );
};
