/*
 * @Date: 2022-04-11 14:41:03
 * @LastEditors: litj
 * @LastEditTime: 2022-07-05 14:21:55
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\route\MainFrameRoute.tsx
 */
import React from "react";
import { Route } from "react-router";
import BusinessManagement from "../page/BusinessManagement"; //商户管理
import MiniprogramHome from "../page/talentHome"; //达人小程序首页管理
import GoodTaskExamples from '../page/goodTaskExamples'
import AgentManagement from "../page/AgentManagement"; //代理商管理
import OperationConfiguration from "../page/OperationConfiguration"; //运营主体管理
import UserManagement from "../page/UserManagement"; //账号管理
import { PromotionMange } from "../page/DDLK/PromotionMange";
import ArticalMange from "../page/DDLK/ArticalMange";
import Advertise from "../page/DDLK/Advertise";
import DarenManage from "../page/DDLK/DarenManage";
import CloudAmountConfig from "../page/DDLK/CloudAmountConfig";
import {VideoStatistics} from "../page/VideoStatistics";//视频统计
import {DomainConfig} from "../page/OperationConfiguration/config/index";//域名注册/备案
import {SubjectConfig} from "../page/OperationConfiguration/config/subjectConfig";//运营主体配置
import {DomainRegistration} from "../page/OperationConfiguration/config/domainRegistration";
import {CreateSubject} from "../page/OperationConfiguration/config/createSubject";
import {CreateAgent} from "../page/OperationConfiguration/config/createAgent";
import {SellerMiniproConfig} from "../page/OperationConfiguration/config/sellerMiniproConfig";
import {TalentMiniproConfig} from "../page/OperationConfiguration/config/talentMiniproConfig";
import {TalentDouyinMiniproConfig} from "../page/OperationConfiguration/config/talentDouyinMiniproConfig";
import {PublicConfig} from "../page/OperationConfiguration/config/publicConfig";
import {WechatPayConfig} from "../page/OperationConfiguration/config/wechatPayConfig";
import {WechatWithdrowConfig} from "../page/OperationConfiguration/config/wechatWithdrowConfig";
import {AlipayConfig} from "../page/OperationConfiguration/config/alipayConfig";
import {AgreementConfig} from "../page/OperationConfiguration/config/agreementConfig";
import {AgentQrcode} from "../page/OperationConfiguration/config/agentQrcode";
import {YunPianConfig} from "../page/OperationConfiguration/config/yunpianConfig";
import {FeeConfig} from "../page/OperationConfiguration/config/feeConfig";

import BalanceList from "../page/finance/BalanceList";
import ViewBalance from "../page/finance/ViewBalance";
import ViewMerchant from "../page/finance/ViewMerchant";
import WithdrawApproval from "../page/finance/WithdrawApproval";
import WithdrawSetting from "../page/finance/WithdrawSetting";
import ViewWithdraw from "../page/finance/ViewWithdraw";
import {TestAccount}  from "../page/testAccount";


import MerchantAdd from "../page/BusinessManagement/MerchantAdd"; //商户管理
import SettingService from "../page/BusinessManagement/SettingService"; //服务配置
import ActivityManagement from "../page/ActivityManagement"; //活动管理
import TopicManagement from "../page/TopicManagement"; //话题管理
import VideoReleaseRecord from "../page/VideoReleaseRecord"; //视频发布记录
import CardRecord from "../page/CardRecord"; //卡券记录

import MiniVersion from "../page/MiniVersion";
import MiniDetailVersion from "../page/MiniVersion/detail";
import MiniApproveVersion from "../page/MiniVersion/approve";
import {Input} from "antd";


// import CloudAmountConifg from "../page/DDLk/CloudAmountConifg";
// import CloudAmountConfig from "../page/DDLk/CloudAmountConfig";
// import ArticalMangeAdd from "../page/DDLK/ArticalMange/Add";

export default class MainFrameRoute extends React.Component<{}, {}> {
  render() {
    // @ts-ignore
      return (
      <>
        <Route exact path="/shop/business" component={BusinessManagement} />
        <Route exact path="/shop/agent" component={AgentManagement} />
        <Route exact path="/shop/agent/:agentId" component={AgentManagement} />
        <Route exact path="/shop/PromotionMange" component={PromotionMange} />
        <Route exact path="/shop/ArticalMange" component={ArticalMange} />
        <Route exact path="/shop/advertise" component={Advertise} />
        <Route exact path="/shop/daren" component={DarenManage} />
        <Route exact path="/shop/config" component={CloudAmountConfig} />
        <Route exact path="/shop/operation" component={OperationConfiguration}/>
        <Route exact path="/shop/user" component={UserManagement} />
        <Route exact path="/shop/videoStatistics" component={VideoStatistics} />
        <Route exact path="/shop/finance/balance" component={BalanceList}/>
        <Route exact path="/shop/finance/view-balance/:id" component={ViewBalance}/>
        <Route exact path="/shop/finance/viewMerchant/:sellerId" component={ViewMerchant}/>
        <Route exact path="/shop/finance/withdraw-approval" component={WithdrawApproval}/>
        <Route exact path="/shop/finance/view/:flowId" component={ViewWithdraw}/>
        <Route exact path="/shop/finance/withdraw-setting" component={WithdrawSetting}/>
        <Route exact path="/shop/goodTaskExamples" component={GoodTaskExamples}/>
        {/* <Route exact path="/shop/darenHome" component={MiniprogramHome} />
         */}
         <Route exact path="/shop/darenHome" component={MiniprogramHome}></Route>
        <Route exact path="/shop/config/index/:operatingEntityId" component={DomainConfig}/>
        <Route exact path="/shop/config/subjectConfig/:operatingEntityId" component={SubjectConfig}/>
        <Route exact path="/shop/config/domainRegistration/:operatingEntityId" component={DomainRegistration}/>
        <Route exact path="/shop/config/createSubject/:operatingEntityId" component={CreateSubject}/>
        <Route exact path="/shop/config/createAgent/:operatingEntityId" component={CreateAgent}/>
        <Route exact path="/shop/config/sellerMiniproConfig/:operatingEntityId" component={SellerMiniproConfig}/>
        <Route exact path="/shop/config/talentMiniproConfig/:operatingEntityId" component={TalentMiniproConfig}/>
        <Route exact path="/shop/config/talentDouyinMiniproConfig/:operatingEntityId" component={TalentDouyinMiniproConfig}/>
        <Route exact path="/shop/config/publicConfig/:operatingEntityId" component={PublicConfig}/>
        <Route exact path="/shop/config/wechatPayConfig/:operatingEntityId" component={WechatPayConfig}/>
        <Route exact path="/shop/config/wechatWithdrowConfig/:operatingEntityId" component={WechatWithdrowConfig}/>
        <Route exact path="/shop/config/alipayConfig/:operatingEntityId" component={AlipayConfig}/>
        <Route exact path="/shop/config/agreementConfig/:operatingEntityId" component={AgreementConfig}/>
        <Route exact path="/shop/config/agentQrcode/:operatingEntityId" component={AgentQrcode}/>
        <Route exact path="/shop/config/FeeConfig/:operatingEntityId" component={FeeConfig}/>
        <Route exact path="/shop/config/yunpianConfig/:operatingEntityId" component={YunPianConfig}/>
        <Route exact path="/shop/testAccount" component={TestAccount}/>

        {/*<Route exact path="/shop/activity" component={ActivityManagement} />*/}
        {/*<Route exact path="/shop/topic" component={TopicManagement} />*/}
        {/*<Route exact path="/shop/video" component={VideoReleaseRecord} />*/}
        {/*<Route exact path="/shop/video/:videoId" component={VideoReleaseRecord}/>*/}
        {/*<Route exact path="/shop/card" component={CardRecord} />*/}
        {/*<Route exact path="/shop/version" component={MiniVersion} />*/}
        {/*<Route exact path="/shop/version/detail/:sellerId/:appId" component={MiniDetailVersion}/>*/}
        {/*<Route exact path="/shop/version/approve/:sellerId/:templateId" component={MiniApproveVersion}/>*/}
        {/*<Route exact path="/shop/version" component={MiniVersion} />*/}
        {/* <Route exact path="/shop/ArticalMangeAdd" component={ArticalMangeAdd} /> */}
      </>
    );
  }
}
