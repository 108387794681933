/*
 * @Date: 2022-04-29 21:58:51
 * @LastEditors: litj
 * @LastEditTime: 2022-05-16 18:47:44
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\PromotionMange\Consumer\index.tsx
 */
import React, { useEffect, useState } from "react";
import { TaskListDetail } from "./detail";
import { TaskListFilter } from "./filter";
import { TaskListTable } from "./table";
import { UserContext } from "../../../../root/MainFrame";
import SubmitModal from "../components/submitModal";
import Request,{ EHostType } from "../../../../utils/Request";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { message, Modal } from "antd";
export const EmployeePromotion = (props) => {
  const [currenItem, setCurrentItem] = useState();
  const [searchParams, setSearchParams] = useState({});
  const [tabActive, setTabActive] = useState("1");
  const [isDetail, setIsDetail] = useState<boolean | undefined>(true);
  const [isLimits, setIsLimits] = useState<boolean | undefined>(true);
  const [worksAudit, setWorksAudit] = useState<boolean | undefined>(true);
  const [queryType, setQueryType] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [detailId, setDetailId] = useState("");
  const [flag, setFlag] = useState(false);
  const [taskInfo,setTaskInfo] = useState("");
  const getQueryType = (key) => {
    setQueryType(key);
  }
  const isShowModal = (res, i, item?) => {
    if (item) {
      let infoData = JSON.parse(item);
      setTaskInfo(infoData);
      if (!infoData.isAppShow || res === true) {
        setIsShow(res)
      } else {
        Modal.confirm({
          title: '提示',
          icon: <ExclamationCircleFilled />,
          content: '确认取消在商家端首页展示吗?',
          centered: true,
          onOk() {
            let params = {
              id: infoData.id,
              orderNo: infoData.orderNo,
              adviserSellerDesc: infoData.adviserSellerDesc,
              saleVolume: infoData.saleVolume,
              saleNo: infoData.saleNo,
              adviserUserShowUrl: infoData.adviserUserShowUrl,
              topProduct: infoData.topProduct,
              videoMaterial: infoData.videoMaterial,
              drainagesProduct: infoData.drainagesProduct,
              mainProduct: infoData.mainProduct,
              profitProduct: infoData.profitProduct,
              summary: infoData.summary,
              logo:infoData.logo,
              isAppShow: 0
            }
            let url = `/mgr/ddlk/excellentTask/updateExcellentTaskDetail`;
            // props.isShowModal(false)
            Request.jsonPost(EHostType.DDlk, url, params).then(res => {
              if (res.code == 0) {
                message.open({
                  type: 'success',
                  content: '提交成功',
                });
                setFlag(true)
              }
              setFlag(false)
            })
          },
          onCancel() { },
        });
      }
    }else{
      setIsShow(res);
    }
    if (i) {
      setDetailId(i)
    }
  }
  const reGetList = (res) => {
    setFlag(true)
    setFlag(false)
  }

  useEffect(() => {
    setIsDetail(sessionStorage.getItem("functionLimits")?.includes("a11"));
    setIsLimits(sessionStorage.getItem("functionLimits")?.includes("a6"));
    setWorksAudit(sessionStorage.getItem("functionLimits")?.includes("a8"));
  })
  return (
    <div>
      <UserContext.Consumer>
        {
          (menuType) => (
            <div>
              {
                !menuType ? <TaskListFilter querType={queryType} onSearch={(item: any) => { setSearchParams(item); }} tab={props.tab} /> : ""
              }
              <TaskListTable tab={props.tab} searchParams={searchParams}
                flag={flag}
                menuType={menuType}
                isDetail={isDetail}
                isLimits={isLimits}
                getQueryKey={getQueryType}
                onOpenDetail={(item: any, active: string) => {
                  setCurrentItem(item);
                  setTabActive(active);
                }}
                isShowModal={isShowModal}
              />
              <TaskListDetail
                menuType={menuType}
                querType={queryType}
                isDetail={isDetail}
                worksAudit={worksAudit}
                currenItem={currenItem}
                tabActive={tabActive}
                onClear={() => {
                  setCurrentItem(undefined);
                }}
              />
             <SubmitModal isShowModal={isShowModal} id={detailId} taskInfo={taskInfo} reGetList={reGetList} isShow={isShow} />
            </div>
          )
        }
      </UserContext.Consumer>

    </div>
  );
};
