/*
 * @Date: 2022-04-11 14:41:03
 * @LastEditors: litj
 * @LastEditTime: 2022-07-07 16:32:13
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\helper.ts
 */
export const comboTypeMap = { "1": "普通套餐(自备素材)", "2": "省心套餐" };

export const taskStatusMap = {
  "1": "待配置",
  "2": "待审核",
  "3": "审核通过",
  "4": "审核未通过",
  "5": "已取消",
  "6": "待运营商确认"
};

export const customerTaskStatusMap = {
  "1": "待审核",
  "2": "审核通过",
  "3": "审核未通过",
  "4": "暂停",
  "5": "已关闭",
};

export const verifyStatusMap = { 1: "待审核", 2: "审核通过", 3: "审核未通过" ,4:"待运营商确认"};

export const verifyStatus = { 1: "待结算", 2: "已结算", 3: "未达标" };

export const darenLevel = ["LV0", "LV1", "LV2", "LV3", "LV4", "LV5", "LV6"];

export const dyGroupbyLevelVerifyStatus = {
  1: " 待审核",
  2: "审核通过",
  3: "待修改",
};
