import React, { Component } from "react";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default class PicturesWall extends Component<
  {
    fileList: any[];
    // upLoadFile: (file: any) => void;
    handleChange: (info: any) => void;
    maxCount: number;
    showUploadList?: any;
  },
  {}
> {
  state = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  };
  beforeUpload = (file) => {
    // const isPNG = file.type === 'image/png';
    // if (!isPNG) {
    //   message.error(`${file.name} is not a png file`);
    // }
    // return isPNG || Upload.LIST_IGNORE;
    return false;
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      // ${filePrefix}
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  // handleChange = (info) => {
  //   console.log(info);
  //   if (info.file.status === "removed" || info.file.status === "done") {
  //     this.props.upLoadFile(
  //       info.fileList.length ? info.fileList[0].originFileObj : ""
  //     );
  //   }
  // console.log(fileList);
  // const formdata = new FormData();
  // formdata.append("file", fileList[0].originFileObj);
  // console.log(formdata);
  //   this.setState({ fileList: info.fileList });
  // };

  render() {
    const { previewVisible, previewImage, previewTitle } = this.state;
    const { fileList } = this.props;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        {/* eslint-disable */}
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.props.handleChange}
          beforeUpload={this.beforeUpload}
          maxCount={this.props.maxCount}
          accept=".jpg, .jpeg, .png, .psd, .gif, .tiff"
          showUploadList={this.props.showUploadList || {}}>
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}>
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </>
    );
  }
}
