import { Button, Col, Descriptions, Divider, Table } from "antd";
import React, {useState} from "react";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import Request from '../../utils/SellerRequest';
import {EHostType} from "../../utils/Request";

interface Props extends RouteComponentProps {}

export default class ViewBalance extends React.Component<Props, { data: any, pageSize:number, total: number, sellerName:string, balance:number }> {

    constructor(props) {
        super(props);
        this.state = {
            data: '',
            pageSize:10,
            total: 0,
            sellerName:'',
            balance:0
        };
    }

    componentDidMount() {
        this.fetchData(1);
    }

    async fetchData(currentPage) {
        console.log(currentPage);
        let sellerId = this.props.match.params['sellerId'];
        let res = await Request.get(EHostType.XMGX,`/seller/balance/getBalanceListByAlliance/${sellerId}/${currentPage}/${this.state.pageSize}`);
        if(res.code===0) {
            this.setState({
                data: res.data?.byAllianceLists?.records,
                total: res.data?.byAllianceLists?.total,
                sellerName: res.data?.sellerName,
                balance: res?.data?.balance
            });
        }
    }

    render() {
        const changeTypeMap:any = {
            1: '余额充值',
            2: '分享商品佣金',
            3: '购物消费',
            4: '计单退款返还',
            5: '股东分红',
            6: '员工分红',
            7: '提现',
            8: '提现退回',
            9: '系统调整',
            10: '盟主分红',
            11: '联盟商品订单销售'
        };
        const columns:any= [
            {
                title: '变动时间',
                dataIndex: 'createdTime' ,
                align:'center'
            },
            {
                title: '总余额变动/变动后(元)',
                dataIndex: 'changeAmount' ,
                align:'center',
                render: (text,record) => (
                    <div><span>{record.changeAmount>0?`+ ${record?.changeAmount}`:record?.changeAmount}</span> / { record?.balanceAfterChange}</div>
                )
            },
            {
                title: '变动原因',
                dataIndex: 'changeType' ,
                align:'center',
                render: (text,record) => <div>{changeTypeMap[record?.changeType]} {record?.changeType===11?`【订单ID: ${record?.orderId}】`:''} {record?.changeType===7?`【提现ID: ${record?.flowId}】`:''} </div>
            }
        ];

        return <div>
            <h1>共商商圈-余额管理 <Button type="link"><Link to="/shop/finance/balance">返回</Link></Button></h1>
            <Divider />

            <Descriptions>
                <Descriptions.Item label="商家ID">{this.props.match.params['sellerId']}</Descriptions.Item>
                <Descriptions.Item label="商家名称">{this.state.sellerName}</Descriptions.Item>
                <Descriptions.Item label="账户余额">{this.state.balance/100}</Descriptions.Item>
            </Descriptions>
            <br />

            <Table
                dataSource={this.state.data}
                columns={columns}
                pagination={{
                    pageSize:this.state.pageSize,
                    showQuickJumper:true,
                    total:this.state.total,
                    onChange:(page:number)=>{ this.fetchData(page) }
                }}/>
        </div>;
    }
}

