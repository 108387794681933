import React, { useCallback, useState, useEffect } from "react";
import { Button, Checkbox, Form, Input,Radio } from 'antd';

interface IProps {
    configInfo: Object;
}
export const PublicConfig=(props)=>{
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };


    return (
        <>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{...props.configInfo,...props.configInfo.mpConfig}}
                onFinish={onFinish}
                autoComplete="off">
                <Form.Item label="APPID" name="appId" rules={[{ required: false, message: '请输入APPID' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item label="Secret" name="secret" rules={[{ required: false, message: '请输入Secret' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item label="token" name="token" rules={[{ required: false, message: '请输入token' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item label="aesKey" name="aesKey" rules={[{ required: false, message: '请输入aseKey' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item label="是否已启用" name="isServerConfigEnabled">
                    <Radio.Group>
                        <Radio value="true">是</Radio>
                        <Radio value="false">否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="通知达人接单的模板消息id" name="visitShopTaskNotifyDarenTemplateId" rules={[{ required: false, message: '请输入通知达人接单的模板消息id' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item label="是否已关联商家端/达人端小程序" name="isMiniProgramRelated">
                    <Radio.Group>
                        <Radio value="true">是</Radio>
                        <Radio value="false">否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="是否已设置开发白名单IP" name="isWhiteIpConfiged">
                    <Radio.Group>
                        <Radio value="true">是</Radio>
                        <Radio value="false">否</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </>
    );
}
