import FormSearch from "../FormSearch";
import TableC from "../TableC";
import {useState, useEffect, useCallback, useRef} from "react";
import { getList, dataDelete } from "../source/action";
import { getColumns } from "../source/column";
import { Add } from "../Add";
import type { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import type { FilterValue, SorterResult } from "antd/lib/table/interface";
import Pagination from "antd/es/pagination";


export default function CloudAmountConfig() {
  const [visible, setVisible] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [params, setParams] = useState({});

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const pageNo=useRef(1);
  const [dataInfo, setDataInfo] = useState<any>({});
  let ids;
  // 点击添加修改
  const onAdd = (id?, data?) => {
    // id && (ids = id);
    let params = { ...data };
    if (data) {
      params.residence = [data.adviserProvince, data.adviserCity];
    }
    setDataInfo(params);
    setVisible(true);
  };
  // 点击删除
  const handleDelete = async function (id) {
    const res = await dataDelete(id);
    if (res.code === 0) {
      onFinish();
    }
  };
  const [columns] = useState(getColumns(onAdd, handleDelete));
  const [data, setData] = useState([]);
  const setList = (data) => {
    setData(data);
  };
  // 渲染列表数据
  const onFinish = async (obj = {}) => {
    setTableLoading(true);
    pagination.current= pageNo.current;
    const data = Object.assign(params, pagination, obj);
    const res = await getList(setList, data);
    setList(res.data);
    //debugger;
    setPagination({ ...pagination, total: res.totalCount });
    setTableLoading(false);
  };

  // const onSearch = (values) => {};
  useEffect(() => {
    onFinish();
  },  []);

  return (
    <>
      <FormSearch onFinish={onFinish} onAdd={onAdd}></FormSearch>
      <TableC
        data={data}
        columns={columns}
        handleTableChange={(gotoPageNo)=>{
          pageNo.current=gotoPageNo.pageNo;
          onFinish();
        }}
        tableHeight={0}
        pagination={pagination}
        tableLoading={tableLoading}
      ></TableC>
      <Add
        visible={visible}
        setVisible={setVisible}
        getList={onFinish}
        id={ids}
        dataInfo={dataInfo}
      />
    </>
  );
}
