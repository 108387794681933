import axios from "axios";
import { Modal, Button, Space, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const AxiosWrap: any = (type: EHostType) => {
  const request: any = axios.create({
    baseURL: "",
    timeout: 300000,
  });
  // 请求拦截器
  request.interceptors.request.use(
    function (config) {
      config.headers.Authorization = sessionStorage.getItem("token");
      let host = sessionStorage.getItem("host");

      host = host && JSON.parse(host);
      if (type == EHostType.NONE) {
        return config;
      } else {
        if (host && host[type]) {
          config.baseURL = host[type];
          return config;
        } else {
          let currentHost = window.location.origin;
          let xmgxHost = process.env.REACT_APP_REMOTE_URL_NEW;
          return new Promise((reslove, reject) => {
            fetch(
              `${xmgxHost}/operatingentity/getServerDomain?domain=${currentHost}`
            )
              .then((res) => res.json())
              .then((res) => {
                if (res.code == 0) {
                  let hostOrg = {
                    ddlk: `https://${res.data.ddlk}`,
                    xmgx: `https://${res.data.xmgx}`,
                  };
                  sessionStorage.setItem("host", JSON.stringify(hostOrg));
                  config.baseURL = hostOrg[type];
                  reslove(config);
                }
              });
          });
        }
      }
    },
    function (error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    }
  );
  // 响应拦截器
  request.interceptors.response.use(
    function (response: any) {
      if (
        response.status === 200 &&
        response?.headers["content-length"] === "0"
      ) {
        response.data = {};
        return response;
      }
      if (response.data.code === 0 || response.data.isSuccess) {
        return response;
      } else {
        if (response.data.code == "401") {
          confirm({
            title: `${response.data.message}`,
            content: ``,
            okText: "去登录",
            onOk() {
              window.location.href = "/";
            },
            onCancel() {
              Modal.destroyAll();
            },
          });
        } else {
          message.error(response.data.message || response.data.errroMsg);
        }
      }
      return response;
    },
    function (error: any) {
      console.log(error);
      return Promise.reject(error);
    }
  );

  return request;
};

export enum EHostType {
  DDlk = "ddlk",
  XMGX = "xmgx",
  NONE = "none",
}

export default class Request {
  public static async get(hostType: EHostType, url: string, params?: any) {
    try {
      let res = await AxiosWrap(hostType).get(url, {
        headers: { Authorization: sessionStorage.getItem("token") },
        params,
      });
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }
  public static async putOss(
    type: EHostType,
    url: string,
    params: any,
    contentType: string,
    cb
  ) {
    // try {
    let res = await AxiosWrap(type).put(url, params, {
      headers: {
        Authorization: sessionStorage.getItem("token"),
        // "Content-Type": "application/octet-stream",
        "Content-Type": contentType,
      },
      onUploadProgress: (progressEvent) => {
        let process = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
        cb(process);
        // arr[0].percent = process;
        // console.log(arr[0].percent);
      },
    });
    return res.data;
    // } catch (err) {
    //   return { success: false, message: err.message };
    // }
  }
  public static async delete(hostType: EHostType, url: string) {
    try {
      let res = await AxiosWrap(hostType).delete(url, {
        headers: { Authorization: sessionStorage.getItem("token") },
      });
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }
  public static async post(hostType: EHostType, url: string, params: any) {
    try {
      let res = await AxiosWrap(hostType).post(url, params, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }
  public static async postFormData(
    hostType: EHostType,
    url: string,
    params: any
  ) {
    try {
      let res = await AxiosWrap(hostType).post(url, params, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "multipart/form-data ",
        },
      });
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }
  public static async postBlob(hostType: EHostType, url: string, params: any) {
    try {
      let res = await AxiosWrap(hostType).post(url, params, {
        responseType: "blob",
        headers: { "Content-Type": "application/json" },
      });
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }

  public static async jsonPost(hostType: EHostType, url: string, params: any) {
    try {
      let res = await AxiosWrap(hostType).post(url, params, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (err:any) {
      return { success: false, message: err.message };
    }
  }
}
