import * as React from "react";
import {Button, Cascader, DatePicker, Form, Input, message, Modal, Radio, Select,} from "antd";
import "./index.css";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import Request, {EHostType} from "../../utils/Request";
import address from "../../assets/js/area.json";

interface page {
  fileList: any;
  uploading: boolean;
  loadings: boolean;
}

export default class AgentAdd extends React.Component<{ parent?: any }, page> {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
      loadings: false,
    };
  }

  onFinish = (value) => {
    console.log(value);
    for (let key in value) {
      if (value[key] === undefined || value[key] === "") delete value[key];
    }
    const params = { ...value };
    params["expiredTime"] = moment(value.expiredTime).format("YYYY-MM-DD");
    const { productCode, level, expiredTime } = params;
    params["products"] = [
      {
        productCode,
        level,
        expiredTime,
      },
    ];
    params["province"] = value.residence[0];
    params["city"] = value.residence[1];
    delete params["residence"];
    delete params["productCode"];
    delete params["level"];
    delete params["expiredTime"];
    this.setState({
      uploading: true,
    });
    let _this = this;
    Modal.confirm({
      title: "是否确认提交",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认提交？</p>,
      async onOk() {
        Request.jsonPost(EHostType.XMGX, `/op/agent/saveOrUpdate`, params).then((ret) => {
          if (ret.code == 0) {
            _this.props.parent.visiblePop(false);
            _this.props.parent.getData({ size: 10, current: 1 });
            message.success("提交成功");
            _this.props.parent.setState({ loadings: false });
          }
        });
      },
    });
  };

  public render() {
    const { fileList } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    return (
      <Form
        name="form"
        className="form-container"
        onFinish={this.onFinish}
        initialValues={{ productCode: "ddlk", level: "2:2" }}
      >
        <div className="form-content">
          <Form.Item
            label="代理商姓名"
            className="form-item ant-col-23"
            name="name"
            rules={[{ required: true, message: "负责人姓名不能为空！" }]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="代理商手机号码"
            className="form-item ant-col-23"
            name="phone"
            rules={[{ required: true, message: "代理商手机号码不能为空！" }]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="推荐人id"
            name="invitedByAgentId"
            className="form-item ant-col-23"
          >
            <Input type="number" min="1" />
          </Form.Item>

          <Form.Item
            label="代理产品"
            className="hidden"
            name="productCode"
          >
            <Select>
              <Select.Option value="ddlk">多多来客</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="代理商等级"
            className="form-radio"
            name="level"
            rules={[{ required: true, message: "代理商等级不能为空！" }]}
          >
            <Radio.Group>
              <Radio value="2:2">城市运营商</Radio>
              <Radio value="2:1">推广员</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="代理有效期"
            className="form-item ant-col-23"
            name="expiredTime"
            rules={[{ required: true, message: "代理有效期不能为空！" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="所在地区"
            name="residence"
            rules={[
              {
                type: "array",
                required: true,
                message: "所在地区不能为空！",
              },
            ]}
            className="form-item ant-col-23 flex"
          >
            <Cascader
              options={address}
              placeholder="请选择地级市"
              style={{ width: "100%" }}
              changeOnSelect
            />
          </Form.Item>
        </div>

        <div className="form-btn">
          <Button type="primary" htmlType="submit">
            提交
          </Button>
          <Button onClick={() => this.props.parent.visiblePop(false)}>
            取消
          </Button>
        </div>
      </Form>
    );
  }
}
