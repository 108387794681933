import {Button, Card, Cascader, Col, DatePicker, Form, Input, Row, Select,} from "antd";
import { useState, useEffect } from "react";
import address from "../../../../assets/js/area.json";
import getArea from "../../../../utils/areaTwoLevel";
const { Option } = Select;
const { RangePicker } = DatePicker;

interface IFormProps {
  onSearch: any;
}

export default function FormSearch(props: IFormProps) {
  const [areaList, setAreaList] = useState([]);
  useEffect(() => {
    setAreaList(getArea(address, 2));
  }, []);

  return (
    <Card className={"search-container"}>
      <Form onFinish={props.onSearch}>
        <Row gutter={24}>
          <Col span={5} className={"hidden"}>
            <Form.Item label="产品" name="productCode" >
              <Select>
                <Select.Option value="ddlk">多多来客</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="达人姓名" name="darenNick">
              <Input />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="达人手机号" name="mobile">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="成为时间" name="date">
              <RangePicker showTime />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="等级" name="dyGroupbyLevel">
              <Select allowClear>
                <Select.Option value="LV0">LV0</Select.Option>
                <Select.Option value="LV1">LV1</Select.Option>
                <Select.Option value="LV2">LV2</Select.Option>
                <Select.Option value="LV3">LV3</Select.Option>
                <Select.Option value="LV4">LV4</Select.Option>
                <Select.Option value="LV5">LV5</Select.Option>
                <Select.Option value="LV6">LV6</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="达人状态" name="dyGroupbyLevelVerifyStatus">
              <Select allowClear>
                <Select.Option value="1">待审核</Select.Option>
                <Select.Option value="2">审核通过</Select.Option>
                <Select.Option value="3">待修改</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label="城市" name="city">
              <Cascader
                fieldNames={{ children: "items" }}
                options={areaList}
                placeholder="请选择地级市"
                style={{ width: "100%" }}
                changeOnSelect
              />
            </Form.Item>
          </Col>
          <Col span={5}>
          <Form.Item label="邀请人" name="inviterName">
              <Input />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
          </Col>
          <Col span={2}>
            <Button type="ghost" htmlType="reset">
              重置
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
