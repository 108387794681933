import {Input, Popover, Table} from "antd";
import Button from "antd/es/button";
import React, {useEffect, useState} from "react";
import Request, {EHostType} from "../../../../../../utils/Request";
import {verifyStatusMap} from "../../../../helper";
import {AuditModal} from "./audit";

interface IProps {
  currenItem?: any;
  isAudit:boolean|undefined;
  queryType:any;
  menuType:any;
}
let host: any = sessionStorage.getItem("host");

export const TalentList = (props: IProps) => {
  let darenPhone;
  const darenPhoneChange = function (e) {
    darenPhone = e.target.value;
  };
  const onSearch = function () {
    getList({ darenPhone, pageNo: 1 });
  };
  let columns: any = [
    {
      title: "达人ID",
      dataIndex: "darenUserId",
      key: "darenUserId",
      // width: "100px",
      // ellipsis: true,
    },
    {
      title: "达人信息",
      // dataIndex: 'darenUserNick',
      key: "darenUserNick",
      align: "center",
      render: (row, i) => {
        return (
          <div>
            <div>昵称:{i.darenDyUserNick ? i.darenDyUserNick : "-"}</div>{
              props.queryType=='excellentTask'?'':<div>手机号:{i.phone}</div>
            }
          </div>
        );
      },
    },
    {
      title: "报名时间",
      dataIndex: "joinTime",
      key: "joinTime",
      align: "center",
    },
    {
      title: "作品上传时间",
      dataIndex: "returnVideoTime",
      key: "returnVideoTime",
      align: "center",
      render: (row, i) => {
        return <div>{i.returnVideoTime ? i.returnVideoTime : "-"}</div>;
      },
    },
    {
      title: "作品预览",
      dataIndex: "address",
      key: "address",
      align: "center",
      width: "120px",
      render: (row, i) => {
        return (
          <div>
            {i.status <= 3 && <div>-</div>}
            {i.status > 3 && (
              <img
                src={i.videoCover}
                style={{ width: "100px", height: "100px", cursor: "pointer" }}
                onClick={() => {
                  window.open(i.videoUrl);
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: "作品数据",
      dataIndex: "summery",
      key: "summery",
      width: "120px",
      align: "center",
      render: (row, i) => {
        console.log(row, i);
        return (
          <>
            <div>
              <div>播放量:{i.videoPlayCount ? i.videoPlayCount : "-"}</div>
              <div>点赞:{i.videoDiggCount ? i.videoDiggCount : "-"}</div>
              <div>评论量:{i.videoCommenCount ? i.videoCommenCount : "-"}</div>
              <div>转发量:{i.videoShareCount ? i.videoShareCount : "-"}</div>
            </div>
          </>
        );
      },
    },
    // {
    //   title: "是否达标",
    //   dataIndex: "upToStandardText",
    //   key: "upToStandardText",
    //   width: "100px",
    //   align: "center",
    // },
    {
      title: "是否接单",
      dataIndex: "statusText",
      key: "statusText",
      width: "100px",
      align: "center",
    },
    {
      title: "审核状态",
      dataIndex: "verifyStatusText",
      key: "verifyStatusText",
      width: "120px",
      align: "center",
      render: (row, i) => {
        return (
          <>
            <div>
              {i?.verifyStatus === 1 && (
                <div style={{ color: "#FFAA33" }}>{i.verifyStatusText}</div>
              )}
              {i?.verifyStatus == 2 && (
                <div style={{ color: "#26BF4D" }}>{i.verifyStatusText}</div>
              )}
              {i?.verifyStatus == 3 && (
                <Popover
                  placement="topLeft"
                  title="驳回原因"
                  content={i?.notApproveReason}
                  arrowPointAtCenter
                >
                  <div style={{ color: "#ff1e1e" }}>{i.verifyStatusText}</div>
                </Popover>
              )}
              {i.verifyStatus == 0 && <div>-</div>}
            </div>
          </>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "operate",
      key: "operate",
      width: "100px",
      render: (row, i) => {
        return (
          <div>
            {i.verifyStatus == 1 && (
              <AuditModal id={i.id} onAuditSuccess={() => getList({})} />
            )}
            {  i.verifyStatus != 1  && (
              <Button type="link" disabled>
                作品审核
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(4);
  const [total, setTotal] = useState<number>(0);
  const [summery, setSummery] = useState<any>({});

  //list
  const getList = async (params: any) => {
    let host: any = sessionStorage.getItem("host");
    let url = `/mgr/ddlk/visitshop/queryAllSubTask`;
    Request.jsonPost(EHostType.DDlk, url, {
      pageNo: params.pageNo ? params.pageNo : pageNo,
      pageSize,
      taskId: props.menuType ? props.currenItem.taskId : props.currenItem.id,
      darenPhone: params.darenPhone,
      videoSubmit: true,
    }).then((res) => {
      if (params)
        setDataSource(
          res?.data?.map((item) => {
            return {
              ...item,
              verifyStatusText: verifyStatusMap[item.verifyStatus],
              upToStandardText: item.upToStandard ? "是" : "否",
              statusText:
                item.status == 1 || item.status == 2 || item.status == 6
                  ? "否"
                  : "是",
            };
          })
        );
      setTotal(res?.totalCount);
    });
  };

  //统计
  const getSummery = () => {
    let url = `/mgr/ddlk/visitshop/getTaskSummary?id=${props.currenItem.taskId ? props.currenItem.taskId : props.currenItem.id}`;
    Request.jsonPost(EHostType.DDlk, url, { id:props.currenItem.taskId ? props.currenItem.taskId:props.currenItem.id }).then((res) => {
      setSummery(res.data);
    });
  };

  const getColumns = () => {
    if(props.isAudit && !props.menuType){
      return columns
    }else if(props.menuType){
      return columns.filter(item => item.title != "操作")
    }else if(!props.isAudit){
      return columns.filter(item => item.title != "操作")
    };
  };

  useEffect(() => {
    if (!props.currenItem) return;
    getList({});
    getSummery();
  }, [props]);

  return (
    <div style={{ marginTop: "10px" }}>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <div style={{ flex: 1 }}>
            {summery?.joinedDarenNum}人<br />
            <span>报名人数</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.approvedDarenNum}人<br />
            <span>已审核人数</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.toVerifyDarenNum}人<br />
            <span>待审核人数</span>
          </div>

          <div style={{ flex: 1 }}>
            {summery.totalPlayCount}
            <br />
            <span>总播放量</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.totalDiggCount}
            <br />
            <span>总点赞量</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.totalCommentCount}
            <br />
            <span>总评论量</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.totalShareCount}
            <br />
            <span>总转发量</span>
          </div>
        </div>
      </div>
      {
        props.queryType != 'excellentTask' ?  <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "10px",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <span>达人手机号：</span>
        <Input
          style={{ width: "300px", marginRight: "30px" }}
          onChange={darenPhoneChange}
        ></Input>
        <Button type="primary" onClick={onSearch}>
          搜索
        </Button>
      </div>:""
      }
      <div></div>
      <Table
        dataSource={dataSource}
        columns={getColumns()}
        scroll={{ y: 600 }}
        pagination={{
          pageSize: pageSize,
          total: total,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: (page: number) => {
            getList({ pageNo: page });
          },
        }}
      />
    </div>
  );
};
