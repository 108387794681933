import { Button, Input, message, Modal, Radio, Space } from "antd";
import { useState } from "react";
import Request, { EHostType } from "../../../../../../utils/Request";

const { TextArea } = Input;

interface IProps {
  item: any;
  onAuditSuccess: any;
  sellerType: number;
  isAudit: boolean | undefined;
}

export const TaskAudit = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(1);
  const [reason, setReason] = useState("");
  const [loading, setloading] = useState(false);

  const doSubmit = () => {
    if (value == 2 && !reason) {
      message.error("请输入不通过原因");
      return;
    }
    let url = `/mgr/ddlk/visitshop/doAudit`;
    setloading(true);
    let auditType = value == 1 ? undefined : value == 3 ? 2 : 1;
    Request.jsonPost(EHostType.DDlk, url, {
      id: props.item.id,
      isApproved: value == 1 ? true : false,
      notApprovedReason: value == 1 ? undefined : reason,
      auditType: auditType,
    })
      .then((res) => {
        if (res?.data) {
          message.success("审核通过");
          props.onAuditSuccess();
          // this.props.parent.getList({});
          setVisible(false);
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <>{
      props.isAudit ? <Button
        type="link"
        onClick={() => {
          setVisible(true);
        }}
      >
        任务审核
      </Button>:""
    }
      
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        title="任务审核"
        footer={null}
        destroyOnClose
      >
        <Space direction="vertical">
          {/* <Alert message="视频审核通过且达标后，奖励才可发布，否则奖励不予发放" type="warning" /> */}
          <Radio.Group
            onChange={(e: any) => { setValue(e.target.value); }}
            value={value}
            style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Space direction="vertical">
              <Radio value={1}>审核通过</Radio>
              <Radio value={2}>审核驳回商家</Radio>
              {(sessionStorage.getItem('isAgentEnabled') == 'true' && props?.item?.isAdvanceVersion == 0) && <Radio value={3}>审核驳回运营商</Radio>}
            </Space>
          </Radio.Group>
          {value == 2 && (
            <TextArea
              placeholder="请输入驳回原因"
              value={reason}
              style={{ width: "450px" }}
              onChange={(e: any) => {
                setReason(e.target.value);
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}>
            <Button
              type="primary"
              style={{ width: "100px" }}
              loading={loading}
              onClick={() => {
                doSubmit();
              }}>
              提交
            </Button>
          </div>
        </Space>
      </Modal>
    </>
  );
};
