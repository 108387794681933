/*
 * @Date: 2022-04-29 21:58:51
 * @LastEditors: litj
 * @LastEditTime: 2022-04-30 12:25:30
 * @FilePath: \算法c:\Users\Admin\Desktop\新建文件夹 (2)\bkm-admin\src\module\page\DDLK\PromotionMange\Consumer\filter\index.tsx
 */
import * as React from "react";
import "../index.css";
import { Form, Input, DatePicker, Row, Col, Card, Button, Select } from "antd";
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  onSearch?: any;
  tab: string;
  querType:string
}

export const TaskListFilter = (props: IProps) => {
  return (
    <div>
      {
        props.querType !='goodExamples' ? <Card className={"taskListFilterWrap search-container" + props.tab}>
        <Form onFinish={props.onSearch}>
          <Row gutter={24}>
            <Col span={9}>
              <Form.Item label="商家名称" name="adviserUserName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item label="任务名称" name="taskName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                type="ghost"
                style={{ marginLeft: "10px" }}
                htmlType="reset"
              >
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>:""
      }
     
    </div>
    
  );
};
