import * as Icon from "@ant-design/icons";
import {Button, message, Modal, Space, Table, Tabs, Typography} from "antd";
import React, {Component, useCallback, useEffect, useState} from "react";
import Add from "./Add";
import BottomContainer from "./BottomContainer";
import Request, {EHostType} from "../../../utils/Request";

interface IAdvertise {
  onTabs: (e: string) => void; // tab切换事件
  // tab切换用到的状态
}
interface IState {
  status: string;
}
let host: any = sessionStorage.getItem("host");

enum btnEnum {
  add = "添加内容",
  edit = "编辑内容",
}
type TableType = {
  pageSize: number;
  pageNo: number;
  total: number;
};

// tab切换的内容组件
function AdvertiseTabItem(props) {
  let data = [{ name: "1" }],
    columns = [
      {
        title: "图片信息",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <img
            src={record.adveriseImageUrl}
            alt=""
            style={{ width: "60px", height: "60px" }}
          />
        ),
      },
      {
        title: "标题",
        dataIndex: "adveriseTitle",
        key: "adveriseTitle",
      },
      {
        title: "链接内容",
        dataIndex: "linkedArticleId",
        key: "linkedArticleId",
      },
      {
        title: "状态",
        dataIndex: "advertiseStatus",
        key: "advertiseStatus",
      },
      {
        title: "更新时间",
        dataIndex: "modifiedTime",
        key: "modifiedTime",
      },
      {
        title: "排序",
        dataIndex: "orderNo",
        key: "orderNo",
      },
      {
        title: "操作",
        key: "action",
        width: 220,
        render: (text, record) => (
          <Space size="middle">
            <Typography.Link onClick={onEdit.bind(null, record)}>
              编辑
            </Typography.Link>
            <Typography.Link onClick={onDown.bind(null, record)}>
              {record.advertiseStatus === 0 ? "下架" : "上架"}
            </Typography.Link>
            <Typography.Link onClick={onDel.bind(null, record.id)}>
              删除
            </Typography.Link>
          </Space>
        ),
      },
    ],
    params: TableType = {
      pageSize: 10,
      pageNo: 1,
      total: 0,
    };
  const [tableData, setTableData] = useState([]),
    [tableParams, setTableParams] = useState(params),
    [total, setTotal] = useState(0),
    [visible, setVisible] = useState(false),
    [tableHeight, setTableHeight] = useState(300);
  const [id, setId] = useState(null);
  // let rowData = {};
  const [rowData, setRowData] = useState({});

  const onEdit = useCallback((record) => {
    setRowData(record);
    visiblePop(true, btnEnum.edit);
  }, []);
  const onDown = useCallback((record) => {
    Modal.confirm({
      title: "下架",
      icon: <Icon.ExclamationCircleOutlined />,
      centered: true,
      content: <p>确定下架吗？</p>,
      async onOk() {
        let host: any = sessionStorage.getItem("host");
        const { advertiseStatus } = record;
        let url = `/mgr/ddlk/advertisement/saveOrUpdate`;
        const formData = new FormData();
        console.log(advertiseStatus);
        // console.log((advertiseStatus + 1) % 2);
        formData.append("advertiseStatus", ((advertiseStatus + 1) % 2) + "");
        formData.append("id", record.id);
        formData.append("advertiseFor", record.advertiseFor);
        Request.postFormData(EHostType.XMGX, url, formData).then((res) => {
          if (!res.error) {
            message.success("操作成功");
            getList();
          }
        });
      },
    });
  }, []);

  const onDel = useCallback((id) => {
    Modal.confirm({
      title: "删除",
      icon: <Icon.ExclamationCircleOutlined />,
      centered: true,
      content: <p>确定删除？</p>,
      async onOk() {
        let host: any = sessionStorage.getItem("host");
        let url = `/mgr/ddlk/advertisement/${
          props.status
        }/${id}`;
        let res = await Request.delete(EHostType.DDlk, url);
        if (!res.error) {
          getList();
          message.success("删除成功");
        }
      },
    });
  }, []);
  const getList = () => {
    let host: any = sessionStorage.getItem("host");
    let url = `/mgr/ddlk/advertisement/queryAllAdvertisement?advertiseFor=${
      props.status
    }`;
    Request.get(EHostType.DDlk, url, {}).then((res) => {
      setTableData(res.data);
    });
  };
  /**表格高度自适应 */
  const tableAdapt = () => {
    let search: any =
      document.getElementsByClassName("search-container")[0].clientHeight + 265;
    setTableHeight(search);
  };
  // let modalTitle: any = "";
  let [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    getList();
    tableAdapt();
  }, [props.status]);
  function visiblePop(visible: boolean, title?: string) {
    setModalTitle(title!);
    setVisible(visible);
  }
  function listContent(title: string) {
    return (
      <Add
        rowData={modalTitle === btnEnum.add ? {} : rowData}
        getList={getList}
        advertiseFor={props.status}
        id={modalTitle === btnEnum.add ? null : id}
        onCancel={() => {
          visiblePop(false);
        }}
      />
    );
    // }
  }
  return (
    <>
      <div className="table-btn search-container">
        <Button
          type="primary"
          icon={<Icon.PlusCircleOutlined />}
          onClick={() => visiblePop(true, btnEnum.add)}
        >
          {btnEnum.add}
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={tableData}
        scroll={{ y: `calc(100vh - ${tableHeight}px)` }}
        pagination={false}
      />
      <Modal
        destroyOnClose={true}
        title={modalTitle}
        centered={true}
        visible={visible}
        maskClosable={false}
        width="800px"
        onCancel={() => {
          visiblePop(false);
        }}
        footer={null}
      >
        {listContent(modalTitle)}
      </Modal>
    </>
  );
}
// 广告管理组件
export default class Advertise extends Component implements IAdvertise {
  state: IState = {
    status: "0",
  };
  onTabs: (e: string) => void = (e: string) => {
    this.setState({
      status: e,
    });
  };
  render() {
    return (
      <BottomContainer>
        <div style={{ background: "#fff", padding: "20px" }}>
          <Tabs
            type="card"
            onChange={this.onTabs}
            tabBarGutter={0}
            tabBarStyle={{ color: "#ff443" }}
          >
            <Tabs.TabPane tab="商家端" key="0">
              <AdvertiseTabItem status={this.state.status} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="达人端" key="1">
              <AdvertiseTabItem status={this.state.status} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </BottomContainer>
    );
  }
}
