import { Descriptions, Typography } from "antd";
import React, { useCallback, useState, useEffect } from "react";
import EditCell from "../../../AgentManagement/EditCell";
import PicturesWall from "../../Advertise/PicturesWall";
import address from "../../../../assets/js/area.json";
import { dataUpdate, upLoadFile } from "../source/action";
import getArea from "../../../../utils/areaTwoLevel";

export default function BaseInfo(props) {
  const { dataInfo } = props;
  const [fileList, setFileList] = useState<any>([]);
  const [areaList, setAreaList] = useState([]);

  useEffect(() => {
    dataInfo.dyGroupbyLevelPictUrl ? setFileList([
          {
            uid: Math.random(),
            url: dataInfo.filePrefix + dataInfo.dyGroupbyLevelPictUrl,
          },
        ])
      : setFileList([]);
  }, [dataInfo]);

  useEffect(() => {
    setAreaList(getArea(address, 2));
  }, []);

  // 图片上传逻辑
  const handleFileChange = async ({ file, fileList }) => {
    if (file.status === "removed") {
      setFileList([]);
      return;
    }
    const res: any = await upLoadFile(file);
    const data = await dataUpdate({
      darenId: dataInfo.darenId,
      dyGroupbyLevelPictUrl: res.data.key.filePath,
    });
    if (data.code === 0) {
      setFileList([
        {
          uid: Math.random(),
          url: res.data.key.filePrefix + res.data.key.filePath,
        },
      ]);
    }
  };
  // const handleSave = useCallback(async (val, data, name) => {
  //   console.log(data, "jlj");
  //   //  await dataUpdate(data)
  // }, []);
  // 添加或修改逻辑
  const handleSave = useCallback(
    async (val, data, name) => {
      // console.log(params, "jljljljlk");
      const res = await dataUpdate({
        province: val[0],
        city: val[1],
        darenId: dataInfo.darenId,
      });
      // dataInfo.provinceText = "浙江";
      return res.code;
      // console.log(res);
    },
    [dataInfo]
  );
  return (
    <>
      <Descriptions title="" bordered style={{ margin: "24px 0" }} column={6} labelStyle={{ width: "150px" }}>
        <Descriptions.Item label="总粉丝数" span={2}>{dataInfo.fansCount}</Descriptions.Item>
        <Descriptions.Item label=" 总点赞数" span={2}>{dataInfo.diggCount}</Descriptions.Item>
        <Descriptions.Item label="总评论数" span={2}>{dataInfo.commentCount}</Descriptions.Item>
        <Descriptions.Item label="接单地区" span={2}>
            {dataInfo.province&&<EditCell
            value={[dataInfo.province, dataInfo.city].filter((e) => e)}
            name="area"
            handleSave={handleSave}
            rules={[{ required: true, message: "请输入" }]}
            options={{
              type: "cascader",
              options: areaList,
              label: "label",
              showName: dataInfo.provinceText ? dataInfo.provinceText + (dataInfo.cityText ? "/" + dataInfo.cityText : "") : "",
              fieldNames: { children: "items" },
            }}></EditCell>}
          {/* {props.orderId} */}
        </Descriptions.Item>

        <Descriptions.Item label="最低接单报价" span={2}>{dataInfo.minContactPrice}</Descriptions.Item>
        <Descriptions.Item label="最高接单报价" span={2}>{dataInfo.maxContactPrice}</Descriptions.Item>

        <Descriptions.Item label="是否接受置换" span={2}>{dataInfo.isReplacedEnabled==1?"接受":'不接受'}</Descriptions.Item>
        <Descriptions.Item label="市场价" span={2}>{dataInfo?.contactMarketPrice?dataInfo.contactMarketPrice/100:0}</Descriptions.Item>
        <Descriptions.Item label="渠道价" span={2}>{dataInfo?.contactChannelPrice?dataInfo?.contactChannelPrice/100:0}</Descriptions.Item>

        <Descriptions.Item label="联系方式" span={2}>{dataInfo.mobile}</Descriptions.Item>
        <Descriptions.Item label="抖音号" span={2}>{dataInfo.dyNo}</Descriptions.Item>
        <Descriptions.Item label="微信号" span={2}>{dataInfo.weixin}</Descriptions.Item>
        <Descriptions.Item label="累计提现收益" span={2}>{dataInfo.totalProfit?dataInfo.totalProfit/100:0}</Descriptions.Item>
        <Descriptions.Item label=" 达人人类型">{dataInfo.darenTags}</Descriptions.Item>
      </Descriptions>
      <div className="picGroup flex-start">
        <div>
          <span>微信二维码:</span>
          <img src={dataInfo.filePrefix + dataInfo.weixinQrCodeUrl} alt="" style={{ width: 100, height: 100, marginLeft: 10 }}/>
        </div>
        <div className="flex-start ml-16">
          <span>带货等级图片:</span>
          <PicturesWall
            maxCount={1}
            fileList={fileList}
            handleChange={handleFileChange}
            showUploadList={{ showRemoveIcon: false }}
          />
        </div>
      </div>
      <div>
        <h3>个人介绍</h3>
        <p>{dataInfo.intro || "暂无"}</p>
      </div>
    </>
  );
}
