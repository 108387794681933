import * as React from "react";
import * as Icon from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Table } from "antd";
import Request, { EHostType } from "../../utils/Request";
import "./index.css";
import ConfigureAdd from "./ConfigureAdd";
import ConfigureShow from "./ConfigureShow";
import ConfigureEdit from "./ConfigureEdit";
import Utils from "../../utils/Utils";
import moment from "moment";
import { VerbConfig } from "./verbConfig/index";

let host: any = sessionStorage.getItem("host");

/**运营主体管理 */
interface page {
  columns: any;
  dataSource: any;
  visible: boolean;
  modelTitle: string;
  rowClass: string;
  rowData: any;
  loadings: boolean;
  total: number;
  size: number;
  current: number;
  searchValue: any;
  imgUrl: any;
  tableHeight: number;
  configPop: boolean;
  dataId: any;
  brandText: string;
}
export default class OperationConfiguration extends React.Component<
  { parent?: any },
  page
> {
  private formSearch: any = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      dataId: null,
      columns: [
        {
          title: "运营主体id",
          dataIndex: "id",
          key: "id",
          align: "center",
        },
        {
          title: "运营主体名称",
          dataIndex: "name",
          key: "name",
          align: "center",
        },
        {
          title: "负责人",
          dataIndex: "realName",
          key: "realName",
          align: "center",
        },
        {
          title: "手机号码",
          dataIndex: "phone",
          key: "phone",
          align: "center",
        },
        {
          title: "注册时间",
          key: "createdTime",
          dataIndex: "createdTime",
          align: "center",
        },
        {
          title: "操作",
          key: "active",
          dataIndex: "active",
          width: "150px",
          align: "center",
          render: (text, record) => {
            return (
              <div className="table-col-btn">
                <Button
                  type="link"
                  onClick={() => this.visiblePop(true, "查看")}
                  style={{ marginRight: "-26px" }}>
                  查看
                </Button>
                <Button
                  type="link"
                  onClick={() => this.visiblePop(true, "编辑")}
                  style={{ marginRight: "-26px" }}
                >
                  编辑
                </Button>
                <Button
                  type="link"
                  onClick={() =>
                    this.setState({
                      configPop: true,
                      dataId: record.id,
                      brandText: record.name, // 运营主体名称
                    })
                  }
                  style={{ marginRight: "-26px" }}>
                  配置
                </Button>
              </div>
            );
          },
        },
      ],
      dataSource: [],
      visible: false,
      modelTitle: "",
      rowClass: "",
      rowData: {},
      loadings: false,
      total: 0,
      size: 12,
      current: 1,
      searchValue: {},
      imgUrl: null,
      tableHeight: 300,
      configPop: false,
      brandText: "",
    };
  }

  componentDidMount() {
    this.getData({ size: 12, current: 1 });
    this.tableAdapt();
    window.addEventListener("resize", this.tableAdapt);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.tableAdapt);
  }

  /**表格高度自适应 */
  tableAdapt = () => {
    let search: any =
      document.getElementsByClassName("search-container")[0].clientHeight + 265;
    this.setState({ tableHeight: search });
  };

  /**获取数据 */

  private async getData(data, searchData?) {
    let host: any = sessionStorage.getItem("host");
    let res = await Request.get(
      EHostType.XMGX,
      `/op/operating-entity/list/${data.size}/${data.current}`,
      searchData
    );
    if (res.code == 0) {
      let data: any = [];
      res.data.records.forEach((e) => {
        data.push({
          id: e.id,
          createdTime: e.createdTime,
          name: e.name,
          modifiedTime: e.modifiedTime,
          realName: e.operatorUser.name,
          userName: e.operatorUser.userName,
          phone: e.operatorUser.phone,
          status: e.operatorUser.status,
          operatorUser: e.operatorUser,
          expiredTime: e.expiredTime,
          businessLicenseUrl: e.businessLicenseUrl,
        });
      });

      this.setState({
        dataSource: data,
        total: res.data.total,
        size: res.data.size,
        current: res.data.current,
      });
    }
  }

  /**分页切换*/
  handleTableChange = (pagination) => {
    this.setState(
      {
        current: pagination.current ? pagination.current : this.state.current,
        size: pagination.pageSize,
      },
      () => {
        this.getData({
          size: this.state.size,
          current: pagination.current ? pagination.current : this.state.current,
        });
      }
    );
  };

  /**显示弹窗 */
  visiblePop(state, tit?) {
    if (tit === "新增") {
      this.setState({
        rowData: {},
      });
    }
    this.setState({
      visible: state,
      modelTitle: tit,
    });
  }

  /**加载页面 */
  listContent = (text) => {
    if (text === "查看") return <ConfigureShow parent={this} />;
    else if (text === "编辑") return <ConfigureEdit parent={this} />;
    else if (text === "新增") return <ConfigureAdd parent={this} />;
  };

  /**查询 */
  onSearch = (value) => {
    for (let key in value) {
      if (value[key] === undefined || value[key] === "") {
        delete value[key];
      }
    }
    this.getData({ size: 12, current: 1 }, value);
  };

  /**重置方法 */
  onReset = () => {
    this.formSearch.current.resetFields();
    this.getData({ size: 12, current: 1 });
  };

  imgUrl(text) {
    this.setState({
      imgUrl: text,
    });
  }

  /**提交 */
  onFinish = (value) => {
    let _this = this;
    this.setState({ loadings: true });
    value["businessLicenseUrl"] = this.state.imgUrl;
    value["expiredTime"] = moment(value.expiredTime).format("YYYY-MM-DD");
    Modal.confirm({
      title: "是否确认提交",
      icon: <Icon.ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认提交？</p>,
      async onOk() {
        let host: any = sessionStorage.getItem("host");
        let url = `/op/operating-entity/saveOrUpdate`;
        if (_this.state.modelTitle === "编辑") {
          value["id"] = _this.state.rowData.id;
          value["expiredTime"] = value.expiredTimes;
          delete value["expiredTimes"];
        }
        let ret = await Request.post(
          EHostType.XMGX,
          url,
          Utils.recordToForm(value)
        );
        if (ret.code == 0) {
          _this.visiblePop(false);
          _this.getData({ size: 12, current: 1 });
          message.success("提交成功");
          _this.setState({ loadings: false });
        }
      },
    });
  };

  public render() {
    return (
      <div className="container">
        {/* 搜索区域 */}
        <Form
          name="search"
          className="search-container"
          ref={this.formSearch}
          initialValues={{ name4: "all", name5: "all" }}
          onFinish={this.onSearch}
        >
          <Form.Item label="运营主体名称" name="name" className="search-item">
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="负责人手机号码"
            name="phone"
            className="search-item"
          >
            <Input allowClear />
          </Form.Item>
          <div className="search-btn ant-col-12">
            <Button
              type="primary"
              htmlType="submit"
              icon={<Icon.SearchOutlined />}
            >
              查询
            </Button>
            <Button icon={<Icon.ReloadOutlined />} onClick={this.onReset}>
              重置
            </Button>
          </div>
        </Form>
        {/* 表格区域 */}
        <div className="table-container">
          <div className="table-btn">
            <Button
              type="primary"
              icon={<Icon.PlusCircleOutlined />}
              onClick={() => this.visiblePop(true, "新增")}
            >
              新增
            </Button>
          </div>
          <div className="tab-container">
            <Table
              size={"small"}
              columns={this.state.columns}
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              pagination={{
                pageSize: this.state.size,
                // current: this.state.current,
                total: this.state.total,
                showTotal: (total) => `共 ${total} 条数据`,
              }}
              onChange={(page) => this.handleTableChange(page)}
              scroll={{ y: `calc(100vh - ${this.state.tableHeight}px)` }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    record["expiredTimes"] = record.expiredTime;
                    record["expiredTime"] = moment(
                      record.expiredTime,
                      "YYYY-MM-DD"
                    );
                    this.setState({
                      rowData: record,
                    });
                  },
                };
              }}
            />
          </div>
        </div>
        <Modal
          destroyOnClose={true}
          title={this.state.modelTitle}
          centered={true}
          visible={this.state.visible}
          maskClosable={false}
          width="500px"
          className="from-modal"
          onCancel={() => {
            this.visiblePop(false);
          }}
          footer={null}
        >
          {this.listContent(this.state.modelTitle)}
        </Modal>
        <VerbConfig dataId={this.state.dataId} configPop={this.state.configPop} brandText={this.state.brandText} onClose={() => this.setState({configPop: false}) }/>
      </div>
    );
  }
}
