import { CloseCircleOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Spin, Upload } from 'antd';
// import form from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import './index.css'
import Request, { EHostType } from '../../../../../utils/Request';
import { a } from '../../../Advertise/BottomContainer';
interface Props {
    isShow: boolean;
    isShowModal: any;
    id: any;
    reGetList: any
    taskInfo?: any
}
let videoUrl: any = [];

const SubmitModal: React.FC<Props> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sellerInfo, setSellerInfo] = useState("");
    const [fileListShow, setFileListShow] = useState<any>([]);
    const [subVideoFileList, setSubVideoFileList] = useState<any>([]);
    const [sum, setSum] = useState("");
    const [adviserUserShowUrl, setAdviserUserShowUrl] = useState<any[]>([]);
    const [sellerLogo, setSellerLogo] = useState<any[]>([]);
    const [currentOemId, setCurrentOemId] = useState<any>(sessionStorage.getItem('ddlkPlatformSellerDTO'))
    const [currentId, setCurrentId] = useState("");
    const oemId = JSON.parse(currentOemId).operatingEntityId;
    let ossUrl = "";
    useEffect(() => {
        if (props.taskInfo.adviserUserShowUrl) {
            let urlSplit = props.taskInfo.adviserUserShowUrl.split('ddlk');
            let filePrefix = urlSplit[0];
            let path = `ddlk${urlSplit[1]}`;
            setAdviserUserShowUrl([{
                status: 'done',
                filePrefix: filePrefix,
                path: path
            }])
        };
        if(props.taskInfo.logo){
            let urlSplit = props.taskInfo.logo.split('ddlk');
            let filePrefix = urlSplit[0];
            let path = `ddlk${urlSplit[1]}`;
            setSellerLogo([{
                status: 'done',
                filePrefix: filePrefix,
                path: path
            }])
        }
        if (props.taskInfo.videoMaterial) {
            // console.log(props.taskInfo.videoMaterial.length)
            let newArr: any = [];
            props.taskInfo.videoMaterial.forEach(item => {
                let urlSplit = item.split('ddlk');
                let filePrefix = urlSplit[0];
                let path = `ddlk${urlSplit[1]}`;
                newArr.push({
                    status: 'done',
                    filePrefix: filePrefix,
                    path: path
                })
                setSubVideoFileList([...newArr]);
            })
            // console.log(props.taskInfo.videoMaterial,'props.taskInfo.videoMaterial')
        };
        if (props.taskInfo.operatingEntityId) {
            setCurrentId(props.taskInfo.operatingEntityId);
        };
        setIsModalOpen(props.isShow);


    }, [props])
    useEffect(() => {
        setSubVideoFileList([])
    }, [])

    const uploadProps: any = {
        accept: "video/*",
        listType: "picture-card",
        multiple: true,
        maxCount: 3,
        showUploadList: true,
        fileList: [],
        // defaultFileList: info,
        beforeUpload: (file, fileList) => {
            if (file.size > 1024 * 1024 * 150) {
                message.info("上传文件不能大于150M");
                return false;
            }
            // var blob = new Blob([file], { type: "application/octet-stream" });
            return new Promise((resolve, reject) => {
                Request.get(EHostType.DDlk, `/mgr/ddlk/getOssTemporaryAuthorizationUrl?fileName=${file.name}`).then((res) => {
                    if (res.code === 0) {
                        ossUrl = res.data.key.filePath;
                        let filePrefix = res.data.key.filePrefix;
                        let result: any = [
                            { status: "uploading", uid: file.uid, percent: 0 },
                        ];
                        isUploading(result)
                        var reader = new FileReader();
                        reader.readAsArrayBuffer(file);
                        reader.onloadend = () => {
                            Request.putOss(EHostType.NONE, res.data.value.url, reader.result, res.data.value.contentType, (percent) => {
                                result[result.length - 1].percent = percent;
                                if (percent === 100) {
                                    setTimeout(() => {
                                        result[result.length - 1].path = res.data.key.filePath;
                                        result[result.length - 1].filePrefix = res.data.key.filePrefix;
                                        result[result.length - 1].status = "done";
                                        isDone(result)
                                    }, 1500);
                                }
                            })
                                .then((res) => {
                                    reject();
                                })
                                .catch((err) => {
                                    message.error("上传失败");
                                });
                        };
                    }
                });
            });

        },
        onChange: ({ fileList }) => {
            // setVideoFileList(fileList);
        },
    };
    const goodImageUploadProps: any = {
        accept: "image/*",
        listType: "picture-card",
        multiple: true,
        maxCount: 1,
        showUploadList: true,
        fileList: [],
        // defaultFileList: info,
        beforeUpload: (file, fileList) => {
            if (file.size > 1024 * 1024 * 150) {
                message.info("上传文件不能大于150M");
                return false;
            }
            // var blob = new Blob([file], { type: "application/octet-stream" });
            return new Promise((resolve, reject) => {
                Request.get(EHostType.DDlk, `/mgr/ddlk/getOssTemporaryAuthorizationUrl?fileName=${file.name}`).then((res) => {
                    if (res.code === 0) {
                        ossUrl = res.data.key.filePath;
                        let filePrefix = res.data.key.filePrefix;
                        let result: any = [
                            { status: "uploading", uid: file.uid, percent: 0 },
                        ];
                        isimageUploading(result);
                        var reader = new FileReader();
                        reader.readAsArrayBuffer(file);
                        reader.onloadend = () => {
                            Request.putOss(EHostType.NONE, res.data.value.url, reader.result, res.data.value.contentType, (percent) => {
                                result[result.length - 1].percent = percent;
                                if (percent === 100) {
                                    setTimeout(() => {
                                        result[result.length - 1].path = res.data.key.filePath;
                                        result[result.length - 1].filePrefix = res.data.key.filePrefix;
                                        result[result.length - 1].status = "done";
                                        isImageDone(result)
                                    }, 1500);
                                }
                            })
                                .then((res) => {
                                    reject();
                                })
                                .catch((err) => {
                                    message.error("上传失败");
                                });
                        };
                    }
                });
            });

        },
        onChange: ({ fileList }) => {
            // setVideoFileList(fileList);
        },
    };
    const logoUploadProps: any = {
        accept: "image/*",
        listType: "picture-card",
        multiple: true,
        maxCount: 1,
        showUploadList: true,
        fileList: [],
        beforeUpload: (file, fileList) => {
            if (file.size > 1024 * 1024 * 150) {
                message.info("上传文件不能大于150M");
                return false;
            }
            return new Promise((resolve, reject) => {
                Request.get(EHostType.DDlk, `/mgr/ddlk/getOssTemporaryAuthorizationUrl?fileName=${file.name}`).then((res) => {
                    if (res.code === 0) {
                        ossUrl = res.data.key.filePath;
                        let filePrefix = res.data.key.filePrefix;
                        let result: any = [
                            { status: "uploading", uid: file.uid, percent: 0 },
                        ];
                        isLogoUploading(result);
                        var reader = new FileReader();
                        reader.readAsArrayBuffer(file);
                        reader.onloadend = () => {
                            Request.putOss(EHostType.NONE, res.data.value.url, reader.result, res.data.value.contentType, (percent) => {
                                result[result.length - 1].percent = percent;
                                if (percent === 100) {
                                    setTimeout(() => {
                                        result[result.length - 1].path = res.data.key.filePath;
                                        result[result.length - 1].filePrefix = res.data.key.filePrefix;
                                        result[result.length - 1].status = "done";
                                        isLogoDone(result)
                                    }, 1500);
                                }
                            })
                                .then((res) => {
                                    reject();
                                })
                                .catch((err) => {
                                    message.error("上传失败");
                                });
                        };
                    }
                });
            });

        },
        onChange: ({ fileList }) => {
            // setVideoFileList(fileList);
        },
    };
    const isUploading = (res) => {
        if (subVideoFileList.length == 0) {
            setSubVideoFileList([...res])
        } else {
            setSubVideoFileList((currentData) => [...currentData, ...res])
        }
    }
    const isLogoUploading = (res) => {
        setSellerLogo([...res]);
    }
    const isLogoDone = (res) => {
        setSellerLogo([...res]);
    }
    const isimageUploading = (res) => {
        setAdviserUserShowUrl([...res]);
    }
    const isImageDone = (res) => {
        setAdviserUserShowUrl([...res]);
    }
    const isDone = (res) => {
        setSubVideoFileList((current) => [...current, ...videoUrl])
    }
    const handleCancel = () => {
        props.isShowModal(false)
        setSubVideoFileList([]);
        setAdviserUserShowUrl([]);
        setSellerLogo([])
        videoUrl = [];
    }
    const handleChange = ({ fileList }) => {
        setFileListShow(fileList)
    };
    const onFinish = (e) => {
        e['adviserUserShowUrl'] = adviserUserShowUrl[0].path;
        e['logo'] = sellerLogo[0].path;
        e["isAppShow"] = 1;
        let newArr: any = []
        subVideoFileList.forEach(item => {
            newArr.push(item.path)
        });
        e["videoMaterial"] = newArr;
        e["id"] = props.id
        Modal.confirm({
            title: "是否确认提交",
            centered: true,
            content: <p>是否确认提交？</p>,
            async onOk() {
                let url = `/mgr/ddlk/excellentTask/updateExcellentTaskDetail`;
                Request.jsonPost(EHostType.DDlk, url, e).then(res => {
                    if (res.code == 0) {
                        message.open({
                            type: 'success',
                            content: '提交成功',
                        });
                        setSubVideoFileList([]);
                        setAdviserUserShowUrl([]);
                        setSellerLogo([]);
                        props.reGetList(true);
                        props.isShowModal(false)
                        props.reGetList(false);


                    }
                })

            },
        });
    }
    const doRemove = (type, path?: string) => {
        Request.jsonPost(EHostType.DDlk, '/mgr/ddlk/cloudVisitShopTask/materials/delete', { key: `https://shws-public.oss-accelerate.aliyuncs.com/${path}` }).then(res => {
            if (res.data) {
                message.open({
                    type: 'success',
                    content: '删除成功',
                });
            }
        });
        if (type == 2) {
            setAdviserUserShowUrl([]);
        }else if (type == 3) {
            setSellerLogo([]);
        } else {
            let removeUrl = subVideoFileList;
            let newArr = removeUrl.filter(item => {
                return item.path != path
            })
            setSubVideoFileList([...newArr])

        }

    };
    const getAction = () => {
        let host: any = sessionStorage.getItem("host");
        return `${JSON.parse(host)?.xmgx}`;
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    return (
        <>
            <Modal
                visible={isModalOpen}
                title={'案例内容编辑'}
                width={1000}
                footer={null}
                centered
                onCancel={handleCancel}
                destroyOnClose
            >
                <Form
                    labelAlign="right"
                    scrollToFirstError={true}
                    name="form"
                    className="form-container"
                    onFinish={onFinish}
                    preserve={false}
                    initialValues={{
                        'orderNo': props.taskInfo.orderNo, 'adviserSellerDesc': props.taskInfo.adviserSellerDesc,
                        "saleVolume": props.taskInfo.saleVolume, "saleNo": props.taskInfo.saleNo, "topProduct": props.taskInfo.topProduct,
                        "drainagesProduct": props.taskInfo.drainagesProduct, "mainProduct": props.taskInfo.mainProduct, "profitProduct": props.taskInfo.profitProduct,
                        "summary": props.taskInfo.summary
                    }}
                // ref={this.formRef}
                >
                    <div className="form-content MerchantAdd">

                        <div>
                            <Form.Item
                                label="展示顺序"
                                className="form-item ant-col-11"
                                name="orderNo"
                                rules={[{ required: true, message: "展示顺序不能为空" }]}
                            >
                                <Input allowClear placeholder="请输入展示顺序" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="商家门头图片" name="logo" className="form-item img" required={true} rules={[{ message: "优秀商家数据不能为空！" }]}>
                                <div>
                                    {

                                        sellerLogo.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    {item.status == "uploading" && (
                                                        <div className="uploading-span">
                                                            <Spin tip={`正在上传${item.percent}%`}></Spin>
                                                        </div>
                                                    )}
                                                    {item.status == "error" && (
                                                        <div className="ddlktaskmaterialfail">
                                                            <CloseCircleOutlined style={{ color: "red" }} />
                                                            <div style={{ color: "red" }}>上传失败</div>
                                                        </div>
                                                    )}
                                                    {item.status == "done" && (

                                                        <div
                                                            key={item.path}
                                                            className="ddlktaskmaterialPic"
                                                            style={{
                                                                position: "relative",
                                                                display: "inline-block",
                                                            }}>{
                                                                oemId != currentId && oemId != '43' && oemId != '1' ? null : <div
                                                                    className="materialClear"
                                                                    onClick={() => {
                                                                        doRemove(3, item.path);
                                                                    }}>
                                                                    <CloseOutlined />
                                                                </div>
                                                            }
                                                            <img
                                                                src={`${item.filePrefix}${item.path
                                                                    }`}
                                                                className="ddlktaskmaterialPic"
                                                            />
                                                        </div>

                                                    )}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    {
                                        sellerLogo.length >= 1 ? null : <Upload {...logoUploadProps}
                                            disabled={oemId != currentId && oemId != '43' && oemId != '1'}
                                        >
                                            <Button icon={<PlusOutlined />} type="text">
                                                上传门头
                                            </Button>
                                        </Upload>
                                    }

                                </div>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="商家介绍"
                                className="form-item ant-col-11"
                                name="adviserSellerDesc"
                                rules={[{ required: true, message: "商家介绍不能为空！" }]}
                            >
                                <TextArea
                                    disabled={oemId != currentId && oemId != '43' && oemId != '1'}
                                    value={sellerInfo}
                                    onChange={(e) => setSellerInfo(e.target.value)}
                                    placeholder="Controlled autosize"
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="销售额" className="form-item ant-col-11"
                                name="saleVolume"
                                rules={[{ required: true, message: "销售额不能为空" }]}
                            >
                                <Input disabled={oemId != currentId && oemId != '43' && oemId != '1'} placeholder="请输入销售额" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="销量" className="form-item ant-col-11"
                                name="saleNo"
                                rules={[{ required: true, message: "销量不能为空" }]}
                            >
                                <Input disabled={oemId != currentId && oemId != '43' && oemId != '1'} placeholder="请输入销量" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="优秀商家数据展示" name="adviserUserShowUrl" className="form-item img" required={true} rules={[{ message: "优秀商家数据不能为空！" }]}>
                                <div>
                                    {

                                        adviserUserShowUrl.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    {item.status == "uploading" && (
                                                        <div className="uploading-span">
                                                            <Spin tip={`正在上传${item.percent}%`}></Spin>
                                                        </div>
                                                    )}
                                                    {item.status == "error" && (
                                                        <div className="ddlktaskmaterialfail">
                                                            <CloseCircleOutlined style={{ color: "red" }} />
                                                            <div style={{ color: "red" }}>上传失败</div>
                                                        </div>
                                                    )}
                                                    {item.status == "done" && (

                                                        <div
                                                            key={item.path}
                                                            className="ddlktaskmaterialPic"
                                                            style={{
                                                                position: "relative",
                                                                display: "inline-block",
                                                            }}>{
                                                                oemId != currentId && oemId != '43' && oemId != '1' ? null : <div
                                                                    className="materialClear"
                                                                    onClick={() => {
                                                                        doRemove(2, item.path);
                                                                    }}>
                                                                    <CloseOutlined />
                                                                </div>
                                                            }
                                                            <img
                                                                src={`${item.filePrefix}${item.path
                                                                    }`}
                                                                className="ddlktaskmaterialPic"
                                                            />
                                                        </div>

                                                    )}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    {
                                        adviserUserShowUrl.length >= 1 ? null : <Upload {...goodImageUploadProps}
                                            disabled={oemId != currentId && oemId != '43' && oemId != '1'}
                                        >
                                            <Button icon={<PlusOutlined />} type="text">
                                                上传图片
                                            </Button>
                                        </Upload>
                                    }

                                </div>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="TOP单品" className="form-item ant-col-11"
                                name="topProduct"
                                rules={[{ required: true, message: "TOP单品不能为空" }]}
                            >
                                <Input disabled={oemId != currentId && oemId != '43' && oemId != '1'} placeholder="请输入TOP单品" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="优秀商家视频" name="videoMaterial" className="form-item img" required={true} rules={[{ message: "优秀商家视频不能为空！" }]}>
                                <div className='uplodVideo'>
                                    <div>
                                        {
                                            subVideoFileList.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        {item.status == "uploading" && (
                                                            <div className="uploading-span">
                                                                <Spin tip={`正在上传${item.percent}%`}></Spin>
                                                            </div>
                                                        )}
                                                        {item.status == "error" && (
                                                            <div className="ddlktaskmaterialfail">
                                                                <CloseCircleOutlined style={{ color: "red" }} />
                                                                <div style={{ color: "red" }}>上传失败</div>
                                                            </div>
                                                        )}
                                                        {item.status == "done" && (
                                                            <div>
                                                                {/* {item.type == 1 && ( */}
                                                                <div
                                                                    key={item.path}
                                                                    className="ddlktaskmaterialvideo"
                                                                    style={{ position: "relative", display: "inline-block" }}
                                                                >   {
                                                                        oemId != currentId && oemId != '43' && oemId != '1' ? null : <span
                                                                            className="materialClear"
                                                                            style={{
                                                                                position: "absolute",
                                                                                right: 0,
                                                                                top: 0,
                                                                                cursor: "pointer",
                                                                                zIndex: 9999,
                                                                            }}
                                                                            onClick={() => {
                                                                                doRemove(1, item.path);
                                                                            }}
                                                                        >
                                                                            <CloseOutlined />
                                                                        </span>

                                                                    }
                                                                    <video
                                                                        width={100}
                                                                        height={100}
                                                                        className="ddlktaskmaterialvideo"
                                                                        src={`${item.filePrefix}${item.path}`}
                                                                        controls
                                                                    ></video>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div>
                                        {
                                            subVideoFileList.length >= 2 ? null : <Upload {...uploadProps}
                                                disabled={oemId != currentId && oemId != '43' && oemId != '1'}
                                            >
                                                <Button icon={<PlusOutlined />} type="text">
                                                    上传视频
                                                </Button>
                                            </Upload>
                                        }
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                        <div>
                            <p>货盘组合</p>
                            <div> <Form.Item label="引流品" className="form-item ant-col-11"
                                name="drainagesProduct"
                                rules={[{ required: true, message: "引流品不能为空" }]}
                            >
                                <Input disabled={oemId != currentId && oemId != '43' && oemId != '1'} placeholder="请输入引流品" />
                            </Form.Item></div>
                            <div> <Form.Item label="主推品" className="form-item ant-col-11"
                                name="mainProduct"
                                rules={[{ required: true, message: "主推品不能为空" }]}
                            >
                                <Input disabled={oemId != currentId && oemId != '43' && oemId != '1'} placeholder="请输入主推品" />
                            </Form.Item></div>
                            <div> <Form.Item label="利润品" className="form-item ant-col-11"
                                name="profitProduct"
                                rules={[{ required: true, message: "利润品不能为空" }]}
                            >
                                <Input disabled={oemId != currentId && oemId != '43' && oemId != '1'} placeholder="请输入利润品" />
                            </Form.Item></div>
                        </div>
                        <div>
                            <Form.Item
                                label="总结"
                                className="form-item ant-col-11"
                                name="summary"
                                rules={[{ required: true, message: "总结不能为空！" }]}
                            >
                                <TextArea
                                    disabled={oemId != currentId && oemId != '43' && oemId != '1'}
                                    value={sum}
                                    onChange={(e) => setSum(e.target.value)}
                                    placeholder="Controlled autosize"
                                    autoSize={{ minRows: 5, maxRows: 10 }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="form-btn">
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </div>
                </Form>
            </Modal>


        </>
    );
}

export default SubmitModal
