import React, { useCallback, useState, useEffect } from "react";
import { Button, Checkbox, Form, Input,Radio } from 'antd';
import Request from "../../../utils/Request";

interface IProps {
    configInfo: Object;
}
export const YunPianConfig=(props) =>{
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    return (
        <div>

            <Form
                name="basic"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                initialValues={{...props?.configInfo,...props.configInfo.yunPianConfig}}
                onFinish={onFinish}
                autoComplete="off">
                <Form.Item
                    label="key"
                    name="key"
                    rules={[{ required: false, message: '请输入key' }]}>
                    <Input  style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="验证码"
                    name="verifyCode"
                    rules={[{ required: false, message: '请输入验证码' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>

                <Form.Item
                    label="重置密码"
                    name="PasswordReset"
                    rules={[{ required: false, message: '请输入重置密码' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="新建运营人员"
                    name="OperatorCreate"
                    rules={[{ required: false, message: '请输入新建运营人员' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="新建代理商"
                    name="AgentCreate"
                    rules={[{ required: false, message: '请输入新建代理商' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="新建商家"
                    name="SellerCreate"
                    rules={[{ required: false, message: '请输入新建商家' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="达人探店接单通知"
                    name="VisitShopTaskNotifyDaren"
                    rules={[{ required: false, message: '请输入达人探店接单通知' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="消费者提供作品成功"
                    name="CustomerTaskJoinedNotify"
                    rules={[{ required: false, message: '请输入消费者提供作品成功' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="达人提现通知"
                    name="ConsumerTaskNotifyDaren"
                    rules={[{ required: false, message: '请输入达人提现通知' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="探店沟通群不足短信通知模板ID"
                    name="MgrNotifyOemAdmin"
                    rules={[{ required: false, message: '请输入探店沟通群不足短信通知模板ID' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="任务审核通过短信通知模板"
                    name="TaskApprovedNotifySeller"
                    rules={[{ required: false, message: '请输入任务审核通过短信通知模板ID' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item
                    label="达人等级通过短信通知模板ID"
                    name="LevelApprovedNotifyDaren"
                    rules={[{ required: false, message: '请输入达人等级通过短信通知模板ID' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item label="是否启用" name="oemType">
                    <Radio.Group>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </div>
    );
}
