// import FormSearch from "./FormSearch";
// import TableC from "./TableC";
import Method from "./Method";

export default function CloudAmountConfig() {
  return <Method />;
}

// let columns: any[] = [];
// export default function DarenManage() {
//   return (
//     <>
//       <FormSearch
//         onSearch={() => {
//           setTimeout(() => {
//             columns = [
//               {
//                 title: "姓名",
//                 dataIndex: "name",
//                 key: "name",
//               },
//               {
//                 title: "年龄",
//                 dataIndex: "age",
//                 key: "age",
//               },
//               {
//                 title: "住址",
//                 dataIndex: "address",
//                 key: "address",
//               },
//             ];
//           });
//         }}
//       ></FormSearch>
//       <TableC
//         data={[]}
//         columns={columns}
//         handleTableChange={() => {}}
//         tableHeight={0}
//         pagination={{}}
//       ></TableC>
//     </>
//   );
// }
