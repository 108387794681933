import React, { useCallback, useState, useEffect } from "react";
import {Button, Checkbox, Form, Input, Radio} from 'antd';
interface IProps {
    configInfo: Object;
}
export const DomainRegistration=(props)=>{
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={props?.configInfo}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <Form.Item label="域名" name="oemDomain" rules={[{ required: false, message: '请输入域名' }]}>
                    <Input style={{width:'200px'}} />
                </Form.Item>
                <Form.Item label="备案是否完成" name="isDomainApproved">
                    <Radio.Group>
                        <Radio value="true"> 是 </Radio>
                        <Radio value="false"> 否 </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </>
    );
}
