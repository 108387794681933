/**
 * @Date: 2022-07-09 11:25:14
 * @LastEditors: litj
 * @LastEditTime: 2022-10-31 19:34:39
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/module/utils/areaTwoLevel.js
 */
let result = {
    lock: true,
    key: 1,
};
export default function hryCity(list, count = 2) {
    let arr = JSON.parse(JSON.stringify(list));
    if (result[count]) 
    { 
        return result[count]
    } else {
        result['lock'] = false;
    };
    // result['lock'] = true;
    if (arr == null) return arr;
    if (count <= 1) {
        // result[result.key] = arr;
        return arr;
    }
    // let con = count;
    count --;
    for(let i = 0; i<arr.length; i++) {
        let current = arr[i];
        current.items = current.children;
        hryCity(current.children, count);
    }
    result.key = ++count;
    !result['lock'] && (result[result.key] = arr);
    result = {
        lock: true,
        key: 1,
        [result.key]: arr
    }
    // result['lock'] = true;

    return arr;
}