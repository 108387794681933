export const RoutesMap = [
    {
        name: "代理商管理",
        path: '/shop/agent',

        icon: 'daili1',
        meta: { limits: "2", menuShow: false }
    },
    {
        name: "商家管理",
        path: '/shop/business',

        icon: 'shangjia2',
        meta: { limits: "3", menuShow: false }
    },
    {
        name: "财务管理",
        path: '/shop/finance',
        icon: "finance",
        meta: { limits: "4", menuShow: false },
        children: [
            {
                name: "余额管理",
                path: '/shop/finance/balance',

                icon: '',
                meta: { limits: "4-1", menuShow: false }
            },
            {
                name: "提现审核",
                path: '/shop/finance/withdraw-approval',

                icon: '',
                meta: { limits: "4-2", menuShow: false }
            },
            {
                name: "提现设置",
                path: '/shop/finance/withdraw-setting',

                icon: '',
                meta: { limits: "4-3", menuShow: false }
            },
        ]
    },
    {
        name: "账号管理",
        path: '/shop/user',

        icon: 'zhanghao',
        meta: { limits: "5", menuShow: false }
    },
    {
        name: "运营主体管理",
        path: '/shop/operation',

        icon: 'peizhi',
        meta: { limits: "1", menuShow: false }
    },
    {
        name: "视频统计",
        path: '/shop/videoStatistics',

        icon: 'peizhi',
        meta: { limits: "6", menuShow: false }
    },
    {
        name: "任务平台",
        path: 'g2',

        otherRole: ['brand'],
        icon: 'task',
        meta: { limits: "7", menuShow: false },
        children: [
            {
                name: "任务列表",
                path: '/shop/PromotionMange',

                icon: '',
                meta: { limits: "7-1", menuShow: false }
            },
            {
                name: "文章管理",
                path: '/shop/ArticalMange',

                icon: '',
                meta: { limits: "7-2", menuShow: false }
            },
            {
                name: "广告管理",
                path: '/shop/Advertise',

                icon: '',
                meta: { limits: "7-3", menuShow: false }
            },
            {
                name: "达人管理",
                path: '/shop/daren',

                icon: '',
                meta: { limits: "7-4", menuShow: false }
            },
            {
                name: "远程探店金额配置",
                path: '/shop/config',
                role: ['1', '2'],
                icon: '',
                meta: { limits: "7-5", menuShow: false }
            }
        ]
    },
    {
        name: "优秀案例",
        path: "/shop/goodTaskExamples",
        icon: 'task',
        meta: {limits: "7-6", menuShow: false}
    },
    {
        name: "测试帐号管理",
        path: '/shop/testAccount',
        icon: 'shangjia2',
        meta: { limits: "8", menuShow: false }
    },
    {
        name: "达人小程序首页管理",
        path: '/shop/darenHome',
        icon: '',
        meta: { limits: "9", menuShow: false }
    }
]



export const getMenuVisible = () => {
    RoutesMap.forEach(item => {
        item.meta.menuShow = false
        item.children?.forEach(ite => {
            ite.meta.menuShow = false
        })
    })
    // }else {
    //     if (item.otherRole) {
    //         const hasBrand = sessionStorage.getItem('brand') != 'undefined';
    //         return hasBrand 
    //     } 
    // }
}



