/*
 * @Date: 2022-04-29 21:58:51
 * @LastEditors: litj
 * @LastEditTime: 2022-10-18 11:52:49
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/module/page/VideoStatistics/index.tsx
 */
import React, { useEffect, useRef, useState } from "react";
import { TaskListDetail } from "./detail";
import { TaskListFilter } from "./filter";
import { TaskListTable } from "./table";
import { Tabs, Radio, Modal, message, Upload, Button } from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";

import type { UploadProps } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import Request, {EHostType} from "../../utils/Request";
// import {isVideos} from "../../../../utils/Utils";
// import {IDataItem} from "./detail/info/components/TabUpload";
export const VideoStatistics = (props) => {
  const [searchParams, setSearchParams] = useState({});
  const [newFileList, setNewFileList] = useState<any>([]);
  //提现记录下载
  const downloadRecords = async () => {
    let res: any = await Request.postBlob(EHostType.DDlk, `/mgr/ddlk/oemVideoBill/downloadOemVideoBill`,
      {
        ...searchParams,
        operatingEntityId: sessionStorage.getItem("operatingEntityId"),
      }
    );
    let blob = new Blob([res], { type: "application/vnd.ms-excel" }); // 获取请求返回的response对象中的blob 设置文件类型excel
    let downloadElement: any = document.createElement("a");
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = "视频统计记录.xlsx"; //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象
  };
  return (
    <>
      <TaskListFilter
        tab={props.tab}
        onSearch={(item: any) => {
          setSearchParams(item);
        }}
        downloadRecords={() => downloadRecords()}
      />
      <TaskListTable
        tab={props.tab}
        searchParams={searchParams}
        onOpenDetail={(item: any, active: string) => {
          // setCurrentItem(item);
          // setTabActive(active);
        }}
      />
    </>
  );
};
