import * as Icon from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import "./index.css";

interface IButton {
  text: string;
  icon?: any;
  onClick: () => void;
}

export default function BottomContainer(props) {
  const btnList =
    props.btns &&
    props.btns.map((e: IButton) => {
      return (
        <Button type="primary" icon={<Icon.PlusCircleOutlined />} onClick={e.onClick}>
          {e.text}
        </Button>
      );
    });
  return (
    <div className="table-container" style={{ height: "100%" }}>
      <div style={{ display: props.tab ? "block" : "none" }}>{props.tab}</div>
      <div
        className="table-btn"
        style={{
          display: btnList && btnList.length ? "block" : "none",
          textAlign: "right",
        }}>
        {btnList}
      </div>
      <div className="tab-container">{props.children}</div>
    </div>
  );
}

export const a = {};
