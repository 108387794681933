import * as React from "react";
import * as Icon from "@ant-design/icons";
import { Menu, Dropdown, Modal, message, Button, Collapse } from "antd";
import "./MainFrame.css";
import Request, { EHostType } from "../utils/Request";
import MainFrameRoute from "../route/MainFrameRoute";
import { FireOutlined, WechatOutlined, VideoCameraAddOutlined, TransactionOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons/lib";
import SubMenu from "antd/lib/menu/SubMenu";
import { getMenuVisible, RoutesMap } from "./Routes";
export const UserContext = React.createContext('');
interface Props {
  match: any;
  history: any;
}

interface State {
  stretchState: boolean;
  moduleName: string;
  role: number;
  userName: any;
  isData: boolean;
  queryId: string;
  isNavClick: boolean;
  realName: string;
  collapsed: boolean;
  showMenu: boolean;
  menuType:any
}

const getIcon = (icon?: string) => {
  if (icon) {
    const mapping = {
      "finance": <TransactionOutlined />,
      "task": <FireOutlined />
    }
    return mapping[icon] ? mapping[icon] : <span className={`iconfont icon-${icon}`} />
  }
}

export default class MainFrame extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      stretchState: false,
      moduleName: "AgentManagement",
      role: 0,
      userName: "",
      isData: false,
      queryId: "",
      isNavClick: true,
      realName: "",
      collapsed: false,
      showMenu: true,
      menuType:false
    };
  }

  componentWillMount(): void {
    this.getMenuLimits();
    if(this.props.history.location.pathname == "/shop/goodTaskExamples"){
      this.setState({menuType:true})
    };

  }
  // componentDidMount(): void {
  //   this.getMenuLimits();
  // }

  //获取菜单权限，功能权限
  getMenuLimits() {
    getMenuVisible()
    let menuLimits: any = sessionStorage.getItem('menuLimits')?.split(',');
    let functionLimits = sessionStorage.getItem('functionLimits')?.split(',');
    RoutesMap.forEach((item: any, index) => {
      menuLimits?.forEach((ite, ind) => {
        if (ite === item.meta.limits) {
          item.meta.menuShow = true;
        }
        if (item.children) {
          item.children.forEach(childrenItem => {
            if (ite === childrenItem.meta.limits) {
              childrenItem.meta.menuShow = true;
            }
          })
        }

      })

    })

  }

  UNSAFE_componentWillMount() {
    let role = Number(sessionStorage.getItem("role"));
    this.setState({
      role: role,
      moduleName: window.location.hash.replace("#", ""),
      userName: sessionStorage.getItem("userName"),
      realName: sessionStorage.getItem("realName")!,
    });
  }

  /**左侧菜单收起 */
  getStretchState() {
    if (this.state.stretchState === true) {
      this.setState({
        stretchState: false,
      });
    } else {
      this.setState({
        stretchState: true,
      });
    }
  }

  toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed, showMenu: !this.state.showMenu })
  }

  /**token验证 */
  verification(text) {
    if (text === "令牌验证失败,无权限用户禁止访问" || text === "请传入合法的Token") {
      this.props.history.push("/");
    }
  }

  /**菜单切换 */
  handleClick = (e) => {
    this.props.history.push(e.key);
    this.setState({ moduleName: e.key });
    const isGoodsTaskExamples = e.key.includes('goodTaskExamples');
    this.setState({menuType:isGoodsTaskExamples})
  };


  /**退出登录 */
  private loginOut = async () => {
    let that = this;
    Modal.confirm({
      icon: <Icon.ExclamationCircleOutlined />,
      content: "是否确认退出系统？",
      centered: true,
      okText: "是",
      cancelText: "否",
      maskClosable: true,
      async onOk() {
        let ret = await Request.jsonPost(EHostType.XMGX, `/op/logout`, "");
        if (!ret.error) {
          message.success("退出系统成功!");
          that.props.history.push("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("menuLimits");
          sessionStorage.removeItem("functionLimits");
        }
      },
    });
  };

  public render() {
    const { stretchState, role, moduleName, collapsed, showMenu } = this.state;
    let name = sessionStorage.getItem('mainName');
    let brandText = sessionStorage.getItem('brand') ? sessionStorage.getItem('brand') + "任务平台" : '';

    // @ts-ignore
    return (
      <div className="wrapper">
        <UserContext.Provider value={this.state.menuType}>
          <div className="content">
            <div className={showMenu ? "subnav" : "subnav-hide"} >
              {/* <div className="head-title">
            </div> */}
              <div className="logo-content">
                <div className="logo">{name}</div>
                <div className="tag">运营平台</div>
              </div>
              <Menu
                className="menu"
                onClick={this.handleClick}
                selectedKeys={[moduleName]}
                defaultSelectedKeys={[moduleName]}
                inlineIndent={24}
                mode="inline"
                style={{ height: "100%" }}
              >

                {
                  RoutesMap.map(item => {
                    if (item.meta.menuShow) {
                      if (item.children) {
                        return <SubMenu key={item.path} title={item.name} icon={getIcon(item.icon)}>
                          {
                            item?.children?.map(subitem => {
                              return subitem.meta.menuShow && <Menu.Item key={subitem.path} icon={getIcon(subitem.icon)}>{subitem.name}</Menu.Item>
                            })
                          }
                        </SubMenu>
                      } else {
                        return <Menu.Item key={item.path} icon={getIcon(item.icon)}>{item.name}</Menu.Item>
                      }
                    }

                  })
                }

                {/*{role === 1 || role === 2 ? (<Menu.Item icon={<span className="iconfont icon-daili1"></span>} key="/shop/agent">代理商管理</Menu.Item>) : null}*/}
                {/*{role === 1 || role === 2 ? (<Menu.Item icon={<span className="iconfont icon-shangjia2"></span>} key="/shop/business">商家管理</Menu.Item>) : null}*/}
                {/*{role === 1 || role === 2 ? (<SubMenu key="g2" title="财务管理" icon={<TransactionOutlined /> } >*/}
                {/*  <Menu.Item key="/shop/finance/balance">余额管理</Menu.Item>*/}
                {/*  <Menu.Item key="/shop/finance/withdraw-approval">提现审核</Menu.Item>*/}
                {/*  <Menu.Item key="/shop/finance/withdraw-setting">提现设置</Menu.Item>*/}
                {/*</SubMenu>):null}*/}
                {/*/!*{role === 1 || role === 2 ? (<Menu.Item icon={<span className="iconfont icon-liwu"></span>} key="/shop/activity">活动管理</Menu.Item>) : null}*!/*/}
                {/*/!*{role === 1 || role === 2 ? (<Menu.Item icon={<span className="iconfont icon-huati"></span>} key="/shop/topic">话题管理</Menu.Item>) : null}*!/*/}
                {/*/!*{role === 1 || role === 2 ? (<Menu.Item icon={<span className="iconfont icon-shipin"></span>} key="/shop/video">视频发布记录</Menu.Item>) : null}*!/*/}
                {/*/!*{role === 1 || role === 2 ? (<Menu.Item icon={<span className="iconfont icon-quan"></span>} key="/shop/card">卡券记录</Menu.Item>) : null}*!/*/}
                {/*/!*{role === 1 || role === 2 ? (<Menu.Item icon={<WechatOutlined />} key="/shop/version">小程序版本管理</Menu.Item>) : null}*!/*/}
                {/*{role === 1 ? (<Menu.Item icon={<span className="iconfont icon-zhanghao"></span>} key="/shop/user">账号管理</Menu.Item>) : null}*/}
                {/*{role === 0 ? (<Menu.Item icon={<span className="iconfont icon-peizhi"></span>} key="/shop/operation">运营主体管理</Menu.Item>) : null}*/}
                {/*/!*<Menu.Item icon={<VideoCameraAddOutlined />} key="/shop/videoStatistics">视频统计</Menu.Item>*!/*/}
                {/*{(sessionStorage.getItem('brand')!='undefined'&&(role === 1 || role === 2))? (<SubMenu key="/shop/task" icon={<FireOutlined />} title={brandText}>*/}
                {/*  <Menu.Item key="/shop/task/PromotionMange">任务列表</Menu.Item>*/}
                {/*  <Menu.Item key="/shop/ArticalMange">文章管理</Menu.Item>*/}
                {/*  <Menu.Item key="/shop/Advertise">广告管理</Menu.Item>*/}
                {/*  <Menu.Item key="/shop/daren">达人管理</Menu.Item>*/}
                {/*  <Menu.Item key="/shop/config">远程探店金额配置</Menu.Item>*/}
                {/*  <Menu.Item key="/shop/videoStatistics">视频统计</Menu.Item>*/}
                {/*</SubMenu>):null}*/}
              </Menu>
            </div>
            <div className="right-content">
              <div className="right-header">
                <Button style={{ background: "rgba(46, 51, 77, 1)", border: 'none' }} type="primary" onClick={this.toggleCollapsed} >
                  {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
                <div>
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <div className="head-user-info">
                        {/* <span onClick={() => this.changePasswordModule(true)} ><Icon.LockOutlined />修改密码</span> */}
                        <span onClick={this.loginOut}>
                          <Icon.ExportOutlined />
                          退出系统
                        </span>
                      </div>}>
                    <div className="head-user">
                      <div className="user-img">
                        <span></span>
                      </div>
                      <div className="user-name">{this.state.realName}</div>
                      <div className="icon">
                        <Icon.DownOutlined />
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
              <div className="nav-item" >
                <MainFrameRoute />
              </div>
            </div>
          </div>
          {/* <div className="head"> */}

          {/* <div className="head-stretch" onClick={this.getStretchState.bind(this)}>
            {stretchState === true ? (<Icon.MenuUnfoldOutlined />) : (<Icon.MenuFoldOutlined />)}
          </div> */}

          {/* </div> */}



        </UserContext.Provider>
      </div>
    );
  }
}
