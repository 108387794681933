import { Alert, Button, Input, message, Modal, Radio, Space } from "antd";
import { useState } from "react";
import Request, { EHostType } from "../../../../../../../utils/Request";

const { TextArea } = Input;

interface IProps {
  id: string;
  onAuditSuccess: any;
}

export const AuditModal = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(1);
  const [reason, setReason] = useState("");

  const doSubmit = () => {
    if (value == 2 && !reason) {
      message.error("请输入不通过原因");
      return;
    }
    let host: any = sessionStorage.getItem("host");

    let url = `/mgr/ddlk/doAuditVideo`;
    Request.jsonPost(EHostType.DDlk, url, {
      id: props.id,
      isApproved: value == 1 ? true : false,
      notApprovedReason: value == 1 ? undefined : reason,
    }).then((res) => {
      if (res?.code === 0) {
        message.success("操作成功");
        props.onAuditSuccess();
        // this.props.parent.getList({});
        setVisible(false);
      }
      // setVisible(false)
    });
  };

  return (
    <>
      <Button
        type="link"
        onClick={() => {
          setVisible(true);
        }}
      >
        作品审核
      </Button>
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        title="视频审核"
        footer={null}
        destroyOnClose
      >
        <Space direction="vertical">
          <Alert
            message="视频审核通过且达标后，奖励才可发布，否则奖励不予发放"
            type="warning"
          />
          <Radio.Group
            onChange={(e: any) => {
              setValue(e.target.value);
            }}
            value={value}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <Space direction="vertical">
              <Radio value={1}>审核通过</Radio>
              <Radio value={2}>审核驳回</Radio>
            </Space>
          </Radio.Group>
          {value == 2 && (
            <TextArea
              placeholder="请输入驳回原因"
              value={reason}
              onChange={(e: any) => {
                setReason(e.target.value);
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              style={{ width: "100px" }}
              onClick={() => {
                doSubmit();
              }}
            >
              提交
            </Button>
          </div>
        </Space>
      </Modal>
    </>
  );
};
