import * as React from "react";
import {Button, DatePicker, Form, Input, message, Modal, Upload} from "antd";
import "./index.css";
import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import moment from "moment";
import Request, {EHostType} from "../../utils/Request";

interface page {
  fileList: any;
  fileListShow: any;
  uploading: boolean;
}

export default class ConfigureAdd extends React.Component<
  { parent?: any },
  page
> {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      fileListShow: [],
      uploading: false,
    };
  }

  handleChange = ({ fileList }) => this.setState({ fileListShow: fileList });

  onFinish = (value) => {
    for (let key in value) {
      if (value[key] === undefined || value[key] === "") {
        delete value[key];
      }
    }
    value["expiredTime"] = moment(value.expiredTime).format("YYYY-MM-DD");
    const formData = new FormData();
    this.state.fileList.forEach((file) => {
      formData.append("businessLicense", file);
    });
    this.setState({
      uploading: true,
    });
    for (let key in value) {
      formData.append(key, value[key]);
    }
    let _this = this;
    Modal.confirm({
      title: "是否确认提交",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: <p>是否确认提交？</p>,
      async onOk() {
        // let url = `${process.env.REACT_APP_REMOTE_URL}/op/operating-entity/saveOrUpdate`;
        let url = `/op/operating-entity/saveOrUpdate`;
        let ret = await Request.post(EHostType.XMGX, url, formData);
        if (ret.code == 0) {
          _this.props.parent.visiblePop(false);
          _this.props.parent.getData({ size: 10, current: 1 });
          message.success("提交成功");
          _this.props.parent.setState({ loadings: false });
        }
        // reqwest({
        //
        //     method: 'post',
        //     processData: false,
        //     data: formData,
        //     headers: { 'Authorization': sessionStorage.getItem("token") },
        //     success: (ret) => {
        //         if (ret.success) {
        //             _this.props.parent.visiblePop(false);
        //             _this.props.parent.getData({ size: 10, current: 1 });
        //             message.success('提交成功');
        //             _this.props.parent.setState({ loadings: false });
        //         } else {
        //             message.error(ret.errroMsg);
        //             _this.props.parent.props.parent.verification(ret.errroMsg);
        //             _this.props.parent.setState({ loadings: false });
        //         }
        //     }
        // })
      },
    });
  };

  public render() {
    const { fileList } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    return (
      <Form
        name="form"
        className="form-container"
        initialValues={this.props.parent.state.rowData}
        onFinish={this.onFinish}>
        <div className="form-content">
          <Form.Item
            label="公司名称"
            className="form-item ant-col-23"
            name="name"
            rules={[{ required: true, message: "公司名称不能为空！" }]}>
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="管理员姓名"
            className="form-item ant-col-23"
            name="realName"
            rules={[{ required: true, message: "负责人姓名不能为空！" }]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="管理员手机号码"
            className="form-item ant-col-23"
            name="phone"
            rules={[{ required: true, message: "负责人手机号码不能为空！" }]}>
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="管理员登录帐号"
            className="form-item ant-col-23"
            name="userName"
            rules={[{ required: true, message: "管理员账号不能为空！" }]}>
            <Input allowClear />
          </Form.Item>

          <Form.Item label="登录密码" className="form-item ant-col-23">
            <Input allowClear placeholder="账号创建后随机生成" disabled />
          </Form.Item>

          <Form.Item label="营业执照(选填)" className="form-item ant-col-23">
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>图片上传</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="系统有效期" className="form-item ant-col-23" name="expiredTime" rules={[{ required: true, message: "系统有效期不能为空！" }]}>
            <DatePicker />
          </Form.Item>
          {/* <p>多多来客产品功能开通</p> */}
        </div>

        <div className="form-btn">
          <Button type="primary" htmlType="submit">
            提交
          </Button>
          <Button onClick={() => this.props.parent.visiblePop(false)}>
            取消
          </Button>
        </div>
      </Form>
    );
  }
}
