import {Button, Col, Form, Modal, Row, Select, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import Request, {EHostType} from '../../../../../utils/Request';

const { Option } = Select;

interface IProps {
    onOpenRewardRecords: boolean,
    details:{
        id:string,
        adviserReferSellerId:string,
        subStore:string[],
        rewardConfig:object,
        examineDays:number
    }
}

export const RewardsTable = (props: IProps) => {
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(6);
    const [total, setTotal] = useState<number>(0);
    const columns:any = [
        {
            title: '发放时间',
            dataIndex: 'rewareTime',
            align: 'center',
            width: 180
        },
        {
            title: '用户信息',
            key: 'darenDyUserNick',
            align:'center',
            render: (row, i) => {
                return  <div style={{display:'flex',flexDirection:'column',alignItems:"flex-start"}}>
                    <div>昵称：{i?.darenDyUserNick}</div>
                    <div>ID：{i?.darenUserId}</div>
                </div>
            }
        },
        {
            title: '所属门店',
            dataIndex: 'adviserUserName',
            align:'center',
            width: 200
        },
        {
            title: '上传时间',
            dataIndex: 'returnVideoTime',
            align:'center',
            width: 180
        },
        {
            title: '播放量',
            dataIndex: 'videoPlayCount',
            align: 'center',
            width: 120
        },
        {
            title: '奖励金额(元)',
            dataIndex: 'rewardMoney',
            align: 'center',
            width: 120
        }
    ];
    const [dataSource, setDataSource] = useState([]);
    const [onOpenRewardRecords, setOnOpenRewardRecords] = useState<boolean>(false);
    const [sellerId, setSellerId] = useState<string>( props?.details?.adviserReferSellerId );

    useEffect(()=>{
        if(props?.details?.id||sellerId){
            getList(1);
        }
    },[props?.details?.id,sellerId]);

    const getList = async (params:any) => {
        console.log(params);
        if(params.pageNo) {
            setPageNo(params.pageNo);
        };
        let host:any=sessionStorage.getItem("host");
        let url = `/mgr/ddlk/consumertask/queryRewardRecords`;
        Request.jsonPost(EHostType.DDlk, url, {
            taskId: props?.details?.id,
            sellerId: sellerId,
            pageNo:params.pageNo?params.pageNo:pageNo,
            pageSize
        }).then(res => {
            if (res.data) {
                setDataSource(res?.data?.map(item => {
                    return {
                        ...item,
                        rewardMoney: item.rewardMoney?`${item.rewardMoney/100}`:0,
                        videoPlayCount: item.videoPlayCount?item.videoPlayCount:0
                    }
                }));
                setTotal(res?.totalCount);
            }
        })
    }

    return (
        <div>
            <Button type="primary"  onClick={()=>{setOnOpenRewardRecords(true)}}>明细</Button>
            <Modal title="奖金发放记录" footer={null} width={1200} visible={onOpenRewardRecords} onCancel={()=>{ setOnOpenRewardRecords(false) }} destroyOnClose>
                <Form onValuesChange={(current,all)=>{ if(all.hasOwnProperty('sellerId')){ setSellerId(all.sellerId) }}}>
                    <Row gutter={24} >
                        <Col >视频发布后{props?.details?.examineDays}天内,</Col>
                        <Col span={6} >
                            <div style={{display:'flex',flexWrap:"nowrap",width:"100%"}}>
                                {props?.details?.rewardConfig&&<div style={{display:'flex',flexDirection:'column'}}>
                                    {Object.keys(props?.details?.rewardConfig).map(item => {return <span>播放量 {item}</span>})}
                                </div>}
                                {props?.details?.rewardConfig&&<span style={{display:'flex',flexDirection:'column',marginLeft:'8px'}}>
                                    {Object.values(props?.details?.rewardConfig).map((item:any) => {return <span>奖励 {item} 元</span>})}
                                </span>}
                            </div>
                        </Col>

                        {props?.details?.subStore?.length>0&&<Col span={6} offset={9}>
                            <Form.Item label=""  name="sellerId" >
                                <Select placeholder="查看参与门店" allowClear>
                                    {props?.details?.subStore?.map((item) => {
                                        return (
                                            <Option key={item.split(",")[0] + 'sellerId'} value={item.split(",")[0]}>{item.split(",")[1]}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>}
                    </Row>
                </Form>

                <Table
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{ y: 620 }}
                    pagination={{
                        pageSize: pageSize,
                        current: pageNo,
                        total: total,
                        showSizeChanger:false,
                        showTotal: (total) => `共 ${total} 条数据`,
                        onChange:(page:number)=>{ getList({ pageNo:page })}
                    }}
                    style={{margin:"24px 0 0"}}
                />
            </Modal>
        </div>
    )
}
