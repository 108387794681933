import {message} from "antd";
import {Base64} from "js-base64";
import Request, {EHostType} from "./Request";

export const isVideos = (path: string) => {
  return (path?.endsWith("MP4") || path?.endsWith("mp4") || path?.endsWith("mov") || path?.endsWith("MOV"));
};

export const isOEM = ()=>{
  return  sessionStorage.getItem("brand")!=="云猫探店";
}

export default class Utils {
  /** 转成base64 */
  public static toBase64(obj: any) {
    return Base64.toBase64(obj);
  }

  public static uploadImage = (blobInfo, progress) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      let host:any=sessionStorage.getItem("host");

      xhr.withCredentials = false;
      xhr.open("POST", `${JSON.parse(host)?.ddlk}/mgr/ddlk/article/uploadArticle`);
      xhr.setRequestHeader("Authorization", sessionStorage.getItem("token")!);
      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status === 403) {
          reject({ message: "HTTP Error: " + xhr.status, remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }
        const json = JSON.parse(xhr.responseText);

        if (!json || typeof json.data != "string") {
          reject("Invalid JSON: " + xhr.responseText);
          return;
        }

        resolve(json.data);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      formData.append("articleId", "0");
      formData.append("fullArticle", "true");
      xhr.send(formData);
    });
    // console.log("uploadImage", blobInfo.filename(), success);
    // setTimeout(() => {}, 2000);
    // new Promise((resolve) => {
    //   resolve(1);
    // });
    // success("http://moxiecode.cachefly.net/tinymce/v9/images/logo.png");

    // var xhr, formData;

    // xhr = new XMLHttpRequest();
    // xhr.withCredentials = false;
    // xhr.open("POST", url);
    // xhr.setRequestHeader("Authorization", sessionStorage.getItem("token"));

    // xhr.upload.onprogress = function (e) {
    //   console.log(progress, e, "kkkkkkkkkk");
    //   progress && progress((e.loaded / e.total) * 100);
    // };

    // xhr.onload = function () {
    //   var json;

    //   if (xhr.status === 403) {
    //     failure("HTTP Error: " + xhr.status, { remove: true });
    //     return;
    //   }

    //   if (xhr.status < 200 || xhr.status >= 300) {
    //     failure("HTTP Error: " + xhr.status);
    //     return;
    //   }

    //   json = JSON.parse(xhr.responseText);

    //   if (!json || typeof json.data !== "string") {
    //     failure("错误: " + xhr.responseText);
    //     return;
    //   }

    //   success(json.data);
    // };

    // xhr.onerror = function () {
    //   failure("Error: " + xhr.status);
    // };

    // formData = new FormData();
    // formData.append("fullAddress", "true");
    // formData.append("file", blobInfo.blob(), blobInfo.filename());

    // xhr.send(formData);
  };

  public static uploadFile(callback: any, value: any, meta: any): void {
    console.log(value, meta);
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    // input.setAttribute("accept", filetype);
    input.click();
    input.onchange = function () {
      const file = (this as any).files[0];
      console.log(file, file.name);
      let host:any=sessionStorage.getItem("host");

      const url = `/mgr/ddlk/article/uploadArticle`,
        formdata = new FormData();
      formdata.append("file", file);
      formdata.append("articleId", "0");
      formdata.append("fullArticle", "true");
      Request.postFormData(EHostType.DDlk, url, formdata).then((res) => {
        console.log(res);
        callback(res.data, { text: file.name });
      });
    };
  }

  public static getUUID(len = 32) {
    let radix = 16;
    let chars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
        ""
      );
    let uuid: any = [];
    let i: any = 0;
    radix = radix || chars.length;
    if (len) {
      for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
    } else {
      var r;
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
      uuid[14] = "4";
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16);
          uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    return uuid.join("");
  }

  /** 数组删除对象 */
  public static listSplice(list: Array<any>, obj: any) {
    if (obj instanceof Function) {
      list.forEach((item, idx) => {
        if (item.toString() === obj.toString()) list.splice(idx, 1);
      });
    } else {
      let index = list.indexOf(obj);
      if (index >= 0) list.splice(index, 1);
    }
  }

  /** 显示信息 */
  public static showMsg(text: string, type: string = "info") {
    message[type](text);
  }

  public static equals(obj1: any, obj2: any) {
    if (obj1 === undefined) return false;
    let keys = Object.keys(obj2);
    for (let i = 0; i < keys.length; i++) {
      if (obj1[keys[i]] !== obj2[keys[i]]) return false;
    }
    return true;
  }

  /** 根据数据生成提交的Form表单 */
  public static recordToForm(record: {}) {
    let formData = new FormData();
    Object.keys(record).map((key) => {
      let values =
        record[key] instanceof Object
          ? JSON.stringify(record[key])
          : record[key];
      return formData.append(key, values);
    }, this);
    return formData;
  }

  static getInitParams() {
    let params = window.sessionStorage.getItem("ddmap_params") || "{}";
    return JSON.parse(params);
  }

  /**
   * 获取AppServerIP
   */
  static getAppServerIP() {
    let json = this.getInitParams();
    return json.AppServerIP;
  }

  /** 判断是否为空 */
  public static isEmpty(obj: any) {
    return obj === null || obj === undefined || obj === "" || (JSON.stringify(obj) == "{}");
  }


  public static eval(str: string) {
    let fn = Function;
    return new fn("return " + str)();
  }

  private static JSCache: any = {};

  /** html模版内容替换 */
  public static htmlParse(template: string, json: any) {
    return template.replace(/{[a-z|0-9|A-Z]+}/gi, (v) => {
      return json[v.substring(1, v.length - 1)] || "";
    });
  }

  public static inputValidator(val: any) {
    var pattern = new RegExp(
      "[/S`~!@#$^&*()=|{}':;',\\[\\].<>/S《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？/S$]"
    );
    if (pattern.test(val)) {
      this.showMsg("不可输入特殊字符或空格!", "warn");
      return false;
    }
    var reg = /^[^\s]*$/;
    if (!reg.test(val)) {
      this.showMsg("输入不能包含空格!", "warn");
      return false;
    }
    if (val.trim().length === 0) {
      this.showMsg("输入不能为空!", "warn");
      return false;
    }
    return true;
  }

  public static format(dt: any, fmt: string) {
    if (!fmt) {
      fmt = "yyyy-MM-dd hh:mm:ss";
    }
    let o = {
      "M+": dt.getMonth() + 1,
      "d+": dt.getDate(),
      "h+": dt.getHours(),
      "m+": dt.getMinutes(),
      "s+": dt.getSeconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (dt.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return fmt;
  }


}
