/*
 * @Date: 2022-10-13 18:58:53
 * @LastEditors: litj
 * @LastEditTime: 2022-10-15 10:08:28
 * @FilePath: /leetcode/Applications/编程/code/j工作/饷猫科技/shxm-boss-web/src/module/page/DDLK/CloudAmountConfig/source/action.ts
 */
import moment from "moment";
import Request, {EHostType} from "../../../../utils/Request";
//  请求列表页数据
export const getList = (fn: (data: any) => void, data: any = {}) => {
  const params = { ...data };
  if (data.residence && data.residence.length > 0) {
    params.adviserProvince = data.residence[0];
    params.adviserCity = data.residence[1];
  }
  if (data.date && data.date.length > 0) {
    params.createTimeFrom = moment(data.date[0]).format("YYYY-MM-DD HH:mm:ss");
    params.createTimeTo = moment(data.date[1]).format("YYYY-MM-DD HH:mm:ss");
  }
  params.pageNo = data.current;
  const url = `/mgr/ddlk/queryAllCloudVisitConfig`;
  return Request.jsonPost(EHostType.DDlk,url, params);
};
// 删除列表页数据
export const dataDelete = (id) => {
  const url = `/mgr/ddlk/delCloudVisitConfig?id=${id}`;
  return Request.jsonPost(EHostType.DDlk,url, {});
};
// 修改数据
export const dataUpdate = (obj) => {
  let url = `/mgr/ddlk/saveOrUpdateCloudVisitConfig`;
  return Request.jsonPost(EHostType.DDlk,url, obj);
};
