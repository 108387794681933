import { Button, Form, InputNumber, message, Popover, Table, Tabs, Typography, } from "antd";
import React, { useEffect, useState } from "react";
import Request, { EHostType } from "../../../../../utils/Request";
import { TaskAudit } from "./taskAudit";
import moment from "moment";
import { verifyStatusMap } from "../../../helper";

const { TabPane } = Tabs;

interface IProps {
  onOpenDetail: any;
  searchParams: any;
  tab: string;
  isLimits: boolean | undefined;
  isDetail: boolean | undefined;
  isCustom: boolean | undefined;
  menuType: any;
  getQueryType: any;
  isShowModal: any;
  flag: any;
  // onOpenJoinList:any
}
let host: any = sessionStorage.getItem("host");
const EditCell = (props) => {
  const [form] = Form.useForm();
  const save = async () => {
    form.setFieldsValue({
      saleNo: props.item.saleNo,
    });
    // console.log((inputRef.current! as any).state.value);
    const values = await (form as any).validateFields();
    props.handleSave(props.item.key);
  };
  return (
    <Form form={form}>
      <Form.Item
        style={{ margin: 0 }}
        name="saleNo"
        rules={[{ required: true, message: `请输入销量.` }]}
      >
        <InputNumber
          min={0}
          value={props.item.saleNo}
          onChange={(value) => {
            props.item.saleNo = value;
          }}
        />
        <Typography.Link onClick={save}>完成</Typography.Link>
      </Form.Item>
    </Form>
  );
};

export const TaskListTable = (props: IProps) => {
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize] = useState<number>(6);
  const [total, setTotal] = useState<number>(0);
  const [queryType, setQueryType] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [loading, setloading] = useState(false);
  const [isShow, setIsShow] = useState<string | number>("");
  const oemId = sessionStorage.getItem('operatingEntityId');
  const isShowGoodTaskColumn = sessionStorage.getItem("functionLimits")?.includes("a15");
  let isShowOemTitle = false;
  if (oemId == "43" || oemId == "1") {
    isShowOemTitle = true;
  } else {
    isShowOemTitle = false;
  };
  const handleSave: (index: number) => void = (index: number) => {
    const dataSourceMap = [...dataSource];
    (dataSourceMap[index] as any).edit = false;
    salaSubmit(dataSourceMap[index]);
  };
  const [tableHeight, setTableHeight] = useState(300);
  /**表格高度自适应 */
  const tableAdapt = () => {
    let search: any = props.menuType ? 300 : document.getElementsByClassName("search-container" + props.tab)[0]
      .clientHeight +
      265 +
      40;
    setTableHeight(search);
  };

  useEffect(() => {
    tableAdapt();
  }, []);
  const customTag = JSON.parse(sessionStorage.getItem('ddlkPlatformSellerDTO') || '0');
  const salaSubmit = async (detailInfo) => {
    const url = `/mgr/ddlk/visitshop/saveTask`;
    const { id, saleNo } = detailInfo;
    const resp = await Request.jsonPost(EHostType.DDlk, url, { id, saleNo: saleNo });
    if (resp.code == 0) {
      setDataSource([...dataSource]);
      message.success("提交成功");
    }
  };




  const setShowOrconceal = (i) => {
    // if(i.isShow){
    //   setIsShow(0)
    // }else{
    //   setIsShow(1)
    // };
    let isShow = i.isShow ? 0 : 1;
    console.log(i.isShow, isShow, 'isShow')
    let params = {
      taskType: 3,
      darenType: [0]
    };
    Request.jsonPost(EHostType.DDlk, `/mgr/ddlk/displayExcellentTask?taskId=${i.id}&isShow=${isShow}`, { ...params }).then(res => {
      if (res.data) {
        let listParams = {
          pageNo: pageNo,
          queryType: props.menuType ? null : ""
        };
        message.open({
          type: 'success',
          content: '操作成功',
        });
        getList(listParams);
      }
    })

  }

  const getList = async (params: any) => {
    if (props.menuType) {
      setQueryType("");
    };
    if (params.pageNo) {
      setPageNo(params.pageNo);
    };
    let host: any = sessionStorage.getItem("host");
    let url = props.menuType ? `mgr/ddlk/excellentTask/queryExcellentTaskV2` : `/mgr/ddlk/visitshop/queryAllTask`;
    setloading(true);
    Request.jsonPost(EHostType.DDlk, url, {
      pageNo: params.pageNo ? params.pageNo : pageNo,
      pageSize,
      queryType: props.menuType ? null : params.queryType,
      taskType: props.menuType ? 3 : '',
      darenType: null
    })
      .then((res) => {
        if (res?.code == 0) {
          setDataSource(
            res?.data?.map((item, index) => {
              const startTime = moment(
                item.pubTimeFrom,
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD");
              const endTime = moment(
                item.pubTimeTo,
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD");
              if (props.menuType) {
                item.status = item.taskStatus;
                item.name = item.taskName;
              };
              return {
                ...item,
                key: index,
                totalPaidFeeText: `${item.totalPaidFee / 100}元`,
                comboTypeText: { "1": "普通套餐", "2": "省心套餐" }[
                  item.comboType
                ],
                timeRange:
                  item?.pubTimeFrom && item?.pubTimeFrom
                    ? `${startTime}-${endTime}`
                    : "-",
                taskStatusText: {
                  "1": "待配置",
                  "2": "待审核",
                  "3": "审核通过",
                  "4": "审核未通过",
                  "5": "已取消",
                }[item.taskStatus],
              };
            })
          );
          setTotal(res.totalCount);
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (props.searchParams || props.flag) {
      props.searchParams.queryType = queryType;
      getList({ ...props.searchParams, pageNo: 1 });
    } else {
      getList({ pageNo: 1 });
    }
  }, [props.searchParams, props.flag, queryType]);

  let columns: any = [
    {
      title: "任务id",
      dataIndex: props.menuType ? "taskId" : "id",
      key: "id",
      width: 80,
      align: "center",
      fixed: "left",
    },
    {
      title: "任务标题",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 200,
      fixed: "left",
    },
    {
      title: "关联商家",
      dataIndex: "adviserUserName",
      key: "adviserUserName",
      align: "center",
      width: 200,
      fixed: "left",
      render: (row, i) => {
        return (
          <>
            {
              props.menuType ? '' : <><p>id：{i.adviserReferSellerId}</p></>
            }

            <p>{props.menuType ? '' : '名称：'}{i.adviserUserName}</p>
          </>
        );
      },
    },
    {
      title: "OEM名称",
      dataIndex: "oemOperatingEntityName",
      key: "oemOperatingEntityName",
      align: "center",
      width: 200,
      fixed: "left",
    },
    {
      title: "任务类型",
      dataIndex: "comboTypeText",
      key: "comboTypeText",
      width: 120,
      align: "center",
      fixed: "left",
      render: () => {
        return customTag.visitShopCustName || '达人探店';
      },
    },
    {
      title: "任务规则",
      dataIndex: "taskDesc",
      key: "taskDesc",
      width: 120,
      align: "center",
      render: (row, i) => {
        return (
          <>
            {/*<p>单价：{i.eachDarenPrice / 100}元/人</p>*/}
            <p>人数：{i.darenNum}人</p>
          </>
        );
      },
    },
    {
      title: "支付金额",
      dataIndex: "totalPaidFee",
      key: "totalPaidFee",
      width: 100,
      align: "center",
      render: (row, i) => {
        return i.totalPaidFee && i.totalPaidFee / 100;
      },
    },
    {
      title: "累计发放金额",
      dataIndex: "sendToTalentTotal",
      key: "sendToTalentTotal",
      width: 80,
      align: "center",
      render: (row, i) => {
        return i.sendToTalentTotal && i.sendToTalentTotal / 100;
      },
    },
    {
      title: "收取服务费总金额",
      dataIndex: "serviceFee",
      key: "serviceFee",
      width: 150,
      align: "center",
      render: (row, i) => {
        return i.serviceFee && i.serviceFee / 100;
      },
    },
    {
      title: "退款金额",
      dataIndex: "refundFee",
      key: "refundFee",
      width: 100,
      align: "center",
      render: (row, i) => {
        return <div>{i.refundFee ? i.refundFee / 100 : "-"}</div>;
      },
    },
    {
      title: "探店时间",
      dataIndex: "timeRange",
      key: "timerange",
      align: "center",
      width: 200,
      fixed: "right",
      render: (row, i) => {
        return <span>{i.visitTimeFrom + "-" + i.visitTimeTo}</span>;
      },
    },
    {
      title: "发布时间",
      dataIndex: "publishTime",
      key: "publishTime",
      align: "center",
      width: 200,
      fixed: "right",
      render: (row, i) => {
        return <span>{i.publishTime}</span>;
      },
    },
    {
      title: "任务状态",
      dataIndex: "taskStatusText",
      key: "taskStatusText",
      align: "center",
      width: 100,
      fixed: "right",
      render: (row, i) => {
        return (
          <div>
            {(i?.status === 1 || i?.status === 4) && (
              <div style={{ color: "#FFAA33" }}>
                {verifyStatusMap[i.status]}
              </div>
            )}
            {i?.status === 2 && (
              <div style={{ color: "#26BF4D" }}>
                {verifyStatusMap[i.status]}
              </div>
            )}
            {i?.status === 3 && (
              <Popover
                placement="topLeft"
                title="驳回原因"
                content={i?.notApprovedReason}
                arrowPointAtCenter
              >
                <div style={{ color: "#ff1e1e" }}>
                  {verifyStatusMap[i.status]}
                </div>
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: "商家端首页展示",
      dataIndex: "isShow",
      key: "isShow",
      width: 100,
      render: (row, i) => {
        return (
          <div>
            <Button
              type="link"
              onClick={() => {
                props.isShowModal(true, i.id, JSON.stringify(i))
              }}
            >
              {i.isAppShow ? "编辑案例" : "展示图文案例"}
            </Button>
            {
              i.isAppShow ?
                <Button
                  type="link"
                  onClick={() => {
                    props.isShowModal(false, i.id, JSON.stringify(i))
                  }}
                >
                  取消展示
                </Button> : ''
            }
          </div>
        );
      },
    },
    //1， 待审核，2， 审核通过，3，审核不通过
    {
      title: "操作",
      dataIndex: "operate",
      key: "operate",
      width: 100,
      fixed: "right",
      render: (row, i) => {
        return (
          <div>
            {
              props.isDetail && !props.menuType ? <Button
                type="link"
                onClick={() => {
                  props.onOpenDetail(i);
                }}
              >
                详情
              </Button> : ""
            }
            {(i.status == 1 || i.status == 4) && (
              <TaskAudit isAudit={props.isLimits} item={i} sellerType={i.adviserReferSellerType} onAuditSuccess={() => getList({})} />
            )}
            {i.status == 2 && (
              <Button
                type="link"
                onClick={() => {
                  props.onOpenDetail(i, "2");
                }}
              >
                参与列表
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const onTabsChange = (key: string) => {
    setQueryType(key);
    props.getQueryType(key);
  };

  const getColumns = () => {
    if (!props.isLimits) {
      return columns.filter(item => item.title != "OEM名称" && item.title != "发布时间" && item.title != "商家端首页展示");
    } else if (props.isLimits && !props.menuType) {
      return columns.filter(item => item.title != "OEM名称" && item.title != "发布时间" && item.title != "商家端首页展示");
    } else if (!props.isLimits && !props.menuType) {
      return columns.filter(item => item.title != "OEM名称" && item.title != "商家端首页展示");
    } else if (props.menuType && !isShowOemTitle) {
      if (isShowGoodTaskColumn) {
        return columns.filter(item => item.title != "任务规则" && item.title != "累计发放金额" && item.title != "退款金额" && item.title != "收取服务费总金额" && item.title != "探店时间" && item.title != "OEM名称");
      } else {
        return columns.filter(item => item.title != "任务规则" && item.title != "累计发放金额" && item.title != "退款金额" && item.title != "收取服务费总金额" && item.title != "探店时间" && item.title != "OEM名称" && item.title != "商家端首页展示");
      }
    } else if (props.menuType && isShowOemTitle) {
      if (isShowGoodTaskColumn) {
        return columns.filter(item => item.title != "任务规则" && item.title != "累计发放金额" && item.title != "退款金额" && item.title != "收取服务费总金额" && item.title != "探店时间");
      } else {
        return columns.filter(item => item.title != "任务规则" && item.title != "累计发放金额" && item.title != "退款金额" && item.title != "收取服务费总金额" && item.title != "探店时间" && item.title != "商家端首页展示");
      }
    } else {
      return columns
    }
  }
  return (
    <div style={{ marginTop: "10px", background: "#fff" }}>
      <Tabs
        defaultActiveKey=""
        type="card"
        size="small"
        activeKey={queryType}
        onChange={(key) => onTabsChange(key)}
      >
        {!props.menuType && <TabPane tab="全部" key=""></TabPane>}
        {!props.menuType && <TabPane tab="进行中" key="undergoing"></TabPane>}
        {!props.menuType && <TabPane tab="已结束" key="isover"></TabPane>}
      </Tabs>

      <Table
        tableLayout="auto"
        dataSource={dataSource}
        columns={getColumns()}
        scroll={{ x: 700, y: `calc(100vh - ${tableHeight}px)` }}
        loading={loading}
        size={"small"}
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: total,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: (page: number) => {
            getList({ ...props.searchParams, pageNo: page });
          },
          style: {
            background: "#fff",
          },
        }}
      />
    </div>
  );
};
