/*
 * @Date: 2022-04-12 18:36:34
 * @LastEditors: litj
 * @LastEditTime: 2022-04-13 10:30:39 */
import React, { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import * as utils from "../../../../utils/Utils";
// var ReactUeditor = require("react-ueditor").default;
// var Ueditor = require("ueditor");

// var Ueditor from "ueditor";
// var Editor = require("react-umeditor").default;

export default function EditorW(props) {
  // this.props.editorChange(this.getUEContent);

  // @ts-ignore
  // this.editRef.current.editor.ready(() => {
  //   // @ts-ignore
  //   this.editRef.current &&
  //     // @ts-ignore
  //     this.editRef.current.editor.setContent(this.props.content);
  // });
  // this.editRef.current.value = this.props.content;
  // @ts-ignore
  // console.log(ReactUeditor, "kljl");
  // UEditor.getEditor("container", {
  //   autoHeightEnabled: true,
  //   autoFloatEnabled: true,
  // });

  // 获取编辑器内容
  const editorRef = useRef(null);
  const [editorValue, setEditorValue] = useState(props.content);
  const videoReg = /\<\s*[a]\s+href=([\w\W]+)\>([\w\W]+)<\/[a]\>/g;

  const editorChange = (value) => {
    // if (value.includes("video")) {
    //   return;
    // }
    let str = value;
    let tag = getVideoSrc(value);
    if (tag) {
      const video = getVideo(tag);
      str = str.replace(videoReg, video);
    }

    setEditorValue(str);
    props.editorChange(str, props);
  };

  useEffect(() => {
    setEditorValue(props.content);
  }, [props.content]);

  const getVideo = (href: string) => {
    return `<video id="" src=${href} width="300" height="100" controls></video>`;
  };

  const getVideoSrc = (value) => {
    if (value == null) return value;
    const arr = videoReg.exec(value);
    // console.log(arr![1]);
    let href = "";
    if (
      arr &&
      arr.length > 1 &&
      utils.isVideos((href = arr![1].replace(/'|"/g, "")))
    ) {
      href = arr[1].replace(/\\/g, "");
      return href;
    } else {
      return false;
    }
  };

  return (
    <>
      <Editor
        apiKey="91c271umsfyurga84kzcsoefvm4qea78yqx721yhilrxcfef"
        value={editorValue}
        onEditorChange={editorChange}
        onInit={(evt, editor) => ((editorRef.current as any) = editor)}
        initialValue={props.content}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
          ],
          ["images_upload_handler" as any]: utils.default.uploadImage,
          file_picker_callback: utils.default.uploadFile,
          //  => function (
          //   blobInfo,
          //   success,
          //   failure,
          //   progress
          // ) {
          //   utils.uploadImage(
          //     blobInfo,
          //     success,
          //     failure,
          //     progress,
          //     `${JSON.parse(host)?.xmgx}/seller/item/itemMaterialsUpload`
          //   );
          // },
          images_reuse_filename: true,
          images_upload_credentials: true,
          convert_urls: false, //这个参数加上去就可以了
          // images_upload_url:
          //   `${JSON.parse(host)?.xmgx}/seller/item/itemMaterialsUpload`,
          // images_upload_base_path: "/some/basepath",
          file_picker_types: "file  media",
          toolbar: [
            "bold italic underline  alignleft aligncenter alignright alignjustify bullist numlist | outdent indent removeformat blocks | fullscreen code fontsizeselect forecolor formatselect backcolor | link table   image insertfile",
          ],
          // menubar: false,
          // "undo redo | blocks | image | " +
          // "bold italic forecolor | alignleft aligncenter " +
          // "alignright alignjustify  | bullist numlist outdent indent | " +
          // "removeformat | help",
          language: "zh_CN",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </>
    // <div></div>
    // <Editor
    //   icons={icons}
    //   value={this.state.content}
    //   defaultValue="<p>React Umeditor</p>"
    //   onChange={this.handleChange.bind(this)}
    //   plugins={plugins}
    // />
    // <div id="editor"></div>
    // <RcUeditor
    //   value={this.props.content}
    //   onChange={this.hanldeChage}
    //   style={{ height: "800px" }}
    //   getEditorInstance={this.getEditor}
    //   // ueditorUrl="https://xmwebstatic.oss-accelerate.aliyuncs.com/ddlkh5/ueditor.all.js"
    //   // ueditorConfigUrl="https://xmwebstatic.oss-accelerate.aliyuncs.com/ddlkh5/ueditor.config.js"
    // />
  );
}
