import {Input, Popover, Select, Table} from "antd";
import Button from "antd/es/button";
import React, {useEffect, useState} from "react";
import Request, {EHostType} from "../../../../../../utils/Request";
import {verifyStatus} from "../../../../helper";
import {AuditModal} from "./audit";

const { Option } = Select;
interface IProps {
  currenItem?: any;
  subStore;
  worksAudit:boolean|undefined;
  queryType:any;
  menuType:any;
}

export const TalentList = (props: IProps) => {
  let columns = [
    {
      title: "达人ID",
      dataIndex: "darenUserId",
      key: "darenUserId",
      width: 200,
      // align: 'center'
    },
    {
      title: "达人信息",
      key: "darenUserNick",
      width: 250,
      // align: 'center',
      render: (row, i) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>昵称:{i.darenDyUserNick ? i.darenDyUserNick : "-"}</div>
            {
              props.queryType != 'goodExamples' ? <div> <div>抖音号:{i.darenUserId}</div>
              {i.phone && <div>手机号:{i.phone}</div>}</div>:""
            }
          </div>
        );
      },
    },
    {
      title: "作品上传时间",
      dataIndex: "returnVideoTime",
      key: "returnVideoTime",
      width: "150px",
    },
    {
      title: "作品预览",
      dataIndex: "dyVideoUrl",
      key: "dyVideoUrl",
      // align: 'center',
      width: "100px",
      render: (row, i) => {
        return (
          <div>
            <img
              src={i.videoCover}
              style={{ width: "100px", height: "100px", cursor: "pointer" }}
              onClick={() => {
                window.open(i.videoUrl);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "作品数据",
      dataIndex: "summery",
      key: "summery",
      width: "100px",
      render: (row, i) => {
        return (
          <div>
            <div>播放量:{i.videoPlayCount}</div>
            <div>点赞:{i.videoDiggCount}</div>
            <div>评论量:{i.videoCommenCount}</div>
            <div>转发量:{i.videoShareCount}</div>
          </div>
        );
      },
    },
    {
      title: "奖励情况",
      dataIndex: "rewardMoney",
      key: "rewardMoney",
      width: "80px",
      render: (row, i) => {
        return <div>{i.rewardMoney ? i.rewardMoney / 100 : 0}元</div>;
      },
    },
    {
      title: "作品状态",
      dataIndex: "verifyStatusText",
      key: "verifyStatusText",
      width: "80px",
      render: (row, i) => {
        return (
          <div>
            {i?.status === 1 && (
              <div style={{ color: "#FFAA33" }}>{i.verifyStatusText}</div>
            )}
            {i?.status == 2 && (
              <div style={{ color: "#26BF4D" }}>{i.verifyStatusText}</div>
            )}
            {i?.status == 3 && (
              <Popover
                placement="topLeft"
                title="未达标原因"
                content={i?.notApproveReason}
                arrowPointAtCenter
              >
                <div style={{ color: "#ff1e1e" }}>{i.verifyStatusText}</div>
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "operate",
      key: "operate",
      width: "80px",
      render: (row, i) => {
        return (
          <div>
            {i.verifyStatus == 1 && (
              <AuditModal id={i.id} onAuditSuccess={() => getList({})} />
            )}
            {i.verifyStatus != 1 && (
              <Button type="link" disabled>
                作品审核
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(6);
  const [total, setTotal] = useState<number>(0);
  const [summery, setSummery] = useState<any>({});
  const [darenPhone, setDarenPhone] = useState<string>("");
  const [subTaskId, setSubTaskId] = useState<string>("");
  //list
  let host: any = sessionStorage.getItem("host");

  const getList = async (params: any) => {
    let host: any = sessionStorage.getItem("host");
    let url = `/mgr/ddlk/consumertask/queryAllSubTask`;
    Request.jsonPost(EHostType.DDlk, url, {
      darenPhone: darenPhone,
      pageNo: params.pageNo ? params.pageNo : pageNo,
      pageSize,
      taskId: subTaskId ? subTaskId :(props.menuType ? props.currenItem.taskId :  props.currenItem.id),
      videoSubmit: true,
    }).then((res) => {
      if (params)
        setDataSource(
          res?.data?.map((item) => {
            return {
              ...item,
              verifyStatusText: verifyStatus[item.status],
              upToStandardText: item.upToStandard ? "是" : "否",
            };
          })
        );
      setTotal(res?.totalCount);
    });
  };

  //统计
  const getSummery = () => {
    let host: any = sessionStorage.getItem("host");
    let url = `/mgr/ddlk/consumertask/getTaskSummary?id=${
      subTaskId ? subTaskId :(props.currenItem.taskId ? props.currenItem.taskId :  props.currenItem.id) 
    }`;
    Request.jsonPost(EHostType.DDlk, url, { id: props.currenItem.taskId ? props.currenItem.taskId :  props.currenItem.id }).then((res) => {
      setSummery(res.data);
    });
  };

  useEffect(() => {
    getList({});
    getSummery();
  }, []);


  const getColums = () => {
    if(!props.worksAudit){
      return columns.filter(item => item.title !="操作")
    }else if(props.menuType){
      return columns.filter(item => item.title !="操作")
    }else{
      return columns;
    };
  }

  return (
    <div style={{ marginTop: "10px" }}>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <div style={{ flex: 1 }}>
            {summery?.joinedDarenNum}人<br />
            <span>报名人数</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.videoNum}人<br />
            <span>提交视频数</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.qualifiedDarenNum}人<br />
            <span>达标人数</span>
          </div>

          <div style={{ flex: 1 }}>
            {summery.totalPlayCount}
            <br />
            <span>总播放量</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.totalDiggCount}
            <br />
            <span>总点赞量</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.totalCommentCount}
            <br />
            <span>总评论量</span>
          </div>
          <div style={{ flex: 1 }}>
            {summery.totalShareCount}
            <br />
            <span>总转发量</span>
          </div>
        </div>
      </div>
      {
        props.queryType != 'goodExamples' ?  <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "10px",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <span>达人手机号：</span>
        <Input
          value={darenPhone}
          onChange={(e) => {
            setDarenPhone(e.target.value);
          }}
          style={{ width: "260px" }}
        />
        {props.subStore.length > 0 && (
          <>
            <span style={{ marginLeft: "20px" }}>子门店筛选：</span>
            <Select
              style={{ width: "260px", marginRight: "20px" }}
              onChange={(e: any) => {
                setSubTaskId(e);
              }}
            >
              {props.subStore.map((e, i) => {
                const subArr = e.split(",");
                return (
                  <Option key={i} value={subArr[2]}>
                    {subArr[1]}
                  </Option>
                );
              })}
            </Select>
          </>
        )}
        <Button
          type="primary"
          onClick={() => {
            getList(1);
            subTaskId && getSummery();
          }}
        >
          搜索
        </Button>
      </div>:"" 
      }
     

      <Table
        dataSource={dataSource}
        columns={getColums()}
        scroll={{ y: 600 }}
        pagination={{
          pageSize: pageSize,
          total: total,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: (page: number) => {
            getList({ pageNo: page });
          },
        }}
      />
    </div>
  );
};
