/*
 * @Date: 2022-07-11 18:47:45
 * @LastEditors: litj
 * @LastEditTime: 2022-07-21 11:34:17
 * @FilePath: \沈宇c:\Users\EDY\Desktop\项目代码\bkm-admin\src\module\page\DDLK\PromotionMange\Cloud\detail\info\components\TaskConfig.tsx
 */
import {Input, message, Select, Typography} from "antd";
// import { info } from "console";
import InputNumber from "rc-input-number";
import React, {useEffect, useState} from "react";
import {darenLevel} from "../../../../../helper";
import {getConfig} from "../index";
import Request, {EHostType} from "../../../../../../../utils/Request";
import _ from "lodash";
import "./index.css";


let taskList: any[] = [];

export default function TaskConfig(props: {
  //编辑或完成状态
  isEditTask: any;
  configKeyLevel: any;
  //奖励金额
  configValue: any;
  //当前是第几个奖励配置
  configIndex: string;
  //达人等级
  configKey: any;
  taskConfigList: any[];
  taskId: any;
  shareBonusFee: any;
  darenType:number
  handelEditClick: (arg0: any, arg1: any, arg2: boolean, arg3: any) => void;
  handelEditClear: (arg0: any, arg1: any) => void;
  handleLevelChange:(arg0: any,arg1:any) => void;
}) {
  console.log("taskId:"+props.taskId+",configKey:"+props.configKey+",configValue:"+props.configValue);
  const [isEditTask, setIsEditTask] = useState(props.isEditTask);
  const [rewardCount, setRewardCount] = useState(props.configValue);
  const [configKey,setConfigKey] = useState<string>(props.configKey)
  //const [flag,setFlag] = useState(true);
  const [darenType,setDarenType] = useState<number>(props.darenType);

  let configReward = props.taskConfigList.filter(item => {
    return item.configKey
  })
  // const [opeionsItem,setOptionsItem] = useState<Array<{}>>([])
  let rewardConfigMap = {
    min: 0,
    max: 0,
  };
   let optionsItem = darenLevel.map(item => {
    return {
      value: item, label: item
    }
   });
  useEffect(() => {
    console.log('props.configKey:'+props.configKey+',props.configValue:'+props.configValue+',props.darenType:'+props.darenType);
    setConfigKey(props.configKey)
    setDarenType(props.darenType);
    setRewardCount(props.configValue);
  }, [props.configValue,props.configKey,props.taskId]);
  // 校验配置项
  const checkResonableness = async (
    rewardCount,
    configKey,
    configIndex,
    taskConfigList
  ) => {
    // console.log(configKey, taskConfigList, "iuiuiuiui");
    // 清空状态 不校验 默认验证通过
    if (!rewardCount && configKey < 0) return true;
    let slow = configIndex - 1;//上一个奖励配置
    let fast = configIndex + 1;//下一个奖励配置
    if (taskConfigList[fast] && rewardCount > taskConfigList[fast].value) {
      message.info("当前金额大于下一项的金额");
      return false;
    }
    if (taskConfigList[slow] && rewardCount < taskConfigList[slow].value) {
      message.info("当前金额小于上一项的金额");
      return false;
    }

    if (taskConfigList[fast] && configKey >= taskConfigList[fast].configKey) {
      message.info("当前等级高于/等于下一项的等级");
      return false;
    }
    if (taskConfigList[slow] && configKey <= taskConfigList[slow].configKey) {
      message.info("当前等级低于/等于上一项的等级");
      return false;
    }
    // console.log(rewardCount);
    if (rewardCount && configKey >= 0) {
      const arr = _.cloneDeep(taskConfigList);
      arr[configIndex].configKey = configKey;
      arr[configIndex].value = rewardCount;
      const rewardConfig = getConfig(arr);
      
      // rewardConfig[configKey] = rewardCount;
      // console.log(rewardConfig, ";;;;;;;;;;");
      const url = `/mgr/ddlk/taskAudit/cloudVisitShop/detail/profit`;
      const res = await Request.jsonPost(EHostType.DDlk, url, {
        rewardConfig,
        taskId: props.taskId,
        shareBonus: props.shareBonusFee,
      });
      rewardConfigMap = {
        min: res.data.min / 100,
        max: res.data.max / 100,
      };
      const { min, max } = res.data;
      // console.log(rewardCount, min / 100, max / 100);
      if (min < 0) {
        message.info("收益小于零");
        return false;
      } else {
        return true;
      }
      // console.log(res, "kkj6666666666");
      // console.log(123);
    }

    return true;
  };
  // 点击进行修改或者确认
  const handelEditClick = async () => {
    //setFlag(false);
    // console.log(taskList, "kkkkkkk", props);
    const editFlag = !isEditTask;
    if (props.configKeyLevel >= 0 && !editFlag && !rewardCount) {
      message.info("请输入奖励金额");
      //setFlag(true);
      return;
    }
    if (!editFlag && rewardCount >= 0 && !(props.configKeyLevel >= 0)) {
      message.info("请选择等级");
      //setFlag(true);
      return;
    }
    // 如果是修改  添加配置项
    if (!isEditTask) {
      taskList.push({
        [props.configIndex]: props.configKey,
      });
    } else {
      // 修改完成 移除配置项 并对配置项进行校验
      taskList = taskList.filter((task) => !(props.configIndex in task));
      const resonAble = await checkResonableness(
        rewardCount,
        props.configKeyLevel,
        props.configIndex,
        props.taskConfigList
      );
      //setFlag(true);
      if (!resonAble) {
        return;
      }
    }
    props.handelEditClick(
      rewardCount,
      props.configIndex,
      editFlag,
      taskList[taskList.length - 1]
    );
    setIsEditTask(editFlag);
  };
  // 清空事件 函数
  const handelEditClear = () => {
    props.handelEditClear(
      props.configIndex,
      taskList[taskList.length - 1]
      //  taskList[taskList.length - 1]
    );
    setRewardCount("");
  };
  // 修改金额函数
  const handelRewardChange = (e: any) => {
    setRewardCount(e);
  };

  const changeTalentLevel = (e) => {
    //debugger;
    props.handleLevelChange(e,'')
    setConfigKey(e.substring(2,3));
  }
  //console.log('default value:'+(configKey ? `LV${configKey}` : ""));
  let defaultValue=configKey ? `LV${configKey}` : "";
  //console.log("defaultValue:"+defaultValue)
  return (
    <div className="levelGroup">
      <div className="levelTitle">
        <p>达人等级</p>
        <p>奖励金额</p>
      </div>
      <div className="levelData">
        {/* <Input
          placeholder="暂无等级"
          disabled
          value={darenLevel[props.configKey]}
          style={{ width: 180, marginRight: 10 }}
        /> */}
        <div className="levelData">
        <Select
          disabled={ props.darenType == 1 || !isEditTask}
          value={ defaultValue}
          // value={configKey}
          style={{ width: 180 }}
          onChange={changeTalentLevel}
          options={optionsItem}
          // options={}
          // options={darenLevel.map((level,index) => {
          //   return level
          // }])}
        >
          {/* {darenLevel.map((level,index) => {
            return<Option value={index}>{level}</Option>
          })} */}
        </Select>
      </div>
        <div>
          <InputNumber
            min={1}
            max={1000000}
            step={1}
            value={rewardCount}
            onChange={handelRewardChange}
            disabled={!isEditTask}
            style={{ width: 180, marginLeft: 10 }}
          />
        </div>
        {/* {

           darenType == 2 ? :""
        } */}
        <Typography.Link className="ml-space mWidth" onClick={handelEditClick}>
           {isEditTask ? "完成" : "修改"}
         </Typography.Link>
         
       
        {/*{isEditTask && (*/}
        {/*  <Typography.Link*/}
        {/*    className="ml-space mWidth"*/}
        {/*    onClick={handelEditClear}*/}
        {/*  >*/}
        {/*    清空*/}
        {/*  </Typography.Link>*/}
        {/*)}*/}
      </div>
    </div>
  );
}
