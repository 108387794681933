/*
 * @Date: 2022-04-11 14:41:03
 * @LastEditors: litj
 * @LastEditTime: 2022-04-30 12:22:32
 * @FilePath: \算法c:\Users\Admin\Desktop\新建文件夹 (2)\bkm-admin\src\module\page\DDLK\PromotionMange\Talent\filter\index.tsx
 */
import * as React from "react";
import "../index.css";
import { Form, Input, DatePicker, Row, Col, Card, Button, Select,Modal,message, Upload  } from "antd";
import {useState} from "react";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  onSearch?: any;
  onShow?: any;
  tab: string;
  isLimits:boolean|undefined;
  queryType:any
}

export const TaskListFilter = (props: IProps) => {
  // @ts-ignore
  // @ts-ignore
  return (
      <div>
        {
          props.queryType != 'excellentTask' ? <Card className={"taskListFilterWrap " + "search-container" + props.tab}>
          <Form onFinish={props.onSearch} >
            <Row gutter={24}>
              <Col span={5}>
                <Form.Item label="商家名称" name="adviserUserName">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="任务名称" name="taskName">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Button type="primary" htmlType="submit">搜索</Button>
                {
                  props.isLimits ?  <Button
                  type="primary"
                  style={{ marginLeft: "10px" }}
                  onClick={()=>props.onShow()}>
                  上传探店沟通群
                </Button>:""
                }
               
              </Col>
            </Row>
          </Form>
        </Card>:""
        }
        

      </div>
  );
};
