import React,{useState,useEffect} from "react";
import * as Icon from "@ant-design/icons";
import { Input, Form, Table, Button, Select, message, Radio, Checkbox, Col, Row } from "antd";
import axios from "axios";
import "./index.css";
import Request, { EHostType } from "../../utils/Request";
import { CheckboxValueType } from "antd/es/checkbox/Group";


const color = {
  0: "https://xmwebstatic.oss-cn-hangzhou.aliyuncs.com/ddlkseller2.0/red.png",
  1: "https://xmwebstatic.oss-cn-hangzhou.aliyuncs.com/ddlkseller2.0/blue.png",
  2: "https://xmwebstatic.oss-cn-hangzhou.aliyuncs.com/ddlkseller2.0/orange.png",
  3: "https://xmwebstatic.oss-cn-hangzhou.aliyuncs.com/ddlkseller2.0/red2.png",
  4: "https://xmwebstatic.oss-cn-hangzhou.aliyuncs.com/ddlkseller2.0/purple.png"
}
const TalentHome: React.FC = () => {
  const [selectBgColor,setSelectBgColor] = useState<any>(0);
  const [bgcolorparams,setBgcolorparams] = useState<any>("");
  const [selectModule,setSelectModule] = useState<CheckboxValueType[]>([]);

  useEffect(()=>{
    defaultSelected();
  },[]);


  const success = () => {
        message.open({
          type: 'success',
          content: '保存成功',
        });
      };
  //切换背景颜色
  const onChangeBgColor = (e) => {
    setSelectBgColor(e.target.value);
    setBgcolorparams(color[e.target.value]);
  }
  const onChangeModul = (e) => {
    setSelectModule(e)
  }
  //提交
  const submitSelect = async () => {
    console.log(bgcolorparams,selectBgColor,'bgcolorparams')
    if(selectBgColor === null || selectBgColor === undefined) {
      message.open({
        type: 'warning',
        content: '请选择背景后再点击确认',
      });
    }else{
      let url = `mgr/ddlk/article/saveDarenPage`;
      let ret = await Request.jsonPost(EHostType.DDlk, url, {
        "backgrourd": bgcolorparams,
        "serverModel": []
      });
      if(ret.code == 0 ){
       success();
      }
    }
  }
  //默认选中
  const defaultSelected = async () => {
    let url = `mgr/ddlk/article/getDarenPage`;
    let ret = await Request.get(EHostType.DDlk, url);
    let defaultModule = JSON.parse('['+ret.data.serverModel+']') ;
    let mergeArray =  defaultModule.map(element => {
        return 'MODEL'+element 
    },[]);
    setSelectModule(mergeArray);
    let colorVal =  Object.values(color);
    let defaultColor = colorVal.find(item => {
      return item == ret.data.backgroud;
    })
    let defaultColorIndex = colorVal.findIndex(item => {
      return item == ret.data.backgroud;
    })
    // console.log(defaultColor,'=>>')
    setSelectBgColor(defaultColorIndex);
    setBgcolorparams(defaultColor);
  }
  return (
    <div className="container">
      <div className="header">达人小程序页面管理</div>
      <div className="container-content">
        <div>
          <div className="selectbg-container">
            <div className="title">背景选择</div>
            <div>
              <Radio.Group   onChange={onChangeBgColor} value={selectBgColor}>
                <Radio value={0}>
                  <div className="bg-container">
                    <div className="bg-red bg-item"></div>
                    <div className="bgFont-red bgFont">
                      <div>朱砂红</div>
                      <div>喜庆、热闹</div>
                    </div>
                  </div>
                </Radio>
                <Radio value={1}>
                  <div className="bg-container">
                    <div className="bg-blue bg-item"></div>
                    <div className="bgFont-blue bgFont">
                      <div>天青蓝</div>
                      <div>稳重、严谨</div>
                    </div>
                  </div>
                </Radio>
                <Radio value={2}>
                  <div className="bg-container">
                    <div className="bg-orange bg-item"></div>
                    <div className="bgFont-orange bgFont">
                      <div>热带橙</div>
                      <div>欢快、动感</div>
                    </div>
                  </div>
                </Radio>
                <Radio value={3}>
                  <div className="bg-container">
                    <div className="bg-red2 bg-item"></div>
                    <div className="bgFont-red2 bgFont">
                      <div>玫瑰红</div>
                      <div>梦幻、典雅</div>
                    </div>
                  </div>
                </Radio>
                <Radio value={4}>
                  <div className="bg-container">
                    <div className="bg-purple bg-item"></div>
                    <div className="bgFont-purple bgFont">
                      <div>落霞紫</div>
                      <div>高雅、时尚</div>
                    </div>
                  </div>
                </Radio>
              </Radio.Group></div>
          </div>
          <div className="submit-button">
            <Button size="large" onClick={submitSelect} type="primary">确认</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TalentHome
