import {Button, Input, message, Modal, Radio, Space} from "antd"
import {useState,useEffect} from "react";
import { isatty } from "tty";
import Request, {EHostType} from "../../../../../../utils/Request";

const { TextArea } = Input;

interface IProps{
    item:any,
    onAuditSuccess:any
}

export const TaskAudit = (props:IProps) => {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState(1);
    const [reason, setReason] = useState("");
     const [isAudit, setAudit] = useState<boolean|undefined>(false);



    const doSubmit =()=>{
        if(value==2&&!reason){
            message.error('请输入不通过原因')
        }
        let host:any=sessionStorage.getItem("host");
        let url = `/mgr/ddlk/consumertask/doAudit`;
        Request.jsonPost(EHostType.DDlk, url,{
            id:props.item.id,
            adviserUserId:props.item.adviserReferSellerId,
            isApproved:value==1?true:false,
            notApprovedReason:value==1?undefined:reason
        }).then(res=>{
            if(res?.data){
                message.success('操作成功');
                props.onAuditSuccess();
                // this.props.parent.getList({});
                setVisible(false);
            }
        })
    }

    useEffect(() => {
        setAudit(sessionStorage.getItem("functionLimits")?.includes("a5"))
      })

    return (
        <>
            {
                isAudit ? <Button type='link' onClick={() => { setVisible(true) }} style={{marginLeft:"-16px"}}>任务审核</Button>:""
            }
            <Modal visible={visible} onCancel={() => { setVisible(false) }} title="任务审核" footer={null} destroyOnClose>
                <Space direction="vertical">
                    {/* <Alert message="视频审核通过且达标后，奖励才可发布，否则奖励不予发放" type="warning" /> */}
                    <Radio.Group onChange={(e: any) => { setValue(e.target.value) }} value={value} style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Space direction="vertical">
                            <Radio value={1}>审核通过</Radio>
                            <Radio value={2}>审核驳回</Radio>
                        </Space>
                    </Radio.Group>
                    {value==2&&<TextArea placeholder="请输入驳回原因" value={reason} style={{width:'450px'}} onChange={(e:any)=>{ setReason(e.target.value) }}/>}
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                            <Button type="primary" style={{width:'100px'}} onClick={()=>{ doSubmit() }}>提交</Button>
                    </div>
                </Space>

            </Modal>
        </>

    )
}
